import React, { useEffect, useState } from "react";
import styles from './modalManagementLead.module.css'
import Modal from '../index';
import Input from '../../Form/Input';
import FormGroup from "../../Form/FormGroup";
import Button from "../../Form/Button";
import Select from "../../Form/Select";
import FormGroupInline from '../../Form/FormGroupInline';
import { RiListSettingsLine } from 'react-icons/ri';
import { useAuth } from "../../../contexts/authContext";
import api from "../../../services/api";
import { toast } from "react-toastify";
import DatePicker from "../../Form/DatePicker";
import Loading from './../../../pages/layouts/Loading';
import Textarea from './../../Form/Textarea';
import { useData } from "../../../contexts/dataContext";
import { getCampaignsByOrigins } from "../../../schemas/Campaign";
import { getStages } from "../../../schemas/Stage";

const newStatus = [
    { id: "operation", value: "Lead em Negociação" },
    { id: "discarted", value: "Lead Descartado" },
]

const optionsAction = [
    { id: "update", value: "Atualizar os Leads" },
    { id: "delete", value: "Excluir os Leads" },
]

const ModalManagementLead = ({ closeModal }) => {
    const [step, setStep] = useState(1)
    const [stages, setStages] = useState([])
    const [stagesConfig, setStagesConfig] = useState([])
    const [usersConfig, setUsersConfig] = useState([])
    const [filters, setFilters] = useState({})
    const [newConfig, setNewConfig] = useState({})
    const [countLeads, setCountLeads] = useState(0)
    const [loading, setLoading] = useState(false)

    const { funnels, teams, users, products, origins, campaigns, typeDate, status, flagsByoptions, reasonsDiscard } = useData()

    const { permissions } = useAuth()

    useEffect(() => {
        changeStages(filters.funnel_id)
    }, [filters.funnel_id])

    useEffect(() => {
        changeStages(newConfig.funnel_id, true)
    }, [newConfig.funnel_id])

    useEffect(() => {
        getCampaignsByOrigins(filters.origins)
    }, [filters.origins])

    useEffect(() => {
        setUsersConfig([{ id: "auto", value: "Rodízio automático" }, ...users])
    }, [users])

    const handleChange = (name, value) => {
        setFilters(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangeConfig = (name, value) => {
        setNewConfig(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const changeStages = async (funnel_id, config = false) => {
        const stages = await getStages(funnel_id)
        if (config) {
            setStagesConfig(stages?.map(stage => { return { id: stage._id, value: stage.name } }) || [])
        } else {
            setStages(stages?.map(stage => { return { id: stage._id, value: stage.name } }) || [])
        }
    }

    const handleChangePeriod = (dateStart, dateEnd) => {
        handleChange("dateStart", dateStart)
        handleChange("dateEnd", dateEnd)
    }

    const nextStep = () => {
        if (!filters?.funnel_id) {
            return toast.error("O funil é obrigatório!")
        }
        if (permissions?.crm > 1 && !filters?.action) {
            return toast.error("A ação é obrigatória!")
        }
        getCountLeadsByFilters().then(() => {
            setStep(2)
        })
    }

    const returnStep = () => {
        setStep(1)
    }

    const getCountLeadsByFilters = async () => {
        try {
            setLoading(true)
            const queryString = new URLSearchParams(filters)?.toString()
            const response = await api.get(`/leads/count?${queryString}`)
            setCountLeads(response?.data?.data || 0)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = () => {
        if (permissions?.crm > 1 && filters?.action === "delete") {
            handleDelete()
        } else {
            handleUpdate()
        }
    }

    const handleDelete = () => {
        setLoading(true)
        const queryString = new URLSearchParams(filters)?.toString()
        api.delete(`/leads?${queryString}`).then(response => {
            toast.success(response?.data?.message)
            closeModal()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdate = () => {
        setLoading(true)
        api.post("/leads/management", { filters, newConfig }).then(response => {
            toast.success(response?.data?.message)
            closeModal()
            setLoading(false)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }

    return (
        <Modal
            icon={<RiListSettingsLine />}
            title="Gerenciar base de leads"
            description="Realize ações em sua base de leads, aplicando alterações de acordo com as suas necessidades!"
            closeModal={closeModal}
        >
            <div className={styles.body}>
                {step === 1 &&
                    <>
                        <FormGroup>
                            <span className={styles.bold}>Para começar, selecione os filtros para determinar quais leads serão modificados!</span>
                        </FormGroup>
                        <FormGroup labelText="Funil">
                            <Select name="Funil" placeholder="Pesquise um funil" options={funnels || []} selected={filters.funnel_id || []} onChange={(value) => handleChange("funnel_id", value)} multiple={false} />
                        </FormGroup>
                        <FormGroup labelText="Etapa">
                            <Select name="Etapa" placeholder="Pesquise uma etapa" options={stages || []} selected={filters.stages || []} onChange={(value) => handleChange("stages", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Busca por texto">
                            <Input type="search" placeholder="Buscar" value={filters.search || ""} onChange={(e) => handleChange("search", e.target.value)} />
                        </FormGroup>
                        <FormGroup labelText="Período">
                            <DatePicker name="Período" dateStart={filters.dateStart || ""} dateEnd={filters.dateEnd || ""} onChange={(dateStart, dateEnd) => handleChangePeriod(dateStart, dateEnd)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Filtro por">
                            <Select name="Filtro por data" placeholder="Pesquise uma opção" options={typeDate || ""} selected={filters.typeDate || []} onChange={(value) => handleChange("typeDate", value)} multiple={false} />
                        </FormGroup>
                        <FormGroup labelText="Produto">
                            <Select name="Produtos" placeholder="Pesquise um produto" options={products || []} selected={filters.products || []} onChange={(value) => handleChange("products", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Origem">
                            <Select name="Origens" placeholder="Pesquise uma origem" options={origins || []} selected={filters.origins || []} onChange={(value) => handleChange("origins", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Campanha">
                            <Select name="Campanhas" placeholder="Pesquise uma campanha" options={campaigns || []} selected={filters.campaigns || []} onChange={(value) => handleChange("campaigns", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Status">
                            <Select name="Status" placeholder="Pesquise um status" options={status || []} selected={filters.status || []} onChange={(value) => handleChange("status", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Etiquetas">
                            <Select name="Etiquetas" placeholder="Pesquise uma etiqueta" options={flagsByoptions || []} selected={filters.flags || []} onChange={(value) => handleChange("flags", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Equipe">
                            <Select name="Equipes" placeholder="Pesquise uma equipe" options={teams || []} selected={filters.teams || []} onChange={(value) => handleChange("teams", value)} multiple={true} />
                        </FormGroup>
                        {permissions?.crm > 1 &&
                            <>
                                <FormGroup labelText="Responsáveis">
                                    <Select name="Responsáveis" placeholder="Pesquise um responsável" options={users || []} selected={filters.users || []} onChange={(value) => handleChange("users", value)} multiple={true} />
                                </FormGroup>
                                <FormGroup labelText="Qual ação deseja realizar?">
                                    <Select name="Qual ação deseja realizar?" placeholder="Pesquise uma opção" options={optionsAction} selected={filters?.action} onChange={(value) => handleChange("action", value)} multiple={false} />
                                </FormGroup>
                            </>
                        }
                    </>
                }
                {step === 2 &&
                    <>
                        {permissions?.crm > 1 && filters?.action === "delete" ?
                            <>
                                <FormGroup>
                                    <h3>Tem certeza que deseja excluir os {countLeads} leads selecionados?</h3>
                                    <p>Todos os leads excluídos serão colocados na lixeira e permacerão lá por 30 dias. Após esse período não será mais possível restaurar um lead!</p>
                                    <p className={styles.bold}>Com base nos filtros selecionados {countLeads} leads serão excluídos. Para prosseguir clique em executar!</p>
                                </FormGroup>
                            </>
                            :
                            <>
                                <FormGroup>
                                    <h3>Tem certeza que deseja atualizar os {countLeads} leads selecionados?</h3>
                                    <p>Defina o que deve ser alterado nos leads, ou seja, quais informações serão modificadas. Todos os campos selecionados aqui serão aplicados aos leads selecionados!</p>
                                    <p>Ex: Só selecione um funil e uma etapa, caso queira editar o funil e a etapa dos leads selecionados. Só selecione uma etiqueta, se quiser aplicar essa etiqueta a todos os leads selecionados. Só selecione uma equipe se deseja alterar a aquipe dos leads selecionado. E assim por diante...</p>
                                    <p className={styles.bold}>Mas lembre-se, todos os {countLeads} leads sofrerão as alterações. Só prossiga se tiver certeza do que está fazendo!</p>
                                </FormGroup>
                                <FormGroup labelText="Funil">
                                    <Select name="Funil" placeholder="Pesquise um funil" options={funnels || []} selected={newConfig.funnel_id || []} onChange={(value) => handleChangeConfig("funnel_id", value)} multiple={false} />
                                </FormGroup>
                                <FormGroup labelText="Etapa">
                                    <Select name="Etapa" placeholder="Pesquise uma etapa" options={stagesConfig || []} selected={newConfig.stage_id || []} onChange={(value) => handleChangeConfig("stage_id", value)} multiple={false} />
                                </FormGroup>
                                <FormGroup labelText="Etiquetas">
                                    <Select name="Etiquetas" placeholder="Pesquise uma etiqueta" options={flagsByoptions || []} selected={newConfig.flags || []} onChange={(value) => handleChangeConfig("flags", value)} multiple={true} />
                                </FormGroup>
                                <FormGroup labelText="Equipe">
                                    <Select name="Equipes" placeholder="Pesquise uma equipe" options={teams || []} selected={newConfig.team_id || []} onChange={(value) => handleChangeConfig("team_id", value)} multiple={false} />
                                </FormGroup>
                                {permissions?.crm > 1 &&
                                    <FormGroup labelText="Responsáveis">
                                        <Select name="Responsáveis" placeholder="Pesquise um responsável" options={usersConfig || []} selected={newConfig.users || []} onChange={(value) => handleChangeConfig("users", value)} multiple={true} />
                                    </FormGroup>
                                }
                                <FormGroup labelText="Status">
                                    <Select name="Status" placeholder="Pesquise um status" options={newStatus || []} selected={newConfig.status || []} onChange={(value) => handleChangeConfig("status", value)} multiple={false} />
                                </FormGroup>
                                {newConfig?.status === "discarted" &&
                                    <>
                                        <FormGroup labelText="Motivo do Descarte">
                                            <Select name="Motivo do Descarte" placeholder="Pesquise um motivo de descarte" options={reasonsDiscard || []} selected={newConfig.reason_discard_id || []} onChange={(value) => handleChangeConfig("reason_discard_id", value)} multiple={false} />
                                        </FormGroup>
                                        <FormGroup labelText="Descrição do Descarte">
                                            <Textarea placeholder="Descrição do descarte" rows={3} value={newConfig.description_discard} onChange={(e) => handleChangeConfig("description_discard", e.target.value)} />
                                        </FormGroup>
                                    </>
                                }
                            </>
                        }
                        <span className={`${styles.bold} pink`}>Esta ação não poderá ser desfeita!</span>
                    </>
                }
                <FormGroupInline>
                    {step === 1 ?
                        <Button className='bg-pink' type="submit" onClick={nextStep}>Avançar</Button>
                        :
                        <>
                            <Button className='bg-purple' onClick={returnStep}>Voltar</Button>
                            <Button className='bg-pink' type="submit" onClick={handleSubmit}>Executar</Button>
                        </>
                    }
                </FormGroupInline>
            </div>
            {loading && <Loading fullPage={true} />}
        </Modal>
    )
}

export default ModalManagementLead