import React, { useEffect, useState } from "react"
import Table from "../../components/Table"
import styles from "./users.module.css"
import ModalUser from "../../components/Modal/ModalUser"
import api from "../../services/api"
import Button from "../../components/Form/Button"
import Input from "../../components/Form/Input"
import ModalDelete from "../../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import Select from "../../components/Form/Select"
import { toast } from "react-toastify"
import Loading from "./../layouts/Loading/index"
import { formatDate } from "../../utils/formatter"

const Users = () => {
    const [modalUser, setModalUser] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [roles, setRoles] = useState([])
    const [teams, setTeams] = useState([])
    const [users, setUsers] = useState([])
    const [user, setUser] = useState({})
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [filters, setFilters] = useState({})
    const [textSearch, setTextSearch] = useState('')
    const [userIdDelete, setUserIdDelete] = useState('')
    const [loading, setLoading] = useState(false)

    let isLoadingUser, isLoadingRole, isLoadingTeam = false

    const headers = [
        "#",
        "E-mail",
        "Cargo",
        "Status",
        "Equipes",
        "Leads",
        "Último Acesso",
        ""
    ]

    const optionsStatus = [
        { id: 1, value: "Ativo" },
        { id: 0, value: "Bloqueado" },
    ]

    useEffect(() => {
        getAllUsers()
        getRoles()
        getTeams()
        setFilters(previousValue => {
            return {
                ...previousValue,
                is_active: optionsStatus?.map(option => { return option.id })
            }
        })
    }, [])

    const getAllUsers = async () => {
        try {
            if (!isLoadingUser) {
                isLoadingUser = true
                setLoading(true)
                const response = await api.get("/users?getCount=1")
                const listUsers = response.data?.data
                setUsers(listUsers)
                setLoading(false)
                isLoadingUser = false
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
            setLoading(false)
            isLoadingUser = false
        }
    }

    const getRoles = async () => {
        try {
            if (!isLoadingRole) {
                isLoadingRole = true
                const response = await api.get("/roles")
                const roles = response?.data?.data
                setFilters(previousValue => {
                    return {
                        ...previousValue,
                        role_id: roles?.map(role => { return role._id })
                    }
                })
                setRoles(roles?.map(role => {
                    return {
                        id: role._id,
                        value: role.name
                    }
                }))
                isLoadingRole = false
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
            isLoadingRole = false
        }
    }

    const getTeams = async () => {
        try {
            if (!isLoadingTeam) {
                isLoadingTeam = true
                const response = await api.get("/teams")
                const teams = response?.data?.data || []
                setFilters(previousValue => {
                    return {
                        ...previousValue,
                        teams: teams?.map(team => { return team._id })
                    }
                })
                setTeams(teams?.map(team => {
                    return {
                        id: team._id,
                        value: team.name
                    }
                }))
                isLoadingTeam = false
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
            isLoadingTeam = false
        }
    }

    const getNameRoleById = (role_id) => {
        const [role] = roles?.filter(role => role.id === role_id)
        return role?.value ?? ""
    }

    const getUserById = async (id) => {
        try {
            setLoading(true)
            const response = await api.get(`/users/${id}`)
            const user = response.data?.data
            if (user) {
                user.user_id = user._id
            }
            setUser(user)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }

    const openModalUser = async (isNew = false, edit = false, id = undefined) => {
        setEdit(edit)
        setIsNew(isNew)
        setUser({})
        if (!isNew && id) {
            await getUserById(id)
        }
        setModalUser(true)
    }

    const closeModalUser = () => {
        setModalUser(false)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const handleSaveUser = (e, isNew, user) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewUser(user)
        } else {
            handleUpdateUser(user)
        }
    }

    const handleSaveNewUser = (user) => {
        setLoading(true)
        api.post("/users/account", user).then(response => {
            const newUser = response.data?.data
            if (newUser) {
                setUsers(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(
                        {
                            ...newUser,
                            teams: user.teams,
                            role_id: user.role_id,
                            accepted: false,
                            is_active: false,
                            leads: 0
                        }
                    )

                    return newValue
                })
            }
            toast.success(response.data.message)
            closeModalUser()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleResendInvite = (user_id) => {
        setLoading(true)
        api.post(`/users/${user_id}/resend-invite`).then(response => {
            toast.success(response.data.message)
            closeModalUser()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getDataNewUser = (newUser) => {
        const data = {
            id: newUser._id,
            name: newUser.name,
            value: [
                newUser.email,
                getNameRoleById(newUser.role_id),
                !newUser.accepted ? "Convite Enviado" : (newUser.is_active ? "Ativo" : "Bloqueado"),
                newUser?.teams?.length ?? "0",
                newUser.leads ?? "0",
                formatDate(newUser.last_access) ?? "-",
            ],
            actions: [
                {
                    label: "Editar",
                    onClick: (id) => openModalUser(false, true, id)
                },
                {
                    label: "Excluir",
                    onClick: handleDeleteUser
                }
            ]
        }
        if (!newUser.accepted) {
            data.actions.push(
                {
                    label: "Reenviar convite",
                    onClick: handleResendInvite
                }
            )
        }
        return data
    }

    const handleUpdateUser = (user) => {
        setLoading(true)
        api.patch(`/users/${user._id}`, user).then(response => {
            setUsers(previousValue => {
                return previousValue?.map(lastUser => {
                    if (lastUser._id === user._id) {
                        lastUser.role_id = user.role_id
                        lastUser.is_active = +user.is_active
                        lastUser.teams = user.teams
                    }
                    return lastUser
                })
            })
            toast.success(response.data.message)
            closeModalUser()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleDeleteUser = (id) => {
        setUserIdDelete(id)
        setModalDelete(true)
    }

    const deleteUser = (id) => {
        setLoading(true)
        api.delete(`/users/${id}`).then(response => {
            setUsers(previousValue => {
                return previousValue?.filter(lastUser => lastUser._id !== id)
            })
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getFilteredValues = () => {
        const values = users
        values.sort((a, b) => {
            if (a.email > b.email) {
                return 1
            } else if (a.email < b.email) {
                return - 1
            } else {
                return 0
            }
        })
        const filteredValues = values
            ?.filter(value => !value.role_id?.length || filters.role_id?.indexOf(value.role_id) !== -1)
            ?.filter(value => filters.teams?.some(team => !value.teams?.length || value.teams?.indexOf(team) !== -1))
            ?.filter(value => filters.is_active?.indexOf(+value.is_active) !== -1)
            ?.map((user) => getDataNewUser(user))
        return textSearch && filteredValues ? filteredValues?.filter(row => {
            for (let i = 0; i < row.value.length; i++) {
                if (row?.value[i] && row.value[i]?.toString()?.toLowerCase()?.indexOf(textSearch?.toLowerCase()) !== -1) {
                    return true
                }
            }
            return false
        }) : filteredValues
    }

    const handleChangeFilters = (name, value) => {
        setFilters(previousValue => { return { ...previousValue, [name]: value } })
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Button className="bg-pink" onClick={() => openModalUser(true, true)}>Adicionar Usuário</Button>
            </div>
            <div className={styles.filters}>
                <Select multiple={true} name="Cargo" placeholder="Buscar..." options={roles} onChange={(value) => handleChangeFilters("role_id", value)} selected={filters.role_id ?? []} required={true} />
                <Select multiple={true} name="Equipe" placeholder="Buscar..." options={teams} onChange={(value) => handleChangeFilters("teams", value)} selected={filters.teams ?? []} required={true} />
                <Select multiple={true} name="Status" placeholder="Buscar..." options={optionsStatus} onChange={(value) => handleChangeFilters("is_active", value)} selected={filters.is_active ?? []} required={true} />
                <Input type="search" placeholder="Buscar..." value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhum usuário encontrado"
                onClick={(id) => openModalUser(false, false, id)}
                showIndex={true}
                showCount={true}
                count={getFilteredValues()?.length}
            />
            {modalUser &&
                <ModalUser
                    closeModal={closeModalUser}
                    onSubmit={handleSaveUser}
                    isNew={isNew}
                    editable={edit}
                    setEdit={setEdit}
                    value={user}
                    optionsRoles={roles}
                    optionsTeams={teams}
                    optionsStatus={optionsStatus}
                />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deleteUser}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de usuário"
                    description="Deseja realmente excluir o usuário?"
                    id={userIdDelete}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Users