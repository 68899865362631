import React, { useEffect, useState } from "react"
import styles from "./apiIntegration.module.css"
import Button from "../../components/Form/Button"
import Table from "../../components/Table"
import FormGroup from "../../components/Form/FormGroup"
import BreakForm from "../../components/Form/BreakForm"
import FormGroupInline from "../../components/Form/FormGroupInline"
import ModalDelete from './../../components/Modal/ModalDelete'
import ModalWebhook from "../../components/Modal/ModalWebhook"
import api from '../../services/api'
import { toast } from 'react-toastify'
import Loading from "../layouts/Loading"
import { MdOutlineDeleteOutline } from "react-icons/md"

const headersWebhook = [
    "#",
    "Identificação",
    "URL",
    "Ações",
    "Status",
    "",
]

const Webhook = () => {
    const [modalWebhook, setModalWebhook] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [webhooks, setWebhooks] = useState([])
    const [actions, setActions] = useState([])
    const [webhook, setWebhook] = useState({})
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [webhookIdDelete, setWebhookIdDelete] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAllWebhooks()
        getAllActions()
    }, [])

    const getAllWebhooks = () => {
        setLoading(true)
        api.get("/webhooks").then(response => {
            const webhooks = response?.data?.data
            setWebhooks(webhooks)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getAllActions = () => {
        setLoading(true)
        api.get("/webhooks/actions").then(response => {
            const actions = response?.data?.data || []
            setActions(actions.map(action => ({ id: action, value: action })))
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getWebhookById = async (webhook_id) => {
        try {
            const response = await api.get(`/webhooks/${webhook_id}`)
            const webhook = response.data?.data
            setWebhook(webhook)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }

    const openModalWebhook = async (isNew = false, edit = false, id = undefined) => {
        setEdit(edit)
        setIsNew(isNew)
        setWebhook({})
        if (!isNew && id) {
            await getWebhookById(id)
        }
        setModalWebhook(true)
    }

    const closeModalWebhook = () => {
        setModalWebhook(false)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const handleSaveWebhook = (e, isNew, webhook) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewWebhook(webhook)
        } else {
            handleUpdateWebhook(webhook)
        }
    }

    const handleSaveNewWebhook = (webhook) => {
        api.post("/webhooks", webhook).then(response => {
            const newWebhook = response.data?.data
            if (newWebhook) {
                setWebhooks(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(
                        newWebhook
                    )

                    return newValue
                })
            }
            toast.success(response.data.message)
            closeModalWebhook()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const handleUpdateWebhook = (webhook) => {
        api.patch(`/webhooks/${webhook._id}`, webhook).then(response => {
            setWebhooks(previousValue => {
                return previousValue?.map(lastWebhook => {
                    if (lastWebhook._id === webhook._id) {
                        lastWebhook = webhook
                    }
                    return lastWebhook
                })
            })
            toast.success(response.data.message)
            closeModalWebhook()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const handleDeleteWebhook = (webhook_id) => {
        setWebhookIdDelete(webhook_id)
        setModalDelete(true)
    }

    const deleteWebhook = (webhook_id) => {
        api.delete(`/webhooks/${webhook_id}`).then(response => {
            setWebhooks(previousValue => {
                return previousValue?.filter(lastWebhook => lastWebhook._id !== webhook_id)
            })
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getFilteredValuesWebhook = () => {
        const values = webhooks
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })
        const filteredValues = values?.map((webhook) => getDataNewWebhook(webhook))
        return filteredValues
    }

    const getDataNewWebhook = (newWebhook) => {
        const data = {
            id: newWebhook._id,
            value: [
                newWebhook?.name || "-",
                newWebhook?.url || "-",
                newWebhook?.actions?.join(", ") || "-",
                newWebhook?.is_active ? "Ativo" : "Inativo",
            ],
            actions: [
                {
                    label: "Editar",
                    onClick: (id) => openModalWebhook(false, true, id)
                },
                {
                    label: "Excluir",
                    onClick: handleDeleteWebhook
                }
            ]
        }
        return data
    }

    return (
        <>
            <div className={styles.container}>
                <FormGroup>
                    <FormGroupInline>
                        <div className={styles.header}>
                            <BreakForm title="Webhooks" showAction={false} />
                            <span>Os webhooks permitem receber notificações em uma URL específica sempre que uma das ações selecionadas ocorrer</span>
                        </div>
                        <Button className="bg-pink" onClick={() => openModalWebhook(true, true)}>Novo Webhook</Button>
                    </FormGroupInline>
                    <Table
                        headers={headersWebhook}
                        values={getFilteredValuesWebhook()}
                        messageNotRegisters="Nenhum registro encontrado"
                        onClick={(id) => openModalWebhook(false, false, id)}
                        showIndex={true}
                    />
                </FormGroup>
            </div>
            {modalWebhook &&
                <ModalWebhook
                    isNew={isNew}
                    setEdit={setEdit}
                    value={webhook}
                    actions={actions}
                    editable={edit}
                    onSubmit={handleSaveWebhook}
                    closeModal={closeModalWebhook} />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deleteWebhook}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de webhook"
                    description="Deseja realmente excluir o webhook?"
                    id={webhookIdDelete}
                />
            }
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default Webhook