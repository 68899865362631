import React, { useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { FiUsers } from "react-icons/fi";
import FormGroupInline from '../Form/FormGroupInline';
import { FaPencilAlt } from "react-icons/fa";

const ModalApiDomain = ({ isNew = false, value = {}, editable = false, setEdit, onSubmit, closeModal }) => {
    const [domain, setDomain] = useState(value)

    const handleChangeValue = (name, value) => {
        setDomain(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setDomain(value)
        }
    }

    return (
        <Modal
            icon={<FiUsers />}
            title={isNew ? "Cadastro de novo domínio" : (editable ? `Editando a equipe: ${domain.name}` : `Visualizando: ${domain.name}`)}
            description={isNew ? "Os domínio adicionados terão permissão para enviar leads para a sua conta" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, domain)}>
                <FormGroupInline>
                    <FormGroup labelText="Identificação">
                        {editable ?
                            <Input type="text" placeholder="Identificação do domínio" value={domain.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                            :
                            <span>{domain.name}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <FormGroup labelText="Domínio">
                    {isNew ?
                        <Input type="text" placeholder="example.com" value={domain.domain ?? ""} onChange={(e) => handleChangeValue("domain", e.target.value)} />
                        :
                        <span>{domain.domain}</span>
                    }
                </FormGroup>
                {!editable &&
                    <FormGroup labelText="Script">
                        <span>Adicione esse script antes do fechamento da tag "Body" nas páginas que contenham formulários.</span>
                        <pre style={{ wordBreak: 'break-all', textWrap: "pretty", fontWeight: 600, fontStyle: "italic" }}>
                            {domain?.script || ""}
                        </pre>
                    </FormGroup>
                }
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalApiDomain