import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useWebSocketContext } from "./webSocketContext"
import { formatDate } from "../utils/formatter"
import { openLinkExternal, showNotification } from "../utils/utils"

const HeaderContext = createContext()

const HeaderProvider = ({ children }) => {
    const [title, setTitle] = useState()
    const [notifications, setNotifications] = useState([])
    const [, setScheduleTasks] = useState([])
    const [showMenu, setShowMenu] = useState(false)
    const timersRef = useRef({})

    const { lastJsonMessage } = useWebSocketContext()

    useEffect(() => {
        if (notifications.length) {
            setTasks()
        }
    }, [notifications])

    const setTasks = () => {
        notifications.forEach(notification => {
            if (notification?.type === "task_schedule") {
                setScheduleTasks(previousValue => {
                    const newValue = [...previousValue]
                    const taskExists = newValue.some(task => task === notification?.id)
                    if (!taskExists) {
                        addConfigNewTask(notification?.data, notification?.message)
                        newValue.push(notification?.id)
                    }
                    return newValue
                })
            }
        })
    }

    useEffect(() => {
        if (lastJsonMessage) {
            updateDataByMessageWebSocket(lastJsonMessage)
        }
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "IMPORT_LEAD_FORM") {
            const form_id = message?.data?.form_id
            removeNotification(form_id)
        } else if (action === "TASK_SCHEDULE") {
            const task = message?.data?.task
            const description = `A tarefa: '${task?.title || "-"}' do lead '${task?.lead_name || "-"}' está agendada para ${formatDate(task?.scheduled_date) || "-"}`
            const openLinkModalLead = () => openLinkExternal(task?.link)
            addNewNotification(task?._id, "task_schedule", description, task, openLinkModalLead)
        } else if (action === "UPDATE_TASK_LEAD") {
            const task = message?.data?.data
            if (task?.finished || task?.canceled) {
                removeNotification(task?._id)
            }
        }
    }

    const addConfigNewTask = (task, description) => {
        const taskId = task?._id
        const differenceMs = (new Date(task?.scheduled_date) - new Date())

        if (timersRef.current[taskId]) {
            clearTimeout(timersRef.current[taskId].notifyTimer)
            clearTimeout(timersRef.current[taskId].removeTimer)
        }

        if (differenceMs > 0) {
            const timeToNotifyTask = setTimeout(() => {
                showNotification("Tarefa Agendada!", description, task?.link)
            }, differenceMs)

            const timeToRemoveTask = setTimeout(() => {
                removeNotification(taskId)
            }, differenceMs + 300000)
            timersRef.current[taskId] = {
                notifyTimer: timeToNotifyTask,
                removeTimer: timeToRemoveTask
            }
        }
    }

    const cancelTaskTimers = (taskId) => {
        if (timersRef.current[taskId]) {
            clearTimeout(timersRef.current[taskId].notifyTimer)
            clearTimeout(timersRef.current[taskId].removeTimer)
            delete timersRef.current[taskId]
        }
    }

    const addNewNotification = (id, type, message, data, onClick) => {
        setNotifications(previousValue => {
            const newValue = [...previousValue]
            const notificationExists = newValue.some(notification => notification.id === id)
            if (!notificationExists) {
                newValue.push({ id, type, message, data, onClick })
            } else {
                removeNotification(id)
                addNewNotification(id, type, message, data, onClick)
            }
            return newValue
        })
    }

    const removeNotification = (id) => {
        setNotifications(previousValue => previousValue?.filter(el => el.id !== id))
        setScheduleTasks(previousValue => previousValue?.filter(el => el !== id))
        cancelTaskTimers(id)
    }

    const handleToogleShowMenu = (e) => {
        e?.stopPropagation()
        setShowMenu(previousValue => !previousValue)
    }

    const handleHideMenu = (e) => {
        e?.stopPropagation()
        setShowMenu(false)
    }

    return (
        <HeaderContext.Provider value={{ title, setTitle, notifications, addConfigNewTask, addNewNotification, removeNotification, handleToogleShowMenu, handleHideMenu, showMenu }}>
            {children}
        </HeaderContext.Provider>
    )
}

const useHeader = () => {
    const context = useContext(HeaderContext)

    return context
}

export { HeaderProvider, useHeader }

export default HeaderContext