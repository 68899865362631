import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"
import ConfigAdditionalFields from "../../components/ConfigAdditionalFields"
import Loading from "../layouts/Loading"

const AdditionalFieldsSettings = ({ account_id }) => {
    const [additionalFields, setAdditionalFields] = useState([])
    const [additionalFieldsEditable, setAdditionalFieldsEditable] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (account_id) {
            getAllAdditionalFields()
        }
    }, [account_id])

    const getAllAdditionalFields = () => {
        setLoading(true)
        api.get("/additionalFields").then(response => {
            const listFields = response?.data?.data || [{}]
            setAdditionalFields(listFields)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateFields = (e, additionalFields) => {
        e.preventDefault()
        setLoading(true)
        api.patch("/additionalFields", { additionalFields }).then(response => {
            const newFields = response?.data?.data
            if (newFields) {
                setAdditionalFields(newFields)
            }
            handleToogleEditFields(e)
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleToogleEditFields = (e) => {
        e.preventDefault()
        setAdditionalFieldsEditable(previousValue => !previousValue)
    }

    return (
        <>
            <ConfigAdditionalFields
                value={additionalFields}
                onSubmit={handleUpdateFields}
                onCancel={handleToogleEditFields}
                editable={additionalFieldsEditable}
                setEdit={handleToogleEditFields}
            />
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default AdditionalFieldsSettings