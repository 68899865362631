import React, { useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import Select from '../../Form/Select'
import { MdOutlineAttachMoney } from "react-icons/md"
import DatePicker from './../../Form/DatePicker/index'
import moment from "moment"

const ModalNewTransaction = ({ listTypes = [], listMethod = [], onSubmit = () => { }, closeModal = () => { } }) => {
    const [transaction, setTransaction] = useState({})

    const handleChangeValue = (name, value) => {
        setTransaction(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        onSubmit(transaction)
    }

    return (
        <Modal
            icon={<MdOutlineAttachMoney />}
            title={"Cadastro de novo registro financeiro"}
            description={"Preencha os campos abaixo e clique em salvar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="Tipo">
                    <Select name="Tipo" placeholder="Pesquise um tipo" options={listTypes || []} selected={transaction?.type ?? ""} onChange={(value) => handleChangeValue("type", value)} multiple={false} />
                </FormGroup>
                <FormGroup labelText="Valor">
                    <Input type="currency" placeholder="Valor" value={transaction?.value ?? ""} onChange={(e) => handleChangeValue("value", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Desconto">
                    <Input type="currency" placeholder="Desconto" value={transaction?.discount ?? ""} onChange={(e) => handleChangeValue("discount", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Método de Pagamento">
                    <Select name="Método de Pagamento" placeholder="Pesquise um método" options={listMethod || []} selected={transaction?.method ?? ""} onChange={(value) => handleChangeValue("method", value)} multiple={false} />
                </FormGroup>
                {transaction?.method === "BANK_SLIP" &&
                    <>
                        <FormGroup labelText="Data de Emissão">
                            <DatePicker name="DD/MM/YYYY" multiple={false} dateStart={transaction?.emit_date ?? ""} onChange={(value) => handleChangeValue("emit_date", value)} min={moment().format("YYYY-MM-DD")} />
                        </FormGroup>
                        <FormGroup labelText="Data de Vencimento">
                            <DatePicker name="DD/MM/YYYY" multiple={false} dateStart={transaction?.due_date ?? ""} onChange={(value) => handleChangeValue("due_date", value)} min={moment().format("YYYY-MM-DD")} />
                        </FormGroup>
                    </>
                }
                <FormGroup labelText="Observação">
                    <Input type="text" placeholder="Observação" value={transaction?.observation ?? ""} onChange={(e) => handleChangeValue("observation", e.target.value)} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalNewTransaction