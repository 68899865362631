import api from "../services/api"
import { toast } from "react-toastify"

const getAllFields = async () => {
    try {
        const response = await api.get(`/accounts/fields`)
        const allFields = response.data?.data
        return allFields
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getAllFields }