import api from "../services/api"

const getAllContacts = async () => {
    try {
        const response = await api.get(`/chat/contacts`)
        const contacts = response.data?.data || []
        return contacts
    } catch (err) {
        throw err
    }
}

const getAllFlags = async () => {
    try {
        const response = await api.get(`/chat/flags`)
        const flags = response.data?.data || []
        return flags
    } catch (err) {
        throw err
    }
}

const getAllLeads = async (conversation_id) => {
    try {
        const response = await api.get(`/chat/conversations/${conversation_id}/leads`)
        const leads = response.data?.data || []
        return leads
    } catch (err) {
        throw err
    }
}

const getConversationById = async (conversation_id) => {
    try {
        const response = await api.get(`/chat/conversations/${conversation_id}`)
        const conversation = response.data?.data || []
        return conversation
    } catch (err) {
        throw err
    }
}

const getAllParticipants = async (conversation_id) => {
    try {
        const response = await api.get(`/chat/conversations/${conversation_id}/participants`)
        const participants = response.data?.data || []
        return participants
    } catch (err) {
        throw err
    }
}

const listAssignTo = [
    { id: "teamAndRole", value: "Equipe e Cargo" },
    { id: "users", value: "Usuários" },
]

export { getAllContacts, getAllFlags, getAllLeads, getAllParticipants, getConversationById, listAssignTo }