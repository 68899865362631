import React, { useEffect, useState } from "react"
import Table from "../../components/Table"
import styles from "./accounts.module.css"
import api from "../../services/api"
import Button from "../../components/Form/Button"
import Input from "../../components/Form/Input"
import ModalAccount from "../../components/Modal/ModalAccount"
import ModalTransactions from "../../components/Modal/ModalTransactions"
import ModalDelete from "../../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { TbLock, TbLockOpen } from "react-icons/tb"
import { toast } from "react-toastify"
import { maskCurrency, maskDocument } from "../../utils/formatter"
import Loading from './../layouts/Loading/index'
import { useAuth } from "../../contexts/authContext"
import { getPropertyForItemInArrayById } from "../../utils/utils"
import Select from "../../components/Form/Select"
import ModalConfirm from "../../components/Modal/ModalConfirm"

const Accounts = () => {
    const [modalAccount, setModalAccount] = useState(false)
    const [modalTransactions, setModalTransactions] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [modalBlock, setModalBlock] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [modules, setModules] = useState([])
    const [plans, setPlans] = useState([])
    const [partnersAccounts, setPartnersAccounts] = useState([])
    const [account, setAccount] = useState({})
    const [transactions, setTransactions] = useState({})
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [filters, setFilters] = useState({})
    const [accountIdDelete, setAccountIdDelete] = useState('')
    const [accountIdBlock, setAccountIdBlock] = useState('')
    const [statusAccountBlock, setStatusAccountBlock] = useState('')
    const [accountIdTransaction, setAccountIdTransaction] = useState('')
    const [loading, setLoading] = useState(false)
    const [headers, setHeaders] = useState([])

    const { permissions } = useAuth()

    const optionsStatus = [
        { id: 1, value: "Ativo" },
        { id: 0, value: "Bloqueado" },
    ]

    const optionsIsPartner = [
        { id: true, value: "Sim" },
        { id: false, value: "Não" },
    ]

    useEffect(() => {
        getAllAccounts()
        getAllModules()
        getAllPlans()
    }, [])

    useEffect(() => {
        setIsAdmin(permissions?.is_admin > 0)
    }, [permissions?.is_admin])

    useEffect(() => {
        setHeaders(isAdmin ?
            [
                "#",
                "CNPJ",
                "Razão Social",
                "Nome da Conta",
                "Status",
                "É Conta Parceira?",
                "Conta Responsável",
                "Plano",
                "Responsável",
                "Última Atividade",
                "Saldo VoIP",
                ""
            ] : [
                "#",
                "CNPJ",
                "Razão Social",
                "Nome da Conta",
                "Status",
                "Plano",
                "Responsável",
                "Última Atividade",
                "Saldo VoIP",
                ""
            ]
        )
        if (isAdmin) {
            getAllPartners()
        }
    }, [isAdmin])

    const getAllAccounts = () => {
        setLoading(true)
        api.get("/accounts/all").then(response => {
            const listAccounts = response.data?.data
            setAccounts(listAccounts)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => setLoading(false))
    }

    const getAccountById = (id) => {
        return new Promise(resolve => {
            setLoading(true)
            api.get(`/accounts/${id}`).then(response => {
                const account = response.data?.data
                if (account) {
                    account.account_id = account._id
                }
                if (account.modules) {
                    account.modules = account.modules?.map(module => { return module.module_id })
                }
                setAccount(account)
                resolve(1)
            }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
        })
    }

    const getTransactionsAccountById = (id) => {
        return new Promise(resolve => {
            setLoading(true)
            api.get(`/accounts/${id}/transactions`).then(response => {
                const transactions = response.data?.data
                setTransactions(transactions)
                resolve(1)
            }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
        })
    }

    const getAllModules = () => {
        api.get(`/modules`).then(response => {
            const modules = response.data?.data || []
            setModules(modules?.map(module => { return { id: module._id, value: module.name } }))
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getAllPartners = () => {
        api.get(`/accounts/allPartners`).then(response => {
            const partners = response.data?.data || []
            setPartnersAccounts(partners?.map(partner => { return { id: partner._id, value: partner.name } }))
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getAllPlans = () => {
        api.get(`/plans`).then(response => {
            const plans = response.data?.data || []
            setPlans(plans)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const openModalAccount = async (isNew = false, edit = false, id = undefined) => {
        setEdit(edit)
        setIsNew(isNew)
        setAccount({})
        if (!isNew && id) {
            await getAccountById(id)
        }
        setModalAccount(true)
    }

    const closeModalAccount = () => {
        setModalAccount(false)
    }

    const openModalTransactions = async (id) => {
        await getTransactionsAccountById(id)
        setAccountIdTransaction(id)
        setModalTransactions(true)
    }

    const closeModalTransactions = () => {
        setModalTransactions(false)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const openModalBlock = async (id) => {
        setAccountIdBlock(id)
        setStatusAccountBlock(getStatusAccountById(id))
        setModalBlock(true)
    }

    const getStatusAccountById = (id) => {
        let status = false
        accounts.forEach(account => {
            if (account._id === id) {
                status = account.is_active
            }
        })
        return status
    }

    const closeModalBlock = () => {
        setModalBlock(false)
    }

    const handleSaveAccount = (e, isNew, account) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewAccount(account)
        } else {
            handleUpdateAccount(account)
        }
    }

    const handleSaveNewAccount = (account) => {
        setLoading(true)
        api.post("/accounts", account).then(response => {
            const newAccount = response.data?.data
            if (newAccount) {
                setAccounts(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(newAccount)

                    return newValue
                })
            }
            toast.success(response.data.message)
            closeModalAccount()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getDataNewAccount = (newAccount) => {
        const value = [
            maskDocument(newAccount.document),
            newAccount?.business_name?.substring(0, 30),
            newAccount?.name?.substring(0, 30),
            newAccount?.is_active ? "Ativo" : "Bloqueado",
        ]
        const actions = [
            {
                label: "Acessar a Conta",
                onClick: redirectToAccount
            },
            {
                label: "Editar",
                onClick: (id) => openModalAccount(false, true, id)
            },
            {
                label: newAccount?.is_active ? "Bloquear a Conta" : "Ativar a Conta",
                onClick: openModalBlock
            }
        ]
        if (isAdmin) {
            value.push(
                newAccount?.is_partner ? "Sim" : "Não",
                getPropertyForItemInArrayById(newAccount?.partner_of, accounts, "_id", "name")?.substring(0, 30) || "-"
            )
            actions.push(
                {
                    label: "Visualizar Financeiro",
                    onClick: openModalTransactions
                },
                {
                    label: "Excluir",
                    onClick: handleDeleteAccount
                }
            )
        }
        value.push(
            getPropertyForItemInArrayById(newAccount?.plan_id, plans, "_id", "name") || "-",
            newAccount?.owner_name?.substring(0, 30) || "-",
            newAccount?.last_activity || "-",
            maskCurrency(parseFloat(newAccount?.credits_voip).toFixed(2)),
        )
        return {
            id: newAccount._id,
            name: newAccount.name,
            partner_of: isAdmin ? newAccount?.partner_of : undefined,
            is_partner: isAdmin ? newAccount?.is_partner : undefined,
            plan_id: isAdmin ? newAccount?.plan_id : undefined,
            is_active: isAdmin ? newAccount?.is_active : undefined,
            value,
            actions
        }
    }

    const handleUpdateAccount = (account) => {
        setLoading(true)
        api.patch(`/accounts/${account._id}`, account).then(response => {
            setAccounts(previousValue => {
                return previousValue?.map(lastAccount => {
                    if (lastAccount._id === account._id) {
                        lastAccount.document = account?.company?.document ? account.company.document : lastAccount.document
                        lastAccount.business_name = account?.company?.business_name ? account.company.business_name : lastAccount.business_name
                        lastAccount.is_partner = account?.is_partner ? account.is_partner : lastAccount?.is_partner
                        lastAccount.partner_of = account?.partner_of ? account.partner_of : lastAccount?.partner_of
                        lastAccount.plan_id = account?.plan_details?.plan_id ? account.plan_details.plan_id : lastAccount?.plan_details?.plan_id
                    }
                    return lastAccount
                })
            })
            toast.success(response.data.message)
            closeModalAccount()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleDeleteAccount = (id) => {
        setAccountIdDelete(id)
        setModalDelete(true)
    }

    const handleToogleIsActiveAccount = () => {
        setLoading(true)
        api.patch(`/accounts/${accountIdBlock}/toogle-status`).then(response => {
            setAccounts(previousValue => {
                return previousValue?.map(lastAccount => {
                    const newValue = { ...lastAccount }
                    if (newValue._id === accountIdBlock) {
                        newValue.is_active = !lastAccount.is_active
                    }
                    return newValue
                })
            })
            closeModalBlock()
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const deleteAccount = (id) => {
        setLoading(true)
        api.delete(`/accounts/${id}`).then(response => {
            setAccounts(previousValue => {
                return previousValue?.filter(lastAccount => lastAccount._id !== id)
            })
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const redirectToAccount = (id) => {
        const link = document.createElement("a")
        link.href = `/${id}`
        link.setAttribute("target", "_blank")
        link.setAttribute("rel", "noopener noreferrer")
        link.click()
    }

    const getFilteredValues = () => {
        const values = accounts
        const textSearch = filters?.textSearch
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })
        let filteredValues = values?.map((account) => getDataNewAccount(account))
        filteredValues = isAdmin && filters?.partners?.length > 0 ? filteredValues?.filter(row => filters?.partners?.includes(row?.partner_of)) : filteredValues
        filteredValues = isAdmin && filters?.is_partner?.length > 0 ? filteredValues?.filter(row => filters?.is_partner?.includes(row?.is_partner)) : filteredValues
        filteredValues = filters?.is_active?.length > 0 ? filteredValues?.filter(row => filters?.is_active?.includes(Number(row?.is_active))) : filteredValues
        filteredValues = filters?.plans?.length > 0 ? filteredValues?.filter(row => filters?.plans?.includes(row?.plan_id)) : filteredValues
        filteredValues = textSearch && filteredValues ? filteredValues?.filter(row => {
            for (let i = 0; i < row.value.length; i++) {
                if (row?.value[i] && row.value[i]?.toString().toLowerCase()?.indexOf(textSearch?.toLowerCase()) !== -1) {
                    return true
                }
            }
            return false
        }) : filteredValues
        return filteredValues
    }

    const handleChangeValue = (name, value) => {
        setFilters(previousValue => { return { ...previousValue, [name]: value } })
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Button className="bg-pink" onClick={() => openModalAccount(true, true)}>Adicionar Conta</Button>
                {isAdmin &&
                    <>
                        <Select multiple={true} name="Parceiros" placeholder="Buscar..." options={partnersAccounts} onChange={(value) => handleChangeValue("partners", value)} selected={filters?.partners || []} />
                        <Select multiple={true} name="É Conta Parceira" placeholder="Buscar..." options={optionsIsPartner} onChange={(value) => handleChangeValue("is_partner", value)} selected={filters?.is_partner || []} />
                    </>
                }
                <Select multiple={true} name="Status" placeholder="Buscar..." options={optionsStatus} onChange={(value) => handleChangeValue("is_active", value)} selected={filters?.is_active || []} />
                <Select multiple={true} name="Planos" placeholder="Buscar..." options={plans?.map(plan => { return { id: plan?._id, value: plan?.name } }) || []} onChange={(value) => handleChangeValue("plans", value)} selected={filters?.plans || []} />
                <Input type="search" placeholder="Buscar..." value={filters?.textSearch || ''} onChange={(e) => handleChangeValue("textSearch", e.target.value)} />
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhuma conta encontrada"
                onClick={(id) => openModalAccount(false, false, id)}
                showIndex={true}
                showCount={true}
                count={getFilteredValues()?.length}
            />
            {modalAccount &&
                <ModalAccount
                    closeModal={closeModalAccount}
                    onSubmit={handleSaveAccount}
                    isNew={isNew}
                    editable={edit}
                    setEdit={setEdit}
                    value={account}
                    modules={modules}
                    plans={plans}
                    partnersAccounts={partnersAccounts}
                    optionsStatus={optionsStatus}
                />
            }
            {modalTransactions &&
                <ModalTransactions
                    account_id={accountIdTransaction}
                    closeModal={closeModalTransactions}
                    value={transactions}
                />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deleteAccount}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de conta"
                    description="Deseja realmente excluir a conta?"
                    id={accountIdDelete}
                />
            }
            {modalBlock &&
                <ModalConfirm
                    closeModal={closeModalBlock}
                    onSubmit={handleToogleIsActiveAccount}
                    icon={statusAccountBlock ? <TbLock /> : <TbLockOpen />}
                    title={statusAccountBlock ? "Bloqueio de Conta" : "Ativação de Conta"}
                    description={statusAccountBlock ? "Deseja realmente bloquear a conta?" : "Deseja realmente ativar a conta?"}
                    text={statusAccountBlock ? "Ao bloquear a conta, os recursos ficarão inacessíveis. Apenas os relatórios estarão disponíveis (desde que o usuário tenha permissão para realizar a extração de dados)!" : "Ao ativar a conta, todos os recursos serão reestabelecidos, e os usuários ativos poderão realizar o acesso!"}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Accounts