import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"
import ConfigProducts from "../../components/ConfigProducts"
import Loading from "../layouts/Loading"

const ProductsSettings = ({ account_id }) => {
    const [products, setProducts] = useState([])
    const [productsEditable, setProductsEditable] = useState(false)
    const [loading, setLoading] = useState(false)

    let isLoadingProduct = false

    useEffect(() => {
        if (account_id) {
            getAllProducts()
        }
    }, [account_id])

    const getAllProducts = () => {
        if (!isLoadingProduct) {
            isLoadingProduct = true
            setLoading(true)
            api.get("/products/group").then(response => {
                const listProducts = response?.data?.data || []
                setProducts(listProducts)
                isLoadingProduct = false
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                isLoadingProduct = false
            }).finally(() => setLoading(false))
        }
    }

    const handleUpdateProducts = (e, _, products) => {
        e.preventDefault()
        setLoading(true)
        api.patch("/products", { products }).then(response => {
            const newProducts = response?.data?.data
            if (newProducts) {
                setProducts(newProducts)
            }
            handleToogleEditProducts(e)
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleToogleEditProducts = (e) => {
        e.preventDefault()
        setProductsEditable(previousValue => !previousValue)
    }

    return (
        <>
            <ConfigProducts
                value={JSON.parse(JSON.stringify(products))}
                onSubmit={handleUpdateProducts}
                onCancel={handleToogleEditProducts}
                editable={productsEditable}
                setEdit={handleToogleEditProducts}
            />
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ProductsSettings