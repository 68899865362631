import React, { useEffect, useRef, useState } from "react"
import { toast } from 'react-toastify'
import { MdArrowBack, MdMoreVert, MdOutlineAttachment, MdOutlineClose, MdOutlineContactPage, MdOutlineDelete, MdOutlineEmojiEmotions, MdOutlineInsertDriveFile, MdOutlineKeyboardArrowDown, MdOutlineLocationOn, MdOutlineMicNone, MdOutlinePause, MdOutlinePhotoSizeSelectActual, MdOutlineSend } from "react-icons/md"
import styles from "./currentConversation.module.css"
import Emojis from "./Emojis"
import FilePreview from "./FilePreview"
import Message from "./Message"
import AudioPlayer from "../Form/AudioPlayer"
import Button from "../Form/Button"
import TextareaAutoResize from "../Form/TextareaAutoResize"
import Loading from "../../pages/layouts/Loading"
import ModalConfirm from './../Modal/ModalConfirm'
import ModalLocation from "../Modal/Chat/ModalLocation"
import ModalAssign from "../Modal/Chat/ModalAssign"
import ModalContact from "../Modal/Chat/ModalContact"
import ModalNewLead from '../Modal/ModalLead/ModalNewLead'
import { getPropertyForItemInArrayById, getUniqueId, resizeImage } from "../../utils/utils"
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import CircleLoading from "../CircleLoading"
import ImageWithFallback from "../ImageWithFallback"
import { useCrm } from "../../contexts/crmContext"
import { useWebSocketChatContext } from "../../contexts/webSocketChatContext"
import Aside from "./Elements/Aside"
import { saveNewLead } from "../../schemas/Lead"
import Flag from "../Flag"
import { getConversationById } from "../../schemas/Chat"

const ffmpeg = new FFmpeg({ log: true })

const CurrentConversation = ({ value = {}, messages = [], defaultPicture = "", backToHome = () => { }, getIconMessageByDirectionDeliveredAndRead = () => { }, sendMessage = () => { }, deleteMessage = () => { }, updateMessage = () => { }, readMessage = () => { }, executeFunctionByAction = () => { } }) => {
    const [conversation, setConversation] = useState(value)
    const [newMessage, setNewMessage] = useState({})
    const [isRecording, setIsRecording] = useState(false)
    const [isPaused, setIsPaused] = useState(false)
    const [recordingTime, setRecordingTime] = useState(0)
    const [audioChunks, setAudioChunks] = useState([])
    const [audioURL, setAudioURL] = useState(null)
    const [contentPreview, setContentPreview] = useState({})
    const [showEmotions, setShowEmotions] = useState(false)
    const [showAttachments, setShowAttachments] = useState(false)
    const [showPreviewFiles, setShowPreviewFiles] = useState(false)
    const [showMessageClosePreviewFiles, setShowMessageClosePreviewFiles] = useState(false)
    const [showModalLocation, setShowModalLocation] = useState(false)
    const [showModalContact, setShowModalContact] = useState(false)
    const [indexPreviewSelected, setIndexPreviewSelected] = useState(0)
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingLocal, setLoadingLocal] = useState(false)
    const [loadingAudio, setLoadingAudio] = useState(false)
    const [internalMessage, setInternalMessage] = useState(false)
    const [composing, setComposing] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [textComposing, setTextComposing] = useState("")
    const [messageByReply, setMessageByReply] = useState({})
    const [messageScrolled, setMessageScrolled] = useState()
    const [contact, setContact] = useState({})
    const [newLead, setNewLead] = useState({})
    const [showModalNewLead, setShowModalNewLead] = useState(false)
    const [showModalAssign, setShowModalAssign] = useState(false)

    const mediaRecorderRef = useRef(null)
    const intervalRef = useRef(null)
    const messagesEndRef = useRef(null)
    const messagesContainerRef = useRef(null)
    const fileInputRef = useRef(null)
    const textareaRef = useRef(null)
    const textareaPreviewRef = useRef(null)
    const asideRef = useRef(null)

    const { openModalLead } = useCrm()
    const { chatContacts, chatFlags, getContactByIdAndPlatform } = useWebSocketChatContext()

    useEffect(() => {
        const container = messagesContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    useEffect(() => {
        const handlePaste = (event) => {
            const items = event.clipboardData.items
            const files = []
            let typeMessage = "image"
            for (const item of items) {
                const file = item?.getAsFile()
                if (file) {
                    files.push(file)
                    if (file.type.indexOf("image") === -1) {
                        typeMessage = "document"
                    }
                }
            }

            if (files.length > 0) {
                setFilesByPreview(files, typeMessage)
            }
        }

        if (textareaRef.current) {
            textareaRef.current.addEventListener("paste", handlePaste)
        }

        return () => {
            if (textareaRef.current) {
                textareaRef.current.removeEventListener("paste", handlePaste)
            }
        }
    }, [])

    useEffect(() => {
        scrollToBottom()
        readAllMessages(messages)
    }, [messages])

    useEffect(() => {
        if (value?.contact_id && value?.inbox_id) {
            setConversation(value)
        }
    }, [value])

    useEffect(() => {
        setConversation(value)
    }, [value?._id])

    useEffect(() => {
        setIsScrolledToBottom(true)
        resetInputs()
        setShowDetails(false)
        if (conversation?._id && !conversation?.contact_id) {
            getAndUpdateConversation(conversation._id)
        }
    }, [conversation?._id])

    useEffect(() => {
        if (chatContacts?.length && conversation?.contact_id) {
            const contactData = getContactByIdAndPlatform(conversation.contact_id, conversation.platform)
            setContact(contactData)
        }
    }, [conversation?.contact_id, chatContacts])

    useEffect(() => {
        setComposing(conversation?.presences?.composing || false)
        setTextComposing(conversation?.presences?.textComposing || "")
    }, [conversation?.presences])

    useEffect(() => {
        setComposing(false)
        setTextComposing("")
    }, [conversation?.last_message_id])

    useEffect(() => {
        setFocusByTextareaPreview()
    }, [indexPreviewSelected])

    useEffect(() => {
        if (isPaused && audioChunks?.length > 0) {
            setLoadingAudio(true)
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' })
            convertAudioToMp3(audioBlob).then(audioMp3 => {
                const audioURL = URL.createObjectURL(audioMp3)
                setAudioURL(audioURL)
                handleChange('audio', audioMp3)
            }).finally(() => setLoadingAudio(false))
        }
    }, [isPaused, audioChunks])

    const getAndUpdateConversation = async (conversation_id) => {
        const data = await getConversationById(conversation_id)
        setConversation(data)
    }

    const handleScroll = () => {
        const container = messagesContainerRef.current
        if (container) {
            const isBottom = (container.scrollHeight - (container.scrollTop + container.clientHeight)) < 200
            setIsScrolledToBottom(isBottom)
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            const container = messagesContainerRef.current
            if (container) {
                container.scrollTop = container.scrollHeight
            }
        }, 200)
    }

    const scrollToMessage = (message_id) => {
        const container = messagesContainerRef.current
        if (container) {
            const messageElement = container.querySelector(`[data-message-id="${message_id}"]`)
            if (messageElement) {
                const containerHeight = container.clientHeight
                const messageTop = messageElement.offsetTop
                const messageHeight = messageElement.clientHeight
                container.scrollTop = messageTop - (containerHeight / 2) + (messageHeight / 2)
                setScrolledMessageById(message_id)
            }
        }
    }

    const setScrolledMessageById = (message_id) => {
        setMessageScrolled(message_id)
        setTimeout(() => {
            setMessageScrolled(undefined)
        }, 1000)
    }

    const resetInputs = () => {
        setIsRecording(false)
        setIsPaused(false)
        setAudioChunks([])
        setNewMessage({})
        setContentPreview({})
        setShowAttachments(false)
        setShowPreviewFiles(false)
        setShowMessageClosePreviewFiles(false)
        setIndexPreviewSelected(0)
        setMessageByReply({})
        textareaRef.current?.focus()
    }

    const sortMessagesByDate = (a, b) => {
        if (a?.created_at > b?.created_at) {
            return 1
        } else if (a?.created_at < b?.created_at) {
            return -1
        } else {
            return 0
        }
    }

    const handleChange = (name, value) => {
        setNewMessage(previousValue => ({ ...previousValue, [name]: value }))
    }

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'Enter') {
            e.preventDefault()
            const textarea = e.target
            const start = textarea.selectionStart
            const end = textarea.selectionEnd

            const newValue = textarea.value.substring(0, start) + "\n" + textarea.value.substring(end)
            handleChange('text', newValue)
        } else if (e.key === 'Enter') {
            e.preventDefault()
            handleSendMessage()
        } else if (e.key === 'Escape' && showPreviewFiles) {
            e.preventDefault()
            handleOpenConfirmClosePreview()
        }
    }

    const handleStartRecording = async () => {
        setIsRecording(true)
        setIsPaused(false)
        setRecordingTime(0)
        setAudioURL(null)
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const mediaRecorder = new MediaRecorder(stream)
        mediaRecorderRef.current = mediaRecorder

        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                setAudioChunks(prev => [...prev, event.data])
            }
        }

        mediaRecorder.start()

        intervalRef.current = setInterval(() => {
            setRecordingTime(prevTime => prevTime + 1)
        }, 1000)
    }

    const handlePauseRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop()
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop())
            setIsPaused(true)
            clearInterval(intervalRef.current)
        }
    }

    const handleSendRecording = () => {
        const messageContent = { ...newMessage, type: "audio" }
        handleSendMessage(messageContent)
    }

    const handleDeleteRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop()
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop())
            clearInterval(intervalRef.current)
            setIsRecording(false)
            setIsPaused(false)
            setRecordingTime(0)
            setAudioURL(null)
            setAudioChunks([])
        }
    }

    const handleSendMessage = () => {
        const formattedMessages = formatMessagesBySend()
        formattedMessages?.forEach(message => {
            sendMessage(message)
        })
        resetInputs()
    }

    const formatMessagesBySend = () => {
        const defaultContent = getDefaultContentByMessage()
        const replyMessage = messageByReply?.message
        const quoted_message = replyMessage ?
            {
                message_id: replyMessage.message_id,
                type: replyMessage.type,
                content: replyMessage.content,
                contact_id: replyMessage.contact_id,
                user_id: replyMessage.user_id,
                direction: replyMessage.direction,
                internal: replyMessage.internal,
            }
            : undefined
        if (contentPreview?.originalFiles?.length > 0 && contentPreview?.type) {
            const messages = contentPreview?.originalFiles?.map(file => (
                {
                    type: contentPreview.type,
                    content: {
                        mimetype: file?.file?.type,
                        size: file?.file?.size,
                        name: file?.file?.name,
                        url: file?.file ? URL.createObjectURL(file.file) : "",
                        text: file?.text || "",
                        quoted_message
                    },
                    upload: {
                        file: file?.file
                    },
                    ...defaultContent
                }
            ))
            return messages
        } else if (newMessage?.text?.trim()) {
            return [
                {
                    type: "text",
                    content: {
                        text: newMessage?.text.trim(),
                        quoted_message
                    },
                    ...defaultContent
                }
            ]
        } else if (newMessage?.audio) {
            return [
                {
                    type: "audio",
                    content: {
                        mimetype: "audio/mp3",
                        url: audioURL,
                        quoted_message,
                        seconds: recordingTime,
                        ptt: true,
                    },
                    upload: {
                        file: newMessage.audio
                    },
                    ...defaultContent
                }
            ]
        } else if (newMessage?.location) {
            return [
                {
                    type: "location",
                    content: {
                        ...newMessage.location
                    },
                    ...defaultContent
                }
            ]
        } else if (newMessage?.contacts) {
            return [
                {
                    type: "contact",
                    content: {
                        contacts: newMessage.contacts,
                        quoted_message
                    },
                    ...defaultContent
                }
            ]
        }
    }

    const getDefaultContentByMessage = () => {
        return {
            inbox_id: conversation?.inbox_id,
            conversation_id: conversation?._id,
            contact_id: conversation?.contact_id,
            temp_id: getUniqueId(),
            internal: internalMessage,
        }
    }

    const convertAudioToMp3 = async (audioBlob) => {
        await ffmpeg.load()
        await ffmpeg.writeFile('input.ogg', await fetchFile(audioBlob))
        await ffmpeg.exec(['-i', 'input.ogg', 'output.mp3'])
        const data = await ffmpeg.readFile('output.mp3')

        const mp3Blob = new Blob([data.buffer], { type: 'audio/mp3' })
        return mp3Blob
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }

    const handleToogleEmotions = () => {
        setShowEmotions(previousValue => !previousValue)
    }

    const handleToogleAttachments = () => {
        setShowAttachments(previousValue => !previousValue)
    }

    const handleSelectFile = (accept = "*", typeMessage) => {
        fileInputRef.current.setAttribute("accept", accept)
        fileInputRef.current.setAttribute("typeMessage", typeMessage)
        fileInputRef.current.value = []
        fileInputRef.current.click()
    }

    const handleFileChange = async (event) => {
        const files = event.target.files
        const typeMessage = event.target.getAttribute("typeMessage")
        setFilesByPreview(files, typeMessage)
    }

    const setFilesByPreview = async (files, typeMessage) => {
        setLoadingLocal(true)
        try {
            if (files?.length > 0) {
                const arrayFiles = Array.from(files)
                const formattedFiles = arrayFiles.map((file, index) => {
                    const text = index === 0 ? newMessage?.text || '' : ''
                    return { file, text }
                })

                const validFiles = []
                const resizePromises = []
                let type = typeMessage

                if (typeMessage === "media") {
                    await Promise.all(formattedFiles.map(async (fileObj) => {
                        const fileType = fileObj.file.type

                        if (fileType.startsWith('image/')) {
                            const resizePromise = new Promise((resolve) => {
                                resizeImage(fileObj.file, 800, 600, (resizedBlob) => {
                                    const resizedFile = new File([resizedBlob], fileObj.file.name, { type: fileType })
                                    resolve({ ...fileObj, file: resizedFile })
                                })
                            })
                            resizePromises.push(resizePromise)
                            type = "image"
                        } else if (fileType.startsWith('video/')) {
                            const thumbnail = await getVideoThumbnail(fileObj?.file)
                            validFiles.push({ ...fileObj, thumbnail })
                            type = "video"
                        } else {
                            toast.error(`O formato do arquivo '${fileObj.file.name}' é incompatível e será ignorado.`)
                        }
                    }))

                    const resizedFiles = await Promise.all(resizePromises)
                    const allFiles = [...resizedFiles, ...validFiles]

                    if (allFiles.length > 0) {
                        setContentPreview({ type, files: allFiles, originalFiles: formattedFiles })
                        setIndexPreviewSelected(0)
                        setShowPreviewFiles(true)
                    } else {
                        toast.error("Nenhum arquivo válido foi carregado!")
                    }
                } else {
                    setContentPreview({ type, files: formattedFiles, originalFiles: formattedFiles })
                }
                setIndexPreviewSelected(0)
                setShowPreviewFiles(true)
            }
        } catch (err) {
            toast.error("Não foi possível carregar os arquivos!")
        } finally {
            setLoadingLocal(false)
        }
    }

    const addEmoji = (emoji, textarea, cbFunction = () => { }) => {
        if (textarea) {
            const startPos = textarea.selectionStart
            const endPos = textarea.selectionEnd
            const text = textarea.value || ""
            const newText = text.slice(0, startPos) + emoji + text.slice(endPos)
            cbFunction(newText)

            setTimeout(() => {
                textarea.focus()
                textarea.selectionStart = startPos + emoji.length
                textarea.selectionEnd = startPos + emoji.length
            }, 10)
        }
    }

    const handleChangePreview = (value, index) => {
        setContentPreview(previousValue => {
            const files = previousValue.files?.map((file, fileIndex) => {
                const newFile = { ...file }
                if (index === fileIndex) {
                    newFile.text = value
                }
                return newFile
            })
            const originalFiles = previousValue.originalFiles?.map((file, fileIndex) => {
                const newFile = { ...file }
                if (index === fileIndex) {
                    newFile.text = value
                }
                return newFile
            })
            return { ...previousValue, files, originalFiles }
        })
    }

    const removeFileByIndex = (index) => {
        setContentPreview(previousValue => {
            const files = previousValue.files?.filter((file, fileIndex) => fileIndex !== index)
            const originalFiles = previousValue.originalFiles?.filter((file, fileIndex) => fileIndex !== index)
            return { ...previousValue, files, originalFiles }
        })
        setFocusByTextareaPreview()
    }

    const handleOpenConfirmClosePreview = () => {
        setShowMessageClosePreviewFiles(true)
    }

    const handleCloseConfirmClosePreview = () => {
        setShowMessageClosePreviewFiles(false)
    }

    const handleClosePreviewFiles = () => {
        handleCloseConfirmClosePreview()
        setShowPreviewFiles(false)
        setContentPreview({})
        setIndexPreviewSelected(0)
    }

    const getVideoThumbnail = (file, timeInSeconds = 1) => {
        return new Promise((resolve) => {
            const video = document.createElement('video')
            video.src = URL.createObjectURL(file)
            video.currentTime = timeInSeconds
            video.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas')
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                const ctx = canvas.getContext('2d')
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                canvas.toBlob((blob) => {
                    resolve(URL.createObjectURL(blob))
                }, 'image/jpeg')
            })
        })
    }

    const getDataByPreviewFiles = () => {
        const { type, files } = contentPreview

        if (!Array.isArray(files) || !files.length) {
            return handleClosePreviewFiles()
        }

        const getFileByIndex = (files, indexPreviewSelected) => {
            const index = indexPreviewSelected < 0 ? 0 : (indexPreviewSelected > files?.length - 1 ? files?.length - 1 : indexPreviewSelected)
            if (index !== indexPreviewSelected) {
                setIndexPreviewSelected(index)
            }
            return files[index]?.file || ""
        }

        const currentFile = getFileByIndex(files, indexPreviewSelected)

        return (
            <>
                <div className={styles.previewFiles}>
                    <div className={styles.container}>
                        <div className={styles.selectedPreview}>
                            <FilePreview
                                file={currentFile}
                                type={type}
                                styles={styles}
                            />
                            <div className={styles.deleteFile} onClick={() => removeFileByIndex(indexPreviewSelected)}>
                                <MdOutlineDelete />
                            </div>
                            <div className={styles.groupText}>
                                <div className={styles.groupEmotions} onClick={handleToogleEmotions}>
                                    <Button className="action bg-pink"><MdOutlineEmojiEmotions /></Button>
                                    {showEmotions &&
                                        <>
                                            <div className={styles.emotions}>
                                                <Emojis
                                                    onClick={(emoji) => addEmoji(emoji, textareaPreviewRef.current, (newText) => handleChangePreview(newText, indexPreviewSelected))}
                                                />
                                            </div>
                                            <div className={styles.background}></div>
                                        </>
                                    }
                                </div>
                                <TextareaAutoResize
                                    elementRef={textareaPreviewRef}
                                    placeholder="Escreva sua mensagem..."
                                    value={files[indexPreviewSelected]?.text || ""}
                                    onChange={(e) => handleChangePreview(e.target.value, indexPreviewSelected)}
                                    onKeyDown={handleKeyDown}
                                    style={{
                                        resize: "none",
                                        border: "none",
                                    }}
                                    minRows={1}
                                    maxRows={1}
                                    autoFocus={true}
                                />
                                <Button className="action bg-pink" onClick={() => handleSendMessage()}><MdOutlineSend /></Button>
                            </div>
                        </div>
                        <div className={styles.groupMiniPreview}>
                            {files.map((file, index) => (
                                <div className={`${styles.miniPreview} ${index === indexPreviewSelected ? styles.active : ''}`} key={index} onClick={() => setIndexPreviewSelected(index)}>
                                    {type === "image" &&
                                        <img src={URL.createObjectURL(file?.file || "")} alt="Imagem" />
                                    }
                                    {type === "video" &&
                                        <img src={file?.thumbnail || ""} alt="Vídeo" />
                                    }
                                    {type === "document" &&
                                        <span>Doc {index < 9 ? '0' : ''}{index + 1}</span>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.background} onClick={handleOpenConfirmClosePreview}></div>
                </div>
            </>
        )
    }

    const setFocusByTextareaPreview = () => {
        textareaPreviewRef.current?.focus()
    }

    const handleOpenModalContact = () => {
        setShowModalContact(true)
    }

    const handleCloseModalContact = () => {
        setShowModalContact(false)
    }

    const handleOpenModalLocation = () => {
        setShowModalLocation(true)
    }

    const handleCloseModalLocation = () => {
        setShowModalLocation(false)
    }

    const handlePreviewLocation = (location) => {
        handleChange("location", location)
    }

    const readAllMessages = (messages) => {
        const existsMessagesNoRead = messages?.find(message => !message.read && message.direction !== "send")
        if (existsMessagesNoRead) {
            readMessage({ conversation_id: conversation?._id, inbox_id: conversation?.inbox_id })
        }
        readChat()
    }

    const readChat = (e) => {
        handleExecuteFunctionByAction(e, "READ_CHAT")
    }

    const replyMessage = (message, formattedContent) => {
        setMessageByReply({ message, formattedContent })
        textareaRef.current?.focus()
    }

    const closeReplyMessage = () => {
        setMessageByReply({})
    }

    const handleOpenDetails = () => {
        setShowDetails(true)
    }

    const handleCloseDetails = () => {
        setShowDetails(false)
    }

    const handleExecuteFunctionByAction = (e, action, data = undefined) => {
        e?.stopPropagation()
        if (action === "ADD_LEAD") {
            handleOpenModalNewLead()
        } else if (action === "ASSIGN_CHAT") {
            handleOpenModalAssign()
        } else {
            if (typeof conversation === "object" && Object.keys(conversation).length > 0) {
                const message = data ? { ...conversation, data } : conversation
                executeFunctionByAction(action, message)
            }
        }
    }

    const handleOpenModalNewLead = () => {
        const name = contact?.name
        const phone = conversation.platform === "whatsapp" ? contact?.identifier : ""
        setNewLead({ name, phone })
        setShowModalNewLead(true)
    }

    const handleCloseModalNewLead = () => {
        setShowModalNewLead(false)
    }

    const handleAddNewLead = async (e, open) => {
        e.preventDefault()
        handleSaveNewLead(newLead, open)
    }

    const handleSaveNewLead = async (newLead, open = false) => {
        try {
            setLoading(true)
            const data = await saveNewLead(newLead)
            if (data) {
                if (open) {
                    openModalLead(data._id)
                }
                if (asideRef?.current) {
                    asideRef.current.handleGetAllLeadsByConversation()
                }
                handleCloseModalNewLead()
            }
            return data
        } catch (err) {
        } finally { setLoading(false) }
    }

    const handleOpenModalAssign = () => {
        setShowModalAssign(true)
    }

    const handleCloseModalAssign = () => {
        setShowModalAssign(false)
    }

    const handleAssignConversation = (data) => {
        const action = "ASSIGN_CHAT"
        const formattedConversation = { ...conversation, data }
        executeFunctionByAction(action, formattedConversation)
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.topBar} onClick={handleOpenDetails}>
                    <div className={styles.profile}>
                        <div className={styles.return} onClick={backToHome}>
                            <MdArrowBack />
                        </div>
                        <ImageWithFallback
                            src={contact?.picture || defaultPicture}
                            alt="Imagem de Perfil"
                            functionError={() => <img src={defaultPicture} alt="Imagem de Perfil" />}
                        />
                        <div className={styles.profileName}>
                            <span>{contact?.name}</span>
                            {composing ?
                                <span className={styles.composing}>{textComposing}</span>
                                :
                                <div className={styles.groupFlags}>
                                    {conversation?.flags?.length > 0 && conversation.flags.map((flag, index) => (
                                        <Flag
                                            name={getPropertyForItemInArrayById(flag.chat_flag_id, chatFlags, "_id", "name")}
                                            color={getPropertyForItemInArrayById(flag.chat_flag_id, chatFlags, "_id", "color")}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    {!showDetails &&
                        <div className={styles.options}>
                            {conversation?.status === "finalized" ?
                                <Button className="bg-orange" onClick={(e) => handleExecuteFunctionByAction(e, "OPEN_CHAT")}>Reabrir atendimento</Button>
                                :
                                <Button className="bg-pink" onClick={(e) => handleExecuteFunctionByAction(e, "END_CHAT")}>Encerrar atendimento</Button>
                            }
                            <Button className="bg-green" onClick={(e) => handleExecuteFunctionByAction(e, "ASSIGN_CHAT")}>Atribuir atendimento</Button>
                            <MdMoreVert />
                        </div>
                    }
                </div>
                <div className={styles.currentConversation}>
                    <div className={styles.groupMessages} ref={messagesContainerRef}>
                        <div className={styles.messages}>
                            {messages?.length > 0 && messages?.sort(sortMessagesByDate).map((message, index) => (
                                <Message
                                    key={message?._id || index}
                                    message={message}
                                    platform={conversation?.platform}
                                    lastMessage={messages[index - 1]}
                                    defaultPicture={defaultPicture}
                                    deleteMessage={deleteMessage}
                                    updateMessage={updateMessage}
                                    replyMessage={replyMessage}
                                    getIconMessageByDirectionDeliveredAndRead={getIconMessageByDirectionDeliveredAndRead}
                                    scrollToMessage={scrollToMessage}
                                    messageScrolled={messageScrolled}
                                />
                            ))}
                            {messages?.length === 0 &&
                                <div className={styles.division}>
                                    <span>Conversa iniciada com {conversation?.contact?.name}</span>
                                </div>
                            }
                            {!isScrolledToBottom &&
                                <div className={styles.navigateToEnd} onClick={scrollToBottom}>
                                    <MdOutlineKeyboardArrowDown />
                                </div>
                            }
                            <div ref={messagesEndRef} />
                        </div>
                    </div>
                    {messageByReply?.formattedContent &&
                        <div className={styles.messageByReply}>
                            <div className={styles.content}>
                                {messageByReply.formattedContent}
                            </div>
                            <div className={styles.close} onClick={closeReplyMessage}>
                                <MdOutlineClose />
                            </div>
                        </div>
                    }
                    <div className={styles.groupSendMessage}>
                        <div className={styles.options}>
                            <div className={styles.groupOption}>
                                Mensagem interna?
                                <input type="checkbox" checked={internalMessage} onChange={(e) => setInternalMessage(e.target.checked)} />
                            </div>
                            {internalMessage && <span>Quando essa opção está ativa, as mensagens não são enviadas para os contatos da conversa, ficam visíveis apenas para a equipe interna!</span>}
                        </div>
                        {!isRecording && <>
                            <div className={styles.groupEmotions} onClick={handleToogleEmotions}>
                                <Button className="action bg-pink"><MdOutlineEmojiEmotions /></Button>
                                {showEmotions && !showPreviewFiles &&
                                    <>
                                        <div className={styles.emotions}>
                                            <Emojis
                                                onClick={(emoji) => addEmoji(emoji, textareaRef.current, (newText) => handleChange("text", newText))}
                                            />
                                        </div>
                                        <div className={styles.background}></div>
                                    </>
                                }
                            </div>
                            <div className={styles.groupAttachment} onClick={handleToogleAttachments}>
                                <Button className={`action bg-purple ${styles.attachmentIcon}`}><MdOutlineAttachment /></Button>
                                {showAttachments &&
                                    <>
                                        <div className={styles.attachment}>
                                            <div className={styles.option} onClick={() => handleSelectFile("image/*,video/*", "media")}>
                                                <MdOutlinePhotoSizeSelectActual />
                                                <span>Fotos e Vídeos</span>
                                            </div>
                                            <div className={styles.option} onClick={() => handleSelectFile("*", "document")}>
                                                <MdOutlineInsertDriveFile />
                                                <span>Documento</span>
                                            </div>
                                            <div className={styles.option} onClick={handleOpenModalContact}>
                                                <MdOutlineContactPage />
                                                <span>Contato</span>
                                            </div>
                                            <div className={styles.option} onClick={handleOpenModalLocation}>
                                                <MdOutlineLocationOn />
                                                <span>Localização</span>
                                            </div>
                                        </div>
                                        <div className={styles.background}></div>
                                    </>
                                }
                            </div>
                            <TextareaAutoResize
                                elementRef={textareaRef}
                                placeholder="Escreva sua mensagem..."
                                value={newMessage?.text || ""}
                                onChange={(e) => handleChange("text", e.target.value)}
                                onKeyDown={handleKeyDown}
                                style={{
                                    resize: "none",
                                    border: "none",
                                }}
                                minRows={1}
                                maxRows={5}
                                autoFocus={true}
                            />
                        </>}
                        {newMessage?.text?.length > 0 ?
                            <Button className="action bg-pink" onClick={handleSendMessage}><MdOutlineSend /></Button>
                            :
                            isRecording ?
                                <div className={`${styles.isRecording} ${isPaused ? styles.paused : ''}`}>
                                    {!loadingAudio && <Button className="action bg-purple" onClick={handleDeleteRecording}><MdOutlineDelete /></Button>}
                                    <div className={styles.recordingTime}>
                                        <div className={styles.iconRecording}></div>
                                        <span>{formatTime(recordingTime)}</span>
                                    </div>
                                    {isPaused ?
                                        <>
                                            <AudioPlayer
                                                src={audioURL}
                                                preload="metadata"
                                                style={{ padding: "0", maxHeight: "36px" }}
                                            />
                                            {loadingAudio ?
                                                <CircleLoading className={styles.loadingAudio} />
                                                :
                                                <Button className="action bg-pink" onClick={handleSendRecording}><MdOutlineSend /></Button>
                                            }
                                        </>
                                        :
                                        <Button className="action bg-purple" onClick={handlePauseRecording}><MdOutlinePause /></Button>
                                    }
                                </div>
                                :
                                <Button className="action bg-purple" onClick={handleStartRecording}><MdOutlineMicNone /></Button>
                        }
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            multiple={true}
                        />
                    </div>
                </div>
            </div>
            {showDetails &&
                <Aside
                    conversation={conversation}
                    contact={contact}
                    defaultPicture={defaultPicture}
                    handleClose={handleCloseDetails}
                    handleExecuteFunctionByAction={handleExecuteFunctionByAction}
                    ref={asideRef}
                />
            }
            {showPreviewFiles && getDataByPreviewFiles()}

            {showMessageClosePreviewFiles &&
                <ModalConfirm
                    closeModal={handleCloseConfirmClosePreview}
                    onSubmit={handleClosePreviewFiles}
                    icon={<MdOutlineDelete />}
                    title="Deseja descartar a mídia selecionada?"
                    description="A mensagem não será enviada, e a mídia será descartada se você fechar essa tela!"
                    text="Clique em cancelar para continuar editando a mídia."
                />
            }
            {showModalLocation &&
                <ModalLocation
                    latitude={newMessage?.location?.latitude}
                    longitude={newMessage?.location?.longitude}
                    onPreview={handlePreviewLocation}
                    onSubmit={handleSendMessage}
                    closeModal={handleCloseModalLocation}
                />
            }
            {showModalContact &&
                <ModalContact
                    onChange={handleChange}
                    onSubmit={handleSendMessage}
                    closeModal={handleCloseModalContact}
                />
            }
            {showModalNewLead &&
                <ModalNewLead
                    value={newLead}
                    selectFunnel={true}
                    onChange={setNewLead}
                    onSubmit={handleAddNewLead}
                    closeModal={handleCloseModalNewLead}
                />
            }
            {showModalAssign &&
                <ModalAssign
                    value={conversation?.owner}
                    inbox_id={conversation?.inbox_id}
                    closeModal={handleCloseModalAssign}
                    onSubmit={handleAssignConversation}
                />
            }
            {loadingLocal && <Loading />}
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default CurrentConversation