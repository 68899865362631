import React, { useEffect, useRef, useState } from "react"
import styles from './crm.module.css'
import Card from "./Card"
import Button from './../Form/Button'
import { HiPlus } from 'react-icons/hi'
import { useCrm } from "../../contexts/crmContext"
import { formatCurrency } from "../../utils/formatter"
import CircleLoading from "../CircleLoading"

const Stage = ({ stage, leads = [], onChangeStage, readOnly = false }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadInProgress, setIsLoadInProgress] = useState(false)
    const [shouldLoadNextPage, setShouldLoadNextPage] = useState(false)
    const [leadCount, setLeadCount] = useState(0)
    const [page, setPage] = useState(2)
    const stageRef = useRef()
    const { cardDrag, overCard, oldStage, openModalNewLead, getLeadsStageAndPage, filteredDataLead } = useCrm()

    useEffect(() => {
        const stageElement = stageRef.current

        if (stageElement) {
            stageElement.addEventListener('scroll', scrollHandler)
        }

        return () => {
            if (stageElement) {
                stageElement.removeEventListener('scroll', scrollHandler)
            }
        }
    }, [])

    useEffect(() => {
        setLeadCount(leads?.length || 0)
    }, [leads?.length])

    useEffect(() => {
        setPage(2)
        setIsLoading(false)
        setIsLoadInProgress(false)
        setShouldLoadNextPage(false)
        setTimeout(() => {
            scrollHandler()
        }, 500)
    }, [filteredDataLead])

    useEffect(() => {
        if (!readOnly && shouldLoadNextPage && !isLoading && !isLoadInProgress) {
            getLeadsProgress()
        }
    }, [readOnly, shouldLoadNextPage, isLoading, isLoadInProgress])

    const scrollHandler = () => {
        if (stageRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = stageRef.current
            if (!isLoading && (scrollTop + clientHeight >= (scrollHeight * 0.8))) {
                loadProgressLeads()
            }
        }
    }

    const loadProgressLeads = () => {
        if (!isLoadInProgress) {
            setShouldLoadNextPage(true)
        }
    }

    const getLeadsProgress = () => {
        const leadMax = stage?.leadCount || 0
        if (leadCount < leadMax) {
            setIsLoadInProgress(true)
            setIsLoading(true)
            getLeadsStageAndPage(stage._id, page).finally(() => {
                setIsLoading(false)
                setIsLoadInProgress(false)
                setShouldLoadNextPage(false)
                setPage(prevPage => prevPage + 1)
            })
        }
    }

    const allowDrop = (e) => {
        e.preventDefault()
    }

    const drop = (e) => {
        e.preventDefault()
        onChangeStage(cardDrag, overCard, oldStage, stage)
    }

    return (
        <div className={styles.stage}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <span>{stage.name} - ({stage.leadCount || 0})</span>
                    <span>{!readOnly ? formatCurrency(stage.totalAmount || 0) : 'R$ 0,00'}</span>
                </div>
                {!readOnly &&
                    <Button className="action bg-pink" onClick={() => openModalNewLead(stage._id)}>
                        <HiPlus />
                    </Button>
                }
            </div>
            <div className={styles.body} onDrop={drop} onDragOver={allowDrop} ref={stageRef}>
                {leads.map((lead) => (
                    <Card item={lead} key={lead._id} stageId={stage._id} />
                ))}
                {!readOnly && leads.length === 0 &&
                    <div className={styles.funnelClean}>
                        <span>Nenhum lead nesta etapa!</span>
                        <span className={styles.btnAdd} onClick={() => openModalNewLead(stage._id)}>Adicione um novo lead</span>
                    </div>
                }
                {readOnly &&
                    <div className={styles.funnelClean}>
                        <span>Esta é uma pré-visualização!</span>
                        <span className="pink">Personalize seu funil editando os campos do painel a esquerda</span>
                    </div>
                }
                {isLoading &&
                    <CircleLoading />
                }
            </div>
        </div>
    )
}

export default Stage