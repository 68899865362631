import React, { useEffect, useState } from "react";
import styles from "./address.module.css"
import FormGroup from '../Form/FormGroup';
import { MdOutlineEdit, MdOutlineDelete, MdOutlineCheck, MdOutlineClose, MdOutlineDeleteOutline } from "react-icons/md";
import ModalDelete from "../Modal/ModalDelete";
import Input from "../Form/Input";
import { maskZipCode } from "../../utils/formatter";
import { getAddressByZipCode } from "../../utils/utils";

const Address = ({ value = {}, handleRemove = () => { }, handleDelete = () => { }, handleUpdate = () => { } }) => {
    const [edit, setEdit] = useState(value?.isNew)
    const [address, setAddress] = useState(value)
    const [modalDelete, setModalDelete] = useState(false)

    useEffect(() => {
        setEdit(value?.isNew)
        setAddress(value)
    }, [value])

    const onChangeValues = (name, value) => {
        setAddress(previousValue => {
            return {
                ...previousValue,
                [name]: value
            }
        })
        if (name === "zip_code") {
            getAddressByZipCode(value).then(newAddress => {
                Object.keys(newAddress)?.forEach(key => {
                    onChangeValues(key, newAddress[key])
                })
            })
        }
    }

    const cancelEdit = () => {
        if (value?.isNew) {
            handleRemove(address._id)
        } else {
            setAddress(value)
            setEdit(false)
        }
    }

    const openModalDelete = () => {
        setModalDelete(true)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    return (
        <div className={styles.groupAddress}>
            <div className={styles.actions}>
                {!edit ?
                    <>
                        <MdOutlineEdit onClick={() => setEdit(true)} />
                        <MdOutlineDelete onClick={openModalDelete} />
                    </>
                    :
                    <>
                        <MdOutlineCheck onClick={() => handleUpdate(address)} />
                        <MdOutlineClose onClick={cancelEdit} />
                    </>
                }
            </div>
            <div className={styles.data}>
                <FormGroup labelText="CEP">
                    {edit ?
                        <Input type="zipCode" placeholder="Digite o CEP" value={address.zip_code || ''} onChange={(e) => onChangeValues("zip_code", e.target.value)} theme="noBorder" />
                        :
                        <span>{maskZipCode(address.zip_code) || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="País">
                    {edit ?
                        <Input placeholder="Digite o País" value={address.country || ''} onChange={(e) => onChangeValues("country", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.country || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Estado">
                    {edit ?
                        <Input placeholder="Digite o Estado" value={address.uf || ''} onChange={(e) => onChangeValues("uf", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.uf || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Cidade">
                    {edit ?
                        <Input placeholder="Digite a Cidade" value={address.city || ''} onChange={(e) => onChangeValues("city", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.city || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Bairro">
                    {edit ?
                        <Input placeholder="Digite o Bairro" value={address.district || ''} onChange={(e) => onChangeValues("district", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.district || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Logradouro">
                    {edit ?
                        <Input placeholder="Digite o Logradouro" value={address.road || ''} onChange={(e) => onChangeValues("road", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.road || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Número">
                    {edit ?
                        <Input placeholder="Digite o Número" value={address.number || ''} onChange={(e) => onChangeValues("number", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.number || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Complemento">
                    {edit ?
                        <Input placeholder="Digite o Complemento" value={address.complement || ''} onChange={(e) => onChangeValues("complement", e.target.value)} theme="noBorder" />
                        :
                        <span>{address.complement || '-'}</span>
                    }
                </FormGroup>
            </div>
            {
                modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={handleDelete}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de contato"
                    description="Deseja realmente excluir o contato?"
                    id={address._id}
                />
            }
        </div>
    )
}

export default Address