import React, { useState } from "react";
import styles from "./formGroupExpand.module.css"
import FormGroup from "../FormGroup";
import { MdKeyboardArrowRight } from "react-icons/md";

const FormGroupExpand = ({ startShow = false, inside = false, fullWidth = true, labelText, children }) => {
    const [showContent, setShowContent] = useState(startShow || false)

    const handleToogleShowContent = () => {
        setShowContent(previousValue => !previousValue)
    }

    return (
        <FormGroup
            inside={inside}
            fullWidth={fullWidth}
            labelText={labelText}
            rightContent={<MdKeyboardArrowRight className={`${styles.icon} ${showContent ? styles.show : ''}`} />}
            onClickHeader={handleToogleShowContent}
        >
            <div className={`${styles.formGroupExpand} ${showContent ? styles.show : ''}`}>
                {children}
            </div>
        </FormGroup>
    )
}

export default FormGroupExpand