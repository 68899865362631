import React, { useEffect, useState } from "react";
import api from "../../services/api";
import styles from './auth.module.css'

import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderAuth from "./HeaderAuth";
import FormGroup from "../../components/Form/FormGroup";
import { useAuth } from "../../contexts/authContext";

const ResetPassword = () => {
    const [user, setUser] = useState({})
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')

    const { resetPassword } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            verifyIfTokenIsValid(token)
        }
    }, [token])

    const verifyIfTokenIsValid = async (token) => {

        api.post("/users/reset-password/verify-token", {
            token
        }).catch(err => {
            navigate("/reset-password")
            toast.error(err?.response?.data?.message)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (await resetPassword(user, token)) {
            navigate("/login")
        }
    }

    return (
        <>
            <HeaderAuth />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.groupForm}>
                        <h1>{token ? "Defina uma nova senha" : "Redefina sua senha"}</h1>
                        <form onSubmit={handleSubmit}>
                            <FormGroup labelText="E-mail">
                                <Input
                                    type="email"
                                    placeholder="E-mail"
                                    value={user?.email || ""}
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                />
                            </FormGroup>
                            {token && <>
                                <FormGroup labelText="Defina uma senha">
                                    <Input
                                        type="password"
                                        placeholder="Senha"
                                        value={user?.password || ""}
                                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    />
                                </FormGroup>
                                <FormGroup labelText="Confirme sua senha">
                                    <Input
                                        type="password"
                                        placeholder="Confirmação de senha"
                                        value={user?.confirmPassword || ""}
                                        onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
                                    />
                                </FormGroup>
                            </>}
                            <Button className="bg-pink" disabled={token ? (!user?.email || !user?.password || !user?.confirmPassword) : !user?.email}>Redefinir Senha</Button>
                        </form>
                    </div>
                    <div className={styles.actions}>
                        <Link to="/login">Já tem uma conta?</Link>
                        <Link to="/politica-privacidade">Política de Privacidade</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword