import React from "react"
import styles from "./report.module.css"
import Button from "../../components/Form/Button"
import Table from "../../components/Table"
import { BsFunnel } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineStickyNote2 } from "react-icons/md"

const Report = () => {
    const { account_id } = useParams()
    const navigate = useNavigate()

    const headers = [
        "#",
        "Nome",
        "Descrição",
        "",
        "",
    ]

    const data = [
        {
            id: 1,
            value: [
                "Base de Leads",
                "Exporte a base de leads dos seus funis.",
                <BsFunnel className="icon-md" />,
                <Button className="bg-pink">Solicitar</Button>,
            ],
            path: `/${account_id}/reports/leads`,
        },
        {
            id: 2,
            value: [
                "Histórico de Anotações",
                "Exporte as anotações realizadas nos leads dos seus funis.",
                <MdOutlineStickyNote2 className="icon-md" />,
                <Button className="bg-pink">Solicitar</Button>,
            ],
            path: `/${account_id}/reports/notes`,
        },
    ]

    const handleNavigateToPage = (id) => {
        data?.forEach(el => {
            if (el.id === id) {
                navigate(el.path)
            }
        })
    }

    return (
        <div className={styles.container}>
            <Table
                headers={headers}
                values={data}
                onClick={(id) => handleNavigateToPage(id)}
                messageNotRegisters="Nenhum registro encontrado"
                showIndex={true}
            />
        </div>
    )
}

export default Report