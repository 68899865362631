import React from "react"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import CrmProvider from "../contexts/crmContext"
import DataProvider from "../contexts/dataContext"
import sharedRoutes from "./SharedRoutes"
import Accounts from "../pages/Accounts"
import ApiForm from "../pages/ApiIntegration/ApiForm"
import ApiIntegration from "../pages/ApiIntegration"
import ApiToken from "../pages/ApiIntegration/ApiToken"
import CallLog from "../pages/CallLog"
import Chat from "../pages/Chat"
import ChatIntegration from "../pages/ChatIntegration"
import Content from "../pages/layouts/Content"
import Crm from "../pages/Crm"
import Dashboard from "../pages/Dashboard"
import DashboardPartner from "../pages/Dashboard/DashboardPartner"
import ImportData from "../pages/ImportData"
import ImportLog from "../pages/ImportLog"
import Integration from "../pages/Integration"
import MetaIntegration from "../pages/MetaIntegration"
import MyAccount from "../pages/MyAccount"
import Notes from "../pages/Notes"
import Plans from "../pages/Plans"
import Profile from "../pages/Profile"
import Report from "../pages/Report"
import ReportDataLead from "../pages/ReportDataLead"
import ReportDataNote from "../pages/ReportDataNote"
import ReportLog from "../pages/ReportLog"
import Roles from "../pages/Roles"
import SelectAccount from "../pages/SelectAccount"
import SelectFunnel from "../pages/Crm/SelectFunnel"
import Settings from "../pages/Settings"
import Tasks from "../pages/Tasks"
import Teams from "../pages/Teams"
import Transactions from "../pages/Transactions"
import Trash from "../pages/Trash"
import TrashLead from "../pages/Trash/TrashLead"
import Users from "../pages/Users"
import Webhook from "../pages/Webhook"
import ImportError from "../pages/ImportError"
import { useAuth } from "../contexts/authContext"

const OtherRoutes = () => {
    const { verifyIfModuleIsActive } = useAuth()

    const listRoutes = [
        {
            path: "/:account_id/crm/:funnel_id",
            element: <Content namePermission="crm" title="CRM"><CrmProvider><Crm /></CrmProvider></Content>,
        },
        {
            path: "/:account_id/crm",
            element: <Content namePermission="crm" title="CRM"><CrmProvider><SelectFunnel /></CrmProvider></Content>,
        },
        {
            path: "/:account_id/dashboard",
            element: <Content namePermission="dashboard" title="Dashboard"><Dashboard /></Content>,
        },
        {
            path: "/:account_id/dashboard-partner",
            element: <Content namePermission="manage_accounts" title="Dashboard do Parceiro"><DashboardPartner /></Content>,
        },
        {
            path: "/:account_id/calls/log",
            element: <Content namePermission="call_log" title="Registro de Chamadas"><CrmProvider><CallLog /></CrmProvider></Content>,
        },
        {
            path: "/:account_id/tasks/log",
            element: <Content namePermission="tasks" title="Lista de Tarefas"><CrmProvider><Tasks /></CrmProvider></Content>,
        },
        {
            path: "/:account_id/notes/log",
            element: <Content namePermission="notes" title="Histórico de anotações"><CrmProvider><Notes /></CrmProvider></Content>,
        },
        {
            path: "/:account_id/profile",
            element: <Content title="Perfil"><Profile /></Content>,
        },
        {
            path: "/:account_id/import",
            element: <Content namePermission="import" title="Importar Dados"><ImportData /></Content>,
        },
        {
            path: "/:account_id/import/log",
            element: <Content namePermission="import" title="Histórico de Importações"><ImportLog /></Content>,
        },
        {
            path: "/:account_id/import/:import_id/error",
            element: <Content namePermission="import" title="Erros na Importação"><ImportError /></Content>,
        },
        {
            path: "/:account_id/myaccount",
            element: <Content namePermission="finance" title="Minha Conta"><MyAccount /></Content>,
        },
        {
            path: "/:account_id/reports/leads",
            element: <Content namePermission="reports" title="Relatório de Leads"><ReportDataLead /></Content>,
        },
        {
            path: "/:account_id/reports/notes",
            element: <Content namePermission="reports" title="Relatório de Anotações"><ReportDataNote /></Content>,
        },
        {
            path: "/:account_id/reports/log",
            element: <Content namePermission="reports" title="Histórico de Exportações"><ReportLog /></Content>,
        },
        {
            path: "/:account_id/reports",
            element: <Content namePermission="reports" title="Relatórios"><Report /></Content>,
        },
        {
            path: "/:account_id/settings",
            element: <Content namePermission="settings" title="Configurações da Conta"><Settings /></Content>,
        },
        {
            path: "/:account_id/integrations",
            element: <Content namePermission="integrations" title="Integrações"><Integration /></Content>,
        },
        // {
        //     path: "/:account_id/integrations/chat",
        //     element: <Content namePermission="integrations" title="Integração Chat"><ChatIntegration /></Content>,
        // },
        {
            path: "/:account_id/integrations/meta",
            element: <Content namePermission="integrations" title="Integração Meta Ads"><MetaIntegration /></Content>,
        },
        {
            path: "/:account_id/integrations/api",
            element: <Content namePermission="integrations" title="Integração via API"><ApiIntegration /></Content>,
        },
        {
            path: "/:account_id/integrations/api/tokens",
            element: <Content namePermission="integrations" title="Integração via API"><ApiToken /></Content>,
        },
        {
            path: "/:account_id/integrations/api/forms",
            element: <Content namePermission="integrations" title="Integração via API"><ApiForm /></Content>,
        },
        {
            path: "/:account_id/integrations/webhook",
            element: <Content namePermission="integrations" title="Integração via API"><Webhook /></Content>,
        },
        {
            path: "/:account_id/trashs",
            element: <Content namePermission="settings" title="Lixeira"><Trash /></Content>,
        },
        {
            path: "/:account_id/trashs/leads",
            element: <Content namePermission="settings" title="Leads na Lixeira"><TrashLead /></Content>,
        },
        {
            path: "/:account_id/roles",
            element: <Content namePermission="manage_roles" title="Gerenciar Cargos"><Roles /></Content>,
        },
        {
            path: "/:account_id/teams",
            element: <Content namePermission="manage_teams" title="Gerenciar Equipes"><Teams /></Content>,
        },
        {
            path: "/:account_id/users",
            element: <Content namePermission="manage_users" title="Gerenciar Usuários"><Users /></Content>,
        },
        {
            path: "/:account_id/accounts",
            element: <Content namePermission="manage_accounts" title="Gerenciar Contas"><Accounts /></Content>,
        },
        {
            path: "/:account_id/plans",
            element: <Content namePermission="is_admin" title="Gerenciar Planos"><Plans /></Content>,
        },
        // {
        //     path: "/:account_id/chat/:chat_id?",
        //     element: <Content namePermission="is_admin" title="Chat"><CrmProvider><Chat /></CrmProvider></Content>,
        // },
        {
            path: "/:account_id/transactions",
            element: <Content namePermission="is_admin" title="Controle Financeiro"><Transactions /></Content>,
        },
        {
            path: "/:account_id/*",
            element: <Content namePermission="crm" title="CRM"><CrmProvider><SelectFunnel /></CrmProvider></Content>,
        },
    ]

    if (verifyIfModuleIsActive("CHAT")) {
        listRoutes.push(
            {
                path: "/:account_id/integrations/chat",
                element: <Content namePermission="integrations" title="Integração Chat"><ChatIntegration /></Content>,
            },
            {
                path: "/:account_id/chat/:chat_id?",
                element: <Content namePermission="chat" title="Chat"><CrmProvider><Chat /></CrmProvider></Content>,
            },
        )
    }

    listRoutes.push(...sharedRoutes)

    listRoutes.push(
        {
            path: "/",
            element: <Content resetConfig={true}><SelectAccount /></Content>,
        },
        {
            path: "*",
            element: <Navigate to="/" />,
        }
    )
    const router = createBrowserRouter(listRoutes)

    return (
        <DataProvider>
            <RouterProvider router={router} />
        </DataProvider>
    )
}

export default OtherRoutes