import React, { useRef } from 'react'
import styles from "./sticker.module.css"

const Sticker = ({ url }) => {
    const imgRef = useRef(null)

    const handleMouseEnter = () => {
        const img = imgRef.current
        if (img) {
            img.src = ''
            img.src = url
        }
    }

    return (
        <div className={styles.container}>
            <img
                src={`${url}`}
                alt="Figurinha"
                className={`${styles.sticker} ${styles.background}`}
            />
            <img
                ref={imgRef}
                src={`${url}`}
                alt="Figurinha"
                className={styles.sticker}
                onMouseEnter={handleMouseEnter}
            />
        </div>
    )
}

export default Sticker
