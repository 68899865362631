import React, { useEffect, useState } from "react"
import styles from "./metaIntegration.module.css"
import Button from './../../components/Form/Button';
import api from './../../services/api';
import { toast } from 'react-toastify';
import { BsMeta } from 'react-icons/bs';
import { MdOutlineCheck } from "react-icons/md";
import { AiOutlineDisconnect } from "react-icons/ai";
import FormGroup from './../../components/Form/FormGroup';
import ImageWithFallback from './../../components/ImageWithFallback';
import ModalConfirm from "../../components/Modal/ModalConfirm";
import ModalMetaPage from "../../components/Modal/ModalMetaPage";
import Loading from './../layouts/Loading';
import { useData } from "../../contexts/dataContext";
import { getStages } from "../../schemas/Stage";

const MetaIntegration = () => {
    const [accessToken, setAccessToken] = useState("")
    const [linkedPages, setLinkedPages] = useState([])
    const [availablePages, setAvailablePages] = useState([])
    const [reAuth, setReAuth] = useState(false)
    const [showModalDisconect, setShowModalDisconect] = useState(false)
    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [showModalPage, setShowModalPage] = useState(false)
    const [pageDisconect, setPageDisconect] = useState({})
    const [pageConect, setPageConect] = useState({})
    const [pageConfig, setPageConfig] = useState({})
    const [stages, setStages] = useState([])
    const [editablePageConfig, setEditablePageConfig] = useState(false)
    const [hasLoadingAvailable, setHasLoadingAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { funnels, teams, users, flagsByoptions, fields } = useData()

    useEffect(() => {
        getAllLinkedPages()
    }, [])

    useEffect(() => {
        if (accessToken) {
            setReAuth(true)
        }
    }, [accessToken])

    const getAllLinkedPages = () => {
        setIsLoading(true)
        api.get("/integrations/meta/accounts/linked").then(response => {
            const pages = response?.data?.data || []
            setLinkedPages(pages)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const getAllNotLinkedPages = () => {
        setIsLoading(true)
        api.get("/integrations/meta/accounts/available").then(response => {
            const pages = response?.data?.data || []
            const listAvailablePages = pages?.filter(availablePage => !linkedPages.some(linkedPage => linkedPage.page_id === availablePage.id))
            setAvailablePages(listAvailablePages)
            setHasLoadingAvailable(true)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const handleSaveAccessToken = async (accessToken) => {
        try {
            setIsLoading(true)
            const response = await api.patch("/integrations/meta/accessToken", { accessToken })
            setIsLoading(false)
            return response
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
            if (err?.action && err.action === "REAUTH") {
                setReAuth(true)
            }
        }
    }

    const handleSaveCode = async (code) => {
        try {
            setIsLoading(true)
            const response = await api.patch("/integrations/meta/accessToken", { code })
            setIsLoading(false)
            return response
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
            if (err?.action && err.action === "REAUTH") {
                setReAuth(true)
            }
        }
    }

    const handleCloseModalConfirm = () => {
        setPageConect({})
        setShowModalConfirm(false)
    }

    const handleShowModalConfirm = (page) => {
        setPageConect(page)
        setShowModalConfirm(true)
    }

    const handleConectPage = (page) => {
        setIsLoading(true)
        api.post("/integrations/meta/connectPage", { pageId: page.id, pageName: page.name, pagePictureUrl: page.pictureUrl }).then(response => {
            const newPage = response?.data?.data
            if (newPage) {
                setLinkedPages(previousValue => {
                    return [...previousValue, newPage]
                })
                setAvailablePages(previousValue => previousValue?.filter(el => el.id !== newPage.page_id))
            }
            handleCloseModalConfirm()
            handleShowModalPage(newPage._id)
            setEditablePageConfig(true)
            setIsLoading(false)
            toast.success(response?.data?.message)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const handleCloseModalDisconect = () => {
        setPageDisconect({})
        setShowModalDisconect(false)
    }

    const handleShowModalDisconect = (page) => {
        setPageDisconect(page)
        setShowModalDisconect(true)
    }

    const handleDisconectPage = (page) => {
        setIsLoading(true)
        api.post("/integrations/meta/disconnectPage", { page_id: page._id }).then(response => {
            setLinkedPages(previousValue => previousValue?.filter(el => el._id !== page._id))
            handleCloseModalDisconect()
            setIsLoading(false)
            setHasLoadingAvailable(false)
            toast.success(response?.data?.message)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const handleShowModalPage = (pageId) => {
        setIsLoading(true)
        api.get(`/integrations/meta/accounts/${pageId}`).then(response => {
            const page = response?.data?.data || {}
            setPageConfig(page)
            setShowModalPage(true)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const handleCloseModalPage = () => {
        setPageConect({})
        setShowModalPage(false)
    }

    const handleChangePage = (pageId, data) => {
        setIsLoading(true)
        api.post(`/integrations/meta/accounts/${pageId}`, data).then(() => {
            setPageConfig(data)
            setEditablePageConfig(false)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const handleLogin = () => {
        const configLogin = reAuth ? { config_id: '1018380495980299', auth_type: "rerequest" } : { config_id: '1018380495980299' }
        window.FB.login(function (response) {
            if (response.status === "connected") {
                const newAccessToken = response?.authResponse?.accessToken
                setAccessToken(newAccessToken)
                handleSaveAccessToken(newAccessToken).then(() => {
                    getAllNotLinkedPages()
                })
            }
        }, configLogin)
    }

    const handleLoginBM = () => {
        const configLogin = {
            config_id: '288810993788488',
            response_type: 'code',
            override_default_response_type: true
        }
        if (reAuth) {
            configLogin.auth_type = "rerequest"
        }
        window.FB.login(function (response) {
            if (response.status === "connected") {
                const newCode = response?.authResponse?.code
                handleSaveCode(newCode).then(() => {
                    getAllNotLinkedPages()
                })
            }
        }, configLogin)
    }

    const sortLinkedPages = (a, b) => {
        if (a.page_name > b.page_name) {
            return 1
        } else if (a.page_name < b.page_name) {
            return -1
        } else {
            return 0
        }
    }

    const sortAvailablePages = (a, b) => {
        if (a.name > b.name) {
            return 1
        } else if (a.name < b.name) {
            return -1
        } else {
            return 0
        }
    }

    const changeStages = async (funnel_id) => {
        getStages(funnel_id).then(stages => {
            setStages(stages?.map(stage => { return { id: stage._id, value: stage.name } }))
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    return (
        <>
            <div className={styles.content}>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <BsMeta />
                        <span>Meta Ads</span>
                        <FormGroup labelText="Conectar uma nova página:">
                            <Button onClick={handleLoginBM} className="bg-purple">
                                Conta Empresarial
                            </Button>
                            <Button onClick={handleLogin} className="bg-purple">
                                Conta Pessoal
                            </Button>
                        </FormGroup>
                    </div>
                    <div className={styles.body}>
                        <FormGroup labelText="Páginas Conectadas">
                            {linkedPages.length > 0 && linkedPages.sort(sortLinkedPages).map((page, index) => (
                                <div className={styles.groupPage} key={index}>
                                    <div className={styles.left}>
                                        {page?.page_picture_url &&
                                            <ImageWithFallback
                                                src={page?.page_picture_url}
                                                alt="Imagem de Perfil da Página"
                                                functionError={() => { }}
                                            />
                                        }
                                    </div>
                                    <div className={styles.center}>
                                        <span>{page.page_name || "-"}</span>
                                        <Button className={styles.link} onClick={() => handleShowModalDisconect(page)}>Desconectar Página</Button>
                                    </div>
                                    <Button className="bg-purple" onClick={() => handleShowModalPage(page._id)}>
                                        Configurar
                                    </Button>
                                </div>
                            ))}
                            {linkedPages.length === 0 && <span>Nenhuma página conectada até o momento!</span>}
                        </FormGroup>
                        <FormGroup labelText="Páginas Disponíveis (Não Conectadas)">
                            {availablePages.length > 0 && availablePages.sort(sortAvailablePages).map((page, index) => (
                                <div className={styles.groupPage} key={index}>
                                    <div className={styles.left}>
                                        {page?.pictureUrl &&
                                            <ImageWithFallback
                                                src={page?.pictureUrl}
                                                alt="Imagem de Perfil da Página"
                                                functionError={() => { }}
                                            />
                                        }
                                    </div>
                                    <div className={styles.center}>
                                        <span>{page.name || "-"}</span>
                                    </div>
                                    <Button className="bg-pink" onClick={() => handleShowModalConfirm(page)}>
                                        Conectar
                                    </Button>
                                </div>
                            ))}
                            {hasLoadingAvailable && availablePages.length === 0 && <span>Nenhuma página disponível para conexão!</span>}
                            {!hasLoadingAvailable && <Button className="bg-pink" onClick={getAllNotLinkedPages}>Listar páginas disponíveis</Button>}
                        </FormGroup>
                    </div>
                </div>
            </div>
            {showModalConfirm &&
                <ModalConfirm
                    closeModal={handleCloseModalConfirm}
                    icon={<MdOutlineCheck />}
                    title="Deseja realmente conectar a página?"
                    description={`Ao conectar a página ${pageConect.name} você concorda com os detalhes abaixo`}
                    text="Após a conexão, os leads gerados em suas campanhas de cadastro vinculados a essa página serão importados automáticamente para nossa plataforma. Na próxima tela será possível definir algumas configurações adicionais!"
                    onSubmit={() => handleConectPage(pageConect)}
                />
            }
            {showModalDisconect &&
                <ModalConfirm
                    closeModal={handleCloseModalDisconect}
                    icon={<AiOutlineDisconnect />}
                    title="Deseja realmente desconectar a página?"
                    description={`Ao desconectar a página ${pageDisconect.page_name} você concorda com os detalhes abaixo`}
                    text="Após a desconexão, os leads gerados em suas campanhas de cadastro vinculados a essa página deixarão de ser importados automáticamente para nossa plataforma."
                    onSubmit={() => handleDisconectPage(pageDisconect)}
                />
            }
            {showModalPage &&
                <ModalMetaPage
                    closeModal={handleCloseModalPage}
                    icon={<ImageWithFallback
                        src={pageConfig?.page_picture_url}
                        alt="Imagem de Perfil da Página"
                        functionError={() => { }}
                    />}
                    title={pageConfig?.page_name}
                    description="Conectada via Meta"
                    data={pageConfig}
                    editable={editablePageConfig}
                    funnels={funnels}
                    stages={stages}
                    teams={teams}
                    users={users}
                    flags={flagsByoptions}
                    allFields={fields}
                    getStages={changeStages}
                    setEditable={setEditablePageConfig}
                    onSubmit={handleChangePage}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </>
    )
}

export default MetaIntegration