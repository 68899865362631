import api from "../services/api"
import { toast } from "react-toastify"

const getCampaignsByOrigins = async (origins) => {
    try {
        const queryString = origins?.join(",") || ''
        const response = await api.get(`/campaigns?origins=${queryString}`)
        const campaigns = response.data?.data || []
        return campaigns
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getCampaignsByOrigins }