import React, { useEffect, useState } from "react"
import styles from "./configTextListItems.module.css"
import BreakForm from "../Form/BreakForm"
import { HiPencil, HiPlus } from "react-icons/hi"
import FormGroupInline from "../Form/FormGroupInline"
import Button from "../Form/Button"
import FormGroup from "../Form/FormGroup"
import Input from "../Form/Input"
import { MdOutlineDelete } from "react-icons/md"

const ConfigTextListItems = ({ title, editable, isNew = false, value = [{ name: "" }], placeholder = "", textNotItems = "", colorSelect = false, showAction = true, setEdit = () => { }, onSubmit = () => { }, onCancel = () => { } }) => {
    const [data, setData] = useState(value)

    useEffect(() => {
        setData(value)
    }, [value])

    useEffect(() => {
        if (editable && data.length === 0) {
            handleAddItem()
        }
    }, [editable])

    const handleChangeValue = (key, value, index) => {
        setData(previusValue => {
            const newValue = [...previusValue]
            newValue[index] = { ...newValue[index], [key]: value }
            return newValue
        })
    }

    const handleAddItem = (e) => {
        e?.preventDefault()
        setData(previusValue => {
            const newValue = [...previusValue]
            newValue.push({})
            return newValue
        })
    }

    const handleRemoveItem = (e, index) => {
        e?.preventDefault()
        setData(previusValue => {
            const newValue = [...previusValue]
            newValue.splice(index, 1)
            return newValue
        })
    }

    const handleCancelChanges = (e) => {
        setData(value)
        onCancel(e)
    }

    return (
        <form onSubmit={(e) => onSubmit(e, isNew, data)}>
            <BreakForm
                title={title}
                iconAction={(editable || isNew) ? <HiPlus /> : <HiPencil />}
                styleAction={(editable || isNew) ? "bg-pink" : "bg-purple"}
                handleAction={(editable || isNew) ? handleAddItem : setEdit}
                showAction={(!editable || showAction)}
            />
            {data?.length > 0 && data.map((data, index) => (
                <FormGroupInline key={index} btnAction={index > 0} uniqueInput={true}>
                    <FormGroup>
                        <Input type="text" placeholder={placeholder} value={data?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value, index)} disabled={!editable && !isNew} />
                        {(data.countDiscards >= 0) &&
                            <div className={styles.countItems}>
                                {data.countDiscards}
                            </div>
                        }
                    </FormGroup>
                    {colorSelect &&
                        <FormGroup fullWidth={false}>
                            <Input type="color" placeholder={placeholder} value={data?.color ?? ""} onChange={(e) => handleChangeValue("color", e.target.value, index)} disabled={!editable && !isNew} />
                        </FormGroup>
                    }
                    {(editable || isNew) &&
                        <Button className='bg-purple action' onClick={(e) => handleRemoveItem(e, index)}><MdOutlineDelete /></Button>
                    }
                </FormGroupInline>
            ))}
            {(data?.length === 0 && !(editable || isNew)) &&
                <span>{textNotItems}</span>
            }
            {(editable || isNew) &&
                <div className={styles.footer}>
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancelChanges}>Cancelar</Button>
                    </FormGroupInline>
                </div>
            }
        </form>
    )
}

export default ConfigTextListItems