import React, { useEffect, useState } from "react"
import styles from "./configProducts.module.css"
import BreakForm from "../Form/BreakForm"
import { HiPencil, HiPlus } from "react-icons/hi"
import FormGroupInline from "../Form/FormGroupInline"
import Button from "../Form/Button"
import FormGroup from "../Form/FormGroup";
import Input from "../Form/Input"
import { MdOutlineDelete } from "react-icons/md"

const ConfigProducts = ({ editable, isNew = false, value = [{ name: "", childs: [] }], setEdit = () => { }, onSubmit = () => { }, onCancel = () => { } }) => {
    const [products, setProducts] = useState(value)

    useEffect(() => {
        setProducts(value)
    }, [value])

    const handleChangeValue = (key, val, path) => {
        setProducts(prevProducts => {
            let updatedProducts = [...prevProducts]
            let currentLevel = updatedProducts

            for (let i = 0; i < path.length; i++) {
                if (currentLevel[path[i]]) {
                    currentLevel = currentLevel[path[i]]
                }
            }
            currentLevel[key] = val
            return updatedProducts
        })
    }

    const handleAddProduct = e => {
        e.preventDefault()
        setProducts(prevProducts => {
            return [...prevProducts, { name: "", childs: [] }]
        })
    }

    const handleAddChildProduct = (e, path) => {
        e.preventDefault()
        let updatedProducts = [...products]
        let currentLevel = updatedProducts

        for (let i = 0; i < path.length; i++) {
            if (!currentLevel[path[i]]) {
                currentLevel[path[i]] = { name: "", childs: [] }
            } else if (!currentLevel[path[i]].childs) {
                currentLevel[path[i]].childs = []
            }
            currentLevel = currentLevel[path[i]]
        }

        currentLevel.childs.push({ name: "", childs: [] })
        setProducts(updatedProducts)
    }

    const handleRemoveProduct = (e, path) => {
        e.preventDefault()
        let updatedProducts = [...products]
        let currentLevel = updatedProducts

        for (let i = 0; i < path.length - 1; i++) {
            if (path[i] !== 'childs') {
                if (currentLevel[path[i]]) {
                    currentLevel = currentLevel[path[i]].childs
                } else {
                    currentLevel = updatedProducts
                }
            }
        }

        const lastIndex = path[path.length - 1]
        if (currentLevel[lastIndex]) {
            currentLevel.splice(lastIndex, 1)
        }

        setProducts(updatedProducts)
    }

    const handleCancelChanges = (e) => {
        setProducts(value)
        onCancel(e)
    }

    const getListProducts = (products, path, isChild = false) => {
        return products &&
            products.map((product, index) => (
                <FormGroup key={index}>
                    <FormGroupInline btnAction={index > 0} uniqueInput={true}>
                        {isChild && <span className={styles.connector} />}
                        <FormGroup>
                            <Input
                                type="text"
                                placeholder="Produto"
                                value={product?.name ?? ""}
                                onChange={e => handleChangeValue("name", e.target.value, [...path, index])}
                                disabled={!editable && !isNew}
                            />
                            {(product.countLeads >= 0 || product.countSales >= 0) &&
                                <div className={styles.countItems}>
                                    {product.countLeads + product.countSales}
                                </div>
                            }
                        </FormGroup>
                        {(editable || isNew) &&
                            <>
                                <Button
                                    className="bg-purple action"
                                    onClick={e => handleRemoveProduct(e, [...path, index])}
                                >
                                    <MdOutlineDelete />
                                </Button>
                                <Button
                                    className="bg-pink action"
                                    onClick={e => handleAddChildProduct(e, [...path, index])}
                                >
                                    <HiPlus />
                                </Button>
                            </>
                        }
                    </FormGroupInline>
                    {product?.childs?.length > 0 && (
                        <div className={styles.childs}>
                            <FormGroup>{getListProducts(product.childs, [...path, index, 'childs'], true)}</FormGroup>
                        </div>
                    )}
                </FormGroup>
            ))
    }

    return (
        <form onSubmit={e => onSubmit(e, isNew, products)}>
            <BreakForm
                title="Produtos"
                iconAction={(editable || isNew) ? <HiPlus /> : <HiPencil />}
                styleAction={(editable || isNew) ? "bg-pink" : "bg-purple"}
                handleAction={(editable || isNew) ? handleAddProduct : setEdit}
            />
            {getListProducts(products, [])}
            {(products?.length === 0 && !(editable || isNew)) &&
                <span>Nenhum produto cadastrado até o momento!</span>
            }
            {(editable || isNew) &&
                <div className={styles.footer}>
                    <FormGroupInline>
                        <Button className="bg-pink" type="submit">
                            Salvar
                        </Button>
                        <Button className="bg-purple" onClick={handleCancelChanges}>
                            Cancelar
                        </Button>
                    </FormGroupInline>
                </div>
            }
        </form>
    )
}

export default ConfigProducts