import React, { useRef } from "react";
import styles from "./inputFile.module.css"
import Button from "../Button";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { getIconByExtensionFile } from "../../../utils/utils";

const InputFile = ({ value = [], onChange, theme, multiple = false }) => {
    const refInput = useRef()

    const handleChangeValues = (e) => {
        const newFiles = [...e.target.files]
        const newValue = [...value]
        newFiles?.map(file => {
            const [fileExists] = newValue?.filter(value => value.name === file.name && value.size === file.size && value.lastModified === file.lastModified)
            if (!fileExists) {
                newValue.push(file)
            }
        })
        onChange(newValue)
    }

    const handleRemoveFile = (index) => {
        const newValue = [...value]
        newValue.splice(index, 1)
        onChange(newValue)
    }

    return (
        <>
            <div className={styles.listFiles}>
                {value.length > 0 && value.map((file, index) => (
                    <div className={styles.file} key={index}>
                        {getIconByExtensionFile(file.name)}
                        <span>{file.name}</span>
                        <div className={styles.remove} onClick={() => handleRemoveFile(index)}>
                            <IoIosCloseCircleOutline />
                        </div>
                    </div>
                ))}
            </div>
            <div className={`${styles.input} ${styles[theme]}`}>
                <Button onClick={() => refInput.current.click()}>
                    <span>Adicionar arquivo</span>
                    <MdOutlineFileUpload />
                </Button>
                <input type="file" value={[]} onChange={handleChangeValues} id="inputFile" multiple={multiple} ref={refInput} />
            </div>
        </>
    )
}

export default InputFile