import React, { useEffect, useState } from "react"
import Table from "../../components/Table"
import styles from "./transactions.module.css"
import api from "../../services/api"
import Input from "../../components/Form/Input"
import ModalDelete from "../../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { toast } from "react-toastify"
import Loading from "../layouts/Loading"
import { maskCurrency, maskDate } from "../../utils/formatter"
import { getOptionByOptionList, openLinkExternal } from "../../utils/utils"
import ModalConfirm from "../../components/Modal/ModalConfirm"

const listTypes = [
    { id: "SUBSCRIPTION", value: "Mensalidade" },
    { id: "VOIP", value: "Créditos VOIP" },
]

const listStatus = [
    { id: "PENDING", value: "Pendente" },
    { id: "ISSUED", value: "Em aberto" },
    { id: "APPROVED", value: "Aprovado" },
    { id: "RECUSED", value: "Recusado" },
    { id: "CANCELED", value: "Cancelado" },
]

const listMethod = [
    { id: "BANK_SLIP", value: "Boleto Bancário" },
    // { id: "CREDIT_CARD", value: "Cartão de Crédito" },
    // { id: "DEBIT_CARD", value: "Cartão de Débito" },
    // { id: "PIX", value: "PIX" },
]

const headers = [
    "#",
    "Conta",
    "Vencimento",
    "Tipo",
    "Valor",
    "Desconto",
    "Total",
    "Método de pagamento",
    "Observação",
    "Status",
    ""
]

const Transactions = () => {
    const [transactions, setTransactions] = useState([])
    const [textSearch, setTextSearch] = useState('')
    const [modalDelete, setModalDelete] = useState(false)
    const [modalCancel, setModalCancel] = useState(false)
    const [transactionIdDelete, setTransactionIdDelete] = useState('')
    const [transactionIdCancel, setTransactionIdCancel] = useState('')
    const [loading, setLoading] = useState(false)

    let isLoadingTransaction = false

    useEffect(() => {
        getAllTransactions()
    }, [])

    const getAllTransactions = async () => {
        try {
            if (!isLoadingTransaction) {
                isLoadingTransaction = true
                setLoading(true)
                const response = await api.get("/transactions")
                const listTransactions = response.data?.data
                setTransactions(listTransactions)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
            isLoadingTransaction = false
        }
    }

    const handleCancelTransaction = (id) => {
        setTransactionIdCancel(id)
        setModalCancel(true)
    }

    const closeModalCancel = () => {
        setModalCancel(false)
    }

    const handleDeleteTransaction = (id) => {
        setTransactionIdDelete(id)
        setModalDelete(true)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const getAccountIdByTransaction = (id) => {
        let account_id
        transactions.forEach(transaction => {
            if (transaction._id === id) {
                account_id = transaction?.account_id
            }
        })
        return account_id
    }

    const deleteTransaction = (id) => {
        setLoading(true)
        const account_id = getAccountIdByTransaction()
        api.delete(`/accounts/${account_id}/transactions/${id}`).then(response => {
            setTransactions(previousValue => previousValue?.filter(transaction => transaction._id !== id))
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const cancelTransaction = () => {
        setLoading(true)
        const account_id = getAccountIdByTransaction()
        api.patch(`/accounts/${account_id}/transactions/${transactionIdCancel}/cancel`).then(response => {
            const dataUpdate = response?.data?.data
            setTransactions(previousValue => {
                return previousValue.map(transaction => {
                    if (transaction._id === transactionIdCancel) {
                        return { ...transaction, ...dataUpdate }
                    }
                    return transaction
                })

            })
            toast.success(response.data.message)
            closeModalCancel()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getDataNewTransaction = (newTransaction) => {
        const actions = []
        if (newTransaction?.status !== "PENDING" && newTransaction?.method === "BANK_SLIP" && newTransaction?.link_charge) {
            actions.push({
                label: "Visualizar Boleto",
                onClick: () => openLinkExternal(newTransaction.link_charge)
            })
        }
        if (newTransaction?.link_invoice) {
            actions.push({
                label: "Visualizar NF",
                onClick: () => openLinkExternal(newTransaction.link_invoice)
            })
        }
        if (newTransaction?.status !== "APPROVED") {
            actions.push({
                label: "Excluir",
                onClick: handleDeleteTransaction
            })
            if (newTransaction?.status !== "CANCELED") {
                actions.push({
                    label: "Cancelar Cobrança",
                    onClick: handleCancelTransaction
                })
            }
        }
        return {
            id: newTransaction._id,
            name: newTransaction.name,
            value: [
                newTransaction?.account_name || "-",
                maskDate(newTransaction?.due_date) || "-",
                getOptionByOptionList(newTransaction.type, listTypes) || "-",
                maskCurrency(parseFloat(newTransaction.value).toFixed(2)) || "-",
                maskCurrency(parseFloat(newTransaction.discount).toFixed(2)) || "-",
                maskCurrency(parseFloat(newTransaction.value - newTransaction.discount).toFixed(2)) || "-",
                getOptionByOptionList(newTransaction.method, listMethod) || "-",
                newTransaction?.observation?.substring(0, 20) || "-",
                getOptionByOptionList(newTransaction.status, listStatus) || "-",
            ],
            actions
        }
    }

    const getFilteredValues = () => {
        const values = transactions
        values.sort((a, b) => {
            const dateA = a?.due_date ? a?.due_date : a.created_at
            const dateB = b?.due_date ? b?.due_date : b.created_at
            if (dateA > dateB) {
                return 1
            } else if (dateA < dateB) {
                return -1
            } else {
                return 0
            }
        })
        const filteredValues = values?.map((transaction) => getDataNewTransaction(transaction))
        return filteredValues
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Input type="search" placeholder="Buscar..." value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhuma cobrança encontrada"
                showIndex={true}
                showCount={true}
                count={getFilteredValues()?.length}
            />
            {modalCancel &&
                <ModalConfirm
                    closeModal={closeModalCancel}
                    onSubmit={cancelTransaction}
                    icon={<MdOutlineDeleteOutline />}
                    title="Cancelamento de cobrança"
                    description="Deseja realmente cancelar a cobrança?"
                    text="Ao cancelar a cobrança, não será mais possível realizar o pagamento. Para isso será necessário criar uma nova cobrança!"
                />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deleteTransaction}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de registro"
                    description="Deseja realmente excluir o registro?"
                    id={transactionIdDelete}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Transactions