import React from 'react'
import styles from "./contact.module.css"
import { maskPhone } from '../../../../utils/formatter'
import Button from '../../../Form/Button'
import { MdOutlineChatBubbleOutline } from 'react-icons/md'
import { useWebSocketChatContext } from '../../../../contexts/webSocketChatContext'

const Contact = ({ contacts = [], defaultPicture, showHeader = true }) => {
    const { handleOpenModalStartChat } = useWebSocketChatContext()

    const handleOpenConversation = (contact) => {
        handleOpenModalStartChat(contact?.details)
    }

    return (
        <div className={styles.container}>
            {showHeader && <span>Contatos: {contacts?.length}</span>}
            {contacts?.map((contact, index) => (
                <div className={styles.contact} key={index}>
                    <div className={styles.profileImage}>
                        <img src={contact?.details?.picture || defaultPicture} alt="Imagem de Perfil" />
                    </div>
                    <div className={styles.info}>
                        <span className={styles.name}>{contact?.details?.name || "-"}</span>
                        <span className={styles.phone}>{maskPhone(contact?.details?.phone) || "-"}</span>
                    </div>
                    <Button className="action" onClick={() => handleOpenConversation(contact)}><MdOutlineChatBubbleOutline /></Button>
                </div>
            ))}
        </div>
    )
}

export default Contact
