import React, { useEffect, useState } from "react";
import styles from "./contact.module.css"
import FormGroup from './../Form/FormGroup';
import { MdOutlineEdit, MdStar, MdStarOutline, MdOutlineDelete, MdOutlineCheck, MdOutlineClose, MdOutlineDeleteOutline, MdOutlinePhone, MdOutlineWhatsapp, MdOutlineEmail } from "react-icons/md";
import Input from "../Form/Input";
import ModalDelete from "../Modal/ModalDelete";
import { maskPhone } from "../../utils/formatter";
import FormGroupInline from "../Form/FormGroupInline";
import Button from "../Form/Button";

const Contact = ({ value = {}, handleFavorite = () => { }, handleRemove = () => { }, handleDelete = () => { }, handleUpdate = () => { }, voipActive = false, lead_id = '', lead_name = '', handleCallToLead = () => { }, handleWhatsAppToLead = () => { }, handleMailToLead = () => { } }) => {
    const [edit, setEdit] = useState(value?.isNew)
    const [contact, setContact] = useState(value)
    const [modalDelete, setModalDelete] = useState(false)

    useEffect(() => {
        setEdit(value?.isNew)
        setContact(value)
    }, [value])

    const onChangeValues = (name, value) => {
        setContact(previousValue => {
            return {
                ...previousValue,
                [name]: value
            }
        })
    }

    const cancelEdit = () => {
        if (value?.isNew) {
            handleRemove(contact._id)
        } else {
            setContact(value)
            setEdit(false)
        }
    }

    const openModalDelete = () => {
        setModalDelete(true)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    return (
        <div className={styles.groupContact}>
            <div className={styles.actions}>
                {!edit ?
                    <>
                        {contact.is_primary ?
                            <MdStar />
                            :
                            <MdStarOutline onClick={() => handleFavorite(contact._id)} />
                        }
                        <MdOutlineEdit onClick={() => setEdit(true)} />
                        <MdOutlineDelete onClick={openModalDelete} />
                    </>
                    :
                    <>
                        <MdOutlineCheck onClick={() => handleUpdate(contact)} />
                        <MdOutlineClose onClick={cancelEdit} />
                    </>
                }
            </div>
            <div className={styles.data}>
                <FormGroup labelText="Nome">
                    {edit ?
                        <Input placeholder="Digite o nome" value={contact.name || ''} onChange={(e) => onChangeValues("name", e.target.value)} theme="noBorder" />
                        :
                        <span>{contact.name || '-'}</span>
                    }
                </FormGroup>
                <FormGroupInline>
                    <FormGroup labelText="Celular">
                        {edit ?
                            <Input type="phone" placeholder="Digite o celular" value={contact.phone || ''} onChange={(e) => onChangeValues("phone", e.target.value)} theme="noBorder" />
                            :
                            <span>{maskPhone(contact.phone) || '-'}</span>
                        }
                    </FormGroup>
                    {!edit && contact.phone &&
                        <FormGroupInline style={{ width: 'fit-content' }}>
                            {voipActive &&
                                <Button onClick={() => handleCallToLead({ _id: lead_id, name: lead_name, contacts: [contact] })} >
                                    <MdOutlinePhone />
                                </Button>
                            }
                            <Button onClick={() => handleWhatsAppToLead({ _id: lead_id, name: lead_name, contacts: [contact] })}>
                                <MdOutlineWhatsapp />
                            </Button>
                        </FormGroupInline>
                    }
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="E-mail">
                        {edit ?
                            <Input placeholder="Digite o e-mail" value={contact.email || ''} onChange={(e) => onChangeValues("email", e.target.value)} theme="noBorder" />
                            :
                            <span>{contact.email || '-'}</span>
                        }
                    </FormGroup>
                    {!edit && contact.email &&
                        <FormGroupInline style={{ width: 'fit-content' }}>
                            <Button onClick={() => handleMailToLead({ _id: lead_id, name: lead_name, contacts: [contact] })}>
                                <MdOutlineEmail />
                            </Button>
                        </FormGroupInline>
                    }
                </FormGroupInline>
            </div>
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={handleDelete}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de contato"
                    description="Deseja realmente excluir o contato?"
                    id={contact._id}
                />
            }
        </div>
    )
}

export default Contact