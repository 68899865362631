import React, { useEffect, useState } from "react"
import styles from './header.module.css'
import { BsMeta } from "react-icons/bs"
import { FaRegUserCircle } from 'react-icons/fa'
import { FiHelpCircle, FiBell, FiLogOut } from 'react-icons/fi'
import { MdOutlineAddCard, MdOutlineMailOutline, MdOutlineMenu, MdOutlineTaskAlt } from "react-icons/md"
import { TbApi } from "react-icons/tb"
import Logo from '../../../logo.svg'
import { useHeader } from "../../../contexts/headerContext"
import { useAuth } from "../../../contexts/authContext"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import ImageWithFallback from "../../../components/ImageWithFallback"
import Button from "../../../components/Form/Button"

const Header = ({ titleDefault = "", initialNotifications = [], showOptions = true }) => {
    const [showPersonalOptions, setShowPersonalOptions] = useState()
    const [showNotifications, setShowNotifications] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const { account_id } = useParams()
    const { title, setTitle, notifications, addNewNotification, handleToogleShowMenu } = useHeader()
    const { account, user, permissions, logout } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if (titleDefault) {
            setTitle(titleDefault)
        }
    }, [titleDefault])

    useEffect(() => {
        if (initialNotifications.length > 0) {
            initialNotifications?.forEach(notification => {
                addNewNotification(notification.id, notification.type, notification.message, notification.data, notification.onClick)
            })
        }
    }, [initialNotifications])

    const handleToogleShowPersonalOptions = (e) => {
        e.stopPropagation()
        setShowPersonalOptions(previousValue => !previousValue)
    }

    const handleToogleShowNotifications = (e) => {
        e.stopPropagation()
        setShowNotifications(previousValue => !previousValue)
    }

    const handleShowSupport = (e) => {
        e.stopPropagation()
        setShowSupport(true)
    }

    const handleHideSupport = (e) => {
        e.stopPropagation()
        setShowSupport(false)
    }

    const getIconNotificationByType = (type) => {
        return type === "meta_ads" ? <BsMeta className="icon-md" /> : (type === "api" ? <TbApi className="icon-md" /> : (type === "credits_voip" ? <MdOutlineAddCard className="icon-md" /> : (type === "task_schedule" ? <MdOutlineTaskAlt className="icon-md" /> : <></>)))
    }

    const handleClickNotification = (notification) => {
        if (notification.type === "api") {
            navigate(`/${account_id}/integrations/api`)
        } else if (notification.type === "credits_voip") {
            navigate(`/${account_id}/myaccount`)
        } else {
            notification?.onClick()
        }
    }

    return (
        <>
            <header className={styles.header}>
                <div className={styles.info}>
                    <div className={styles.hamburguerMenu} onClick={handleToogleShowMenu}>
                        <MdOutlineMenu />
                    </div>
                    <img src={Logo} alt="Logo Oktto" />
                    <span className={styles.title}>{title}</span>
                </div>
                <div className={styles.actions}>
                    {showOptions ?
                        <>
                            <div className={styles.notifications} onClick={handleToogleShowNotifications}>
                                <FiBell />
                                {notifications.length > 0 && <span className={styles.number}>{notifications.length}</span>}
                                {showNotifications &&
                                    <>
                                        <div className={styles.body}>
                                            {notifications.length > 0 && notifications.map(notification => (
                                                <div className={styles.notification} key={notification.id}>
                                                    <span>{notification.message}</span>
                                                    {(notification.type === "meta_ads" || notification.type === "api" || notification.type === "credits_voip" || notification.type === "task_schedule") &&
                                                        <Button onClick={() => handleClickNotification(notification)}>
                                                            {getIconNotificationByType(notification.type)}
                                                            {notification.type === "credits_voip" ? "Realizar Recarga" : notification.type === "task_schedule" ? "Visualizar" : "Corrigir"}
                                                        </Button>
                                                    }
                                                </div>
                                            ))}
                                            {notifications.length === 0 && <div className={styles.notification}><span>Nenhuma notificação no momento!</span></div>}
                                        </div>
                                        <div className={styles.background} />
                                    </>
                                }
                            </div>
                            <div className={`${styles.notifications} ${styles.support}`} onClick={handleShowSupport}>
                                <FiHelpCircle />
                                {showSupport &&
                                    <>
                                        <div className={styles.body}>
                                            <div className={styles.notification}>
                                                <span className={styles.support}><MdOutlineMailOutline /> contato@oktto.com.br</span>
                                            </div>
                                        </div>
                                        <div className={styles.background} onClick={handleHideSupport} />
                                    </>
                                }
                            </div>
                            <div className={styles.option} onClick={handleToogleShowPersonalOptions}>
                                <div className={styles.groupData}>
                                    <div className={styles.userData}>
                                        <span>{user.name || ''}</span>
                                        <span>{account?.name || ''}</span>
                                    </div>
                                    {user?.image ?
                                        <ImageWithFallback
                                            src={user.image}
                                            alt="Imagem de Perfil"
                                            functionError={() => (<FaRegUserCircle />)}
                                        />
                                        :
                                        <FaRegUserCircle />
                                    }
                                </div>
                                {showPersonalOptions &&
                                    <>
                                        <ul>
                                            <li><NavLink to={`/${account_id}/profile`}>Perfil</NavLink></li>
                                            {permissions?.finance >= 1 && <li><NavLink to={`/${account_id}/myaccount`}>Minha Conta</NavLink></li>}
                                            {permissions?.import >= 1 && <li><NavLink to={`/${account_id}/import`}>Importar Dados</NavLink></li>}
                                            {permissions?.reports >= 1 && <li><NavLink to={`/${account_id}/reports`}>Relatórios</NavLink></li>}
                                            {permissions?.settings >= 1 && <li><NavLink to={`/${account_id}/settings`}>Configurações</NavLink></li>}
                                            {permissions?.integrations >= 1 && <li><NavLink to={`/${account_id}/integrations`}>Integrações</NavLink></li>}
                                            {permissions?.settings >= 1 && <li><NavLink to={`/${account_id}/trashs`}>Lixeira</NavLink></li>}
                                            <li><NavLink to={"/"}>Acessar Outra Conta</NavLink></li>
                                            <li onClick={logout}>Sair</li>
                                        </ul>
                                        <div className={styles.background} />
                                    </>
                                }
                            </div>
                        </>
                        :
                        <FiLogOut onClick={logout} />
                    }
                </div>
            </header>
        </>
    )
}

export default Header