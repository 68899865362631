import React from "react"
import styles from "./emojis.module.css"

const listEmojis = [
    {
        title: "Smileys e Pessoas", list: [
            { icon: "😀" }, { icon: "😃" }, { icon: "😄" }, { icon: "😁" }, { icon: "😆" }, { icon: "🥹" }, { icon: "😅" }, { icon: "😂" }, { icon: "🤣" }, { icon: "🥲" }, { icon: "☺" },
            { icon: "😊" }, { icon: "😇" }, { icon: "🙂" }, { icon: "🙃" }, { icon: "😉" }, { icon: "😌" }, { icon: "😍" }, { icon: "🥰" }, { icon: "😘" }, { icon: "😗" }, { icon: "😙" },
            { icon: "😚" }, { icon: "😋" }, { icon: "😛" }, { icon: "😝" }, { icon: "😜" }, { icon: "🤪" }, { icon: "🤨" }, { icon: "🧐" }, { icon: "🤓" }, { icon: "😎" }, { icon: "🥸" },
            { icon: "🤩" }, { icon: "🥳" }, { icon: "😏" }, { icon: "😒" }, { icon: "😞" }, { icon: "😔" }, { icon: "😟" }, { icon: "😕" }, { icon: "🙁" }, { icon: "☹" }, { icon: "😣" },
            { icon: "😖" }, { icon: "😫" }, { icon: "😩" }, { icon: "🥺" }, { icon: "😢" }, { icon: "😭" }, { icon: "😤" }, { icon: "😠" }, { icon: "😡" }, { icon: "🤬" }, { icon: "🤯" },
            { icon: "😳" }, { icon: "🥵" }, { icon: "🥶" }, { icon: "😶‍🌫" }, { icon: "😱" }, { icon: "😨" }, { icon: "😰" }, { icon: "😥" }, { icon: "😓" }, { icon: "🤗" }, { icon: "🤔" },
            { icon: "🫣" }, { icon: "🤭" }, { icon: "🫢" }, { icon: "🫡" }, { icon: "🤫" }, { icon: "🫠" }, { icon: "🤥" }, { icon: "😶" }, { icon: "🫥" }, { icon: "😐" }, { icon: "🫤" },
            { icon: "😑" }, { icon: "🫨" }, { icon: "😬" }, { icon: "🙄" }, { icon: "😯" }, { icon: "😦" }, { icon: "😧" }, { icon: "😮" }, { icon: "😲" }, { icon: "🥱" }, { icon: "😴" },
            { icon: "🤤" }, { icon: "😪" }, { icon: "😮‍💨" }, { icon: "😵" }, { icon: "😵‍💫" }, { icon: "🤐" }, { icon: "🥴" }, { icon: "🤢" }, { icon: "🤮" }, { icon: "🤧" }, { icon: "😷" },
            { icon: "🤒" }, { icon: "🤕" }, { icon: "🤑" }, { icon: "🤠" }, { icon: "😈" }, { icon: "👿" }, { icon: "👹" }, { icon: "👺" }, { icon: "🤡" }, { icon: "💩" }, { icon: "👻" },
            { icon: "💀" }, { icon: "☠" }, { icon: "👽" }, { icon: "👾" }, { icon: "🤖" }, { icon: "🎃" }, { icon: "😺" }, { icon: "😸" }, { icon: "😹" }, { icon: "😻" }, { icon: "😼" },
            { icon: "😽" }, { icon: "🙀" }, { icon: "😿" }, { icon: "😾" }, { icon: "🫶" }, { icon: "🤲" }, { icon: "👐" }, { icon: "🙌" }, { icon: "👏" }, { icon: "🤝" }, { icon: "👍" },
            { icon: "👎" }, { icon: "👊🏼" }, { icon: "✊" }, { icon: "🤛" }, { icon: "🤜" }, { icon: "🫷🫸" }, { icon: "🤞" }, { icon: "✌" }, { icon: "🫰" }, { icon: "🤟" }, { icon: "" },
            { icon: "🤘" }, { icon: "👌" }, { icon: "🤌" }, { icon: "🤏" }, { icon: "🫳" }, { icon: "🫴" }, { icon: "👈" }, { icon: "👉" }, { icon: "👆" }, { icon: "👇" }, { icon: "☝" },
            { icon: "✋" }, { icon: "🤚" }, { icon: "🖐" }, { icon: "🖖" }, { icon: "👋" }, { icon: "🤙" }, { icon: "🫲" }, { icon: "🫱" }, { icon: "💪" }, { icon: "🦾" }, { icon: "🖕" },
            { icon: "✍" }, { icon: "🙏🏻" }, { icon: "🫵" }, { icon: "🦶" }, { icon: "🦵" }, { icon: "🦿" }, { icon: "💄" }, { icon: "💋" }, { icon: "👄" }, { icon: "🫦" }, { icon: "🦷" },
            { icon: "👅" }, { icon: "👂" }, { icon: "🦻" }, { icon: "👃" }, { icon: "👣" }, { icon: "👁" }, { icon: "👀" }, { icon: "🫀" }, { icon: "🫁" }, { icon: "🧠" }, { icon: "🗣" },
            { icon: "👤" }, { icon: "👥" }, { icon: "🫂" }, { icon: "👶" }, { icon: "👧" }, { icon: "🧒" }, { icon: "👦" }, { icon: "👩" }, { icon: "🧑" }, { icon: "👨" }, { icon: "👩‍🦱" },
            { icon: "🧑‍🦱" }, { icon: "👨‍🦱" }, { icon: "👩‍🦰" }, { icon: "🧑‍🦰" }, { icon: "👨‍🦰" }, { icon: "👱‍♀" }, { icon: "👱‍♂" }, { icon: "👩‍🦳" }, { icon: "🧑‍🦳" }, { icon: "👨‍🦳" }, { icon: "👩‍🦲" },
            { icon: "🧑‍🦲" }, { icon: "👨‍🦲" }, { icon: "🧔‍♀" }, { icon: "🧔" }, { icon: "👵" }, { icon: "🧓" }, { icon: "👴" }, { icon: "👲" }, { icon: "👳‍♀" }, { icon: "👳‍♂" }, { icon: "🧕" },
            { icon: "👮‍♀" }, { icon: "👮‍♂" }, { icon: "👷‍♀" }, { icon: "👷‍♂" }, { icon: "💂‍♀" }, { icon: "💂‍♂" }, { icon: "🕵‍♀" }, { icon: "🕵‍♂" }, { icon: "👩‍⚕" }, { icon: "🧑‍⚕" }, { icon: "👨‍⚕" },
            { icon: "👩‍🌾" }, { icon: "🧑‍🌾" }, { icon: "👨‍🌾" }, { icon: "👩‍🍳" }, { icon: "🧑‍🍳" }, { icon: "👨‍🍳" }, { icon: "👩‍🎓" }, { icon: "🧑‍🎓" }, { icon: "👨‍🎓" }, { icon: "👩‍🎤" }, { icon: "🧑‍🎤" },
            { icon: "👨‍🎤" }, { icon: "👩‍🏫" }, { icon: "🧑‍🏫" }, { icon: "👨‍🏫" }, { icon: "👩‍🏭" }, { icon: "🧑‍🏭" }, { icon: "👨‍🏭" }, { icon: "👩‍💻" }, { icon: "🧑‍💻" }, { icon: "👨‍💻" }, { icon: "👩‍💼" },
            { icon: "🧑‍💼" }, { icon: "👨‍💼" }, { icon: "👩‍🔧" }, { icon: "🧑‍🔧" }, { icon: "👨‍🔧" }, { icon: "👩‍🔬" }, { icon: "🧑‍🔬" }, { icon: "👨‍🔬" }, { icon: "👩‍🎨" }, { icon: "🧑‍🎨" }, { icon: "👨‍🎨" },
            { icon: "👩‍🚒" }, { icon: "🧑‍🚒" }, { icon: "👨‍🚒" }, { icon: "👩‍✈" }, { icon: "🧑‍✈" }, { icon: "👨‍✈" }, { icon: "👩‍🚀" }, { icon: "🧑‍🚀" }, { icon: "👨‍🚀" }, { icon: "👩‍⚖" }, { icon: "🧑‍⚖" },
            { icon: "👨‍⚖" }, { icon: "👰‍♀" }, { icon: "👰" }, { icon: "👰‍♂" }, { icon: "🤵‍♀" }, { icon: "🤵" }, { icon: "🤵‍♂" }, { icon: "👸" }, { icon: "🫅" }, { icon: "🤴" }, { icon: "🥷" },
            { icon: "🦸‍♀" }, { icon: "🦸‍♂" }, { icon: "🦹‍♀" }, { icon: "🦹‍♂" }, { icon: "🤶" }, { icon: "🧑‍🎄" }, { icon: "🎅" }, { icon: "🧙‍♀" }, { icon: "🧙‍♂" }, { icon: "🧝‍♀" }, { icon: "🧝‍♂" },
            { icon: "🧌" }, { icon: "🧛‍♀" }, { icon: "🧛‍♂" }, { icon: "🧟‍♀" }, { icon: "🧟‍♂" }, { icon: "🧞‍♀" }, { icon: "🧞‍♂" }, { icon: "🧜‍♀" }, { icon: "🧜‍♂" }, { icon: "🧚‍♀" }, { icon: "🧚‍♂" },
            { icon: "👼" }, { icon: "🤰" }, { icon: "🫄" }, { icon: "🫃" }, { icon: "🤱" }, { icon: "👩‍🍼" }, { icon: "🧑‍🍼" }, { icon: "👨‍🍼" }, { icon: "🙇‍♀" }, { icon: "🙇‍♂" }, { icon: "💁‍♀" },
            { icon: "💁‍♂" }, { icon: "🙅‍♀" }, { icon: "🙅‍♂" }, { icon: "🙆‍♀" }, { icon: "🙆‍♂" }, { icon: "🙋‍♀" }, { icon: "🙋‍♂" }, { icon: "🧏‍♀" }, { icon: "🧏" }, { icon: "🧏‍♂" }, { icon: "🤦‍♀" },
            { icon: "🤦‍♂" }, { icon: "🤷‍♀" }, { icon: "🤷‍♂" }, { icon: "🙎‍♀" }, { icon: "🙎‍♂" }, { icon: "🙍‍♀" }, { icon: "🙍‍♂" }, { icon: "💇‍♀" }, { icon: "💇‍♂" }, { icon: "💆‍♀" }, { icon: "💆‍♂" },
            { icon: "🧖‍♀" }, { icon: "🧖" }, { icon: "🧖‍♂" }, { icon: "💅" }, { icon: "🤳" }, { icon: "💃" }, { icon: "🕺" }, { icon: "👯‍♀" }, { icon: "👯‍♂" }, { icon: "🕴" }, { icon: "👩‍🦽" },
            { icon: "🧑‍🦽" }, { icon: "👨‍🦽" }, { icon: "👩‍🦼" }, { icon: "🧑‍🦼" }, { icon: "👨‍🦼" }, { icon: "🚶‍♀" }, { icon: "🚶" }, { icon: "🚶‍♂" }, { icon: "👩‍🦯" }, { icon: "🧑‍🦯" }, { icon: "👨‍🦯" },
            { icon: "🧎‍♀" }, { icon: "🧎" }, { icon: "🧎‍♂" }, { icon: "🏃‍♀" }, { icon: "🏃" }, { icon: "🏃‍♂" }, { icon: "🧍‍♀" }, { icon: "🧍" }, { icon: "🧍‍♂" }, { icon: "👫" }, { icon: "👭" },
            { icon: "👬" }, { icon: "👩‍❤‍👨" }, { icon: "👩‍❤️‍👩" }, { icon: "💑" }, { icon: "👨‍❤️‍👨" }, { icon: "👩‍❤‍💋‍👨" }, { icon: "👩‍❤️‍💋‍👩" }, { icon: "💏" }, { icon: "👨‍❤️‍💋‍👨" }, { icon: "👨‍👩‍👦" }, { icon: "👨‍👩‍👧" },
            { icon: "👨‍👩‍👧‍👦" }, { icon: "👨‍👩‍👦‍👦" }, { icon: "👨‍👩‍👧‍👧" }, { icon: "👩‍👩‍👦" }, { icon: "👩‍👩‍👧" }, { icon: "👩‍👩‍👧‍👦" }, { icon: "👩‍👩‍👦‍👦" }, { icon: "👩‍👩‍👧‍👧" }, { icon: "👨‍👨‍👦" }, { icon: "👨‍👨‍👧" }, { icon: "👨‍👨‍👧‍👦" },
            { icon: "👨‍👨‍👦‍👦" }, { icon: "👨‍👨‍👧‍👧" }, { icon: "👩‍👦" }, { icon: "👩‍👧" }, { icon: "👩‍👧‍👦" }, { icon: "👩‍👦‍👦" }, { icon: "👩‍👧‍👧" }, { icon: "👨‍👦" }, { icon: "👨‍👧" }, { icon: "👨‍👧‍👦" }, { icon: "👨‍👦‍👦" },
            { icon: "👨‍👧‍👧" }, { icon: "🪢" }, { icon: "🧶" }, { icon: "🧵" }, { icon: "🪡" }, { icon: "🧥" }, { icon: "🥼" }, { icon: "🦺" }, { icon: "👚" }, { icon: "👕" }, { icon: "👖" },
            { icon: "🩲" }, { icon: "🩳" }, { icon: "👔" }, { icon: "👗" }, { icon: "👙" }, { icon: "🩱" }, { icon: "👘" }, { icon: "🥻" }, { icon: "🩴" }, { icon: "🥿" }, { icon: "👠" },
            { icon: "👡" }, { icon: "👢" }, { icon: "👞" }, { icon: "👟" }, { icon: "🥾" }, { icon: "🧦" }, { icon: "🧤" }, { icon: "🧣" }, { icon: "🎩" }, { icon: "🧢" }, { icon: "👒" },
            { icon: "🎓" }, { icon: "⛑" }, { icon: "🪖" }, { icon: "👑" }, { icon: "💍" }, { icon: "👝" }, { icon: "👛" }, { icon: "👜" }, { icon: "💼" }, { icon: "🎒" }, { icon: "🧳" },
            { icon: "👓" }, { icon: "🕶" }, { icon: "🥽" }, { icon: "🌂" },
        ]
    },
    {
        title: "Animais e Natureza", list: [
            { icon: "🐶" }, { icon: "🐱" }, { icon: "🐭" }, { icon: "🐹" }, { icon: "🐰" }, { icon: "🦊" }, { icon: "🐻" }, { icon: "🐼" }, { icon: "🐻‍❄" }, { icon: "🐨" }, { icon: "🐯" },
            { icon: "🦁" }, { icon: "🐮" }, { icon: "🐷" }, { icon: "🐽" }, { icon: "🐸" }, { icon: "🐵" }, { icon: "🙈" }, { icon: "🙉" }, { icon: "🙊" }, { icon: "🐒" }, { icon: "🐔" },
            { icon: "🐧" }, { icon: "🐦" }, { icon: "🐤" }, { icon: "🐣" }, { icon: "🐥" }, { icon: "🪿" }, { icon: "🦆" }, { icon: "🐦‍⬛" }, { icon: "🦅" }, { icon: "🦉" }, { icon: "🦇" },
            { icon: "🐺" }, { icon: "🐗" }, { icon: "🐴" }, { icon: "🦄" }, { icon: "🫎" }, { icon: "🐝" }, { icon: "🪱" }, { icon: "🐛" }, { icon: "🦋" }, { icon: "🐌" }, { icon: "🐞" },
            { icon: "🐜" }, { icon: "🪰" }, { icon: "🪲" }, { icon: "🪳" }, { icon: "🦟" }, { icon: "🦗" }, { icon: "🕷" }, { icon: "🕸" }, { icon: "🦂" }, { icon: "🐢" }, { icon: "🐍" },
            { icon: "🦎" }, { icon: "🦖" }, { icon: "🦕" }, { icon: "🐙" }, { icon: "🦑" }, { icon: "🪼" }, { icon: "🦐" }, { icon: "🦞" }, { icon: "🦀" }, { icon: "🐡" }, { icon: "🐠" },
            { icon: "🐟" }, { icon: "🐬" }, { icon: "🐳" }, { icon: "🐋" }, { icon: "🦈" }, { icon: "🦭" }, { icon: "🐊" }, { icon: "🐅" }, { icon: "🐆" }, { icon: "🦓" }, { icon: "🦍" },
            { icon: "🦧" }, { icon: "🦣" }, { icon: "🐘" }, { icon: "🦛" }, { icon: "🦏" }, { icon: "🐪" }, { icon: "🐫" }, { icon: "🦒" }, { icon: "🦘" }, { icon: "🦬" }, { icon: "🐃" },
            { icon: "🐂" }, { icon: "🐄" }, { icon: "🫏" }, { icon: "🐎" }, { icon: "🐖" }, { icon: "🐏" }, { icon: "🐑" }, { icon: "🦙" }, { icon: "🐐" }, { icon: "🦌" }, { icon: "🐕" },
            { icon: "🐩" }, { icon: "🦮" }, { icon: "🐕‍🦺" }, { icon: "🐈" }, { icon: "🐈‍⬛" }, { icon: "🪶" }, { icon: "🪽" }, { icon: "🐓" }, { icon: "🦃" }, { icon: "🦤" }, { icon: "🦚" },
            { icon: "🦜" }, { icon: "🦢" }, { icon: "🦩" }, { icon: "🕊" }, { icon: "🐇" }, { icon: "🦝" }, { icon: "🦨" }, { icon: "🦡" }, { icon: "🦫" }, { icon: "🦦" }, { icon: "🦥" },
            { icon: "🐁" }, { icon: "🐀" }, { icon: "🐿" }, { icon: "🦔" }, { icon: "🐾" }, { icon: "🐉" }, { icon: "🐲" }, { icon: "🌵" }, { icon: "🎄" }, { icon: "🌲" }, { icon: "🌳" },
            { icon: "🌴" }, { icon: "🪵" }, { icon: "🌱" }, { icon: "🌿" }, { icon: "☘" }, { icon: "🍀" }, { icon: "🎍" }, { icon: "🪴" }, { icon: "🎋" }, { icon: "🍃" }, { icon: "🍂" },
            { icon: "🍁" }, { icon: "🪺" }, { icon: "🪹" }, { icon: "🍄" }, { icon: "🐚" }, { icon: "🪸" }, { icon: "🪨" }, { icon: "🌾" }, { icon: "💐" }, { icon: "🌷" }, { icon: "🌹" },
            { icon: "🥀" }, { icon: "🪻" }, { icon: "🪷" }, { icon: "🌺" }, { icon: "🌸" }, { icon: "🌼" }, { icon: "🌻" }, { icon: "🌞" }, { icon: "🌝" }, { icon: "🌛" }, { icon: "🌜" },
            { icon: "🌚" }, { icon: "🌕" }, { icon: "🌖" }, { icon: "🌗" }, { icon: "🌘" }, { icon: "🌑" }, { icon: "🌒" }, { icon: "🌓" }, { icon: "🌔" }, { icon: "🌙" }, { icon: "🌎" },
            { icon: "🌍" }, { icon: "🌏" }, { icon: "🪐" }, { icon: "💫" }, { icon: "⭐" }, { icon: "🌟" }, { icon: "✨" }, { icon: "⚡" }, { icon: "☄" }, { icon: "💥" }, { icon: "🔥" },
            { icon: "🌪" }, { icon: "🌈" }, { icon: "☀" }, { icon: "🌤" }, { icon: "⛅" }, { icon: "🌥" }, { icon: "☁" }, { icon: "🌦" }, { icon: "🌧" }, { icon: "⛈" }, { icon: "🌩" },
            { icon: "🌨" }, { icon: "❄" }, { icon: "☃" }, { icon: "⛄" }, { icon: "🌬" }, { icon: "💨" }, { icon: "💧" }, { icon: "💦" }, { icon: "🫧" }, { icon: "☔" }, { icon: "☂" },
            { icon: "🌊" }, { icon: "🌫" },
        ]
    },
    {
        title: "Comidas e Bebidas", list: [
            { icon: "🍏" }, { icon: "🍎" }, { icon: "🍐" }, { icon: "🍊" }, { icon: "🍋" }, { icon: "🍌" }, { icon: "🍉" }, { icon: "🍇" }, { icon: "🍓" }, { icon: "🫐" }, { icon: "🍈" },
            { icon: "🍒" }, { icon: "🍑" }, { icon: "🥭" }, { icon: "🍍" }, { icon: "🥥" }, { icon: "🥝" }, { icon: "🍅" }, { icon: "🍆" }, { icon: "🥑" }, { icon: "🫛" }, { icon: "🥦" },
            { icon: "🥬" }, { icon: "🥒" }, { icon: "🌶" }, { icon: "🫑" }, { icon: "🌽" }, { icon: "🥕" }, { icon: "🫒" }, { icon: "🧄" }, { icon: "🧅" }, { icon: "🥔" }, { icon: "🍠" },
            { icon: "🫚" }, { icon: "🥐" }, { icon: "🥯" }, { icon: "🍞" }, { icon: "🥖" }, { icon: "🥨" }, { icon: "🧀" }, { icon: "🥚" }, { icon: "🍳" }, { icon: "🧈" }, { icon: "🥞" },
            { icon: "🧇" }, { icon: "🥓" }, { icon: "🥩" }, { icon: "🍗" }, { icon: "🍖" }, { icon: "🦴" }, { icon: "🌭" }, { icon: "🍔" }, { icon: "🍟" }, { icon: "🍕" }, { icon: "🫓" },
            { icon: "🥪" }, { icon: "🥙" }, { icon: "🧆" }, { icon: "🌮" }, { icon: "🌯" }, { icon: "🫔" }, { icon: "🥗" }, { icon: "🥘" }, { icon: "🫕" }, { icon: "🥫" }, { icon: "🫙" },
            { icon: "🍝" }, { icon: "🍜" }, { icon: "🍲" }, { icon: "🍛" }, { icon: "🍣" }, { icon: "🍱" }, { icon: "🥟" }, { icon: "🦪" }, { icon: "🍤" }, { icon: "🍙" }, { icon: "🍚" },
            { icon: "🍘" }, { icon: "🍥" }, { icon: "🥠" }, { icon: "🥮" }, { icon: "🍢" }, { icon: "🍡" }, { icon: "🍧" }, { icon: "🍨" }, { icon: "🍦" }, { icon: "🥧" }, { icon: "🧁" },
            { icon: "🍰" }, { icon: "🎂" }, { icon: "🍮" }, { icon: "🍭" }, { icon: "🍬" }, { icon: "🍫" }, { icon: "🍿" }, { icon: "🍩" }, { icon: "🍪" }, { icon: "🌰" }, { icon: "🥜" },
            { icon: "🫘" }, { icon: "🍯" }, { icon: "🥛" }, { icon: "🫗" }, { icon: "🍼" }, { icon: "🫖" }, { icon: "☕" }, { icon: "🍵" }, { icon: "🧃" }, { icon: "🥤" }, { icon: "🧋" },
            { icon: "🍶" }, { icon: "🍺" }, { icon: "🍻" }, { icon: "🥂" }, { icon: "🍷" }, { icon: "🥃" }, { icon: "🍸" }, { icon: "🍹" }, { icon: "🧉" }, { icon: "🍾" }, { icon: "🧊" },
            { icon: "🥄" }, { icon: "🍴" }, { icon: "🍽" }, { icon: "🥣" }, { icon: "🥡" }, { icon: "🥢" }, { icon: "🧂" },
        ]
    },
    {
        title: "Atividades", list: [
            { icon: "⚽" }, { icon: "🏀" }, { icon: "🏈" }, { icon: "⚾" }, { icon: "🥎" }, { icon: "🎾" }, { icon: "🏐" }, { icon: "🏉" }, { icon: "🥏" }, { icon: "🎱" }, { icon: "🪀" },
            { icon: "🏓" }, { icon: "🏸" }, { icon: "🏒" }, { icon: "🏑" }, { icon: "🥍" }, { icon: "🏏" }, { icon: "🪃" }, { icon: "🥅" }, { icon: "⛳" }, { icon: "🪁" }, { icon: "🛝" },
            { icon: "🏹" }, { icon: "🎣" }, { icon: "🤿" }, { icon: "🥊" }, { icon: "🥋" }, { icon: "🎽" }, { icon: "🛹" }, { icon: "🛼" }, { icon: "🛷" }, { icon: "⛸" }, { icon: "🥌" },
            { icon: "🎿" }, { icon: "⛷" }, { icon: "🏂" }, { icon: "🪂" }, { icon: "🏋‍♀" }, { icon: "🏋‍♂" }, { icon: "🤼‍♀" }, { icon: "🤼‍♂" }, { icon: "🤸‍♀" }, { icon: "🤸" }, { icon: "🤸‍♂" },
            { icon: "⛹‍♀" }, { icon: "⛹" }, { icon: "⛹‍♂" }, { icon: "🤺" }, { icon: "🤾‍♀" }, { icon: "🤾" }, { icon: "🤾‍♂" }, { icon: "🏌‍♀" }, { icon: "🏌" }, { icon: "🏌‍♂" }, { icon: "🏇" },
            { icon: "🧘‍♀" }, { icon: "🧘" }, { icon: "🧘‍♂" }, { icon: "🏄‍♀" }, { icon: "🏄" }, { icon: "🏄‍♂" }, { icon: "🏊‍♀" }, { icon: "🏊" }, { icon: "🏊‍♂" }, { icon: "🤽‍♀" }, { icon: "🤽" },
            { icon: "🤽‍♂" }, { icon: "🚣‍♀" }, { icon: "🚣" }, { icon: "🚣‍♂" }, { icon: "🧗‍♀" }, { icon: "🧗" }, { icon: "🧗‍♂" }, { icon: "🚵‍♀" }, { icon: "🚵" }, { icon: "🚵‍♂" }, { icon: "🚴‍♀" },
            { icon: "🚴" }, { icon: "🚴‍♂" }, { icon: "🏆" }, { icon: "🥇" }, { icon: "🥈" }, { icon: "🥉" }, { icon: "🏅" }, { icon: "🎖" }, { icon: "🏵" }, { icon: "🎗" }, { icon: "🎫" },
            { icon: "🎟" }, { icon: "🎪" }, { icon: "🤹‍♀" }, { icon: "🤹‍♂" }, { icon: "🎭" }, { icon: "🩰" }, { icon: "🎨" }, { icon: "🎬" }, { icon: "🎤" }, { icon: "🎧" }, { icon: "🎼" },
            { icon: "🎹" }, { icon: "🪇" }, { icon: "🥁" }, { icon: "🪘" }, { icon: "🎷" }, { icon: "🎺" }, { icon: "🪗" }, { icon: "🎸" }, { icon: "🪕" }, { icon: "🎻" }, { icon: "🪈" },
            { icon: "🎲" }, { icon: "♟" }, { icon: "🎯" }, { icon: "🎳" }, { icon: "🎮" }, { icon: "🎰" }, { icon: "🧩" },
        ]
    },
    {
        title: "Viagens e Lugares", list: [
            { icon: "🚗" }, { icon: "🚕" }, { icon: "🚙" }, { icon: "🚌" }, { icon: "🚎" }, { icon: "🏎" }, { icon: "🚓" }, { icon: "🚑" }, { icon: "🚒" }, { icon: "🚐" }, { icon: "🛻" },
            { icon: "🚚" }, { icon: "🚛" }, { icon: "🚜" }, { icon: "🦯" }, { icon: "🦽" }, { icon: "🦼" }, { icon: "🩼" }, { icon: "🛴" }, { icon: "🚲" }, { icon: "🛵" }, { icon: "🏍" },
            { icon: "🛺" }, { icon: "🛞" }, { icon: "🚨" }, { icon: "🚔" }, { icon: "🚍" }, { icon: "🚘" }, { icon: "🚖" }, { icon: "🚡" }, { icon: "🚠" }, { icon: "🚟" }, { icon: "🚃" },
            { icon: "🚋" }, { icon: "🚞" }, { icon: "🚝" }, { icon: "🚄" }, { icon: "🚅" }, { icon: "🚈" }, { icon: "🚂" }, { icon: "🚆" }, { icon: "🚇" }, { icon: "🚊" }, { icon: "🚉" },
            { icon: "✈" }, { icon: "🛫" }, { icon: "🛬" }, { icon: "🛩" }, { icon: "💺" }, { icon: "🛰" }, { icon: "🚀" }, { icon: "🛸" }, { icon: "🚁" }, { icon: "🛶" }, { icon: "⛵" },
            { icon: "🚤" }, { icon: "🛥" }, { icon: "🛳" }, { icon: "⛴" }, { icon: "🚢" }, { icon: "🛟" }, { icon: "⚓" }, { icon: "🪝" }, { icon: "⛽" }, { icon: "🚧" }, { icon: "🚦" },
            { icon: "🚥" }, { icon: "🚏" }, { icon: "🗺" }, { icon: "🗿" }, { icon: "🗽" }, { icon: "🗼" }, { icon: "🏰" }, { icon: "🏯" }, { icon: "🏟" }, { icon: "🎡" }, { icon: "🎢" },
            { icon: "🎠" }, { icon: "⛲" }, { icon: "⛱" }, { icon: "🏖" }, { icon: "🏝" }, { icon: "🏜" }, { icon: "🌋" }, { icon: "⛰" }, { icon: "🏔" }, { icon: "🗻" }, { icon: "🏕" },
            { icon: "⛺" }, { icon: "🛖" }, { icon: "🏠" }, { icon: "🏡" }, { icon: "🏘" }, { icon: "🏚" }, { icon: "🏗" }, { icon: "🏭" }, { icon: "🏢" }, { icon: "🏬" }, { icon: "🏣" },
            { icon: "🏤" }, { icon: "🏥" }, { icon: "🏦" }, { icon: "🏨" }, { icon: "🏪" }, { icon: "🏫" }, { icon: "🏩" }, { icon: "💒" }, { icon: "🏛" }, { icon: "⛪" }, { icon: "🕌" },
            { icon: "🕍" }, { icon: "🛕" }, { icon: "🕋" }, { icon: "⛩" }, { icon: "🛤" }, { icon: "🛣" }, { icon: "🗾" }, { icon: "🎑" }, { icon: "🏞" }, { icon: "🌅" }, { icon: "🌄" },
            { icon: "🌠" }, { icon: "🎇" }, { icon: "🎆" }, { icon: "🌇" }, { icon: "🌆" }, { icon: "🏙" }, { icon: "🌃" }, { icon: "🌌" }, { icon: "🌉" }, { icon: "🌁" },
        ]
    },
    {
        title: "Objetos", list: [
            { icon: "⌚" }, { icon: "📱" }, { icon: "📲" }, { icon: "💻" }, { icon: "⌨" }, { icon: "🖥" }, { icon: "🖨" }, { icon: "🖱" }, { icon: "🖲" }, { icon: "🕹" }, { icon: "🗜" },
            { icon: "💽" }, { icon: "💾" }, { icon: "💿" }, { icon: "📀" }, { icon: "📼" }, { icon: "📷" }, { icon: "📸" }, { icon: "📹" }, { icon: "🎥" }, { icon: "📽" }, { icon: "🎞" },
            { icon: "📞" }, { icon: "☎" }, { icon: "📟" }, { icon: "📠" }, { icon: "📺" }, { icon: "📻" }, { icon: "🎙" }, { icon: "🎚" }, { icon: "🎛" }, { icon: "🧭" }, { icon: "⏱" },
            { icon: "⏲" }, { icon: "⏰" }, { icon: "🕰" }, { icon: "⌛" }, { icon: "⏳" }, { icon: "📡" }, { icon: "🔋" }, { icon: "🪫" }, { icon: "🔌" }, { icon: "💡" }, { icon: "🔦" },
            { icon: "🕯" }, { icon: "🪔" }, { icon: "🧯" }, { icon: "🛢" }, { icon: "💸" }, { icon: "💵" }, { icon: "💴" }, { icon: "💶" }, { icon: "💷" }, { icon: "🪙" }, { icon: "💰" },
            { icon: "💳" }, { icon: "🪪" }, { icon: "💎" }, { icon: "⚖" }, { icon: "🪜" }, { icon: "🧰" }, { icon: "🪛" }, { icon: "🔧" }, { icon: "🔨" }, { icon: "⚒" }, { icon: "🛠" },
            { icon: "⛏" }, { icon: "🪚" }, { icon: "🔩" }, { icon: "⚙" }, { icon: "🪤" }, { icon: "🧱" }, { icon: "⛓" }, { icon: "🧲" }, { icon: "🔫" }, { icon: "💣" }, { icon: "🧨" },
            { icon: "🪓" }, { icon: "🔪" }, { icon: "🗡" }, { icon: "⚔" }, { icon: "🛡" }, { icon: "🚬" }, { icon: "⚰" }, { icon: "🪦" }, { icon: "⚱" }, { icon: "🏺" }, { icon: "🔮" },
            { icon: "📿" }, { icon: "🧿" }, { icon: "🪬" }, { icon: "💈" }, { icon: "⚗" }, { icon: "🔭" }, { icon: "🔬" }, { icon: "🕳" }, { icon: "🩻" }, { icon: "🩹" }, { icon: "🩺" },
            { icon: "💊" }, { icon: "💉" }, { icon: "🩸" }, { icon: "🧬" }, { icon: "🦠" }, { icon: "🧫" }, { icon: "🧪" }, { icon: "🌡" }, { icon: "🧹" }, { icon: "🪠" }, { icon: "🧺" },
            { icon: "🧻" }, { icon: "🚽" }, { icon: "🚰" }, { icon: "🚿" }, { icon: "🛁" }, { icon: "🛀" }, { icon: "🧼" }, { icon: "🪥" }, { icon: "🪒" }, { icon: "🪮" }, { icon: "🧽" },
            { icon: "🪣" }, { icon: "🧴" }, { icon: "🛎" }, { icon: "🔑" }, { icon: "🗝" }, { icon: "🚪" }, { icon: "🪑" }, { icon: "🛋" }, { icon: "🛏" }, { icon: "🛌" }, { icon: "🧸" },
            { icon: "🪆" }, { icon: "🖼" }, { icon: "🪞" }, { icon: "🪟" }, { icon: "🛍" }, { icon: "🛒" }, { icon: "🎁" }, { icon: "🎈" }, { icon: "🎏" }, { icon: "🎀" }, { icon: "🪄" },
            { icon: "🪅" }, { icon: "🎊" }, { icon: "🎉" }, { icon: "🎎" }, { icon: "🪭" }, { icon: "🏮" }, { icon: "🎐" }, { icon: "🪩" }, { icon: "🧧" }, { icon: "✉" }, { icon: "📩" },
            { icon: "📨" }, { icon: "📧" }, { icon: "💌" }, { icon: "📥" }, { icon: "📤" }, { icon: "📦" }, { icon: "🏷" }, { icon: "🪧" }, { icon: "📪" }, { icon: "📫" }, { icon: "📬" },
            { icon: "📭" }, { icon: "📮" }, { icon: "📯" }, { icon: "📜" }, { icon: "📃" }, { icon: "📄" }, { icon: "📑" }, { icon: "🧾" }, { icon: "📊" }, { icon: "📈" }, { icon: "📉" },
            { icon: "🗒" }, { icon: "🗓" }, { icon: "📆" }, { icon: "📅" }, { icon: "🗑" }, { icon: "📇" }, { icon: "🗃" }, { icon: "🗳" }, { icon: "🗄" }, { icon: "📋" }, { icon: "📁" },
            { icon: "📂" }, { icon: "🗂" }, { icon: "🗞" }, { icon: "📰" }, { icon: "📓" }, { icon: "📔" }, { icon: "📒" }, { icon: "📕" }, { icon: "📗" }, { icon: "📘" }, { icon: "📙" },
            { icon: "📚" }, { icon: "📖" }, { icon: "🔖" }, { icon: "🧷" }, { icon: "🔗" }, { icon: "📎" }, { icon: "🖇" }, { icon: "📐" }, { icon: "📏" }, { icon: "🧮" }, { icon: "📌" },
            { icon: "📍" }, { icon: "✂" }, { icon: "🖊" }, { icon: "🖋" }, { icon: "✒" }, { icon: "🖌" }, { icon: "🖍" }, { icon: "📝" }, { icon: "✏" }, { icon: "🔍" }, { icon: "🔎" },
            { icon: "🔏" }, { icon: "🔐" }, { icon: "🔒" }, { icon: "🔓" },

        ]
    },
    {
        title: "Símbolos", list: [
            { icon: "🩷" }, { icon: "❤" }, { icon: "🧡" }, { icon: "💛" }, { icon: "💚" }, { icon: "🩵" }, { icon: "💙" }, { icon: "💜" }, { icon: "🖤" }, { icon: "🩶" }, { icon: "🤍" },
            { icon: "🤎" }, { icon: "💔" }, { icon: "❤‍🔥" }, { icon: "❤‍🩹" }, { icon: "❣" }, { icon: "💕" }, { icon: "💞" }, { icon: "💓" }, { icon: "💗" }, { icon: "💖" }, { icon: "💘" },
            { icon: "💝" }, { icon: "💟" }, { icon: "☮" }, { icon: "✝" }, { icon: "☪" }, { icon: "🕉" }, { icon: "☸" }, { icon: "🪯" }, { icon: "✡" }, { icon: "🔯" }, { icon: "🕎" },
            { icon: "☯" }, { icon: "☦" }, { icon: "🛐" }, { icon: "⛎" }, { icon: "♈" }, { icon: "♉" }, { icon: "♊" }, { icon: "♋" }, { icon: "♌" }, { icon: "♍" }, { icon: "♎" },
            { icon: "♏" }, { icon: "♐" }, { icon: "♑" }, { icon: "♒" }, { icon: "♓" }, { icon: "🆔" }, { icon: "⚛" }, { icon: "🉑" }, { icon: "☢" }, { icon: "☣" }, { icon: "📴" },
            { icon: "📳" }, { icon: "🈶" }, { icon: "🈚" }, { icon: "🈸" }, { icon: "🈺" }, { icon: "🈷" }, { icon: "✴" }, { icon: "🆚" }, { icon: "💮" }, { icon: "🉐" }, { icon: "㊙" },
            { icon: "㊗" }, { icon: "🈴" }, { icon: "🈵" }, { icon: "🈹" }, { icon: "🈲" }, { icon: "🅰" }, { icon: "🅱" }, { icon: "🆎" }, { icon: "🆑" }, { icon: "🅾" }, { icon: "🆘" },
            { icon: "❌" }, { icon: "⭕" }, { icon: "🛑" }, { icon: "⛔" }, { icon: "📛" }, { icon: "🚫" }, { icon: "💯" }, { icon: "💢" }, { icon: "♨" }, { icon: "🚷" }, { icon: "🚯" },
            { icon: "🚳" }, { icon: "🚱" }, { icon: "🔞" }, { icon: "📵" }, { icon: "🚭" }, { icon: "❗" }, { icon: "❕" }, { icon: "❓" }, { icon: "❔" }, { icon: "‼" }, { icon: "⁉" },
            { icon: "🔅" }, { icon: "🔆" }, { icon: "〽" }, { icon: "⚠" }, { icon: "🚸" }, { icon: "🔱" }, { icon: "⚜" }, { icon: "🔰" }, { icon: "♻" }, { icon: "✅" }, { icon: "🈯" },
            { icon: "💹" }, { icon: "❇" }, { icon: "✳" }, { icon: "❎" }, { icon: "🌐" }, { icon: "💠" }, { icon: "Ⓜ" }, { icon: "🌀" }, { icon: "💤" }, { icon: "🏧" }, { icon: "🚾" },
            { icon: "♿" }, { icon: "🅿" }, { icon: "🛗" }, { icon: "🈳" }, { icon: "🈂" }, { icon: "🛂" }, { icon: "🛃" }, { icon: "🛄" }, { icon: "🛅" }, { icon: "🛜" }, { icon: "🚹" },
            { icon: "🚺" }, { icon: "🚼" }, { icon: "⚧" }, { icon: "🚻" }, { icon: "🚮" }, { icon: "🎦" }, { icon: "📶" }, { icon: "🈁" }, { icon: "🔣" }, { icon: "ℹ" }, { icon: "🔤" },
            { icon: "🔡" }, { icon: "🔠" }, { icon: "🆖" }, { icon: "🆗" }, { icon: "🆙" }, { icon: "🆒" }, { icon: "🆕" }, { icon: "🆓" }, { icon: "⿠" }, { icon: "⿡" }, { icon: "⿢" },
            { icon: "⿣" }, { icon: "⿤" }, { icon: "⿥" }, { icon: "⿦" }, { icon: "⿧" }, { icon: "⿨" }, { icon: "⿩" }, { icon: "🔟" }, { icon: "🔢" }, { icon: "⿪" }, { icon: "⿫" },
            { icon: "⏏" }, { icon: "▶" }, { icon: "⏸" }, { icon: "⏯" }, { icon: "⏹" }, { icon: "⏺" }, { icon: "⏭" }, { icon: "⏮" }, { icon: "⏩" }, { icon: "⏪" }, { icon: "⏫" },
            { icon: "⏬" }, { icon: "◀" }, { icon: "🔼" }, { icon: "🔽" }, { icon: "➡" }, { icon: "⬅" }, { icon: "⬆" }, { icon: "⬇" }, { icon: "↗" }, { icon: "↘" }, { icon: "↙" },
            { icon: "↖" }, { icon: "↕" }, { icon: "↔" }, { icon: "↪" }, { icon: "↩" }, { icon: "⤴" }, { icon: "⤵" }, { icon: "🔀" }, { icon: "🔁" }, { icon: "🔂" }, { icon: "🔄" },
            { icon: "🔃" }, { icon: "🎵" }, { icon: "🎶" }, { icon: "➕" }, { icon: "➖" }, { icon: "➗" }, { icon: "✖" }, { icon: "🟰" }, { icon: "♾" }, { icon: "💲" }, { icon: "💱" },
            { icon: "™" }, { icon: "©" }, { icon: "®" }, { icon: "👁‍🗨" }, { icon: "🔚" }, { icon: "🔙" }, { icon: "🔛" }, { icon: "🔝" }, { icon: "🔜" }, { icon: "〰" }, { icon: "➰" },
            { icon: "➿" }, { icon: "✔" }, { icon: "☑" }, { icon: "🔘" }, { icon: "🔴" }, { icon: "🟠" }, { icon: "🟡" }, { icon: "🟢" }, { icon: "🔵" }, { icon: "🟣" }, { icon: "⚫" },
            { icon: "⚪" }, { icon: "🟤" }, { icon: "🔺" }, { icon: "🔻" }, { icon: "🔸" }, { icon: "🔹" }, { icon: "🔶" }, { icon: "🔷" }, { icon: "🔳" }, { icon: "🔲" }, { icon: "▪" },
            { icon: "▫" }, { icon: "◾" }, { icon: "◽" }, { icon: "◼" }, { icon: "◻" }, { icon: "🟥" }, { icon: "🟧" }, { icon: "🟨" }, { icon: "🟩" }, { icon: "🟦" }, { icon: "🟪" },
            { icon: "⬛" }, { icon: "⬜" }, { icon: "🟫" }, { icon: "🔈" }, { icon: "🔇" }, { icon: "🔉" }, { icon: "🔊" }, { icon: "🔔" }, { icon: "🔕" }, { icon: "📣" }, { icon: "📢" },
            { icon: "💬" }, { icon: "💭" }, { icon: "🗯" }, { icon: "♠" }, { icon: "♣" }, { icon: "♥" }, { icon: "♦" }, { icon: "🃏" }, { icon: "🎴" }, { icon: "🀄" }, { icon: "🕐" },
            { icon: "🕑" }, { icon: "🕒" }, { icon: "🕓" }, { icon: "🕔" }, { icon: "🕕" }, { icon: "🕖" }, { icon: "🕗" }, { icon: "🕘" }, { icon: "🕙" }, { icon: "🕚" }, { icon: "🕛" },
            { icon: "🕜" }, { icon: "🕝" }, { icon: "🕞" }, { icon: "🕟" }, { icon: "🕠" }, { icon: "🕡" }, { icon: "🕢" }, { icon: "🕣" }, { icon: "🕤" }, { icon: "🕥" }, { icon: "🕦" },
            { icon: "🕧" },

        ]
    },
]

const Emojis = ({ onClick = () => { } }) => {
    return (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
            {listEmojis.map((groupEmoji, index) => (
                <div className={styles.groupEmojis} key={index}>
                    <span className={styles.title}>{groupEmoji.title}</span>
                    <div className={styles.icons}>
                        {groupEmoji.list.map((emoji, key) => (
                            <div
                                className={styles.icon}
                                key={key}
                                onClick={() => onClick(emoji.icon)}
                            >{emoji.icon}</div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Emojis