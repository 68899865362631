import React, { useEffect, useState } from "react";
import Modal from '../index';
import styles from "./modalMetaPage.module.css"
import Button from "../../Form/Button";
import FormGroupInline from "../../Form/FormGroupInline";
import Select from "../../Form/Select";
import FormGroup from "../../Form/FormGroup";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { getUsersByTeamId } from "../../../utils/utils";

const statusForm = [
    { id: '1', value: "Sim" },
    { id: '0', value: "Não" },
]

const ModalConfigForm = ({ icon, title = "", description = "", questions = [], formConfig = {}, questionsConfig = [], fields = [], funnels = [], stages = [], teams = [], users = [], flags = [], closeModal, onSubmit }) => {
    const [form, setForm] = useState(formConfig)
    const [funnelsForm, setFunnelsForm] = useState([])
    const [stagesForm, setStagesForm] = useState([])
    const [teamsForm, setTeamsForm] = useState([])
    const [usersForm, setUsersForm] = useState([])
    const [flagsForm, setFlagsForm] = useState([])
    const [questionsForm, setQuestionsForm] = useState([])
    const [onlyQuestions, setOnlyQuestions] = useState(false)

    useEffect(() => {
        if (questions.length > 0) {
            setInitialValues()
        }
    }, [questions])

    useEffect(() => {
        if (teams.length === 0 && users.length === 0 && funnels.length === 0 && stages.length === 0) {
            setOnlyQuestions(true)
        }
    }, [])

    useEffect(() => {
        if (funnels.length > 0) {
            setFunnelsForm([{ id: "default", value: "Utilizar padrão da página" }, ...funnels])
        }
    }, [funnels])

    useEffect(() => {
        if (stages.length > 0) {
            setStagesForm([{ id: "default", value: "Utilizar padrão da página" }, ...stages])
        }
    }, [stages])

    useEffect(() => {
        if (teams.length > 0) {
            setTeamsForm([{ id: "default", value: "Utilizar padrão da página" }, ...teams])
        }
    }, [teams])

    useEffect(() => {
        if (users.length > 0) {
            setUsersForm(users)
        }
    }, [users])

    useEffect(() => {
        if (flags.length > 0) {
            setFlagsForm(flags)
        }
    }, [flags])

    useEffect(() => {
        getStages(form?.funnel_id)
    }, [form?.funnel_id])

    const setInitialValues = () => {
        if (questionsConfig.length > 0) {
            questions?.forEach((question, index) => {
                const [questionData] = questionsConfig?.filter(q => q.question_id === question.id)
                if (questionData && questionData?.field_id) {
                    handleChangeValue(questionData.field_id, index)
                }
            })
        } else {
            questions?.forEach((question, index) => {
                let exactResult = false
                fields?.forEach(field => {
                    if (question.type?.toLowerCase() === field.id || question.type?.toLowerCase() === field.value) {
                        handleChangeValue(field.id, index)
                        exactResult = true
                    }
                })
                if (!exactResult) {
                    fields?.forEach(field => {
                        if (question.type?.toLowerCase().indexOf(field.id) !== -1 || question.type?.toLowerCase().indexOf(field.value) !== -1) {
                            handleChangeValue(field.id, index)
                        } else if (field.id?.toString().indexOf(question.type?.toLowerCase()) !== -1 || field.value.indexOf(question.type?.toLowerCase()) !== -1) {
                            handleChangeValue(field.id, index)
                        }
                    })
                }
            })
        }
    }

    const getStages = async (funnel_id) => {
        if (funnel_id && funnel_id !== "default") {
            api.get(`/funnels/${funnel_id}/stages`).then(response => {
                const stages = response.data?.data?.map(stage => { return { id: stage._id, value: stage.name } })
                setStagesForm([{ id: "default", value: "Utilizar padrão da página" }, ...stages])
            }).catch(err => toast.error(err?.response?.data?.message))
        } else {
            setStagesForm([{ id: "default", value: "Utilizar padrão da página" }])
        }
    }

    const getTeamsByFunnelId = (funnel_id) => {
        const idsTeamsFunnel = getIdsTeamsByFunnelId(funnel_id)
        return idsTeamsFunnel ? teamsForm?.filter(team => idsTeamsFunnel?.indexOf(team.id) !== -1) : [{ id: "default", value: "Utilizar padrão da página" }]
    }

    const getIdsTeamsByFunnelId = (funnel_id) => {
        if (funnel_id) {
            const [idsTeamsFunnel] = funnelsForm?.filter(funnel => funnel.id === funnel_id)?.map(funnel => { return funnel.teams })
            return idsTeamsFunnel
        }
        return []
    }

    const handleChangeData = (name, value) => {
        setForm(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangeValue = (field_id, index) => {
        setQuestionsForm(previousValue => {
            const newValue = [...previousValue]
            newValue[index] = field_id
            return newValue
        })
    }

    const handleUpdateForm = () => {
        const dataQuestions = questions?.map((question, index) => {
            return { id: question.id, field_id: questionsForm[index] }
        })
        const dataUpdate = { ...form, questions: dataQuestions }
        onSubmit(dataUpdate)
    }

    return (
        <Modal
            icon={icon}
            title={title}
            description={description}
            closeModal={closeModal}
        >
            <div className={styles.questions}>
                <FormGroup labelText="Leads desse formulário devem ser importados automaticamente?">
                    <Select name="Selecione uma opção" placeholder="Pesquise uma opção" multiple={false} selected={!isNaN(form?.is_active) && Number(form?.is_active) ? '1' : '0'} onChange={(value) => handleChangeData("is_active", value)} options={statusForm} />
                </FormGroup>
                {!isNaN(form?.is_active) && Number(form?.is_active) > 0 &&
                    <>
                        {!onlyQuestions && <>
                            <FormGroup labelText="Funil que receberá os cadastros">
                                <Select name="Funil" placeholder="Pesquise um funil" multiple={false} selected={form?.funnel_id || ""} onChange={(value) => handleChangeData("funnel_id", value)} options={funnelsForm} />
                            </FormGroup>
                            <FormGroup labelText="Etapa que receberá os cadastros">
                                <Select name="Etapa" placeholder="Pesquise um etapa" multiple={false} selected={form?.stage_id || ""} onChange={(value) => handleChangeData("stage_id", value)} options={stagesForm} />
                            </FormGroup>
                            <FormGroup labelText="Equipe que receberá os cadastros">
                                <Select name="Equipe" placeholder="Pesquise uma equipe" multiple={false} selected={form?.team_id || ""} onChange={(value) => handleChangeData("team_id", value)} options={form?.funnel_id ? getTeamsByFunnelId(form?.funnel_id) : teamsForm} />
                            </FormGroup>
                            <FormGroup labelText="Usuários que receberão os cadastros (Deixe em branco para utilizar padrão)">
                                <Select name="Usuários" placeholder="Pesquise um usuário" multiple={true} selected={form?.users || []} onChange={(value) => handleChangeData("users", value)} options={form?.team_id ? getUsersByTeamId(form?.team_id, usersForm, teamsForm, [{ id: "auto", value: "Rodízio automático" }], (user) => { return user.id === "auto" }) : usersForm} />
                            </FormGroup>
                            <FormGroup labelText="Etiquetas">
                                <Select name="Etiquetas" placeholder="Pesquise uma etiqueta" multiple={true} selected={form?.flags || []} onChange={(value) => handleChangeData("flags", value)} options={flagsForm} />
                            </FormGroup>
                        </>}
                        {questions?.length > 0 && questions.map((question, index) => (
                            <div key={index}>
                                <FormGroup labelText={question.label}>
                                    <Select multiple={false} name="Selecione o campo correspondente" placeholder="Selecione um campo" options={fields || []} selected={questionsForm[index] ?? ""} onChange={(value) => handleChangeValue(value, index)} />
                                </FormGroup>
                            </div>
                        ))}
                    </>
                }
                <FormGroupInline>
                    <Button className='bg-pink' onClick={handleUpdateForm}>Salvar</Button>
                </FormGroupInline>
            </div>
        </Modal>
    )
}

export default ModalConfigForm