import React, { useEffect, useState } from "react"
import styles from "./integration.module.css"
import Button from "../../components/Form/Button"
import Table from "../../components/Table"
import { BsMeta } from 'react-icons/bs'
import { TbApi, TbWebhook } from "react-icons/tb"
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineChatBubbleOutline } from "react-icons/md"
import { useAuth } from "../../contexts/authContext"
import { sortValuesByPropertyAndDirection } from "../../utils/utils"

const Integration = () => {
    const [data, setData] = useState([])
    const { account_id } = useParams()
    const navigate = useNavigate()
    const { verifyIfModuleIsActive } = useAuth()

    const headers = [
        "#",
        "Nome",
        "Descrição",
        "",
        "",
    ]

    useEffect(() => {
        getDataByTable()
    }, [])

    const getDataByTable = () => {
        const data = [
            {
                id: 1,
                value: [
                    "API",
                    "Conecte seu CRM a sites, landing pages e outros sistemas para receber seus cadastros automaticamente",
                    <TbApi className="icon-md" />,
                    <Button className="bg-pink">Configurar</Button>,
                ],
                path: `/${account_id}/integrations/api`,
            },
            {
                id: 3,
                value: [
                    "Meta Ads",
                    "Conecte suas páginas e receba seus cadastros em tempo real",
                    <BsMeta className="icon-md" />,
                    <Button className="bg-pink">Configurar</Button>,
                ],
                path: `/${account_id}/integrations/meta`,
            },
            {
                id: 4,
                value: [
                    "Webhook",
                    "Configure seus webhooks e receba atualizações sobre a plataforma",
                    <TbWebhook className="icon-md" />,
                    <Button className="bg-pink">Configurar</Button>,
                ],
                path: `/${account_id}/integrations/webhook`,
            },
        ]

        if (verifyIfModuleIsActive("CHAT")) {
            data.push(
                {
                    id: 2,
                    value: [
                        "Chat",
                        "Conecte suas caixas de entrada e gerencie suas mensagens em um só lugar!",
                        <MdOutlineChatBubbleOutline className="icon-md" />,
                        <Button className="bg-pink">Configurar</Button>,
                    ],
                    path: `/${account_id}/integrations/chat`,
                }
            )
        }

        setData(data.sort((a, b) => sortValuesByPropertyAndDirection(a, b, "id")))
    }

    const handleNavigateToPage = (id) => {
        data?.forEach(el => {
            if (el.id === id) {
                navigate(el.path)
            }
        })
    }

    return (
        <div className={styles.container}>
            <Table
                headers={headers}
                values={data}
                onClick={(id) => handleNavigateToPage(id)}
                messageNotRegisters="Nenhum registro encontrado"
                showIndex={true}
            />
        </div>
    )
}

export default Integration