import React, { createContext, useContext, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useAuth } from './authContext'
import { useParams } from 'react-router-dom'

const WebSocketContext = createContext()

const WebSocketProvider = ({ children }) => {
    const { token, verifyUserLogged } = useAuth()
    const { account_id } = useParams()
    const wsUrl = `${process.env.REACT_APP_SERVER_HOST_WS}`

    const socketOptions = {
        onMessage: (event) => {
            if (event.data === 'ping') {
                sendMessage("pong")
            }
        },
        queryParams: { token, account_id },
        share: true,
        shouldReconnect: () => true,
        reconnectInterval: 3000
    }

    const { lastJsonMessage, readyState, sendMessage, sendJsonMessage } = useWebSocket(wsUrl, socketOptions)

    useEffect(() => {
        if (lastJsonMessage?.action === "VERIFY_AUTH") {
            verifyUserLogged(true)
        }
    }, [lastJsonMessage])

    return (
        <WebSocketContext.Provider value={{ lastJsonMessage, readyState, sendJsonMessage, ReadyState }}>
            {children}
        </WebSocketContext.Provider>
    )
}

const useWebSocketContext = () => {
    return useContext(WebSocketContext)
}

export { useWebSocketContext }

export default WebSocketProvider