import React, { useState } from "react";
import styles from './menu.module.css'
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { useHeader } from "../../../contexts/headerContext";

const Item = ({ icon, text, to }) => {
    const [showLegend, setShowLegend] = useState(false)
    const [stylesLegend, setStylesLegend] = useState({})
    const resolved = useResolvedPath(to)
    const isActive = useMatch({ path: `${resolved.pathname}/*`, end: true })
    const { handleHideMenu } = useHeader()

    const handleShowLegend = (e) => {
        const rect = e.currentTarget.getBoundingClientRect()
        const top = `${rect.top + window.scrollY}px`
        setStylesLegend({ top: top })
        setShowLegend(true)
    }

    const handleHiddenLegend = () => {
        setShowLegend(false)
    }

    return (
        <div className={styles.groupLink}>
            <NavLink className={`${isActive ? styles.active : ''} ${styles.item} `} to={to} onMouseEnter={handleShowLegend} onMouseLeave={handleHiddenLegend} onClick={handleHideMenu}>
                {icon}
            </NavLink>
            {showLegend && (
                <span style={stylesLegend} className={styles.legend}>{text}</span>
            )}
        </div>
    )
}

export default Item