import { useEffect, useState } from "react"
import api from "../../services/api"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import InitialSetup from "./InitialSetup"
import { useData } from "../../contexts/dataContext"
import Loading from "../layouts/Loading"
import NotFunnel from "./NotFunnel"

const SelectFunnel = () => {
    const { account_id } = useParams()
    const [initialLoading, setInitialLoading] = useState(true)
    const [setupCompleted, setSetupCompleted] = useState(false)
    const [statusSetup, setStatusSetup] = useState({})
    const [welcomeCompleted, setWelcomeCompleted] = useState(false)
    const [notFunnels, setNotFunnels] = useState(false)
    const [loading, setLoading] = useState(false)

    const { funnels, lastFunnel, loadFunnels } = useData()

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        api.defaults.headers.common['x-api-key'] = account_id
        if (account_id) {
            verifyInitialSetup()
        }
    }, [account_id])

    useEffect(() => {
        if (account_id && funnels?.length > 0) {
            verifyInitialSetup()
        }
    }, [account_id, funnels])

    useEffect(() => {
        if (account_id && setupCompleted) {
            verifyInitialSetup()
        }
    }, [account_id, setupCompleted])

    const finishSetup = () => {
        setSetupCompleted(true)
    }

    const redirectWithQueryStrings = (path) => {
        const currentQueryStrings = location.search
        const newPath = `${path}${currentQueryStrings}`
        navigate(newPath)
    }

    const verifyInitialSetup = async (redirectToFinish = false) => {
        if (!loading) {
            setLoading(true)
            if (lastFunnel) {
                redirectWithQueryStrings(`/${account_id}/crm/${lastFunnel}`)
                setSetupCompleted(true)
                setLoadingToFalse()
            } else {
                try {
                    const response = await api.get("/accounts/setup")
                    const setup = response?.data?.data
                    const setupCompleted = setup?.setupCompleted
                    if (setupCompleted) {
                        if (redirectToFinish) {
                            setSetupCompleted(false)
                        } else {
                            if (!funnels?.length) {
                                const funnels = await loadFunnels()
                                if (!funnels?.length) {
                                    setNotFunnels(true)
                                    setSetupCompleted(setupCompleted)
                                    return setLoadingToFalse()
                                }
                            }
                            if (setup?.funnel_id || lastFunnel) {
                                const funnel_id = setup?.funnel_id ? setup.funnel_id : lastFunnel
                                setSetupCompleted(true)
                                redirectWithQueryStrings(`/${account_id}/crm/${funnel_id}`)
                            } else {
                                setSetupCompleted(false)
                            }
                        }
                    } else {
                        setSetupCompleted(false)
                    }
                    setStatusSetup(setup)
                    setLoadingToFalse()
                } catch (err) {
                    toast.error(err?.response?.data?.message)
                    setLoadingToFalse()
                }
            }
        }
    }

    const setLoadingToFalse = () => {
        setLoading(false)
        setInitialLoading(false)
    }

    return (
        <>
            {
                !initialLoading && !loading && !setupCompleted && !notFunnels &&
                < InitialSetup
                    statusSetup={statusSetup}
                    finishSetup={finishSetup}
                    verifyInitialSetup={verifyInitialSetup}
                    welcomeCompleted={welcomeCompleted}
                    setWelcomeCompleted={setWelcomeCompleted}
                />
            }
            {setupCompleted && notFunnels && <NotFunnel />}
            {(initialLoading || loading) && <Loading fullPage={true} />}
        </>
    )
}

export default SelectFunnel