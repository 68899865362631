import React, { useEffect, useState } from "react"
import styles from "./modalImport.module.css"
import Modal from '../index'
import { MdOutlineFileUpload } from "react-icons/md"
import ConfigSend from "./ConfigSend"
import { toast } from "react-toastify"
import VerifyFields from "./VerifyFields"
import { getUsersByTeamId } from "../../../utils/utils"
import { useData } from "../../../contexts/dataContext"
import { getStages } from "../../../schemas/Stage"

const ModalImport = ({ dataFile = [], verifyNameIsValid = async () => { }, onSubmit = () => { }, closeModal = () => { } }) => {
    const [dataConfig, setDataConfig] = useState({})
    const [dataVerifyFields, setDataVerifyFields] = useState([])
    const [stages, setStages] = useState([])
    const [step, setStep] = useState(1)

    const { funnels, teams, users, flagsByoptions, fields, origins } = useData()

    useEffect(() => {
        if (dataConfig?.funnel_id) {
            changeStages(dataConfig?.funnel_id)
        } else {
            setStages([])
        }
    }, [dataConfig?.funnel_id])

    const handleChangeValueConfig = (name, value) => {
        setDataConfig(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleChangeValueVerifyFields = (value, index) => {
        setDataVerifyFields(previousValue => {
            const newValue = [...previousValue]
            newValue[index] = value
            return newValue
        })
    }

    const handleChangeStep = async (newStep) => {
        if (newStep > step) {
            if (!dataConfig?.name) {
                toast.error("O nome da importação é obrigatório!")
            }
            const nameIsValid = await verifyNameIsValid(dataConfig?.name)
            if (!nameIsValid) {
                return
            }
        }
        setStep(newStep)
    }

    const getTeamsByFunnelId = (funnel_id) => {
        const idsTeamsFunnel = getIdsTeamsByFunnelId(funnel_id)
        return teams?.filter(team => idsTeamsFunnel.indexOf(team.id) !== -1) || []
    }

    const getIdsTeamsByFunnelId = (funnel_id) => {
        if (funnel_id) {
            const [idsTeamsFunnel] = funnels?.filter(funnel => funnel.id === funnel_id)?.map(funnel => { return funnel.teams })
            return idsTeamsFunnel
        }
        return []
    }

    const changeStages = async (funnel_id) => {
        getStages(funnel_id).then(stages => {
            setStages(stages?.map(stage => { return { id: stage._id, value: stage.name } }))
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    return (
        <Modal
            icon={<MdOutlineFileUpload />}
            title={step === 1 ? "Configurações de importação" : (step === 2 ? "Combinar campos e colunas" : "Confirmar combinação")}
            description={step === 1 ? "Preencha os campos abaixo, ou deixe em branco para informações que tenham dados diferentes para cada registro" : (step === 2 ? "Determine a quais campos os dados importados se referem" : "Confirme se as colunas correspondem aos campos selecionados!")}
            closeModal={closeModal}
        >
            <div className={styles.body}>
                {step === 1 &&
                    <ConfigSend
                        handleChangeValue={handleChangeValueConfig}
                        handleChangeStep={handleChangeStep}
                        data={dataConfig}
                        funnels={funnels}
                        stages={stages}
                        origins={origins}
                        teams={dataConfig?.funnel_id ? getTeamsByFunnelId(dataConfig?.funnel_id) : teams}
                        users={dataConfig?.team_id ? getUsersByTeamId(dataConfig?.team_id, users, teams, [{ id: "auto", value: "Rodízio automático" }], (user) => { return user.id === "auto" }) : users}
                        flags={flagsByoptions}
                    />
                }
                {step >= 2 &&
                    <VerifyFields
                        handleChangeValue={handleChangeValueVerifyFields}
                        handleChangeStep={handleChangeStep}
                        step={step}
                        data={dataVerifyFields}
                        fields={fields}
                        headers={dataFile[0]}
                        onSubmit={() => onSubmit(dataConfig, dataVerifyFields)}
                    />
                }
            </div>
        </Modal>
    )
}

export default ModalImport