import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import api from "../../../services/api"
import Header from "../Header"
import Sidebar from "../Menu"
import Message from "../../../components/Message"
import ModalConfigForm from "../../../components/Modal/ModalMetaPage/ModalConfigForm"
import RenderIfUserHasPermission from "../../../components/RenderIfUserHasPermission"
import { HeaderProvider } from "../../../contexts/headerContext"
import WebSocketProvider from "../../../contexts/webSocketContext"
import WebSocketChatProvider from "../../../contexts/webSocketChatContext"
import { useData } from "../../../contexts/dataContext"
import VoipProvider from "../../../contexts/voipContext"
import { useAuth } from "../../../contexts/authContext"
import { BsMeta } from "react-icons/bs"
import { toast } from "react-toastify"
import { isValidAccountId, openLinkExternal } from "../../../utils/utils"

const Content = ({ title, namePermission = undefined, resetConfig = false, children }) => {
    const [formData, setFormData] = useState([])
    const [formConfig, setFormConfig] = useState({})
    const [questionsConfig, setQuestionsConfig] = useState([])
    const [allFields, setAllFields] = useState([])
    const [showModalConfigForms, setShowModalConfigForms] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [accountExpiresIn, setAccountExpiresIn] = useState()
    const [showMessage, setShowMessage] = useState(false)

    const { account_id } = useParams()
    const { setAccountId, fields, resetAllData } = useData()
    const { getAccount, statusAccount } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if (resetConfig) {
            api.defaults.headers.common['x-api-key'] = undefined
            setNotifications([])
            resetAllData()
        }
    }, [resetConfig])

    useEffect(() => {
        if (account_id) {
            api.defaults.headers.common['x-api-key'] = account_id
            if (isValidAccountId(account_id)) {
                getAccount(account_id)
                getAllNotifications()
                setAccountId(account_id)
                verifyIfAccountExpires()
            } else {
                navigate("/")
            }
        }
    }, [account_id])

    useEffect(() => {
        if (statusAccount?.daysToExpires != null && accountExpiresIn == null) {
            verifyIfAccountExpires()
        }
    }, [statusAccount])

    useEffect(() => {
        if (fields?.length > 0)
            setAllFields([{ id: -1, value: "Ignorar campo" }, ...fields])
    }, [fields])

    const verifyIfAccountExpires = () => {
        const daysToExpires = statusAccount?.daysToExpires
        if (daysToExpires <= 2) {
            setAccountExpiresIn(daysToExpires)
            setShowMessage(true)
        }
    }

    const closeMessage = () => {
        setShowMessage(false)
    }

    const getHeaderProviderAndChildren = () => (
        <HeaderProvider>
            <Header titleDefault={title} showOptions={!!account_id} initialNotifications={notifications} />
            <div className="content">
                {account_id &&
                    <Sidebar />
                }
                <div className="container">
                    <RenderIfUserHasPermission namePermission={namePermission}>
                        {showMessage && <Message
                            closeMessage={closeMessage}
                            text={`Lembrete: Sua licença ${accountExpiresIn > 0 ? `expira em ${accountExpiresIn} ${accountExpiresIn > 1 ? "dias" : "dia"}` : (accountExpiresIn === 0 ? "expira hoje" : (accountExpiresIn === -1 ? "expirou ontem" : `expirou há ${-accountExpiresIn} dias`))}. ${accountExpiresIn >= 0 ? "Não se preocupe, basta renová-la" : "Renove agora"} para continuar desfrutando da plataforma sem interrupções!`}
                        />}
                        {children}
                    </RenderIfUserHasPermission>
                </div>

            </div>
            {showModalConfigForms &&
                <ModalConfigForm
                    icon={<BsMeta />}
                    title={formData?.name}
                    description="Configurar campos"
                    fields={allFields}
                    questions={formData?.questions}
                    formConfig={formConfig}
                    questionsConfig={questionsConfig}
                    closeModal={handleCloseModalConfigForm}
                    onSubmit={handleSaveUpdateForm}
                />
            }
        </HeaderProvider>
    )

    const getAllNotifications = async () => {
        api.get("/accounts/notifications").then(response => {
            const notifications = response?.data?.data
            if (notifications.length > 0) {
                notifications?.forEach(notification => {
                    setNotifications(previousValue => {
                        const newValue = [...previousValue]
                        newValue.push({ id: notification.id, type: notification.type, message: notification.message, data: notification.data, onClick: () => handleClickNotification(notification.type, notification.data) })
                        return newValue
                    })
                })
            }
        }).catch(err => { })
    }

    const handleCloseModalConfigForm = () => {
        setFormData([])
        setShowModalConfigForms(false)
    }

    const handleSaveUpdateForm = async (data) => {
        api.patch(`/integrations/meta/accounts/${formData.page_id}/forms/${formData.id}`, data).then(response => {
            toast.success(response.data.message)
            handleCloseModalConfigForm()
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    const handleClickNotification = (type, data) => {
        if (type === "meta_ads") {
            handleShowModalConfigForm(data.page_id, data.form_id)
        } else if (type === "task_schedule") {
            openLinkExternal(data?.link)
        }
    }

    const handleShowModalConfigForm = (page_id, form_id) => {
        api.get(`/integrations/meta/accounts/${page_id}/forms/${form_id}/questions`).then(response => {
            const data = response?.data?.data || []
            setFormData(data.form)
            setFormConfig(data.formConfig)
            setQuestionsConfig(data.questionsConfig)
            setShowModalConfigForms(true)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    return (
        account_id ?
            <WebSocketProvider >
                <WebSocketChatProvider>
                    <VoipProvider>
                        {getHeaderProviderAndChildren()}
                    </VoipProvider>
                </WebSocketChatProvider>
            </WebSocketProvider>
            :
            <WebSocketProvider >
                {getHeaderProviderAndChildren()}
            </WebSocketProvider>

    )
}

export default Content