import React, { useEffect, useState } from "react"
import api from "../../services/api"
import styles from './auth.module.css'

import Input from "../../components/Form/Input"
import Button from "../../components/Form/Button"
import { useNavigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import HeaderAuth from "./HeaderAuth"
import FormGroup from "../../components/Form/FormGroup"
import { useAuth } from "../../contexts/authContext"
import Loading from "../layouts/Loading"

const Welcome = () => {
    const [user, setUser] = useState({})
    const [loaded, setLoaded] = useState(false)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')
    const redirect = searchParams.get('redirect')

    const { finishWelcome, logout } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            verifyIfTokenIsValid(token)
        }
    }, [token])

    const verifyIfTokenIsValid = async (token) => {
        api.post("/users/validate-user/verify-token", {
            token
        }).then(response => {
            const userHasNameAndPassword = response?.data?.data?.userHasNameAndPassword
            if (userHasNameAndPassword) {
                navigate("/login")
                logout()
                toast.success(response?.data?.message)
            }
            setLoaded(!userHasNameAndPassword)
        }).catch(err => {
            navigate("/login")
            toast.error(err?.response?.data?.message)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (await finishWelcome(user, token)) {
            navigate(redirect || "/")
        }
    }

    return (
        loaded ?
            <>
                <HeaderAuth />
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.groupForm}>
                            <div className={styles.description}>
                                <h1>Seja bem vindo(a)!</h1>
                                <span>Para realizar seu primeiro acesso é necessário completar seu cadastro!</span>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <FormGroup labelText="Nome Completo">
                                    <Input
                                        type="text"
                                        placeholder="Nome completo"
                                        value={user?.name || ""}
                                        onChange={(e) => setUser({ ...user, name: e.target.value })}
                                    />
                                </FormGroup>
                                {token && <>
                                    <FormGroup labelText="Defina uma senha">
                                        <Input
                                            type="password"
                                            placeholder="Senha"
                                            value={user?.password || ""}
                                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup labelText="Confirme sua senha">
                                        <Input
                                            type="password"
                                            placeholder="Confirmação de senha"
                                            value={user?.confirmPassword || ""}
                                            onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
                                        />
                                    </FormGroup>
                                </>}
                                <Button className="bg-pink" disabled={!user?.name || !user?.password || !user?.confirmPassword}>Concluir Cadastro</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </>
            :
            <Loading fullPage={true} />
    )
}

export default Welcome