import React, { memo } from 'react'
import { formatDataSizeInKb } from '../../utils/formatter'

const FilePreview = memo(({ file, type, styles }) => {
    if (type === 'image') {
        return <img src={URL.createObjectURL(file)} alt="Imagem" />
    } else if (type === 'video') {
        return <video src={URL.createObjectURL(file)} controls alt="Vídeo" />
    }
    else if (type === 'document') {
        return (
            <div className={styles.documentPreview}>
                <img src="https://oktto-models.s3.sa-east-1.amazonaws.com/files/document-chat.jpg" alt="Documento" />
                <span>{file.name} - {formatDataSizeInKb(file.size)}</span>
            </div>
        )
    }
    return null
})

export default FilePreview
