import React, { useEffect } from "react";
import styles from './form.module.css'
import { GoSearch } from 'react-icons/go';
import { maskCurrency, maskPhone, maskZipCode, currencyToFloat, getNumbersByString, maskDocument, formatCurrency, formatPhoneNumber } from "../../utils/formatter";

const Input = ({ type, placeholder, value, onChange, className, theme, required, disabled = false, autoFocus = false }) => {
    useEffect(() => {
        if (type === "currency") {
            const e = {
                target: {
                    value: formatCurrency(value)
                }
            }
            handleChange(e)
        }
    }, [])

    const handleChange = (e) => {
        if (type === "currency") {
            e.target.value = currencyToFloat(e.target.value)
        } else if (type === "phone") {
            e.target.value = `${formatPhoneNumber(e.target.value)}`
        } else if (type === "zipCode" || type === "document") {
            e.target.value = getNumbersByString(e.target.value)
        }
        onChange(e)
    }

    const getValue = () => {
        if (type === "currency") {
            return maskCurrency(value)
        } else if (type === "phone") {
            return maskPhone(value)
        } else if (type === "zipCode") {
            return maskZipCode(value)
        } else if (type === "document") {
            return maskDocument(value)
        }
        return value
    }

    const getInput = () => (
        <input
            type={type === "search" ? "text" : type}
            placeholder={placeholder}
            value={getValue()}
            onChange={handleChange}
            className={`${styles.input} ${styles[className]} ${styles[theme]}`}
            required={required}
            autoFocus={autoFocus}
            tabIndex={1}
            disabled={disabled}
        />
    )

    return (
        <>
            {(type === "search" ?
                <div className={styles.divInput}>
                    {getInput()}
                    <div className={styles.iconSearch}>
                        <GoSearch />
                    </div>
                </div>
                :
                getInput()
            )}
        </>
    )
}

export default Input