import React from "react"
import styles from "./conversationNotSelected.module.css"
import { MdOutlineChatBubbleOutline } from "react-icons/md"
import { HiPlus } from "react-icons/hi"
import { BiFilter } from "react-icons/bi"

const ConversationNotSelected = () => {
    return (
        <div className={styles.conversationNotSelected}>
            <MdOutlineChatBubbleOutline className={styles.icon} />
            <span className={styles.title}>Oktto Chat</span>
            <span className={styles.subtitle}>Comunique-se por multiplos canais e gerencie em um só lugar!</span>
            <span>Clique sobre uma conversa existente para visualizar suas mensagens!</span>
            <span>Inicie uma nova conversa clicando no botão <HiPlus /></span>
            <span>Altere a visibilidade dos filtros adicionais clicando no botão <BiFilter /></span>
        </div>
    )
}

export default ConversationNotSelected