import React from "react";
import styles from "./form.module.css"

const FormGroup = ({ inside = false, fullWidth = true, labelText, rightContent, children, onClickHeader = () => { } }) => {
    return (
        <div className={`${styles.formGroup} ${fullWidth ? styles.fullWidth : ''} ${inside ? styles.inside : ''}`}>
            <div className={styles.header} onClick={onClickHeader}>
                {labelText && <label>{labelText}</label>}
                {rightContent &&
                    <div className={styles.rightContent}>
                        {rightContent}
                    </div>
                }
            </div>
            <div className={styles.body}>
                {children}
            </div>
        </div>
    )
}

export default FormGroup