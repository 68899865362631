import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "./audioPlayer.module.css"
import { useWavesurfer } from '@wavesurfer/react'
import { formatSecondsToTime } from '../../../utils/formatter'
import { MdOutlinePause, MdPlayArrow } from 'react-icons/md'
import CircleLoading from './../../CircleLoading'

const AudioPlayer = ({ src, preload, style, pink = true }) => {
    const containerRef = useRef(null)
    const [waveSurferError, setWaveSurferError] = useState(false)
    const [loading, setLoading] = useState(true)

    const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
        container: containerRef,
        height: 30,
        waveColor: '#363636',
        progressColor: pink ? '#d2417c' : '#6245ae',
        barWidth: 2,
        barGap: 2,
        barRadius: 3,
        url: src,
    })

    const tooglePlayPause = useCallback(() => {
        wavesurfer && wavesurfer.playPause()
    }, [wavesurfer])

    useEffect(() => {
        if (wavesurfer) {
            const handleError = () => {
                setWaveSurferError(true)
                setLoading(false)
            }
            const handleReady = () => {
                setWaveSurferError(false)
                setLoading(false)
            }

            wavesurfer.on('error', handleError)
            wavesurfer.on('ready', handleReady)

            return () => {
                wavesurfer.un('error', handleError)
                wavesurfer.un('ready', handleReady)
            }
        }
    }, [wavesurfer])

    return (
        <>
            <div className={`${styles.container} ${waveSurferError ? '' : styles.hidden}`} style={style}>
                <audio src={src} preload={preload} style={{ width: '100%' }} controls />
            </div>
            <div className={`${styles.container} ${waveSurferError ? styles.hidden : ''}`}>
                <div className={styles.controls} onClick={tooglePlayPause}>
                    {isPlaying ? <MdOutlinePause /> : <MdPlayArrow />}
                </div>
                <div className={`${styles.audio} ${loading ? styles.hidden : ''}`} ref={containerRef}></div>
                {loading &&
                    <div className={`${styles.loading} ${pink ? '' : styles.purple}`}>
                        <CircleLoading className={styles.icon} />
                    </div>
                }
                <div className={styles.time}><span>{formatSecondsToTime(currentTime, false)}</span></div>
            </div>
        </>
    )
}

export default AudioPlayer
