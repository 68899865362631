import React, { createContext, useContext, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useAuth } from './authContext'
import { useParams } from 'react-router-dom'
import { getAllContacts, getAllFlags } from '../schemas/Chat'
import { getItemForArrayById } from '../utils/utils'
import { maskPhone } from '../utils/formatter'
import { toast } from 'react-toastify'
import api from '../services/api'
import ModalStartChat from '../components/Modal/Chat/ModalStartChat'
import Loading from '../pages/layouts/Loading'

const WebSocketChatContext = createContext()

const WebSocketChatProvider = ({ children }) => {
    const [chatContacts, setChatContacts] = useState([])
    const [chatInboxes, setChatInboxes] = useState([])
    const [chatFlags, setChatFlags] = useState([])
    const [connect, setConnect] = useState(true)
    const [dataStartChat, setDataStartChat] = useState(false)
    const [showModalStartChat, setShowModalStartChat] = useState(false)
    const [loading, setLoading] = useState(false)

    const { token } = useAuth()
    const { account_id } = useParams()
    const wsUrl = `${process.env.REACT_APP_SERVER_HOST_CHAT_WS}`

    const socketOptions = {
        onMessage: (event) => {
            if (event.data === 'ping') {
                sendMessage("pong")
            }
        },
        queryParams: { token, account_id },
        share: true,
        shouldReconnect: () => true,
        reconnectInterval: 3000
    }

    const { lastJsonMessage, readyState, sendMessage, sendJsonMessage } = useWebSocket(wsUrl, socketOptions, connect)

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    useEffect(() => {
        if (!connect) {
            setConnect(true)
        }
    }, [connect])

    const updateDataByMessageWebSocket = (message) => {
        const event = message?.event
        const content = message?.content
        switch (event) {
            case "UPDATE_CONTACT":
                updateChatContact(content)
                break
            default:
                break
        }
    }
    const getAllChatContacts = async () => {
        if (chatContacts.length <= 0) {
            getAllContacts().then(contacts => {
                setChatContacts(contacts)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
            })
        }
    }
    const getAllChatInboxes = () => {
        api.get("/chat/inboxes").then(response => {
            const inboxes = response.data?.data || []
            setChatInboxes(inboxes)
        }).catch(err => toast.error(err?.response?.data?.message))
    }
    const getAllChatFlags = () => {
        getAllFlags().then(flags => {
            setChatFlags(flags)
        }).catch(err => toast.error(err?.response?.data?.message))
    }
    const getContactByIdAndPlatform = (contact_id, platform) => {
        const contactData = getItemForArrayById(chatContacts, "_id", contact_id)
        const name = platform === "whatsapp" ? contactData?.name || contactData?.whatsapp?.number : ""
        const picture = platform === "whatsapp" ? contactData?.whatsapp?.picture : ""
        const identifier = getIdentifierContactByPlatform(contactData, platform)
        return { name, picture, identifier }
    }
    const getIdentifierContactByPlatform = (contact, platform) => {
        if (platform === "whatsapp") {
            return contact?.whatsapp?.number ? maskPhone(contact.whatsapp.number) : ""
        }
    }
    const updateChatContact = (newContact) => {
        const contact_id = newContact?._id
        if (contact_id) {
            setChatContacts(previousValue => {
                const contactExists = previousValue.find(contact => contact._id === contact_id)
                if (contactExists) {
                    return previousValue.map(contact => {
                        if (contact._id === contact_id) {
                            return { ...contact, ...newContact }
                        }
                        return contact
                    })
                } else {
                    return [...previousValue, newContact]
                }
            })
        }
    }
    const handleReconnect = () => {
        setConnect(false)
    }
    const handleOpenModalStartChat = (data) => {
        setShowModalStartChat(true)
        setDataStartChat(data)
    }
    const handleCloseModalStartChat = () => {
        setShowModalStartChat(false)
        setDataStartChat({})
    }
    const startConversationByInboxAndContactId = (message) => {
        setLoading(true)
        sendJsonMessage({ event: "START_CHAT", message })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    return (
        <WebSocketChatContext.Provider value={{ lastJsonMessage, readyState, sendJsonMessage, handleReconnect, ReadyState, chatContacts, getAllChatContacts, chatInboxes, getAllChatInboxes, chatFlags, getAllChatFlags, getContactByIdAndPlatform, handleOpenModalStartChat, handleCloseModalStartChat }}>
            {children}
            {showModalStartChat &&
                <ModalStartChat
                    value={dataStartChat}
                    inboxes={chatInboxes.filter(inbox => inbox.status === "connected").map(inbox => ({ id: inbox._id, value: inbox.name }))}
                    contacts={chatContacts}
                    onSubmit={startConversationByInboxAndContactId}
                    closeModal={handleCloseModalStartChat}
                />
            }
            {showModalStartChat && loading && <Loading fullPage={true} />}
        </WebSocketChatContext.Provider>
    )
}

const useWebSocketChatContext = () => {
    return useContext(WebSocketChatContext)
}

export { useWebSocketChatContext }

export default WebSocketChatProvider