import React, { useEffect, useState } from "react"
import api from "../../services/api"
import styles from './auth.module.css'
import Button from "../../components/Form/Button"
import { useNavigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import HeaderAuth from "./HeaderAuth"

const Invite = () => {
    const [data, setData] = useState({})
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')

    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            verifyIfTokenIsValid(token)
        }
    }, [token])

    const verifyIfTokenIsValid = async (token) => {
        api.post("/users/invite/verify-token", {
            token
        }).then(response => {
            const data = response.data?.data
            if (!data?.userHasNameAndPassword) {
                const tokenWelcome = data?.tokenWelcome
                navigate(`/welcome?token=${tokenWelcome}&redirect=/invite?token=${token}`)
                toast.success("Para aceitar o convite é necessário concluir o seu cadastro!")
            }
            setData(data)
        }).catch(err => {
            navigate("/")
            toast.error(err?.response?.data?.message)
        })
    }

    const handleAcceptInvite = () => {
        api.post(`/users/invite/${token}`).then(response => {
            navigate(`/`)
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    return (
        <>
            <HeaderAuth />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.inviteForm}>
                        <img src="https://oktto-models.s3.sa-east-1.amazonaws.com/emails/default/header.png" alt="Logo" />
                        <div className={styles.text}>
                            <h1>{data.userInvite} te convidou para entrar na conta {data.accountName}!</h1>
                            <span>Se deseja aceitar, clique no botão abaixo!</span>
                            <Button className="bg-pink" onClick={handleAcceptInvite}>Juntar-se a {data.accountName}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invite