import React, { useState } from "react"
import Modal from './index'
import Input from '../Form/Input'
import FormGroup from "../Form/FormGroup"
import Button from "../Form/Button"
import FormGroupInline from '../Form/FormGroupInline'
import { toast } from "react-toastify"

const ModalDelete = ({ id, icon, title = "", description = "", text, onSubmit, closeModal }) => {
    const [textValidate, setTextValidate] = useState()
    const passValidate = "excluir"

    const handleChangeValue = (value) => {
        setTextValidate(value)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSubmit = (e, id) => {
        e.preventDefault()
        if (textValidate === passValidate) {
            onSubmit(id)
        } else {
            toast.error("Preencha todos os campos antes de prosseguir")
        }
    }

    return (
        <Modal
            icon={icon}
            title={title}
            description={description}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => handleSubmit(e, id)}>
                {text &&
                    <FormGroup>
                        {text}
                    </FormGroup>
                }
                <FormGroupInline>
                    <FormGroup labelText={`Digite "${passValidate}" para prosseguir com a exclusão`}>
                        <Input type="text" placeholder={`Digite "${passValidate}" para prosseguir com a exclusão`} onChange={(e) => handleChangeValue(e.target.value)} textInvalid={`Digite "${passValidate}" para prosseguir com a exclusão`} />
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Excluir</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalDelete