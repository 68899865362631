import React, { useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { MdWorkOutline } from "react-icons/md";
import FormGroupInline from '../Form/FormGroupInline';
import Select from '../Form/Select';
import { FaPencilAlt } from "react-icons/fa";
import { getOptionByOptionList } from "../../utils/utils";
import { useAuth } from "../../contexts/authContext";

const ModalRole = ({ isNew = false, value = {}, editable = false, setEdit, onSubmit, closeModal }) => {
    const [role, setRole] = useState(value)
    const [permissions, setPermissions] = useState(value.permissions || {})

    const { verifyIfModuleIsActive } = useAuth()

    const optionsAccess = [
        { id: 0, value: "Sem acesso" },
        { id: 1, value: "Acesso Parcial" },
        { id: 2, value: "Acesso Total" },
    ]
    const optionsAccessManager = [
        { id: 0, value: "Sem acesso" },
        { id: 1, value: "Acesso Total" },
    ]
    const optionsRodizio = [
        { id: 0, value: "Não, usuários desse cargo não participam do rodízio automático" },
        { id: 1, value: "Sim, usuários desse cargo recebem leads do rodízio automático" },
    ]

    const handleChangeValue = (name, value) => {
        setRole(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleChangeValuePermissions = (name, value) => {
        setPermissions(previousValue => {
            const newValue = { ...previousValue, [name]: value }
            handleChangeValue("permissions", newValue)
            return newValue
        })

    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setRole(value)
            setPermissions(value.permissions || {})
        }
    }

    return (
        <Modal
            icon={<MdWorkOutline />}
            title={isNew ? "Cadastro de novo cargo" : (editable ? `Editando o cargo: ${role.name}` : `Visualizando: ${role.name}`)}
            description={isNew ? "Adicione um novo cargo a sua conta" : (editable ? "Altere as informações abaixo" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, role)}>
                <FormGroupInline>
                    <FormGroup labelText="Nome">
                        {editable ?
                            <Input type="text" placeholder="Nome do cargo" value={role.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} invalid={true} textInvalid="Preencha o nome do cargo" />
                            :
                            <span>{role.name}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Dashboard">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.dashboard ?? ''} onChange={(value) => handleChangeValuePermissions("dashboard", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.dashboard, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="CRM">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccess} selected={permissions?.crm ?? ''} onChange={(value) => handleChangeValuePermissions("crm", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.crm, optionsAccess)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Gerenciar Cargos">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.manage_roles ?? ''} onChange={(value) => handleChangeValuePermissions("manage_roles", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.manage_roles, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Gerenciar Equipes">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.manage_teams ?? ''} onChange={(value) => handleChangeValuePermissions("manage_teams", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.manage_teams, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Gerenciar Usuários">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.manage_users ?? ''} onChange={(value) => handleChangeValuePermissions("manage_users", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.manage_users, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Configurações da Conta">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.settings ?? ''} onChange={(value) => handleChangeValuePermissions("settings", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.settings, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Importação em Massa">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.import ?? ''} onChange={(value) => handleChangeValuePermissions("import", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.import, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Relatórios">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.reports ?? ''} onChange={(value) => handleChangeValuePermissions("reports", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.reports, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Lista de Tarefas">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.tasks ?? ''} onChange={(value) => handleChangeValuePermissions("tasks", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.tasks, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Histórico de anotações">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.notes ?? ''} onChange={(value) => handleChangeValuePermissions("notes", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.notes, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Histórico de Chamadas">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.call_log ?? ''} onChange={(value) => handleChangeValuePermissions("call_log", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.call_log, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Integrações">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.integrations ?? ''} onChange={(value) => handleChangeValuePermissions("integrations", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.integrations, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Edição de Funil">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.edit_funnels ?? ''} onChange={(value) => handleChangeValuePermissions("edit_funnels", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.edit_funnels, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Configurar Leads Novos (Integrações)">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.config_new_leads ?? ''} onChange={(value) => handleChangeValuePermissions("config_new_leads", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.config_new_leads, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    {verifyIfModuleIsActive("CHAT") &&
                        <FormGroup labelText="Chat">
                            {editable ?
                                <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccess} selected={permissions?.chat ?? ''} onChange={(value) => handleChangeValuePermissions("chat", value)} />
                                :
                                <span>{getOptionByOptionList(permissions?.chat, optionsAccess)}</span>
                            }
                        </FormGroup>
                    }
                    <FormGroup labelText="Controle Financeiro">
                        {editable ?
                            <Select multiple={false} name="Selecione o nível de acesso" placeholder="Buscar" options={optionsAccessManager} selected={permissions?.finance ?? ''} onChange={(value) => handleChangeValuePermissions("finance", value)} />
                            :
                            <span>{getOptionByOptionList(permissions?.finance, optionsAccessManager)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Rodízio Automático de Leads">
                        {editable ?
                            <Select multiple={false} name="Selecione uma opção" placeholder="Buscar" options={optionsRodizio} selected={(+role?.rodizio)?.toString()} onChange={(value) => handleChangeValue("rodizio", value)} />
                            :
                            <span>{getOptionByOptionList(+role?.rodizio, optionsRodizio) || "-"}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Rodízio Automático de Chats">
                        {editable ?
                            <Select multiple={false} name="Selecione uma opção" placeholder="Buscar" options={optionsRodizio} selected={(+role?.rodizio_chat)?.toString()} onChange={(value) => handleChangeValue("rodizio_chat", value)} />
                            :
                            <span>{getOptionByOptionList(+role?.rodizio_chat, optionsRodizio) || "-"}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalRole