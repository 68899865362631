import React, { useRef, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import styles from './tr.module.css'

const Tr = ({ row = {}, indexRow = false, onClick }) => {
    const [showActions, setShowActions] = useState(false)
    const [styleAction, setStyleAction] = useState({})

    const refAction = useRef()

    const handleToogleShowActions = (e) => {
        e.stopPropagation()
        setShowActions(previousValue => {
            if (!previousValue) {
                adjustPositionActions()
            }
            return !previousValue
        })
    }

    const adjustPositionActions = () => {
        const heightWindow = window.innerHeight
        const positions = refAction.current.getBoundingClientRect()
        if ((heightWindow - (positions.top + positions.height)) > heightWindow / 3) {
            const top = `calc(${positions.height}px + ${positions.top}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px + 16px)`
            setStyleAction({ top, right })
        } else {
            const bottom = `calc(${heightWindow}px - ${positions.bottom}px + ${positions.height}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px + 16px)`
            setStyleAction({ bottom, right })
        }
    }

    return (
        <tr key={row.id} onClick={onClick}>
            {indexRow > 0 && <td key={`index-${indexRow}`}>{indexRow}</td>}
            {row.value && row.value.map((data, index) => (
                <td key={index}>{data}</td>
            ))}
            {row.actions &&
                <td align="end" className={styles.actions} onClick={handleToogleShowActions} ref={refAction}>
                    <MdMoreVert />
                    {showActions &&
                        <div className={styles.background}>
                            <ul style={styleAction}>
                                {row.actions.map((action, index) => (
                                    <li key={index} onClick={() => action.onClick(row.id)}>{action.label}</li>
                                ))}
                            </ul>
                        </div>
                    }
                </td>
            }
        </tr>
    )
}

export default Tr