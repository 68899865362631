import React from "react"
import styles from "./dashboard.module.css"
import { MdArrowCircleDown, MdArrowCircleUp } from "react-icons/md"

const Card = ({ icon, name, value, variation, is_positive }) => {
    return (
        <div className={styles.card}>
            <div className={styles.header}>
                {icon}
                <span>{name}</span>
            </div>
            <div className={styles.body}>
                <span>{value}</span>
                {variation &&
                    <div className={`${styles.comparison} ${is_positive ? styles.success : styles.danger}`}>
                        {is_positive ?
                            <MdArrowCircleUp />
                            :
                            <MdArrowCircleDown />
                        }
                        <span>{variation}</span>
                    </div>
                }
            </div>
            {variation &&
                <div className={styles.footer}>
                    <span>{is_positive ? "Aumento" : "Redução"} em relação ao período anterior</span>
                </div>
            }
        </div>
    )
}

export default Card