import React, { useEffect, useState } from "react"
import BreakForm from "../../Form/BreakForm"
import styles from "./interations.module.css"
import { toast } from "react-toastify"
import moment from "moment"
import api from './../../../services/api'
import CircleLoading from "../../CircleLoading"
import { useData } from "../../../contexts/dataContext"
import MenuToogle from "../../MenuToogle"
import CardCall from "./CardCall"
import CardChat from "./CardChat"

const optionsMenu = [
    { id: 1, value: "Ligações" },
    { id: 2, value: "Chats" },
]

const Interations = ({ lead_id }) => {
    const [interations, setInterations] = useState([])
    const [initialLoading, setInitialLoading] = useState(false)
    const [activeMenu, setActiveMenu] = useState(1)

    const { teams, roles, allUsersGroup } = useData()

    useEffect(() => {
        getAllInterations()
    }, [])

    const getAllInterations = () => {
        setInitialLoading(true)
        const url = `/leads/${lead_id}/interations`
        api.get(url).then(response => {
            const interations = response?.data?.data
            setInterations(interations || [])
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setInitialLoading(false))
    }

    const orderInterations = (a, b) => {
        if (moment(a.created_at).unix() < moment(b.created_at).unix()) {
            return 1
        } else if (moment(a.created_at).unix() > moment(b.created_at).unix()) {
            return -1
        }
        return 0
    }

    return (
        <div className={styles.interations}>
            <div className={styles.historyInterations}>
                <BreakForm title={"Histórico de interações"} showAction={false} />
                <div className={styles.body}>
                    <MenuToogle
                        options={optionsMenu}
                        activeOption={activeMenu}
                        setActiveOption={setActiveMenu}
                    />
                    <div className={styles.groupInteractions}>
                        {activeMenu === 1 && (
                            interations?.calls?.length > 0 ? interations.calls.sort(orderInterations).map(el => (
                                <CardCall
                                    key={el._id}
                                    data={el}
                                    listUsers={allUsersGroup}
                                />
                            )) : <span>Nenhuma ligação realizada até o momento!</span>
                        )}
                        {activeMenu === 2 && (
                            interations?.conversations?.length > 0 ? interations.conversations.sort(orderInterations).map(el => (
                                <CardChat
                                    key={el._id}
                                    data={el}
                                    teams={teams}
                                    roles={roles}
                                    listUsers={allUsersGroup}
                                />
                            )) : <span>Nenhum chat encontrado!</span>
                        )}
                    </div>
                    {initialLoading && <CircleLoading />}
                </div>
            </div>
        </div >
    )
}

export default Interations