import React, { useState } from 'react'
import styles from "./reportDataNote.module.css"
import { MdOutlineFileDownload } from 'react-icons/md'
import { toast } from 'react-toastify'
import Button from '../../components/Form/Button'
import Loading from '../layouts/Loading/index'
import api from '../../services/api'
import FormGroup from '../../components/Form/FormGroup'
import DatePicker from '../../components/Form/DatePicker'
import Select from '../../components/Form/Select'
import { useAuth } from '../../contexts/authContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useData } from '../../contexts/dataContext'

const ReportDataNote = () => {
    const [filters, setFilters] = useState({})
    const [loading, setLoading] = useState(false)

    const { account_id } = useParams()
    const { teams, users } = useData()

    const { permissions } = useAuth()

    const navigate = useNavigate()

    const handleChange = (name, value) => {
        setFilters(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangePeriod = (dateStart, dateEnd) => {
        handleChange("dateStart", dateStart)
        handleChange("dateEnd", dateEnd)
    }

    const handleSubmit = () => {
        setLoading(true)
        const keys = Object.keys(filters)
        const filtersFormatted = { ...filters }
        keys?.forEach(key => {
            if (Array.isArray(filtersFormatted[key])) {
                filtersFormatted[key] = filtersFormatted[key].join(",")
            }
        })
        api.post("/reports/notes", filtersFormatted).then(response => {
            toast.success(response?.data?.message)
            navigate(`/${account_id}/reports/log`)
            setLoading(false)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }

    return (
        <>
            <div className={styles.content}>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <MdOutlineFileDownload />
                        <span>Exportar Histórico de Anotações</span>
                    </div>
                    <div className={styles.link}>
                        <NavLink to={`/${account_id}/reports/log`}>Acessar histórico de exportações</NavLink>
                    </div>
                    <div className={styles.body}>
                        <FormGroup labelText="Período">
                            <DatePicker name="Período" dateStart={filters.dateStart || ""} dateEnd={filters.dateEnd || ""} onChange={(dateStart, dateEnd) => handleChangePeriod(dateStart, dateEnd)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Equipe">
                            <Select name="Equipes" placeholder="Pesquise uma equipe" options={teams || []} selected={filters.teams || []} onChange={(value) => handleChange("teams", value)} multiple={true} />
                        </FormGroup>
                        {permissions?.crm > 1 &&
                            <FormGroup labelText="Responsável">
                                <Select name="Responsáveis" placeholder="Pesquise um responsável" options={users || []} selected={filters.users || []} onChange={(value) => handleChange("users", value)} multiple={true} />
                            </FormGroup>
                        }
                    </div>
                    <div className={styles.footer}>
                        <Button className="bg-pink" onClick={handleSubmit} disabled={loading}>
                            Solicitar Relatório
                        </Button>
                    </div>
                </div>
            </div>
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ReportDataNote