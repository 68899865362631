import React, { useState } from "react";
import Modal from './index';
import { MdOutlineMoneyOffCsred } from "react-icons/md";
import ConfigTextListItems from "../ConfigTextListItems";

const ModalReasonsDiscard = ({ isNew = false, value = [{}], editable = false, setEdit, onSubmit, closeModal }) => {
    const [reasonsDiscard, setReasonsDiscard] = useState(value)

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setReasonsDiscard(value)
        }
    }
    return (
        <Modal
            icon={<MdOutlineMoneyOffCsred />}
            title={isNew ? "Cadastro de Motivos de Descarte" : (editable ? `Editando os Motivos de Descarte` : `Visualizando os Motivos de Descarte`)}
            description={isNew ? "Adicione os Motivos de Descarte a sua conta" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <ConfigTextListItems
                title="Motivos de Descarte"
                placeholder="Motivo de Descarte"
                textNotItems="Nenhuma origem cadastrada até o momento!"
                value={reasonsDiscard}
                onSubmit={onSubmit}
                onCancel={handleCancel}
                editable={editable}
                isNew={isNew}
            />
        </Modal>
    )
}

export default ModalReasonsDiscard