import React, { useEffect, useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { FiUsers } from "react-icons/fi";
import FormGroupInline from '../Form/FormGroupInline';
import BreakForm from "../Form/BreakForm";
import { HiPlus } from "react-icons/hi";
import Select from "../Form/Select";
import { MdOutlineDelete } from "react-icons/md";
import Textarea from "../Form/Textarea";
import { useData } from "../../contexts/dataContext";

const ModalSale = ({ isNew = false, value = {}, onSubmit, closeModal }) => {
    const [data, setData] = useState(value)
    const [products, setProducts] = useState(value?.products || [])

    const { products: listProducts } = useData()

    useEffect(() => {
        handleChangeValue('products', products)
    }, [products])

    const handleChangeProduct = (key, value, index) => {
        setProducts(previusValue => {
            const newValue = [...previusValue]
            newValue[index] = { ...newValue[index], [key]: value }
            return newValue
        })

    }

    const handleAddProduct = (e) => {
        e.preventDefault()
        setProducts(previusValue => {
            const newValue = [...previusValue]
            newValue.push({})
            return newValue
        })
    }

    const handleRemoveProduct = (e, index) => {
        e.preventDefault()
        setProducts(previusValue => {
            const newValue = [...previusValue]
            newValue.splice(index, 1)
            return newValue
        })
    }

    const handleChangeValue = (name, value) => {
        setData(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    return (
        <Modal
            icon={<FiUsers />}
            title={"Cadastro de nova venda"}
            description={"Registre uma nova venda para o lead"}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, data, isNew)}>
                <FormGroupInline>
                    <FormGroup labelText="Descrição">
                        <Textarea placeholder="Descrição da venda" value={data.description ?? ""} onChange={(e) => handleChangeValue("description", e.target.value)} rows={3} />
                    </FormGroup>
                </FormGroupInline>
                <BreakForm title="Produtos" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={handleAddProduct} />
                {products && products.map((product, index) => (
                    <FormGroupInline key={index} btnAction={index > 0}>
                        <FormGroup labelText={index === 0 && 'Produto'}>
                            <Select multiple={false} name="Produto" placeholder="Selecione um produto" options={listProducts || []} selected={product?.product_id ?? ""} onChange={(id) => handleChangeProduct("product_id", id, index)} invalid={true} textInvalid="Selecione um produto" />
                        </FormGroup>
                        <FormGroup labelText={index === 0 && 'Valor'}>
                            <Input type="currency" placeholder="Valor do produto" value={product?.value ?? ""} onChange={(e) => handleChangeProduct("value", e.target.value, index)} invalid={true} textInvalid="Preencha o valor do produto" />
                        </FormGroup>
                        {index > 0 && <Button className='bg-purple action' onClick={(e) => handleRemoveProduct(e, index)}><MdOutlineDelete /></Button>}
                    </FormGroupInline>
                ))}
                <FormGroup labelText="Remover os produtos do lead após registrar a venda?">
                    <input type="checkbox" checked={data.removeProducts ?? false} onChange={(e) => handleChangeValue("removeProducts", e.target.checked)} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalSale