import BankSlip from "../pages/Views/Transactions/BankSlip"
import Invite from "../pages/Auth/Invite"
import Loading from "../pages/layouts/Loading"
import Welcome from "../pages/Auth/Welcome"

const sharedRoutes = [
    {
        path: "/welcome",
        element: <Welcome />,
        errorElement: <Loading />,
    },
    {
        path: "/invite",
        element: <Invite />,
        errorElement: <Loading />,
    },
    {
        path: "/view/transactions/:transaction_id/:charge_id/bank-slip",
        element: <BankSlip />,
        errorElement: <Loading />,
    },
]

export default sharedRoutes