import React, { useEffect, useState } from "react"
import styles from "./apiIntegration.module.css"
import Button from "../../components/Form/Button"
import Table from "../../components/Table"
import FormGroup from "../../components/Form/FormGroup"
import BreakForm from "../../components/Form/BreakForm"
import FormGroupInline from "../../components/Form/FormGroupInline"
import ModalApiToken from "../../components/Modal/ModalApiToken"
import api from '../../services/api'
import { toast } from 'react-toastify'
import moment from "moment"

const headersToken = [
    "#",
    "Identificação",
    "Token",
    "Expira em",
    "Status",
    "",
]

const ApiToken = () => {
    const [tokens, setTokens] = useState([])
    const [showModalToken, setShowModalToken] = useState(false)
    const [showNewToken, setShowNewToken] = useState(false)
    const [newToken, setNewToken] = useState("")

    useEffect(() => {
        getAllTokens()
    }, [])

    const getAllTokens = () => {
        api.get("/integrations/api/tokens").then(response => {
            const tokens = response?.data?.data
            setTokens(tokens)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getFilteredValuesToken = () => {
        const values = tokens
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })
        const filteredValues = values?.map((token) => getDataNewToken(token))
        return filteredValues
    }

    const getDataNewToken = (newToken) => {
        const data = {
            id: newToken._id,
            value: [
                newToken.name,
                newToken.token,
                newToken.expires ? moment(newToken.expires_at).format("DD/MM/YYYY") : "Nunca",
                (!newToken.expires || moment(newToken.expires_at).isSameOrAfter(moment()) ? "Ativo" : "Expirado"),
            ],
            actions: [
                {
                    label: "Excluir",
                    onClick: handleDeleteToken
                }
            ]
        }
        return data
    }

    const handleShowModalToken = () => {
        setShowModalToken(true)
    }

    const handleCloseModalToken = () => {
        setShowModalToken(false)
        setShowNewToken(false)
        setNewToken("")
    }

    const handleSaveNewToken = (e, data) => {
        e.preventDefault()
        api.post("/integrations/api/tokens", data).then(response => {
            const newToken = response?.data?.data
            if (newToken) {
                setTokens(previousValue => {
                    const tokenExists = previousValue.some(token => token._id === newToken._id)
                    if (!tokenExists) {
                        return [...previousValue, newToken]
                    }
                    return previousValue
                })
                setNewToken(newToken.fullToken)
                setShowNewToken(true)
            }
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const handleDeleteToken = (token_id) => {
        api.delete(`/integrations/api/tokens/${token_id}`).then(response => {
            setTokens(previousValue => previousValue?.filter(token => token._id !== token_id))
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    return (
        <>
            <div className={styles.container}>
                <FormGroup>
                    <FormGroupInline>
                        <div className={styles.header}>
                            <BreakForm title="Tokens" showAction={false} />
                            <span>Os tokens são utilizados para autenticar suas requisições em nossa API. Guarde eles em segurança, e não os compartilhe com ninguém.</span>
                        </div>
                        <Button className="bg-pink" onClick={handleShowModalToken}>Novo token</Button>
                    </FormGroupInline>
                    <Table
                        headers={headersToken}
                        values={getFilteredValuesToken()}
                        messageNotRegisters="Nenhum registro encontrado"
                        showIndex={true}
                    />
                </FormGroup>
            </div>
            {showModalToken && <ModalApiToken onSubmit={handleSaveNewToken} closeModal={handleCloseModalToken} newToken={newToken} showNewToken={showNewToken} />}
        </>
    )
}

export default ApiToken