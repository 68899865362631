import api from "../services/api"
import { toast } from "react-toastify"

const getFunnels = async () => {
    try {
        const response = await api.get("/funnels")
        const funnels = response?.data?.data
        funnels?.map(funnel => {
            funnel.value = funnel.name
            funnel.id = funnel._id
            funnel.teams = funnel.teams?.map(team => team.team_id)
            return funnel
        })
        return funnels
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

const getLastFunnel = async () => {
    try {
        const response = await api.get("/funnels/lastFunnel")
        const funnel = response?.data?.data
        return funnel
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

const changeLastFunnel = async (funnel_id) => {
    try {
        await api.post("/funnels/lastFunnel", { funnel_id })
        return funnel_id
    } catch (err) {
        return
    }
}

export { getFunnels, getLastFunnel, changeLastFunnel }