import api from "../services/api"
import { toast } from "react-toastify"
import { adjustOptionsProduct, getIdByProduct, getNameByProduct } from "../utils/utils"

const getProducts = async () => {
    try {
        const response = await api.get(`/products`)
        const productList = await adjustOptionsProduct(response.data?.data)

        const newProductList = productList?.map(product => {
            return { id: getIdByProduct(product), value: getNameByProduct(product) }
        }).sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } else if (a.value > b.value) {
                return 1
            } else {
                return 0
            }
        })
        return newProductList
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getProducts }