import React, { useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import { MdOutlineLocationOn } from "react-icons/md"
import { getAddressByZipCode, getCoordinates, getThumbnailByLocation } from "../../../utils/utils"
import Location from "../../Chat/Elements/Location"
import { maskZipCode } from "../../../utils/formatter"

const ModalLocation = ({ latitude, longitude, onPreview = () => { }, onSubmit = () => { }, closeModal = () => { } }) => {
    const [address, setAddress] = useState({})
    const [jpegThumbnail, setJpegThumbnail] = useState("")

    const handleChangeValue = (name, value) => {
        setAddress(previousValue => { return { ...previousValue, [name]: value } })
        if (name === "zip_code") {
            getAddressByZipCode(value).then(newAddress => {
                Object.keys(newAddress)?.forEach(key => {
                    handleChangeValue(key, newAddress[key])
                })
            })
        }
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handlePreview = (e) => {
        e.preventDefault()
        const addressFormatted = getFormattedAddress(address)
        getCoordinates(addressFormatted).then(location => {
            if (location?.latitude && location?.longitude) {
                getThumbnailByLocation(location.latitude, location.longitude).then(jpegThumbnail => {
                    onPreview({ ...location, jpegThumbnail })
                })
            }
        })
    }

    const getFormattedAddress = (address) => {
        const addressFormatted = []
        if (address?.road) {
            addressFormatted.push(address.road)
        }
        if (address?.number) {
            addressFormatted.push(address.number)
        }
        if (address?.complement) {
            addressFormatted.push(address.complement)
        }
        if (address?.district) {
            addressFormatted.push(address.district)
        }
        if (address?.city) {
            addressFormatted.push(address.city)
        }
        if (address?.uf) {
            addressFormatted.push(address.uf)
        }
        if (address?.zip_code) {
            addressFormatted.push(maskZipCode(address.zip_code))
        }
        if (address?.country) {
            addressFormatted.push(address.country)
        }
        return addressFormatted.join(", ")
    }

    const handleSave = (e) => {
        e.preventDefault()
        if (latitude && longitude) {
            onSubmit({ latitude, longitude, jpegThumbnail })
            closeModal()
        }
    }

    return (
        <Modal
            icon={<MdOutlineLocationOn />}
            title={"Enviar Localização"}
            description={"Preencha os campos abaixo, clique em visualizar e depois em enviar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="CEP">
                    <Input type="zipCode" placeholder="CEP" value={address?.zip_code ?? ""} onChange={(e) => handleChangeValue("zip_code", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="País">
                    <Input type="text" placeholder="País" value={address?.country ?? ""} onChange={(e) => handleChangeValue("country", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Estado">
                    <Input type="text" placeholder="Estado" value={address?.uf ?? ""} onChange={(e) => handleChangeValue("uf", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Cidade">
                    <Input type="text" placeholder="Cidade" value={address?.city ?? ""} onChange={(e) => handleChangeValue("city", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Bairro">
                    <Input type="text" placeholder="Bairro" value={address?.district ?? ""} onChange={(e) => handleChangeValue("district", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Logradouro">
                    <Input type="text" placeholder="Logradouro" value={address?.road ?? ""} onChange={(e) => handleChangeValue("road", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Número">
                    <Input type="text" placeholder="Número" value={address?.number ?? ""} onChange={(e) => handleChangeValue("number", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Complemento">
                    <Input type="text" placeholder="Complemento" value={address?.complement ?? ""} onChange={(e) => handleChangeValue("complement", e.target.value)} />
                </FormGroup>
                {(latitude && longitude) &&
                    <Location
                        latitude={latitude}
                        longitude={longitude}
                    />
                }
                <FormGroupInline>
                    <Button className='bg-orange' onClick={handlePreview}>Visualizar</Button>
                </FormGroupInline>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit" disabled={!latitude || !longitude}>Enviar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalLocation