import React, { useEffect, useState } from "react"
import BreakForm from "../../Form/BreakForm"
import { HiPlus } from "react-icons/hi"
import styles from "./tasks.module.css"
import api from "../../../services/api"
import { toast } from "react-toastify"
import moment from "moment"
import CardTask from "./CardTask"
import Select from './../../Form/Select'
import DatePicker from './../../Form/DatePicker/index'
import { useWebSocketContext } from "../../../contexts/webSocketContext"
import { getPropertyForItemInArrayById } from "../../../utils/utils"
import { useData } from "../../../contexts/dataContext"
import { useCrm } from "../../../contexts/crmContext"
import Loading from './../../../pages/layouts/Loading/index'
import CircleLoading from "../../CircleLoading"

const Tasks = ({ lead_id }) => {
    const initialNewTask = { isNew: true }
    const [newTask, setNewTask] = useState(initialNewTask)
    const [showNewTask, setShowNewTask] = useState(false)
    const [tasks, setTasks] = useState([])
    const [activeTasks, setActiveTasks] = useState([])
    const [doneTasks, setDoneTasks] = useState([])
    const [filters, setFilters] = useState({})
    const [usersFilters, setUsersFilters] = useState([])
    const [typesFilters, setTypesFilters] = useState([])
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)

    const { getNextTaskByLead } = useCrm()
    const { typesTask } = useData()
    const { lastJsonMessage } = useWebSocketContext()

    useEffect(() => {
        getAllTasks()
    }, [])

    useEffect(() => {
        getAllActiveTasks()
        getAllDoneTasks()
        if (tasks?.length > 0) {
            getNextTaskByLead(lead_id)
        }
    }, [tasks])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "ADD_TASK_LEAD") {
            const leadId = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (leadId === lead_id) {
                addNewTask(dataUpdate)
            }
        } else if (action === "UPDATE_TASK_LEAD") {
            const leadId = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (leadId === lead_id) {
                updateTaskById(dataUpdate._id, dataUpdate)
            }
        }
    }

    const handleCancelNewTask = () => {
        setNewTask(initialNewTask)
        setShowNewTask(false)
    }

    const handleSubmitNewTask = async (data) => {
        try {
            setLoading(true)
            if (!data.date) {
                throw toast.error("A data da tarefa é obrigatória!")
            }
            if (!data.time) {
                throw toast.error("O horário da tarefa é obrigatório!")
            }
            if (!data.title) {
                throw toast.error("O título da tarefa é obrigatório!")
            }
            if (!data.type_task_id) {
                throw toast.error("O tipo da tarefa é obrigatório!")
            }

            const response = await api.post(`/leads/${lead_id}/tasks`, data)
            const newTask = response?.data?.data
            addNewTask(newTask)
            handleCancelNewTask()
        } catch (err) {
            toast.error(err?.response?.data?.message)
            throw err
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateTask = async (task_id, dataUpdate) => {
        setLoading(true)
        api.patch(`/leads/${lead_id}/tasks/${task_id}`, dataUpdate).then(response => {
            const task = response.data.data
            updateTaskById(task_id, task)
            toast.error(response.data.message)
            return response
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => setLoading(false))
    }

    const addNewTask = (newTask) => {
        setTasks(previousValue => {
            const taskExists = previousValue.some(task => task._id === newTask._id)
            if (!taskExists) {
                return [...previousValue, newTask]
            }
            return previousValue
        })
    }

    const updateTaskById = (task_id, newTask) => {
        setTasks(previousValue =>
            previousValue?.map(task => {
                const newValue = task._id === task_id ? newTask : task
                return newValue
            })
        )
    }

    const getAllActiveTasks = () => {
        const active = tasks?.filter(task => !task.finished && !task.canceled).sort(orderActiveTasks)
        setActiveTasks(active)
    }

    const getAllDoneTasks = () => {
        const done = tasks?.filter(task => task.finished || task.canceled)
        done?.forEach(task => {
            setUsersFilters(previousValue => {
                const newValue = [...previousValue]
                const [userExists] = newValue?.filter(user => user.id === task.user_done_id)
                if (!userExists) {
                    newValue.push({ id: task.user_done_id, value: task.user_done })
                }
                return newValue
            })
            setTypesFilters(previousValue => {
                const newValue = [...previousValue]
                const [typeExists] = newValue?.filter(type => type.id === task.type_task_id)
                if (!typeExists) {
                    newValue.push({ id: task.type_task_id, value: getPropertyForItemInArrayById(task.type_task_id, typesTask, "id", "value") })
                }
                return newValue
            })
        })
        setDoneTasks(done)
    }

    const getAllTasks = () => {
        setInitialLoading(true)
        api.get(`/leads/${lead_id}/tasks`).then(response => {
            const tasks = response?.data?.data
            setTasks(tasks || [])
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setInitialLoading(false))
    }

    const orderActiveTasks = (a, b) => {
        if (moment(a.scheduled_date).unix() < moment(b.scheduled_date).unix()) {
            return -1
        } else if (moment(a.scheduled_date).unix() > moment(b.scheduled_date).unix()) {
            return 1
        }
        return 0
    }

    const orderDoneTasks = (a, b) => {
        if (moment(a.done_in).unix() < moment(b.done_in).unix()) {
            return 1
        } else if (moment(a.done_in).unix() > moment(b.done_in).unix()) {
            return -1
        }
        return 0
    }

    const filterDoneTasks = (task) => {
        const dateTask = moment(task.done_in).format("YYYY-MM-DD")
        const validPeriod = (!filters.dateStart || moment(filters.dateStart).isSameOrBefore(dateTask)) && (!filters.dateEnd || moment(filters.dateEnd).isSameOrAfter(dateTask))
        const validUsers = !filters.users || filters.users.indexOf(task.user_done_id) !== -1
        const validTypes = !filters.types || filters.types.indexOf(task.type_task_id) !== -1
        return validPeriod && validUsers && validTypes
    }

    const getFilteredDoneTasks = () => {
        const listTasks = doneTasks?.filter(filterDoneTasks)
        return listTasks
    }

    const onChangeFilters = (name, value) => {
        if (name === "period") {
            setFilters(previousValue => {
                return { ...previousValue, dateStart: value.dateStart, dateEnd: value.dateEnd }
            })
        } else {
            setFilters(previousValue => {
                return { ...previousValue, [name]: value }
            })
        }
    }

    return (
        <div className={styles.tasks}>
            <div className={styles.activeTasks}>
                <BreakForm title="Tarefas" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={() => { setShowNewTask(true) }} />
                {showNewTask &&
                    <CardTask
                        value={newTask}
                        onSubmit={handleSubmitNewTask}
                        onCancel={handleCancelNewTask}
                    />
                }
                {activeTasks && activeTasks.map(el => (
                    <CardTask
                        key={el._id}
                        value={el}
                        onUpdate={handleUpdateTask}
                    />
                ))}
                {!showNewTask && !activeTasks?.length && <span>Nenhuma tarefa pendente no momento!</span>}
            </div>
            <div className={styles.historyTasks}>
                <BreakForm title="Histórico de tarefas" showAction={false} />
                {doneTasks?.length > 0 &&
                    <div className={styles.filters}>
                        <DatePicker name="Período" dateStart={filters.dateStart} dateEnd={filters.dateEnd} onChange={(dateStart, dateEnd) => onChangeFilters("period", { dateStart, dateEnd })} multiple={true} />
                        <Select name="Responsável" placeholder="Selecione o responsável" selected={filters.users} options={usersFilters} onChange={(value) => onChangeFilters("users", value)} multiple={true} />
                        <Select name="Tipo" placeholder="Selecione o tipo" selected={filters.types} options={typesFilters} onChange={(value) => onChangeFilters("types", value)} multiple={true} />
                    </div>
                }
                <div className={styles.body}>
                    {doneTasks?.length > 0 && getFilteredDoneTasks().sort(orderDoneTasks).map(el => (
                        <CardTask
                            key={el._id}
                            value={el}
                            isNew={false}
                            onUpdate={handleUpdateTask}
                        />
                    ))}
                    {!initialLoading && !doneTasks?.length && <span>Nenhuma tarefa finalizada até o momento!</span>}
                    {!initialLoading && (doneTasks?.length > 0 && !getFilteredDoneTasks().length > 0) && <span>Nenhum resultado para sua busca!</span>}
                    {initialLoading && <CircleLoading />}
                </div>
            </div>
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Tasks