import React, { useEffect, useRef, useState } from "react"
import styles from "./message.module.css"
import moment from "moment"
import { adjustTextByIndentation, getLocalDate, getNameUserById, getProfileUserById } from "../../utils/utils"
import AudioPlayer from "../Form/AudioPlayer"
import { MdArrowBack, MdKeyboardArrowDown, MdKeyboardDoubleArrowLeft, MdOutlineContactPage, MdOutlineDelete, MdOutlineFormatListBulleted, MdOutlineGifBox, MdOutlineImage, MdOutlineInfo, MdOutlineInsertDriveFile, MdOutlineKeyboardDoubleArrowRight, MdOutlineLocationOn, MdOutlineMicNone, MdOutlineVideocam } from "react-icons/md"
import Sticker from "./Elements/Sticker"
import Poll from "./Elements/Poll"
import List from "./Elements/List"
import Location from "./Elements/Location"
import Gif from "./Elements/Gif"
import Contact from "./Elements/Contact"
import Document from "./Elements/Document"
import Video from "./Elements/Video"
import Image from "./Elements/Image"
import { formatSecondsToTime, formatTextByElipsis } from "../../utils/formatter"
import { HiOutlinePencil } from "react-icons/hi"
import ModalConfirm from "../Modal/ModalConfirm"
import { toast } from "react-toastify"
import ModalEdit from "../Modal/Chat/ModalEdit"
import ImageWithFallback from "../ImageWithFallback"
import { useData } from "../../contexts/dataContext"
import { useWebSocketChatContext } from "../../contexts/webSocketChatContext"

const Message = ({ message, platform, lastMessage, defaultPicture, messageScrolled, getIconMessageByDirectionDeliveredAndRead = () => { }, deleteMessage = () => { }, updateMessage = () => { }, replyMessage = () => { }, scrollToMessage = () => { } }) => {
    const [newDayDivision, setNewDayDivision] = useState(false)
    const [showDataBySender, setShowDataBySender] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [active, setActive] = useState(false)
    const [styleAction, setStyleAction] = useState({})
    const { allUsersGroup } = useData()
    const { getContactByIdAndPlatform } = useWebSocketChatContext()

    const refAction = useRef()

    useEffect(() => {
        verfifyIfIsNewDay(message.created_at, lastMessage?.created_at)
    }, [message?.created_at, lastMessage?.created_at])

    useEffect(() => {
        if (messageScrolled && messageScrolled === message?.message_id) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [messageScrolled, message?.message_id])

    useEffect(() => {
        setShowDataBySender(newDayDivision || !lastMessage || lastMessage?.direction !== message.direction || lastMessage?.user_id !== message.user_id || lastMessage?.contact_id !== message.contact_id || lastMessage?.internal !== message.internal)
    }, [message, newDayDivision, lastMessage])

    const getProfileNameByMessage = (message) => {
        if (message.direction === "send") {
            const user = getUserProfileSender(message.user_id, message?.bot_details?.is_bot)
            return user?.name
        } else {
            const contact = getContactProfileSender(message.contact_id)
            return contact?.name
        }
    }

    const getProfileImageByMessage = (message) => {
        if (message.direction === "send") {
            const user = getUserProfileSender(message.user_id, message?.bot_details?.is_bot)
            return user?.image || <img src={defaultPicture} alt="Imagem de Perfil" />
        } else {
            const contact = getContactProfileSender(message.contact_id)
            return contact?.image || <img src={defaultPicture} alt="Imagem de Perfil" />
        }
    }

    const getUserProfileSender = (user_id, is_bot = false) => {
        let user = { name: is_bot ? "Você (Bot AI)" : "Você (Envio externo)" }
        if (user_id) {
            user = getProfileUserById(allUsersGroup, user_id)
        }
        return user
    }

    const getContactProfileSender = (contact_id) => {
        const contactData = getContactByIdAndPlatform(contact_id, platform)
        return { name: contactData?.name, image: contactData?.picture ? <ImageWithFallback src={contactData?.picture || defaultPicture} alt="Imagem de Perfil" functionError={() => <img src={defaultPicture} alt="Imagem de Perfil" />} /> : "" }
    }

    const verfifyIfIsNewDay = (created_at, lastMessageDate = new Date()) => {
        const currentDate = getLocalDate(created_at).startOf("day")
        const lastDate = getLocalDate(lastMessageDate).startOf("day")
        const diff = Math.abs(currentDate.diff(lastDate, "days"))
        const diffToday = Math.abs(getLocalDate().startOf("day").diff(currentDate, "days"))
        const textDivision = diff > 0 ? (diffToday > 1 ? currentDate.format("DD/MM/YYYY") : (diffToday > 0 ? "Ontem" : "Hoje")) : false
        setNewDayDivision(textDivision)
    }

    const getTextByDate = (dateMessage) => {
        const date = getLocalDate(dateMessage)
        return date.format("HH:mm")
    }

    const getMessageByTypeAndContent = (type, content) => {
        return (
            <div className={`${styles.content} ${message?.reactions?.length > 0 ? styles.hasReaction : ''}`}>
                <div className={styles.profileImage}>
                    {showDataBySender && getProfileImageByMessage(message)}
                </div>
                <div className={styles.groupContentMessage}>
                    {message.deleted?.status && <div className={styles.italicText}><MdOutlineDelete /> <span>Mensagem apagada {message.deleted?.forEveryone ? "para todos" : "para você"}  {message.deleted?.user_id ? `por ${getNameUserById(allUsersGroup, message.deleted.user_id)}` : ''}</span></div>}
                    {showDataBySender &&
                        <div className={styles.senderInfo}>
                            <span className={styles.profileName}>{getProfileNameByMessage(message)}</span>
                        </div>
                    }
                    <div className={`${styles.options} ${showOptions ? styles.active : ''}`} onClick={handleToogleShowoptions} ref={refAction}>
                        <MdKeyboardArrowDown />
                        {showOptions &&
                            <>
                                <ul style={styleAction}>
                                    {getOptionsByMessage()}
                                </ul>
                                <div className={styles.background}></div>
                            </>
                        }
                    </div>
                    {content?.quoted_message &&
                        getContentByQuoted(content?.quoted_message)
                    }
                    {message.forwarded?.status && <div className={styles.italicText}><MdOutlineKeyboardDoubleArrowRight /> <span>Encaminhada  {message.forwarded?.user_id ? `por ${getNameUserById(allUsersGroup, message.forwarded.user_id)}` : ''}</span></div>}
                    <div className={styles.contentMessage}>
                        <div className={styles.groupMedia}>
                            {getContentMessageByType(type, content, true)}
                        </div>
                        <div className={styles.footer}>
                            {message.edited?.status && <span className={styles.italicText}>Editada {message.edited?.user_id ? `por ${getNameUserById(allUsersGroup, message.edited.user_id)}` : ''}</span>}
                            <span className={styles.date}>{getTextByDate(message.created_at)}</span>
                            {getIconMessageByDirectionDeliveredAndRead(message.direction, message.sent, message.delivered, message.read, message.internal, styles)}
                        </div>
                    </div>
                    {message?.reactions?.length > 0 &&
                        <div className={styles.reactions}>
                            {message.reactions.map((reaction, index) => (
                                <span key={reaction?._id || index}>{reaction?.text || ""}</span>
                            ))}
                        </div>
                    }
                </div>
            </div>
        )
    }

    const getContentByQuoted = (message) => {
        return (
            <div className={`${styles.contentMessage} ${styles.quotedMessage}`} onClick={() => scrollToMessage(message?.message_id)}>
                <span className={styles.senderInfo}>{getProfileNameByMessage(message)}</span>
                <div className={styles.groupMedia}>
                    {getContentMessageByType(message?.type, message?.content, false, true)}
                </div>
            </div>
        )
    }

    const getContentMessageByType = (type, content, showPreview = false, quotedMessage = false) => {
        const text = adjustTextByIndentation(content?.text || "")
        if (type === "text") {
            return <>
                {quotedMessage && <div></div>}
                <span className={styles.text}>{text}</span>
            </>
        } else if (type === "image" && (content?.url || content?.urlThumb)) {
            return <>
                <Image
                    url={showPreview ? content?.urlThumb || content?.url : content?.url || content?.urlThumb}
                    width={content?.width}
                    height={content?.height}
                    showPreview={showPreview}
                    openPreview={openPreview}
                />
                {showPreview && content?.text && <span>{text}</span>}
                {quotedMessage && <div className={styles.defaultMessage}><MdOutlineImage /><span>{content?.text ? (content?.text?.length > 100 ? `${content.text.substring(0, 100)}...` : content?.text) : "Imagem"}</span></div>}
            </>
        } else if (type === "video" && content?.url) {
            return <>
                <Video
                    url={content.url}
                    showPreview={showPreview}
                    openPreview={openPreview}
                    quotedMessage={quotedMessage}
                />
                {showPreview && content?.text && <span>{text}</span>}
                {quotedMessage && <div className={styles.defaultMessage}><MdOutlineVideocam /><span>{content?.text ? (content?.text?.length > 100 ? `${content.text.substring(0, 100)}...` : content?.text) : "Vídeo"}</span></div>}
            </>
        } else if (type === "audio" && content?.url) {
            return quotedMessage ?
                <>
                    {quotedMessage && <div></div>}
                    <div className={`${styles.defaultMessage} ${styles.text}`}>
                        <MdOutlineMicNone /><span>{formatSecondsToTime(content?.seconds, false)}</span>
                    </div>
                </>
                :
                <AudioPlayer
                    src={content.url}
                    pink={!message.internal}
                />
        } else if (type === "document" && content?.url) {
            return quotedMessage ?
                <>
                    {quotedMessage && <div></div>}
                    <div className={`${styles.defaultMessage} ${styles.text}`}>
                        <MdOutlineInsertDriveFile /><span>{content?.text ? text : content.name}</span>
                    </div>
                </>
                :
                <>
                    <Document name={content.name} url={content.url} />
                    {content?.text && <span>{text}</span>}
                </>
        } else if (type === "contact" && content?.contacts?.length) {
            return quotedMessage ?
                <>
                    {quotedMessage && <div></div>}
                    <div className={`${styles.defaultMessage} ${styles.text}`}>
                        <MdOutlineContactPage /><span>{getNameByContacts(content.contacts)}</span>
                    </div>
                </>
                : <>
                    <Contact contacts={content.contacts} defaultPicture={defaultPicture} />
                    {content?.text && <span>{text}</span>}
                </>
        } else if (type === "location" && content?.latitude && content?.longitude) {
            return <>
                <Location
                    latitude={content.latitude}
                    longitude={content.longitude}
                    quotedMessage={quotedMessage}
                />
                {!quotedMessage && content?.address && <span className={styles.address}>{content?.address}</span>}
                {!quotedMessage && content?.text && <span>{text}</span>}
                {quotedMessage && <div className={styles.defaultMessage}><MdOutlineLocationOn /><span>{content?.text ? (content?.text?.length > 100 ? `${content.text.substring(0, 100)}...` : content?.text) : "Localização"}</span></div>}
            </>
        } else if (type === "gif" && content?.url) {
            return <>
                <Gif
                    url={content.url}
                    quotedMessage={quotedMessage}
                />
                {!quotedMessage && content?.text && <span>{text}</span>}
                {quotedMessage && <div className={styles.defaultMessage}><MdOutlineGifBox /><span>{content?.text ? (content?.text?.length > 100 ? `${content.text.substring(0, 100)}...` : content?.text) : "GIF"}</span></div>}
            </>
        } else if (type === "sticker" && content?.url) {
            return <>
                {quotedMessage && <div></div>}
                <Sticker url={content.url} />
                {content?.text && <span>{text}</span>}
            </>
        } else if (type === "poll") {
            return quotedMessage ?
                <>
                    {quotedMessage && <div></div>}
                    <div className={`${styles.defaultMessage} ${styles.text}`}>
                        <MdOutlineFormatListBulleted /><span>{content?.name || "Enquete"}</span>
                    </div>
                </>
                : <>
                    <Poll content={content} />
                    {content?.text && <span>{text}</span>}
                </>
        } else if (type === "list") {
            return quotedMessage ?
                <>
                    {quotedMessage && <div></div>}
                    <div className={`${styles.defaultMessage} ${styles.text}`}>
                        <MdOutlineFormatListBulleted /><span>{formatTextByElipsis(content?.title, 30) || formatTextByElipsis(content?.description, 30) || "Lista"}</span>
                    </div>
                </>
                : <>
                    <List content={content} />
                    {content?.text && <span>{text}</span>}
                </>
        } else if (type === "listResponse") {
            return quotedMessage ?
                <>
                    {quotedMessage && <div></div>}
                    <div className={`${styles.defaultMessage} ${styles.text}`}>
                        <span>{content?.text}</span>
                    </div>
                </>
                : <>
                    {content?.text && <span>{text}</span>}
                </>
        } else {
            return <>
                <span>Mensagem não suportada</span>
            </>
        }
    }

    const getNameByContacts = (contacts) => {
        const names = contacts?.map(contact => contact.displayName).join(", ")
        return names
    }

    const openPreview = (showPreview) => {
        if (showPreview) {
            setShowPreview(true)
        }
    }

    const closePreview = () => {
        setShowPreview(false)
    }

    const handleToogleShowoptions = () => {
        setShowOptions(previousValue => {
            if (!previousValue) {
                adjustPositionActions()
            }
            return !previousValue
        })
    }

    const adjustPositionActions = () => {
        const heightWindow = window.innerHeight
        const positions = refAction.current.getBoundingClientRect()
        if ((heightWindow - (positions.top + positions.height)) > heightWindow / 3) {
            const top = `calc(${positions.height}px + ${positions.top}px + 0.5em)`
            const right = `calc(100vw - ${positions.right}px)`
            setStyleAction({ top, right })
        } else {
            const bottom = `calc(${heightWindow}px - ${positions.bottom}px + ${positions.height}px + 0.5em)`
            const right = `calc(100vw - ${positions.right}px)`
            setStyleAction({ bottom, right })
        }
    }

    const getOptionsByMessage = () => {
        const diffMinutes = moment().diff(moment(message.created_at), "minutes")
        const directionIsSend = message?.direction === "send"
        const messageNotDeletedForEveryone = (!message?.deleted?.status || !message?.deleted?.forEveryone)
        const deleteAvailable = directionIsSend && messageNotDeletedForEveryone && diffMinutes < 1440
        const editAvailable = directionIsSend && messageNotDeletedForEveryone && diffMinutes < 150
        const forwardAvailable = messageNotDeletedForEveryone && diffMinutes < 0
        const replyAvailable = messageNotDeletedForEveryone
        return (
            <>
                {deleteAvailable && <li onClick={handleOpenModalDelete}><MdOutlineDelete /> Apagar</li>}
                {editAvailable && <li onClick={handleOpenModalEdit}><HiOutlinePencil /> Editar</li>}
                {forwardAvailable && <li><MdOutlineKeyboardDoubleArrowRight /> Encaminhar</li>}
                {replyAvailable && <li onClick={handleReplyMessage}><MdKeyboardDoubleArrowLeft /> Responder</li>}
                <li><MdOutlineInfo /> Dados</li>
            </>
        )
    }

    const handleOpenModalDelete = () => {
        setShowModalDelete(true)
    }

    const handleCloseModalDelete = () => {
        setShowModalDelete(false)
    }

    const handleDeleteMessage = () => {
        deleteMessage({ message_id: message._id, conversation_id: message.conversation_id, inbox_id: message.inbox_id })
        handleCloseModalDelete()
    }

    const handleOpenModalEdit = () => {
        if (message?.type !== "text") {
            return toast.error("Só é possível editar mensagens de texto!")
        }
        setShowModalEdit(true)
    }

    const handleCloseModalEdit = () => {
        setShowModalEdit(false)
    }

    const handleUpdateMessage = (text) => {
        updateMessage({ message_id: message._id, conversation_id: message.conversation_id, inbox_id: message.inbox_id, text })
    }

    const handleReplyMessage = () => {
        const formattedContent = getContentByQuoted(message)
        replyMessage(message, formattedContent)
    }

    const formatMessageSystem = (message) => {
        const prefix = message?.user_id ? `${getNameUserById(allUsersGroup, message.user_id)}` : ""
        return `${prefix} ${message?.content?.text || "-"} em ${getLocalDate(message.created_at).format("DD/MM/YYYY HH:mm:ss")}`
    }

    return (
        <>
            {newDayDivision &&
                <div className={styles.division}>
                    <span>{newDayDivision}</span>
                </div>
            }
            {message.internal !== lastMessage?.internal && lastMessage?.internal !== undefined &&
                <div className={`${styles.division} ${styles.internal}`}>
                    <span>{message.internal ? "Início das mensagens internas" : "Fim das mensagens internas"}</span>
                </div>
            }
            {message.type === "system" ?
                <div className={styles.system}>
                    <span>{formatMessageSystem(message)}</span>
                </div>
                :
                <div className={`${styles.groupMessage} ${message.direction === "send" ? styles.send : ''} ${message.internal ? styles.internal : ''} ${message.deleted?.status ? styles.deleted : ''} ${active ? styles.active : ''}`} data-message-id={message?.message_id}>
                    <div className={styles.message}>
                        {getMessageByTypeAndContent(message.type, message.content)}
                    </div>
                </div>
            }
            {showPreview &&
                <div className={styles.preview}>
                    <div className={styles.close} onClick={closePreview}>
                        <MdArrowBack />
                    </div>
                    {getContentMessageByType(message.type, message.content)}
                </div>
            }
            {showModalDelete &&
                <ModalConfirm
                    closeModal={handleCloseModalDelete}
                    onSubmit={handleDeleteMessage}
                    icon={<MdOutlineDelete />}
                    title="Deseja apagar a mensagem?"
                    description="A mensagem será apagada para todos na conversa!"
                    text="Se deseja manter a mensagem clique em cancelar"
                />
            }
            {showModalEdit &&
                <ModalEdit
                    closeModal={handleCloseModalEdit}
                    onSubmit={handleUpdateMessage}
                    value={message?.content?.text}
                />
            }
        </>
    )
}

export default Message