import React, { useEffect, useRef, useState } from "react"
import { IoMdArrowDropdown } from "react-icons/io"
import styles from "./form.module.css"
import Checkbox from './Checkbox'
import Input from "./Input"

const Select = ({ name, placeholder, multiple = false, options = [], selected = "", theme, required = false, onChange = () => { } }) => {
    const [textSearch, setTextSearch] = useState("")
    const [active, setActive] = useState(false)
    const [stylesSelect, setStylesSelect] = useState({})
    const [stylesOptions, setStylesOptions] = useState({})

    const selectRef = useRef()

    useEffect(() => {
        if (multiple) {
            if (!selected) {
                handleToogleAll()
            }
        } else {
            if (Array.isArray(selected)) {
                onChange("")
            }
        }
    }, [])

    const getOptionsByValue = () => {
        if (selected || selected?.toString()) {
            if (multiple) {
                const optionsSelected = options?.filter(option => selected.includes(option.id))
                return `${name} (${optionsSelected?.length || 0})`
            } else {
                let selectedText = ''
                options?.forEach(option => {
                    if (option.id?.toString() === selected?.toString()) {
                        selectedText = option.value
                    }
                })
                return selectedText ? selectedText : name
            }
        } else {
            return name
        }
    }

    const setSelectedOption = (id, only = false) => {
        if (multiple) {
            if (only) {
                onChange([id])
            } else {
                const index = selected.indexOf(id)
                if (index !== -1) {
                    const newValue = [...selected]
                    newValue.splice(index, 1)
                    onChange(newValue)
                } else {
                    const newValue = [...selected]
                    newValue.push(id)
                    onChange(newValue)
                }
            }
        } else {
            if (id?.toString() === selected) {
                onChange()
            } else {
                onChange(id?.toString())
                setActive(false)
                setTextSearch("")
            }
        }
    }

    const handleTextSearch = (text) => {
        setTextSearch(text)
    }

    const handleToogleAll = () => {
        let newValue = []
        if (selected?.length !== options.length) {
            newValue = options?.map(option => option.id)
        }
        onChange(newValue)
    }

    const handleToogleVisibleOptions = () => {
        setActive(previousActive => {
            if (previousActive) {
                setTextSearch("")
            } else {
                const windowHeight = window.innerHeight
                const details = selectRef.current.getBoundingClientRect()

                const distanceBottom = windowHeight - details.bottom - details.height
                const distanceTop = windowHeight - (windowHeight - details.top)

                const setToUp = distanceBottom < 150 && distanceTop > distanceBottom

                const maxHeight = setToUp ? (distanceTop > 500 ? `${400}px` : `${distanceTop}px - 1.5em`) : (distanceBottom > 500 ? `${400}px` : `${distanceBottom}px - 1.5em`)
                const top = setToUp ? `calc(${details.top}px - 0.5em)` : `calc(${details.bottom}px + 0.5em)`
                const transform = setToUp ? `translateY(-100%)` : ''
                setStylesSelect({
                    top,
                    minWidth: `${details.width}px`,
                    transform
                })

                setStylesOptions({
                    maxHeight: `calc(${maxHeight})`
                })
            }
            return !previousActive
        })
    }

    return (
        <>
            <div className={`${styles.select} ${active ? styles.active : ''} ${styles[theme]}`} ref={selectRef} tabIndex={1}>
                <div className={styles.text} onClick={handleToogleVisibleOptions} onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleToogleVisibleOptions()} tabIndex="0" role="button">
                    <span>
                        {getOptionsByValue()}
                    </span>
                    <IoMdArrowDropdown />
                </div>
                {active &&
                    <div className={styles.groupOptions} style={stylesSelect}>
                        <div className={styles.search}>
                            <Input type="search" placeholder={placeholder} value={textSearch} onChange={(e) => handleTextSearch(e.target.value)} autoFocus={true} />
                        </div>
                        <div className={styles.options} style={stylesOptions}>
                            {multiple &&
                                <div className={styles.selectAll} onClick={handleToogleAll}>
                                    <Checkbox checked={selected.length === options.length} />
                                    <span>Todos</span>
                                </div>
                            }
                            {options && options.map(option => (
                                (!textSearch || (option?.value && option?.value?.toLowerCase().indexOf(textSearch?.toLowerCase())) !== -1) &&
                                <div key={option.id} className={styles.option} onClick={() => setSelectedOption(option.id)}>
                                    <Checkbox checked={multiple ? selected.indexOf(option.id) !== -1 : selected?.toString() === option.id?.toString()} />
                                    <span>{option.value}</span>
                                    {multiple &&
                                        <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); setSelectedOption(option.id, true) }}>Somente</button>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            {active &&
                <div className={styles.backgroundForm} onClick={handleToogleVisibleOptions} />
            }
        </>
    )
}

export default Select