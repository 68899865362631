import React, { useState } from "react";
import styles from "./webPhone.module.css"
import Modal from "../Modal";
import { MdFullscreen, MdMoreHoriz, MdOutlineArrowDropDown, MdOutlinePause, MdOutlineVolumeDown, MdOutlineVolumeOff, MdOutlineVolumeUp, MdPhone, MdPictureInPictureAlt, MdPlayArrow } from "react-icons/md";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import Select from './../Form/Select';
import { formatCurrency, formatMsToTime, maskPhone } from "../../utils/formatter";
import Button from './../Form/Button';

const WebPhone = ({ name, phone, credits, time, calling, endedCall, connecting, callConnected, statusCall, open, volume, mute, hold, inputDevices, inputSelected, outputDevices, outputSelected, handleToogleMute, handleToogleHold, handleToogleOpen, changeInputSelected, changeOutputSelected, changeVolumeOutput, handleStartCall, handleEndCall, onClose }) => {
    const [showChangeVolume, setShowChangeVolume] = useState(false)
    const [showChangeDevices, setShowChangeDevices] = useState(false)

    const handleToogleShowVolume = () => {
        setShowChangeVolume(previousValue => !previousValue)
    }

    const handleToogleShowDevices = () => {
        setShowChangeDevices(previousValue => !previousValue)
    }

    const getDescriptionByCallStatus = () => (
        statusCall === 0 ?
            <span className={styles.time}>Aguardando inicio da chamada!</span>
            :
            (statusCall === 1 ?
                <span className={styles.time}>Conectando...</span>
                :
                <span className={styles.time}>{formatMsToTime(time)}</span>
            )
    )

    return (
        <>
            {(endedCall || open) &&
                <Modal
                    fitContent={true}
                    closeModal={onClose}
                    closeOnEsc={false}
                >
                    <div className={styles.webPhone}>
                        <span className={styles.name}>{name}</span>
                        <span className={styles.number}>{maskPhone(phone)}</span>
                        <span className={`${styles.credits} ${credits < 10 ? styles.ended : (credits < 20 ? styles.ending : '')}`}>Créditos {formatCurrency(credits)}</span>
                        {endedCall && !connecting ?
                            <>
                                <span className={styles.time}>Chamada encerrada!</span>
                                <span className={styles.time}>{formatMsToTime(time)}</span>
                                <Button className={`bg-purple ${styles.redial} ${connecting || callConnected ? styles.disabled : ''}`} onClick={handleStartCall}>Rediscar</Button>
                            </> : <>
                                {getDescriptionByCallStatus()}
                                <div className={styles.options}>
                                    <div className={styles.groupOption}>
                                        <div className={`${styles.option} ${styles.border} ${volume === 0 ? styles.disabled : ''}`} onClick={handleToogleShowVolume}>
                                            {volume === 0 ?
                                                <MdOutlineVolumeOff />
                                                :
                                                (volume <= 50 ?
                                                    <MdOutlineVolumeDown />
                                                    :
                                                    <MdOutlineVolumeUp />
                                                )
                                            }
                                            <MdOutlineArrowDropDown />
                                        </div>
                                        {showChangeVolume &&
                                            <>
                                                <div className={styles.elementFloat}>
                                                    <input type="range" min="0" max="100" step="5" value={volume} onChange={changeVolumeOutput}></input>
                                                </div>
                                                <div className={styles.background} onClick={handleToogleShowVolume} />
                                            </>
                                        }
                                    </div>
                                    {calling &&
                                        <>
                                            <div className={`${styles.option} ${styles.border} ${styles.rounded} ${mute ? styles.disabled : ''}`} onClick={handleToogleMute}>
                                                {mute ?
                                                    <BiMicrophoneOff />
                                                    :
                                                    <BiMicrophone />
                                                }
                                            </div>
                                            <div className={`${styles.option} ${styles.border} ${styles.rounded} ${hold ? styles.disabled : ''}`} onClick={handleToogleHold}>
                                                {hold ?
                                                    <MdPlayArrow />
                                                    :
                                                    <MdOutlinePause />
                                                }
                                            </div>
                                        </>
                                    }
                                    <div className={styles.groupOption}>
                                        <div className={`${styles.option} ${styles.border} ${styles.rounded}`} onClick={handleToogleShowDevices}>
                                            <MdMoreHoriz />
                                        </div>
                                        {showChangeDevices &&
                                            <>
                                                <div className={styles.elementFloat}>
                                                    <Select name="Microfone" placeholder="Selecione uma opção..." options={inputDevices} selected={inputSelected} onChange={changeInputSelected} multiple={false} />
                                                    <Select name="Saída de Som" placeholder="Selecione uma opção..." options={outputDevices} selected={outputSelected} onChange={changeOutputSelected} multiple={false} />
                                                </div>
                                                <div className={styles.background} onClick={handleToogleShowDevices} />
                                            </>
                                        }
                                    </div>
                                    {calling && callConnected &&
                                        <>
                                            <div className={`${styles.option} ${styles.border}`} onClick={handleToogleOpen}>
                                                <MdPictureInPictureAlt />
                                            </div>
                                            <div className={`${styles.option} ${styles.endCall}`} onClick={handleEndCall}>
                                                <MdPhone />
                                            </div>
                                        </>
                                    }
                                    {!calling &&
                                        <div className={`${styles.option} ${styles.startCall} ${connecting || callConnected ? styles.disabled : ''}`} onClick={handleStartCall}>
                                            <MdPhone />
                                        </div>
                                    }
                                </div>
                            </>}
                    </div>
                </Modal >
            }
            {(!endedCall && !open) &&
                <div className={styles.minimized}>
                    {getDescriptionByCallStatus()}
                    <div className={styles.options}>
                        <div className={`${styles.option} ${styles.border} ${styles.rounded} ${mute ? styles.disabled : ''}`} onClick={handleToogleMute}>
                            {mute ?
                                <BiMicrophoneOff />
                                :
                                <BiMicrophone />
                            }
                        </div>
                        <div className={`${styles.option} ${styles.border} ${styles.rounded}`} onClick={handleToogleOpen}>
                            <MdFullscreen />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default WebPhone