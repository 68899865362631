import React, { useEffect, useState } from "react"
import styles from '../modal.module.css'
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import { HiPlus } from "react-icons/hi"
import { IoIosFunnel } from "react-icons/io"
import Stage from "../../Crm/Stage"
import BreakForm from "../../Form/BreakForm"
import Select from "../../Form/Select"
import StageModalFunnel from "./StageModalFunnel"
import { useData } from "../../../contexts/dataContext"
import { getItemForArrayById, getUniqueId } from "../../../utils/utils"
import { getStages } from "../../../schemas/Stage"
import CircleLoading from "../../CircleLoading"

const ModalFunnel = ({ isNew = false, funnel_id, initialData, onSubmit, closeModal }) => {
    const [stages, setStages] = useState([])
    const [funnel, setFunnel] = useState({ funnel_id })
    const [stylesMove, setStylesMove] = useState({})
    const [initialPositionMove, setInitialPositionMove] = useState()
    const [loading, setLoading] = useState(false)

    const { funnels, teams } = useData()

    useEffect(() => {
        if (funnel_id) {
            getStartValuesByFunnel(funnel_id)
        } else {
            setinitialValueByFunnel()
            setinitialValueByStages()
        }
    }, [funnel_id])

    const getStartValuesByFunnel = (funnel_id) => {
        loadStages(funnel_id)
        const dataFunnel = getItemForArrayById(funnels, "_id", funnel_id)
        setFunnel(dataFunnel)
        handleChangeFunnel("teams", dataFunnel?.teams || [])
    }

    const loadStages = async (funnel_id) => {
        setLoading(true)
        const newStages = await getStages(funnel_id)
        if (newStages?.length > 0) {
            setStages(newStages)
        } else {
            setinitialValueByStages()
        }
        setLoading(false)
    }

    const setinitialValueByFunnel = () => {
        if (initialData?.funnel?.name) {
            handleChangeFunnel("name", initialData?.funnel?.name)
        }
    }

    const setinitialValueByStages = () => {
        if (!stages?.length && initialData?.stages) {
            initialData.stages?.forEach(stage => {
                addStage(stage.name, stage.time_to_cool)
            })
        } else {
            addStage()
        }
    }

    const handleChangeFunnel = (name, value) => {
        setFunnel(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangeStage = (name, value, stage_id) => {
        setStages(previousValue => previousValue?.map(stage => {
            return stage._id === stage_id ? { ...stage, [name]: value } : stage
        }))
    }

    const handleChangeVisibleStage = (e, stage_id) => {
        e.preventDefault()
        const newStages = stages?.map(stage => {
            if (stage._id === stage_id) {
                stage.show = stage?.show ? false : true
            }
            return stage
        })
        setStages(newStages)
    }

    const handleAddStage = (e) => {
        e.preventDefault()
        addStage()
    }

    const addStage = (name = undefined, time_to_cool = undefined) => {
        setStages(previousValue => {
            const newId = getUniqueId()
            const newValue = [...previousValue]
            if (!newValue.some(el => el.name === name && name !== undefined)) {
                newValue.push({ _id: newId, name, time_to_cool, isNew: true, show: true })
            }
            return newValue
        })
    }

    const handleRemoveStage = (e, stage_id) => {
        e.preventDefault()
        const newStages = stages?.filter(stage => stage._id !== stage_id)
        setStages(newStages)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(funnel, stages)
    }

    const setStageMoving = (stage_id, moving = true) => {
        setStages(previousValue => previousValue?.map(stage => {
            const newValue = { ...stage }
            if (newValue._id === stage_id) {
                newValue.moving = moving
            }
            return newValue
        }))
    }

    const handleMouseStart = (e, stage_id) => {
        const positionY = e?.screenY
        onMoveStart(stage_id, positionY)
    }

    const handleMouseMove = (stage_id, indexMove, positionY, positionMove) => {
        onMove(stage_id, indexMove, positionY, positionMove)
    }

    const handleTouchStart = (e, stage_id) => {
        const positionY = e.touches[0]?.screenY
        onMoveStart(stage_id, positionY)
    }

    const handleTouchMove = (e, stage_id, indexMove) => {
        const positionY = e.touches[0]?.screenY
        const positionMove = positionY - initialPositionMove
        onMove(stage_id, indexMove, positionY, positionMove)
    }

    const handleMoveEnd = (stage_id) => {
        setStageMoving(stage_id, false)
        setStylesMove({})
        setInitialPositionMove()
    }

    const onMoveStart = (stage_id, positionY) => {
        setStageMoving(stage_id)
        setInitialPositionMove(positionY)
    }

    const onMove = (stage_id, indexMove, positionY, positionMove) => {
        setStylesMove({ transform: `translateY(${positionMove}px)` })
        stages?.forEach((stage, index) => {
            const positions = stage?.ref?.getBoundingClientRect()
            const height = positions?.height / 2
            const posY = positions?.y + height
            if (posY >= 0) {
                if (positionY > posY && indexMove < index) {
                    onChangeSequenceByStages(stage._id, stage_id)
                    setInitialPositionMove(positionY)
                }
                if (positionY < posY && indexMove > index) {
                    onChangeSequenceByStages(stage_id, stage._id)
                    setInitialPositionMove(positionY)
                }
            }
        })
    }

    const onChangeSequenceByStages = (stageUpId, stageDownId) => {
        const [stageUp] = stages?.filter(stage => stage._id === stageUpId)
        const [stageDown] = stages?.filter(stage => stage._id === stageDownId)
        if (stageUp && stageDown) {
            setStylesMove({})
            setStages(previousValue => previousValue?.map(stage => {
                return stage._id === stageUpId ? stageDown : (stage._id === stageDownId ? stageUp : stage)
            }))
        }
    }

    return (
        <Modal
            icon={<IoIosFunnel />}
            title={isNew ? "Crie seu funil" : "Configure seu funil"}
            description={isNew ? "Adicione novas etapas e crie suas regras abaixo" : "Edite suas etapas e gerencie suas regras abaixo"}
            closeModal={closeModal}
            fullWidith={true}
        >
            <form onSubmit={handleSubmit}>
                <div className={styles.inlineMaxHeight}>
                    <div className={styles.options}>
                        <div className={styles.top} style={initialPositionMove ? { overflow: 'hidden', paddingRight: '17px' } : {}}>
                            <FormGroup labelText="Nome do funil">
                                <Input type="text" placeholder="Preencha o nome do funil" value={funnel?.name || ''} onChange={(e) => handleChangeFunnel("name", e.target.value)} autoFocus={isNew} />
                            </FormGroup>
                            <FormGroup labelText="Equipes">
                                <Select multiple={true} name="Equipes" placeholder="Selecione as equipes" options={teams || []} selected={funnel?.teams ?? []} onChange={(value) => handleChangeFunnel("teams", value)} />
                            </FormGroup>
                            <BreakForm title="Etapas" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={handleAddStage} />
                            {!loading && stages?.length > 0 && stages.map((stage, index) => (
                                <StageModalFunnel
                                    key={stage._id}
                                    stage={stage}
                                    index={index}
                                    handleChangeVisibleStage={handleChangeVisibleStage}
                                    handleRemoveStage={handleRemoveStage}
                                    handleChangeStage={handleChangeStage}
                                    handleTouchStart={handleTouchStart}
                                    handleTouchMove={handleTouchMove}
                                    handleMoveEnd={handleMoveEnd}
                                    handleMouseStart={handleMouseStart}
                                    handleMouseMove={handleMouseMove}
                                    setInitialPositionMove={setInitialPositionMove}
                                    stylesMove={stylesMove}
                                />
                            ))}
                            {loading && <CircleLoading />}
                        </div>
                        <div className={styles.bottom}>
                            <FormGroupInline>
                                <Button className='bg-pink' type="submit">Salvar</Button>
                            </FormGroupInline>
                        </div>
                    </div>
                    <div className={`${styles.stages} ${loading ? styles.loading : ''}`}>
                        {!loading && stages?.length > 0 && stages.map(stage => (
                            <Stage stage={stage} key={stage._id} readOnly={true} />
                        ))}
                        {loading && <CircleLoading />}
                    </div>
                </div>
            </form>
        </Modal >
    )
}

export default ModalFunnel