import React, { useEffect, useState } from "react"
import BreakForm from "../../Form/BreakForm"
import styles from "./history.module.css"
import CardHistoric from "./CardHistoric"
import { toast } from "react-toastify"
import moment from "moment"
import api from '../../../services/api'
import DatePicker from '../../Form/DatePicker/index'
import Select from '../../Form/Select'
import { useWebSocketContext } from "../../../contexts/webSocketContext"
import CircleLoading from './../../CircleLoading'

const History = ({ lead_id }) => {
    const [historics, setHistorics] = useState([])
    const [filters, setFilters] = useState({})
    const [usersFilters, setUsersFilters] = useState([])
    const { lastJsonMessage } = useWebSocketContext()
    const [updateFiltersSeleted, setUpdateFiltersSeleted] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)

    useEffect(() => {
        getAllHistorics()
    }, [])

    useEffect(() => {
        if (historics?.length > 0) {
            updateUsersFilters()
        }
    }, [historics])

    useEffect(() => {
        if (updateFiltersSeleted) {
            setFilters(previousValue => {
                const usersSelected = usersFilters?.map(user => { return user.id })
                return { ...previousValue, users: usersSelected }
            })
            setUpdateFiltersSeleted(false)
        }
    }, [usersFilters])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "ADD_HISTORIC_LEAD") {
            const leadId = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (leadId === lead_id) {
                setHistorics(previousValue => {
                    return [...previousValue, dataUpdate]
                })
            }
        }
    }

    const updateUsersFilters = () => {
        if (!filters?.users?.length || usersFilters.length === filters?.users?.length) {
            setUpdateFiltersSeleted(true)
        }
        const users = []
        historics?.forEach(historic => {
            const [userExists] = users?.filter(user => user.id === historic.user_id)
            if (!userExists) {
                users.push({
                    id: historic.user_id, value: historic.user_created
                })
            }
        })
        users.sort((a, b) => {
            if (a.value > b.value) {
                return 1
            } else if (a.value < b.value) {
                return -1
            }
            return 0
        })
        setUsersFilters(users)
    }

    const getAllHistorics = () => {
        setInitialLoading(true)
        const url = `/leads/${lead_id}/historics`
        api.get(url).then(response => {
            const historics = response?.data?.data
            historics?.map(historic => {
                setUsersFilters(previousValue => {
                    const newValue = [...previousValue]
                    const [userExists] = newValue?.filter(user => user.id === historic.user_id)
                    if (!userExists) {
                        newValue.push({
                            id: historic.user_id, value: historic.user_created
                        })
                    }
                    return newValue
                })
            })
            setHistorics(historics || [])
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setInitialLoading(false))
    }

    const orderHistorics = (a, b) => {
        if (moment(a.created_at).unix() < moment(b.created_at).unix()) {
            return 1
        } else if (moment(a.created_at).unix() > moment(b.created_at).unix()) {
            return -1
        }
        return 0
    }

    const filterHistorics = (historic) => {
        const dateHistoric = moment(historic.created_at).format("YYYY-MM-DD")
        const validPeriod = (!filters.dateStart || moment(filters.dateStart).isSameOrBefore(dateHistoric)) && (!filters.dateEnd || moment(filters.dateEnd).isSameOrAfter(dateHistoric))
        const validUsers = !filters.users || filters.users.indexOf(historic.user_id) !== -1
        return validPeriod && validUsers
    }

    const getFilteredHistorics = () => {
        const listHistorics = historics?.filter(filterHistorics)
        return listHistorics
    }

    const onChangeFilters = (name, value) => {
        if (name === "period") {
            setFilters(previousValue => {
                return { ...previousValue, dateStart: value.dateStart, dateEnd: value.dateEnd }
            })
        } else {
            setFilters(previousValue => {
                return { ...previousValue, [name]: value }
            })
        }
    }

    return (
        <div className={styles.historics}>
            <div className={styles.historyHistorics}>
                <BreakForm title={"Histórico geral"} showAction={false} />
                {historics?.length > 0 &&
                    <div className={styles.filters}>
                        <DatePicker name="Período" dateStart={filters.dateStart} dateEnd={filters.dateEnd} onChange={(dateStart, dateEnd) => onChangeFilters("period", { dateStart, dateEnd })} multiple={true} />
                        <Select name="Responsável" placeholder="Selecione o responsável" selected={filters.users} options={usersFilters} onChange={(value) => onChangeFilters("users", value)} multiple={true} />
                    </div>
                }
                <div className={styles.body}>
                    {historics?.length > 0 && getFilteredHistorics().sort(orderHistorics).map(el => (
                        <CardHistoric
                            key={el._id}
                            value={el}
                        />
                    ))}
                    {!initialLoading && !historics?.length && (<span>Nenhuma anotação feita até o momento!</span>)}
                    {!initialLoading && (historics?.length > 0 && !getFilteredHistorics().length > 0) && <span>Nenhum resultado para sua busca!</span>}
                    {initialLoading && <CircleLoading />}
                </div>
            </div>
        </div >
    )
}

export default History