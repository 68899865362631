import React, { useEffect, useState } from "react"
import styles from './modalLead.module.css'
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import Select from "../../Form/Select"
import { MdOutlineDelete, MdOutlineGroupAdd } from "react-icons/md"
import FormGroupInline from '../../Form/FormGroupInline'
import BreakForm from '../../Form/BreakForm/index'
import { HiPlus } from "react-icons/hi"
import { useCrm } from "../../../contexts/crmContext"
import { getIdsUsersByTeamId, getUsersByTeamId } from "../../../utils/utils"
import { useData } from "../../../contexts/dataContext"

const ModalNewLead = ({ value = {}, selectFunnel = false, onChange = () => { }, onSubmit = () => { }, closeModal = () => { } }) => {
    const [products, setProducts] = useState([{}])
    const [listFunnels, setListFunnels] = useState([])
    const [listStages, setListStages] = useState([])
    const [showAllFields, setShowAllFields] = useState(false)

    const { stages, changeStages } = useCrm()
    const { origins: listOrigins, products: listProducts, teams, users, funnels } = useData()

    useEffect(() => {
        if (selectFunnel) {
            setListFunnels(funnels?.map(funnel => { return { id: funnel._id, value: funnel.name } }))
        }
    }, [funnels, selectFunnel])

    useEffect(() => {
        if (selectFunnel) {
            changeStages(value?.funnel_id)
        }
    }, [value?.funnel_id, selectFunnel])

    useEffect(() => {
        setListStages(stages?.map(stage => { return { id: stage._id, value: stage.name } }))
    }, [stages])

    useEffect(() => {
        handleChangeValue('products', products)
    }, [products])

    useEffect(() => {
        setUsersSelected()
    }, [value?.team_id])

    const handleChangeValue = (key, value) => {
        onChange(previusValue => { return { ...previusValue, [key]: value } })
    }

    const handleChangeProduct = (key, value, index) => {
        setProducts(previusValue => {
            const newValue = [...previusValue]
            newValue[index] = { ...newValue[index], [key]: value }
            return newValue
        })

    }

    const handleAddProduct = (e) => {
        e.preventDefault()
        setProducts(previusValue => {
            const newValue = [...previusValue]
            newValue.push({})
            return newValue
        })
    }

    const handleRemoveProduct = (e, index) => {
        e.preventDefault()
        setProducts(previusValue => {
            const newValue = [...previusValue]
            newValue.splice(index, 1)
            return newValue
        })
    }

    const setUsersSelected = () => {
        const idsUsersTeam = getIdsUsersByTeamId(value.team_id, teams)
        const selected = value?.users?.filter(user => idsUsersTeam.indexOf(user) !== -1) || []
        handleChangeValue("users", selected)
    }

    const handleShowAllFields = () => {
        setShowAllFields(previousValue => !previousValue)
    }

    return (
        <Modal
            icon={<MdOutlineGroupAdd />}
            title="Cadastro de novo lead"
            description="Adicione uma nova oportunidade ao seu funil"
            closeModal={closeModal}
        >
            <form>
                <FormGroup labelText="Nome *">
                    <Input type="text" placeholder="Nome completo" value={value?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} invalid={true} textInvalid="Preencha o nome do lead" />
                </FormGroup>
                <FormGroupInline>
                    <FormGroup labelText={`Celular ${!value?.email ? "*" : ""}`}>
                        <Input type="phone" placeholder="Número de celular" value={value?.phone ?? ""} onChange={(e) => handleChangeValue("phone", e.target.value)} invalid={true} textInvalid="Preencha o celular do lead" />
                    </FormGroup>
                    <FormGroup labelText={`E-mail ${!value?.phone ? "*" : ""}`}>
                        <Input type="email" placeholder="Preencha um e-mail válido" value={value?.email ?? ""} onChange={(e) => handleChangeValue("email", e.target.value)} invalid={true} textInvalid="Preencha o e-mail do lead" />
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    {selectFunnel &&
                        <FormGroup labelText="Funil *">
                            <Select multiple={false} name="Funil" placeholder="Selecione um funil" options={listFunnels || []} selected={value?.funnel_id ?? ""} onChange={(id) => handleChangeValue("funnel_id", id)} invalid={true} textInvalid="Selecione o funil do lead" />
                        </FormGroup>
                    }
                    <FormGroup labelText="Etapa *">
                        <Select multiple={false} name="Etapa" placeholder="Selecione uma etapa" options={listStages || []} selected={value?.stage_id ?? ""} onChange={(id) => handleChangeValue("stage_id", id)} invalid={true} textInvalid="Selecione a etapa do lead" />
                    </FormGroup>
                </FormGroupInline>
                {showAllFields &&
                    <FormGroup labelText="Empresa">
                        <Input type="text" placeholder="Em que empresa ele atua?" value={value?.business_name ?? ""} onChange={(e) => handleChangeValue("business_name", e.target.value)} invalid={true} textInvalid="Preencha a empresa do lead" />
                    </FormGroup>
                }
                <FormGroupInline>
                    <FormGroup labelText="Origem *">
                        <Select multiple={false} name="Origem" placeholder="Selecione uma origem" options={listOrigins || []} selected={value?.origin_id ?? ""} onChange={(id) => handleChangeValue("origin_id", id)} invalid={true} textInvalid="Selecione a origem do lead" />
                    </FormGroup>
                    {showAllFields &&
                        <FormGroup labelText="Campanha">
                            <Input type="text" placeholder="Em que campanha o lead foi captado?" value={value?.campaign ?? ""} onChange={(e) => handleChangeValue("campaign", e.target.value)} invalid={true} textInvalid="Preencha a empresa do lead" />
                        </FormGroup>
                    }
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Equipe *">
                        <Select multiple={false} name="Equipe" placeholder="Selecione uma equipe" options={teams || []} selected={value?.team_id ?? ""} onChange={(id) => handleChangeValue("team_id", id)} invalid={true} textInvalid="Selecione a equipe do lead" />
                    </FormGroup>
                    <FormGroup labelText="Responsáveis *">
                        <Select multiple={true} name="Responsáveis" placeholder="Selecione os responsáveis" options={getUsersByTeamId(value?.team_id, users, teams) || []} selected={value?.users || []} onChange={(id) => handleChangeValue("users", id)} invalid={true} textInvalid="Selecione os responsáveis pelo lead" />
                    </FormGroup>
                </FormGroupInline>
                {showAllFields &&
                    <>
                        <BreakForm title="Produtos" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={handleAddProduct} />
                        {products && products.map((product, index) => (
                            <FormGroupInline key={index} btnAction={index > 0}>
                                <FormGroup labelText={index === 0 && 'Produto'}>
                                    <Select multiple={false} name="Produto" placeholder="Selecione um produto" options={listProducts || []} selected={product?.product_id ?? ""} onChange={(id) => handleChangeProduct("product_id", id, index)} invalid={true} textInvalid="Selecione um produto" />
                                </FormGroup>
                                <FormGroup labelText={index === 0 && 'Valor'}>
                                    <Input type="currency" placeholder="Valor do produto" value={product?.value ?? ""} onChange={(e) => handleChangeProduct("value", e.target.value, index)} invalid={true} textInvalid="Preencha o valor do produto" />
                                </FormGroup>
                                {index > 0 && <Button className='bg-purple action' onClick={(e) => handleRemoveProduct(e, index)}><MdOutlineDelete /></Button>}
                            </FormGroupInline>
                        ))}
                    </>
                }
                <div className={styles.showAllFields}>
                    <span onClick={handleShowAllFields}>{showAllFields ? "Mostrar menos" : "Mostrar mais"}</span>
                </div>
                <FormGroupInline>
                    <Button className='bg-purple' type="submit" onClick={(e) => onSubmit(e, true)}>Salvar e Abrir</Button>
                    <Button className='bg-pink' type="submit" onClick={onSubmit}>Salvar</Button>
                </FormGroupInline>
            </form>
        </Modal >
    )
}

export default ModalNewLead