import React, { useEffect, useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import { MdOutlineContactPage } from "react-icons/md"
import { toast } from "react-toastify"

const ModalContact = ({ onChange = () => { }, onSubmit = () => { }, closeModal = () => { } }) => {
    const [contact, setContact] = useState({})

    useEffect(() => {
        onChange("contacts", [{ displayName: contact.name, details: contact }])
    }, [contact])

    const handleChangeValue = (name, value) => {
        setContact(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        if (contact.name && contact.phone) {
            onSubmit()
            closeModal()
        } else {
            toast.error("Preencha todos os campos para prosseguir!")
        }
    }

    return (
        <Modal
            icon={<MdOutlineContactPage />}
            title={"Enviar Contato"}
            description={"Preencha os campos abaixo e clique em enviar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="Nome">
                    <Input type="text" placeholder="Nome" value={contact?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Celular">
                    <Input type="phone" placeholder="+55 99 9 9999-9999" value={contact?.phone ?? ""} onChange={(e) => handleChangeValue("phone", e.target.value)} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Enviar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalContact