import React, { useState } from "react";
import Modal from './../index';
import FormGroup from "../../Form/FormGroup";
import styles from "./modalMetaPage.module.css"
import Button from "../../Form/Button";
import moment from 'moment';
import api from "../../../services/api";
import { toast } from "react-toastify";
import ModalConfigForm from "./ModalConfigForm";
import Loading from "../../../pages/layouts/Loading";

const ModalForms = ({ icon, title = "", description = "", listForms = [], pagingForms = {}, funnels = [], stages = [], teams = [], users = [], flags = [], allFields = [], loadMore = () => { }, closeModal }) => {
    const [formData, setFormData] = useState({})
    const [formConfig, setFormConfig] = useState({})
    const [questionsConfig, setQuestionsConfig] = useState([])
    const [fields] = useState([{ id: -1, value: "Ignorar campo" }, ...allFields])
    const [showModalConfigForms, setShowModalConfigForms] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleShowModalConfigForm = (page_id, form_id) => {
        setIsLoading(true)
        api.get(`/integrations/meta/accounts/${page_id}/forms/${form_id}/questions`).then(response => {
            const data = response?.data?.data || []
            setFormData(data.form || {})
            setFormConfig(data.formConfig || {})
            setQuestionsConfig(data.questionsConfig || [])
            setShowModalConfigForms(true)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    const handleCloseModalConfigForm = () => {
        setFormData({})
        setShowModalConfigForms(false)
    }

    const handleSaveUpdateForm = async (data) => {
        setIsLoading(true)
        api.patch(`/integrations/meta/accounts/${formData.page_id}/forms/${formData.id}`, data).then(response => {
            toast.success(response.data.message)
            handleCloseModalConfigForm()
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }

    return (
        <Modal
            icon={icon}
            title={title}
            description={description}
            closeModal={closeModal}
        >
            <div className={styles.body}>
                <FormGroup labelText="Formulários">
                    {listForms.length > 0 && listForms.map((form, index) => (
                        <div className={styles.groupPage} key={index}>
                            <div className={styles.left}>
                                <span>{form.name || "-"}</span>
                                <span className={styles.small}>{form.created_time ? moment(form.created_time).format("DD/MM/YYYY HH:mm:ss") : "-"} - {form.status || "-"}</span>
                            </div>
                            <div className={styles.right}>
                                <Button className="bg-purple" onClick={() => handleShowModalConfigForm(form.page_id, form.id)}>
                                    Configurar
                                </Button>
                            </div>
                        </div>
                    ))}
                    {listForms.length === 0 && <span>Nenhum formulário encontrado!</span>}
                </FormGroup>
            </div>
            {pagingForms &&
                <div className={styles.footer}>
                    <Button className="bg-pink" onClick={loadMore}>Carregar Mais</Button>
                </div>
            }
            {showModalConfigForms &&
                <ModalConfigForm
                    icon={icon}
                    title={formData?.name}
                    description="Configurar campos"
                    fields={fields}
                    questions={formData?.questions}
                    formConfig={formConfig}
                    questionsConfig={questionsConfig}
                    funnels={funnels}
                    stages={stages}
                    teams={teams}
                    users={users}
                    flags={flags}
                    closeModal={handleCloseModalConfigForm}
                    onSubmit={handleSaveUpdateForm}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </Modal>
    )
}

export default ModalForms