import api from "../services/api"
import { toast } from "react-toastify"

const saveNewLead = async (newLead) => {
    try {
        const response = await api.post("/leads", newLead)
        const data = response.data?.data
        return data
    } catch (err) {
        toast.error(err?.response?.data?.message)
        throw err
    }
}

export { saveNewLead }