import React, { useEffect, useState } from 'react'
import api from './../../services/api'
import styles from "./chat.module.css"
import Button from '../../components/Form/Button'
import Input from '../../components/Form/Input'
import Select from './../../components/Form/Select'
import FormGroupInline from './../../components/Form/FormGroupInline'
import { BiFilter } from 'react-icons/bi'
import { BsClock } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import { MdOutlineBusiness } from 'react-icons/md'
import { RiCheckDoubleFill, RiCheckFill } from 'react-icons/ri'
import { TbReload } from 'react-icons/tb'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Conversation from '../../components/Chat/Conversation'
import CurrentConversation from '../../components/Chat/CurrentConversation'
import ConversationNotSelected from '../../components/Chat/ConversationNotSelected'
import Loading from './../layouts/Loading/index'
import CircleLoading from './../../components/CircleLoading'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/authContext'
import { useData } from '../../contexts/dataContext'
import { useWebSocketChatContext } from '../../contexts/webSocketChatContext'

const optionsReadStatus = [
    { id: "read", value: "Lidas" },
    { id: "noRead", value: "Não lidas" },
]
const optionsStatus = [
    { id: "waiting", value: "Aguardando" },
    { id: "opened", value: "Em Aberto" },
    { id: "finalized", value: "Finalizados" },
]

const initialFilters = { status: ["waiting", "opened"] }

const Chat = () => {
    const [filters, setFilters] = useState(initialFilters)
    const [tempFilters, setTempFilters] = useState(initialFilters)
    const [inboxes, setInboxes] = useState([])
    const [flags, setFlags] = useState([])
    const [conversations, setConversations] = useState([])
    const [currentMessages, setCurrentMessages] = useState([])
    const [currentConversation, setCurrentConversation] = useState()
    const [showOthersFilters, setShowOthersFilters] = useState(true)
    const [loadingConversations, setLoadingConversations] = useState(false)
    const [loadingConversation, setLoadingConversation] = useState(false)
    const [connectedWebsocket, setConnectedWebsocket] = useState(false)
    const [defaultPicture, setDefaultPicture] = useState("")

    const { teams, roles, users } = useData()
    const { lastJsonMessage, sendJsonMessage, readyState, handleReconnect, getAllChatContacts, getAllChatInboxes, chatInboxes, chatFlags, getAllChatFlags, handleOpenModalStartChat, handleCloseModalStartChat } = useWebSocketChatContext()
    const { user } = useAuth()
    const { account_id, chat_id } = useParams()
    const query = new URLSearchParams(useLocation().search)
    const navigate = useNavigate()

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    useEffect(() => {
        getAllChatFlags()
        getAllConversations()
        getDefaultProfilePicture()
        getAllChatContacts()
        getAllChatInboxes()
        verifyIfActionByExecute()
    }, [])

    useEffect(() => {
        setInboxes(chatInboxes.map(inbox => ({ id: inbox._id, value: inbox.name })))
        handleChange("inboxes", chatInboxes.map(inbox => inbox._id))
    }, [chatInboxes])

    useEffect(() => {
        setFlags(chatFlags.map(flag => ({ id: flag._id, value: flag.name })))
    }, [chatFlags])

    useEffect(() => {
        setConnectedWebsocket(readyState === 1)
    }, [readyState])

    useEffect(() => {
        navigate(`/${account_id}/chat/${currentConversation || ''}`)
    }, [account_id, currentConversation])

    useEffect(() => {
        setConversationActiveById(chat_id)
    }, [chat_id])

    const verifyIfActionByExecute = () => {
        const action = query.get('action')
        if (action) {
            switch (action) {
                case "startChat":
                    const phone = query.get('phone')
                    if (!phone) {
                        return
                    }
                    handleOpenModalStartChat({ phone })
                    break
                default:
                    break
            }
        }
    }

    const updateDataByMessageWebSocket = (message) => {
        const event = message?.event
        const content = message?.content
        switch (event) {
            case "UPDATE_MESSAGE":
                updateMessageByContent(content)
                break;
            case "UPDATE_CONVERSATION":
                updateConversationByContent(content)
                break;
            case "START_CONVERSATION":
                startConversationByContent(content)
                break;
            case "ERROR":
                if (content?.error) {
                    toast.error(content.error)
                }
                break;
            default:
                break;
        }
    }

    const updateMessageByContent = (newMessage) => {
        const message_id = newMessage?._id
        const temp_id = newMessage?.temp_id
        const conversation_id = newMessage?.conversation_id
        if ((message_id || temp_id) && currentConversation === conversation_id) {
            setCurrentMessages(previousValue => {
                if (previousValue.find(message => message?._id ? message?._id === message_id : message?.temp_id === temp_id)) {
                    return previousValue.map(message => {
                        if (message?._id ? message?._id === message_id : message?.temp_id === temp_id) {
                            return { ...message, ...newMessage }
                        }
                        return message
                    })
                }
                return [...previousValue, newMessage]
            })
        }
    }

    const updateConversationByContent = (newConversation) => {
        const conversation_id = newConversation?._id
        setConversations(previousValue => {
            if (previousValue.find(conversation => conversation?._id === conversation_id)) {
                return previousValue.map(conversation => {
                    if (conversation?._id === conversation_id) {
                        return { ...conversation, ...newConversation }
                    }
                    return conversation
                })
            }
            return [...previousValue, newConversation]
        })
    }

    const startConversationByContent = (conversation) => {
        const conversation_id = conversation?._id
        if (conversation_id) {
            updateConversationByContent(conversation)
            setConversationActiveById(conversation_id)
            handleCloseModalStartChat()
        }
    }

    const handleChange = (name, value) => {
        setTempFilters(previousValue => ({ ...previousValue, [name]: value }))
    }

    const handleToggleShowOthersFilters = () => {
        setShowOthersFilters(previousValue => !previousValue)
    }

    const handleFilterAllConversations = () => {
        getAllConversations()
        setCurrentConversation()
    }

    const getAllConversations = () => {
        setLoadingConversations(true)
        const queryString = new URLSearchParams(tempFilters)?.toString()
        api.get(`/chat/conversations?${queryString}`).then(response => {
            const conversations = response.data?.data || []
            setConversations(conversations)
            setFilters(tempFilters)
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setLoadingConversations(false))
    }

    const setConversationActiveById = (conversation_id) => {
        if (currentConversation !== conversation_id) {
            setConversations(previousValue => (
                previousValue.map(conversation => {
                    const is_active = conversation._id === conversation_id
                    return { ...conversation, is_active }
                })
            ))
            if (conversation_id) {
                getAllMessagesByConversationId(conversation_id)
            }
        }
    }

    const getAllMessagesByConversationId = (conversation_id) => {
        setLoadingConversation(true)
        api.get(`/chat/conversations/${conversation_id}/messages`).then(response => {
            const messages = response.data?.data || []
            setCurrentMessages(messages)
            setCurrentConversation(conversation_id)
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setLoadingConversation(false))
    }

    const getDefaultProfilePicture = () => {
        api.get(`/chat/default-profile-picture`).then(response => {
            const link = response.data?.data || ""
            setDefaultPicture(link)
        }).catch(err => console.log(err?.response?.data?.message))
    }

    const backToHome = (e) => {
        e?.stopPropagation()
        setCurrentConversation(undefined)
        setCurrentMessages([])
    }

    const getIconMessageByDirectionDeliveredAndRead = (direction, sent, delivered, read, internal, styles) => {
        if (internal) {
            return <MdOutlineBusiness />
        }
        if (direction === "send") {
            return sent ? (delivered ? <RiCheckDoubleFill className={`${styles.deliveredStatus} ${read ? styles.read : ''}`} /> : <RiCheckFill className={styles.deliveredStatus} />) : <BsClock className={styles.sendStatus} />
        }
        return ""
    }

    const sortByDate = (a, b) => {
        if (a?.last_message?.created_at > b?.last_message?.created_at) {
            return -1
        } else if (a?.last_message?.created_at < b?.last_message?.created_at) {
            return 1
        } else {
            return 0
        }
    }

    const sendMessage = (message) => {
        const newMessage = {
            ...message,
            direction: "send",
            created_at: new Date(),
            user_id: user?._id,
        }

        if (!message?.internal && message?.content?.quoted_message?.internal) {
            return toast.error("Mensagens internas só podem ser respondidas internamente!")
        }

        if (message?.upload?.file) {
            const maxSizeInBytes = 60 * 1024 * 1024
            const file = message.upload.file

            if (file.size > maxSizeInBytes) {
                return toast.error("Tamanho máximo de arquivo permitido é 60MB!")
            }

            uploadFile(file, message?.type === "image").then(([keyFileS3, keyFileS3Thumb]) => {
                message.content.keyFileS3 = keyFileS3
                message.content.keyFileS3Thumb = keyFileS3Thumb
                message.upload = undefined
                sendJsonMessage({ event: "SEND_MESSAGE", message })
            }).catch(err => {
                console.log(err)
            })
        } else {
            sendJsonMessage({ event: "SEND_MESSAGE", message })
        }
        updateMessageByContent(newMessage)
    }

    const deleteMessage = (message) => {
        sendJsonMessage({ event: "DELETE_MESSAGE", message })
    }

    const updateMessage = (message) => {
        sendJsonMessage({ event: "UPDATE_MESSAGE", message })
    }

    const readMessage = (message) => {
        sendJsonMessage({ event: "READ_MESSAGE", message })
    }

    const uploadFile = async (file, resize = false) => {
        try {
            const formData = new FormData()
            formData.append("file", file)
            formData.append("resize", resize)
            const response = await api.post("/chat/upload", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            const { keyFileS3, keyFileS3Thumb } = response?.data?.data
            return [keyFileS3, keyFileS3Thumb]
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    const executeFunctionByAction = async (action, conversation) => {
        const conversation_id = conversation?._id
        const inbox_id = conversation?.inbox_id
        const data = conversation?.data
        if (!conversation_id || !inbox_id) {
            return
        }
        switch (action) {
            case "END_CHAT":
                sendJsonMessage({ event: "END_CHAT", message: { conversation_id, inbox_id } })
                break
            case "OPEN_CHAT":
                sendJsonMessage({ event: "OPEN_CHAT", message: { conversation_id, inbox_id } })
                break
            case "ASSIGN_CHAT":
                sendJsonMessage({ event: "ASSIGN_CHAT", message: { conversation_id, inbox_id, data } })
                break
            case "UPDATE_CONTACT":
                sendJsonMessage({ event: "UPDATE_CONTACT", message: { conversation_id, inbox_id, data } })
                break
            case "NO_READ_CHAT":
                sendJsonMessage({ event: "NO_READ_CHAT", message: { conversation_id, inbox_id } })
                break
            case "READ_CHAT":
                sendJsonMessage({ event: "READ_CHAT", message: { conversation_id, inbox_id } })
                break
            default:
                break
        }
    }

    const getFilteredConversations = (conversations) => {
        const filteredConversations = filters?.status?.length > 0 ? conversations.filter(conversation => filters.status.includes(conversation.status)) : conversations
        return filteredConversations.sort(sortByDate)
    }

    return (
        <div className={`${styles.container} ${chat_id ? styles.chatOpened : ''}`}>
            <div className={styles.leftBar}>
                <div className={styles.groupFilters}>
                    <FormGroupInline>
                        <Input type="search" placeholder="Buscar" value={tempFilters?.search || ""} onChange={(e) => handleChange("search", e.target.value)} autoFocus={true} />
                        <Button className="action bg-pink" onClick={() => handleOpenModalStartChat()}><HiPlus /></Button>
                        <Button className="action bg-purple" onClick={handleToggleShowOthersFilters}><BiFilter /></Button>
                    </FormGroupInline>
                    <div className={`${styles.othersFilters} ${showOthersFilters ? styles.show : ''}`}>
                        <Select name="Status" placeholder="Pesquise um status..." multiple={true} options={optionsReadStatus || []} onChange={(value) => handleChange("readStatus", value)} selected={tempFilters?.readStatus || []} />
                        <Select name="Situação" placeholder="Pesquise uma situação..." multiple={true} options={optionsStatus || []} onChange={(value) => handleChange("status", value)} selected={tempFilters?.status || []} />
                        <Select name="Caixa de Entrada" placeholder="Pesquise uma caixa de entrada..." multiple={true} options={inboxes || []} onChange={(value) => handleChange("inboxes", value)} selected={tempFilters?.inboxes || []} />
                        <Select name="Etiquetas" placeholder="Pesquise uma etiqueta..." multiple={true} options={flags || []} onChange={(value) => handleChange("flags", value)} selected={tempFilters?.flags || []} />
                        <Select name="Equipes" placeholder="Pesquise uma equipe..." options={teams} selected={tempFilters?.teams || []} multiple={true} onChange={(value) => handleChange("teams", value)} />
                        <Select name="Cargos" placeholder="Pesquise um cargo..." options={roles} selected={tempFilters?.roles || []} multiple={true} onChange={(value) => handleChange("roles", value)} />
                        <Select name="Usuários" placeholder="Pesquise um usuário..." options={users} selected={tempFilters?.users || []} multiple={true} onChange={(value) => handleChange("users", value)} />
                        <Button className="bg-pink" onClick={handleFilterAllConversations}>Filtrar</Button>
                    </div>
                </div>
                <div className={styles.conversations}>
                    {!connectedWebsocket &&
                        <div className={styles.lostConnection}>
                            <span>Conexão Perdida</span>
                            <TbReload onClick={handleReconnect} />
                        </div>
                    }
                    {loadingConversations &&
                        <CircleLoading />
                    }
                    {conversations?.length > 0 && getFilteredConversations(conversations).map(conversation => (
                        <Conversation
                            key={conversation._id}
                            conversation={conversation}
                            onClick={() => setConversationActiveById(conversation._id)}
                            getIconMessageByDirectionDeliveredAndRead={getIconMessageByDirectionDeliveredAndRead}
                            defaultPicture={defaultPicture}
                            executeFunctionByAction={executeFunctionByAction}
                            updateConversationByContent={updateConversationByContent}
                        />
                    ))}
                    {conversations?.length === 0 ?
                        (!loadingConversations &&
                            <span>Nenhuma conversa encontrada...</span>
                        )
                        :
                        <>
                            <hr />
                            <span className={styles.messageSearch}>Não encontrou o que estava buscando? <br />Verifique os filtros...</span>
                        </>
                    }
                </div>
            </div>
            <div className={styles.rightBar}>
                {chat_id &&
                    <CurrentConversation
                        messages={currentMessages}
                        value={conversations?.find(conversation => conversation._id === currentConversation || conversation._id === chat_id) || { _id: currentConversation || chat_id }}
                        backToHome={backToHome}
                        getIconMessageByDirectionDeliveredAndRead={getIconMessageByDirectionDeliveredAndRead}
                        sendMessage={sendMessage}
                        deleteMessage={deleteMessage}
                        updateMessage={updateMessage}
                        readMessage={readMessage}
                        executeFunctionByAction={executeFunctionByAction}
                        defaultPicture={defaultPicture}
                    />
                }
                {!chat_id &&
                    <>
                        {loadingConversation ? <Loading /> : <ConversationNotSelected />}
                    </>
                }
            </div>
        </div>
    )
}

export default Chat