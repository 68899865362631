import React, { useCallback, useEffect, useState } from "react"
import styles from "./callLog.module.css"
import Table from "../../components/Table"
import api from "../../services/api"
import { toast } from "react-toastify"
import Select from "../../components/Form/Select"
import { formatCurrency, maskPhone } from "../../utils/formatter"
import { MdOutlinePlayCircleFilled } from "react-icons/md"
import DatePicker from './../../components/Form/DatePicker/index'
import Button from "../../components/Form/Button"
import Loading from './../layouts/Loading/index'
import Card from "../Dashboard/Card"
import { useData } from "../../contexts/dataContext"
import { useCrm } from "../../contexts/crmContext"
import moment from "moment"
import imageFilterCall from "../../images/filter-call.png"
import BackgroundSelectFilter from "../../components/BackgroundSelectFilter"
import ModalInfo from "../../components/Modal/ModalInfo"
import { adjustTextByIndentation } from "../../utils/utils"

const optionsStatus = [{ id: "answer", value: "Atendida" }, { id: "refused", value: "Caixa Postal" }]
const initialFilters = { dateStart: moment().subtract(7, 'd').format("YYYY-MM-DD"), dateEnd: moment().subtract(1, 'd').format("YYYY-MM-DD"), status: ["answer", "refused"] }

const CallLog = () => {
    const [calls, setCalls] = useState([])
    const [cards, setCards] = useState([])
    const [maxCalls, setMaxCalls] = useState(0)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState(initialFilters)
    const [isLoading, setIsLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [backgroundFilter, setBackgroundFilter] = useState(true)
    const [modalTranscription, setModalTranscription] = useState(false)
    const [descriptionTranscription, setDescriptionTranscription] = useState("")
    const [contentTranscription, setContentTranscription] = useState("")

    let isLoadingCall = false

    const { openModalLead } = useCrm()
    const { teams, users } = useData()

    const headers = [
        "#",
        "Data",
        "Celular",
        "Status",
        "Duração",
        "Valor da Ligação",
        "Valor da Transcrição",
        "Valor Total",
        "Responsável",
        "Gravação",
        "",
    ]

    useEffect(() => {
        if (loadMore) {
            getAllCallLogByFilters()
        }
    }, [loadMore])

    useEffect(() => {
        const teamsIds = teams?.map(team => { return team.id })
        const usersIds = users?.map(user => { return user.id })
        setFilters(previousValue => {
            return { ...previousValue, teams: teamsIds, users: usersIds }
        })
    }, [teams, users,])

    const getAllCallLogByFilters = async (newFind = false) => {
        try {
            if (!isLoadingCall) {
                isLoadingCall = true
                if (newFind) {
                    setIsLoading(true)
                }
                const queryString = new URLSearchParams(filters)?.toString()
                const response = await api.get(`/voip/log?${queryString}&page=${newFind ? 1 : page}`)
                const calls = response.data?.data?.calls || []

                if (newFind) {
                    const cards = response.data?.data?.cards || []
                    const maxCalls = response.data?.data?.maxCalls || 0
                    setCalls(calls)
                    setCards(cards)
                    setMaxCalls(maxCalls)
                } else {
                    setCalls(previousValue => {
                        return [...previousValue, ...calls]
                    })
                }
                setIsLoading(false)
                setPage(prev => newFind ? 2 : prev + 1)
                setLoadMore(false)
                setBackgroundFilter(false)
                isLoadingCall = false
            }
        } catch (err) {
            setIsLoading(false)
            setLoadMore(false)
            toast.error(err?.response?.data?.message)
            isLoadingCall = false
        }
    }

    const getDataNewCall = (newCall) => {
        return {
            id: newCall._id,
            value: [
                newCall.created_at ?? "-",
                maskPhone(newCall.phone) ?? "-",
                newCall.duration > 0 ? "Atendida" : "Caixa Postal",
                newCall.duration_formatted ?? "00:00:00",
                formatCurrency(newCall.cost_call ?? 0) ?? "R$ 0,00",
                formatCurrency(newCall.cost_transcription ?? 0) ?? "R$ 0,00",
                formatCurrency(newCall.cost_total ?? 0) ?? "R$ 0,00",
                newCall.user_created ?? "-",
                newCall.url ? <a href={newCall.url} target="_blank" className={styles.link} rel="noreferrer" onClick={(e) => e.stopPropagation()}><MdOutlinePlayCircleFilled /></a> : "-",
            ],
            actions: [
                {
                    label: "Abrir Lead",
                    onClick: handleOpenModalLead
                },
                {
                    label: "Resumo e Transcrição",
                    onClick: handleOpenModalTranscription
                }
            ]
        }
    }

    const getFilteredValues = () => {
        const values = calls
        values.sort((a, b) => {
            if (a.created_at < b.created_at) {
                return 1
            } else if (a.created_at > b.created_at) {
                return -1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map((call) => getDataNewCall(call))
        return filteredValues
    }

    const handleChangeFilters = (name, value) => {
        if (name === "period") {
            const dateStart = value.dateStart
            const dateEnd = value.dateEnd
            setFilters(previousValue => { return { ...previousValue, dateStart, dateEnd } })
        } else {
            setFilters(previousValue => { return { ...previousValue, [name]: value } })
        }
    }

    const handleOpenModalLead = (callId) => {
        const call = calls?.find(call => call._id === callId)
        if (call) {
            const leadId = call.lead_id
            openModalLead(leadId)
        }
    }

    const handleOpenModalTranscription = (callId) => {
        const call = calls?.find(call => call._id === callId)
        if (call) {
            const transcription = call?.transcription
            if (transcription?.full_text || transcription?.summary_text) {
                const formattedText = formatContentByTranscription(transcription?.full_text, transcription?.summary_text)
                setDescriptionTranscription(`Resumo e transcrição da chamada realizada em ${call.created_at} para o número: ${maskPhone(call.phone)}`)
                setContentTranscription(formattedText)
                setModalTranscription(true)
            } else {
                toast.error("Nenhuma transcrição encontrada!")
            }
        }
    }

    const formatContentByTranscription = (fullText = "", sumaryText = "") => {
        return (
            <div className={styles.containerTranscription}>
                <div className={styles.groupContent}>
                    <span className={styles.title}>Resumo</span>
                    <div className={styles.content}>
                        {sumaryText ?
                            adjustTextByIndentation(sumaryText)
                            :
                            <span className={styles.notFound}>O resumo não foi encontrado!</span>
                        }
                    </div>
                </div>
                <div className={styles.groupContent}>
                    <span className={styles.title}>Transcrição Completa</span>
                    <div className={styles.content}>
                        {fullText ?
                            adjustTextByIndentation(fullText)
                            :
                            <span className={styles.notFound}>A transcrição não foi encontrada!</span>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const handleCloseModalTranscription = () => {
        setModalTranscription(false)
    }

    const handleScrollTable = useCallback(() => {
        if (!loadMore && maxCalls > calls.length) {
            setLoadMore(true)
        }
    }, [maxCalls, calls, loadMore])

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <Select multiple={true} name="Equipe" placeholder="Buscar..." options={teams} onChange={(value) => handleChangeFilters("teams", value)} selected={filters.teams ?? []} required={true} />
                <Select multiple={true} name="Usuário" placeholder="Buscar..." options={users} onChange={(value) => handleChangeFilters("users", value)} selected={filters.users ?? []} required={true} />
                <Select multiple={true} name="Status" placeholder="Buscar..." options={optionsStatus} onChange={(value) => handleChangeFilters("status", value)} selected={filters.status ?? []} required={true} />
                <DatePicker multiple={true} name="Período" dateStart={filters.dateStart ?? ""} dateEnd={filters.dateEnd ?? ""} onChange={(dateStart, dateEnd) => handleChangeFilters("period", { dateStart, dateEnd })} />
                <Button className="bg-pink" onClick={getAllCallLogByFilters} disabled={isLoading}>Filtrar</Button>
            </div>
            <div className={styles.cards}>
                {cards?.length > 0 && cards.map(item => (
                    <Card
                        key={item.id}
                        icon=""
                        name={item.name}
                        value={item.value}
                        variation={item.variation}
                        is_positive={item.is_positive}
                    />
                ))}
            </div>
            {!backgroundFilter &&
                <Table
                    headers={headers}
                    values={getFilteredValues()}
                    onClick={(id) => handleOpenModalLead(id)}
                    onScroll={handleScrollTable}
                    loadMore={loadMore}
                    messageNotRegisters="Nenhum registro encontrado"
                    showIndex={true}
                    showCount={true}
                    count={maxCalls}
                />
            }
            {backgroundFilter &&
                <BackgroundSelectFilter
                    text="Selecione os filtros desejados e aplique para visualizar os dados!"
                    image={imageFilterCall}
                />
            }
            {modalTranscription &&
                <ModalInfo
                    title="Resumo e Transcrição"
                    description={descriptionTranscription}
                    content={contentTranscription}
                    closeModal={handleCloseModalTranscription}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </div>
    )
}

export default CallLog