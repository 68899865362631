import React, { useCallback, useEffect, useState } from "react"
import styles from "./tasks.module.css"
import Table from "../../components/Table"
import api from "../../services/api"
import { toast } from "react-toastify"
import Select from "../../components/Form/Select"
import { maskDate } from "../../utils/formatter"
import DatePicker from '../../components/Form/DatePicker/index'
import Button from "../../components/Form/Button"
import Loading from '../layouts/Loading/index'
import { useCrm } from "../../contexts/crmContext"
import moment from "moment"
import { getOptionByOptionList } from "../../utils/utils"
import { useWebSocketContext } from "../../contexts/webSocketContext"
import { useData } from "../../contexts/dataContext"
import imageFilterTask from "../../images/filter-task.png"
import BackgroundSelectFilter from "../../components/BackgroundSelectFilter"

const listStatus = [
    { id: 1, value: "Atrasado" },
    { id: 2, value: "Em breve" },
    { id: 3, value: "Concluído" },
    { id: 4, value: "Cancelado" },
]

const initialFilters = { status: [1, 2], dateStart: moment().subtract(7, 'd').format("YYYY-MM-DD"), dateEnd: moment().subtract(1, 'd').format("YYYY-MM-DD") }

const Tasks = () => {
    const [tasks, setTasks] = useState([])
    const [maxTasks, setMaxTasks] = useState(0)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState(initialFilters)
    const [isLoading, setIsLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [backgroundFilter, setBackgroundFilter] = useState(true)

    let isLoadingTask = false

    const { openModalLead } = useCrm()
    const { teams, users, typesTask } = useData()
    const { lastJsonMessage } = useWebSocketContext()

    const headers = [
        "#",
        "Agendada para",
        "Status",
        "Tipo",
        "Nome do Lead",
        "Responsáveis pelo lead",
        "Título",
        "Descrição",
        "Data de Conclusão",
    ]

    useEffect(() => {
        if (loadMore) {
            getAllTasksByFilters()
        }
    }, [loadMore])

    useEffect(() => {
        const teamsIds = teams?.map(team => { return team.id })
        const usersIds = users?.map(user => { return user.id })
        const typesTaskIds = typesTask?.map(type => { return type.id })
        setFilters(previousValue => {
            return { ...previousValue, teams: teamsIds, users: usersIds, typesTask: typesTaskIds }
        })
    }, [teams, users, typesTask])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "UPDATE_TASK_LEAD") {
            const dataUpdate = message?.data?.data
            const taskId = dataUpdate?._id
            if (dataUpdate && taskId) {
                updateTaskById(dataUpdate, taskId)
            }
        }
    }

    const updateTaskById = (newTask, taskId) => {
        setTasks(previousValue => {
            return previousValue?.map(task => {
                if (task._id === taskId) {
                    return { ...task, ...newTask }
                }
                return task
            })
        })
    }

    const getAllTasksByFilters = async (newFind = false) => {
        try {
            if (!isLoadingTask) {
                isLoadingTask = true
                if (newFind) {
                    setIsLoading(true)
                }
                const queryString = new URLSearchParams(filters)?.toString()
                const response = await api.get(`/tasks?${queryString}&page=${newFind ? 1 : page}`)
                const data = response.data?.data?.tasks || []
                if (newFind) {
                    const maxTasks = response.data?.data?.maxTasks || 0
                    setTasks(data)
                    setMaxTasks(maxTasks)
                } else {
                    setTasks(previousValue => {
                        return [...previousValue, ...data]
                    })
                }
                setPage(prev => newFind ? 2 : prev + 1)
                setBackgroundFilter(false)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setIsLoading(false)
            setLoadMore(false)
            isLoadingTask = false

        }
    }

    const getDataNewTask = (newTask) => {
        return {
            id: newTask._id,
            value: [
                maskDate(newTask.scheduled_date, true) ?? "-",
                newTask.finished ? <span className={`${styles.status} bg-green`}>Concluído</span> : (newTask.canceled ? <span className={`${styles.status} bg-purple`}>Cancelado</span> : (moment(newTask.scheduled_date).isBefore(new Date()) ? <span className={`${styles.status} bg-pink`}>Atrasado</span> : <span className={`${styles.status} bg-blue`}>Em breve</span>)),
                getOptionByOptionList(newTask.type_task_id, typesTask) ?? "-",
                newTask?.lead?.name ?? "-",
                newTask?.users?.length > 0 ? newTask.users?.map(user => { return user?.name ?? '' }).join(", ") : "-",
                newTask?.title ? `${newTask?.title?.substring(0, 40)}${newTask?.title?.length > 40 ? "..." : ""}` : '',
                newTask?.description ? `${newTask?.description?.substring(0, 40)}${newTask?.description?.length > 40 ? "..." : ""}` : '',
                maskDate(newTask.done_in, true) ?? "-",
            ]
        }
    }

    const getFilteredValues = () => {
        const values = tasks
        values.sort((a, b) => {
            if (a.scheduled_date > b.scheduled_date) {
                return 1
            } else if (a.scheduled_date < b.scheduled_date) {
                return - 1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map((call) => getDataNewTask(call))
        return filteredValues
    }

    const handleChangeFilters = (name, value) => {
        if (name === "period") {
            const dateStart = value.dateStart
            const dateEnd = value.dateEnd
            setFilters(previousValue => { return { ...previousValue, dateStart, dateEnd } })
        } else if (name === "periodFinish") {
            const dateStart = value.dateStart
            const dateEnd = value.dateEnd
            setFilters(previousValue => { return { ...previousValue, dateFinishStart: dateStart, dateFinishEnd: dateEnd } })
        } else {
            setFilters(previousValue => { return { ...previousValue, [name]: value } })
        }
    }

    const handleOpenModalLead = (taskId) => {
        const [task] = tasks?.filter(task => task._id === taskId)
        if (task) {
            const leadId = task.lead_id
            openModalLead(leadId)
        }
    }

    const handleScrollTable = useCallback(() => {
        if (!loadMore && maxTasks > tasks.length) {
            setLoadMore(true)
        }
    }, [maxTasks, tasks, loadMore])

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <Select multiple={true} name="Equipe" placeholder="Buscar..." options={teams} onChange={(value) => handleChangeFilters("teams", value)} selected={filters.teams ?? []} required={true} />
                <Select multiple={true} name="Usuário" placeholder="Buscar..." options={users} onChange={(value) => handleChangeFilters("users", value)} selected={filters.users ?? []} required={true} />
                <Select multiple={true} name="Status" placeholder="Buscar..." options={listStatus} onChange={(value) => handleChangeFilters("status", value)} selected={filters.status ?? []} required={true} />
                <Select multiple={true} name="Tipo" placeholder="Buscar..." options={typesTask} onChange={(value) => handleChangeFilters("typesTask", value)} selected={filters.typesTask ?? []} required={true} />
                <DatePicker multiple={true} name="Agendada Para" dateStart={filters?.dateStart ?? ""} dateEnd={filters?.dateEnd ?? ""} onChange={(dateStart, dateEnd) => handleChangeFilters("period", { dateStart, dateEnd })} />
                <DatePicker multiple={true} name="Data de Conclusão" dateStart={filters?.dateFinishStart ?? ""} dateEnd={filters?.dateFinishEnd ?? ""} onChange={(dateStart, dateEnd) => handleChangeFilters("periodFinish", { dateStart, dateEnd })} />
                <Button className="bg-pink" onClick={getAllTasksByFilters} disabled={isLoading}>Filtrar</Button>
            </div>
            {!backgroundFilter &&
                <Table
                    headers={headers}
                    values={getFilteredValues()}
                    onClick={(id) => handleOpenModalLead(id)}
                    onScroll={handleScrollTable}
                    loadMore={loadMore}
                    messageNotRegisters="Nenhum registro encontrado"
                    showIndex={true}
                    showCount={true}
                    count={maxTasks}
                />
            }
            {backgroundFilter &&
                <BackgroundSelectFilter
                    text="Selecione os filtros desejados e aplique para visualizar os dados!"
                    image={imageFilterTask}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </div>
    )
}

export default Tasks