import React from "react"
import styles from "./notFunnel.module.css"
import image from "../../../images/setup/funnel.png"

const NotFunnel = () => {
    return (
        <div className={styles.progressSetup}>
            <div className={styles.text}>
                <h1 className={styles.title}>Nenhum fúnil configurado para as suas equipes!</h1>
                <p className={styles.description}>Entre em contato com o administrador da sua conta, e solicite a configuração!</p>
            </div>
            <div className={styles.image}>
                <img src={image} alt="Funil" />
            </div>
        </div>
    )
}

export default NotFunnel