import React, { useEffect, useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import { FaPencilAlt } from "react-icons/fa"
import { HiPlus } from 'react-icons/hi'
import Select from '../../Form/Select'
import BreakForm from '../../Form/BreakForm/index'
import { MdOutlineAccountCircle, MdOutlineDelete } from "react-icons/md"
import styles from "./modalAccount.module.css"
import { formatCurrency, formatDataSize, maskCurrency, maskDocument, maskZipCode } from "../../../utils/formatter"
import { getAddressByZipCode, getOptionByOptionList } from "../../../utils/utils"
import { useAuth } from "../../../contexts/authContext"
import api from "../../../services/api"

const ModalAccount = ({ isNew = false, value = {}, modules = [], plans = [], partnersAccounts = [], editable = false, setEdit, onSubmit, closeModal }) => {
    const [account, setAccount] = useState(value)
    const [contacts, setContacts] = useState(value?.contacts || [{}])
    const [paymentByUser, setPaymentByUser] = useState(value?.payment_by_user || [{}])
    const [optionsPlans] = useState(plans?.map(plan => { return { id: plan._id, value: plan.name } }))

    const { permissions } = useAuth()

    useEffect(() => {
        handleChangeValue("contacts", contacts)
    }, [contacts])

    useEffect(() => {
        handleChangeValue("payment_by_user", paymentByUser)
    }, [paymentByUser])

    const optionsDepartment = [
        { id: "Administrativo", value: "Administrativo" },
        { id: "Financeiro", value: "Financeiro" },
        { id: "Marketing", value: "Marketing" },
        { id: "Tecnologia da Informação", value: "Tecnologia da Informação" },
        { id: "Outro", value: "Outro" },
    ]

    const optionsPermissionsPartner = [
        { id: "NOT_ACCESS", value: "Sem Acesso" },
        { id: "READ_ONLY", value: "Somente leitura" },
        { id: "ONLY_ESSENTIAL", value: "Somente alterações essenciais" },
        { id: "FULL_ACCESS", value: "Acesso total" },
    ]

    const optionsBoolean = [
        { id: 1, value: "Sim" },
        { id: 0, value: "Não" },
    ]

    const optionsPaymentFor = [
        { id: 'ACCOUNT', value: "Conta Ativa" },
        { id: 'USER', value: "Usuário Ativo" },
    ]

    const optionsMethod = [
        { id: "BANK_SLIP", value: "Boleto Bancário" },
        // { id: "CREDIT_CARD", value: "Cartão de Crédito" },
        // { id: "DEBIT_CARD", value: "Cartão de Débito" },
        // { id: "PIX", value: "PIX" },
    ]

    const getDataByCNPJ = async (cnpj) => {
        try {
            const response = await api.get(`/accounts/cnpj/${cnpj}`)
            const data = response?.data?.data
            const business_name = data?.business_name
            const fantasy_name = data?.fantasy_name
            const cityCode = data?.cityCode
            if (business_name) {
                handleChangeCompany("cityCode", business_name)
            }
            if (fantasy_name) {
                handleChangeCompany("cityCode", fantasy_name)
            }
            if (cityCode) {
                handleChangeCompany("cityCode", cityCode)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangeValue = (name, value) => {
        setAccount(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleChangeCompany = (name, value) => {
        if (name === "document" && value.length >= 14) {
            getDataByCNPJ(value)
        }
        setAccount(previousValue => {
            const updatedAccount = { ...previousValue }
            updatedAccount.company = { ...updatedAccount.company }
            updatedAccount.company[name] = value
            return updatedAccount
        })
    }

    const handleChangeAddress = (name, value) => {
        setAccount(previousValue => {
            const updatedAccount = { ...previousValue }
            updatedAccount.address = { ...updatedAccount.address }
            updatedAccount.address[name] = value
            return updatedAccount
        })
        if (name === "zip_code") {
            getAddressByZipCode(value).then(newAddress => {
                Object.keys(newAddress)?.forEach(key => {
                    handleChangeAddress(key, newAddress[key])
                })
            })
        }
    }

    const handleChangeContacts = (name, value, index) => {
        setContacts(previousValue => previousValue?.map((contact, i) => {
            if (i === index) {
                return { ...contact, [name]: value }
            }
            return contact
        }))
    }

    const handleChangePaymentUser = (name, value, index) => {
        if (name === "end_amount") {
            const newName = "start_amount"
            const newValue = parseInt(value) + 1
            const newIndex = index + 1
            handleChangePaymentUser(newName, newValue, newIndex)
        }

        setPaymentByUser(previousValue => previousValue?.map((paymentUser, i) => {
            if (i === index) {
                return { ...paymentUser, [name]: value }
            }
            return paymentUser
        }))
    }

    const handleChangePlan = (name, value) => {
        setAccount(previousValue => {
            const updatedAccount = { ...previousValue }
            updatedAccount.plan_details = { ...updatedAccount.plan_details, [name]: value }
            return updatedAccount
        })
    }

    const handleChangePlanDetailsAdditional = (name, value) => {
        setAccount(previousValue => {
            const updatedAccount = { ...previousValue }
            updatedAccount.plan_details = { ...updatedAccount.plan_details }
            updatedAccount.plan_details.additional = { ...updatedAccount?.plan_details?.additional, [name]: value }
            return updatedAccount
        })
    }

    const handleChangePlanAdditional = (name, value) => {
        if (name === "users") {
            const [users] = getLimitUsersByAccount(true)
            const newAdditionalUsers = value < users ? 0 : value - users
            handleChangePlanDetailsAdditional("users", newAdditionalUsers)
        } else if (name === "leads") {
            const [leads] = getLimitLeadsByAccount(true)
            const newAdditionalLeads = value < leads ? 0 : value - leads
            handleChangePlanDetailsAdditional("leads", newAdditionalLeads)
        } else if (name === "teams") {
            const [teams] = getLimitTeamsByAccount(true)
            const newAdditionalLeads = value < teams ? 0 : value - teams
            handleChangePlanDetailsAdditional("teams", newAdditionalLeads)
        }
    }

    const handleChangeVoip = (name, value) => {
        setAccount(previousValue => {
            const updatedAccount = { ...previousValue }
            updatedAccount.voip = { ...updatedAccount.voip }
            updatedAccount.voip[name] = value
            return updatedAccount
        })
    }

    const handleAddNewContact = (e) => {
        e.preventDefault()
        setContacts(previousValue => { return [...previousValue, {}] })
    }

    const handleRemoveContact = (e, index) => {
        e.preventDefault()
        setContacts(previousValue => previousValue?.filter((_, i) => i !== index))
    }

    const handleAddNewPaymentUser = (e) => {
        e.preventDefault()
        setPaymentByUser(previousValue => { return [...previousValue, {}] })
    }

    const handleRemovePaymentUser = (e, index) => {
        e.preventDefault()
        setPaymentByUser(previousValue => previousValue?.filter((_, i) => i !== index))
    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setAccount(value)
            setContacts(value?.contacts || [{}])
        }
    }

    const getValueBySelectedPlan = () => {
        const plan = getPlanByPlanId(account?.plan_details?.plan_id)
        if (!plan) {
            return "Nenhum plano selecionado!"
        }
        if (plan?.payment_by_partner) {
            return "Preço para parceiros!"
        }

        const value = parseFloat(plan?.value) || 0
        const discount = parseFloat(account?.plan_details?.discount) || 0
        const additionalDiscount = parseFloat(account?.plan_details?.additional_discount) || 0
        const additionalUsers = parseFloat(account?.plan_details?.additional?.users) || 0
        const additionalLeads = parseFloat(account?.plan_details?.additional?.leads) || 0
        const additionalUsersCost = parseFloat(plan?.additional_cost?.users) || 0
        const additionalLeadsCost = parseFloat(plan?.additional_cost?.leads) || 0
        const usersCost = additionalUsers * additionalUsersCost
        const leadsCost = additionalLeads * additionalLeadsCost
        const fullValue = (value + usersCost + leadsCost) - (discount + additionalDiscount)
        return formatCurrency(fullValue > 0 ? fullValue : 0)
    }

    const getLimitUsersByAccount = (array = false) => {
        const plan = getPlanByPlanId(account?.plan_details?.plan_id)
        const users = plan?.limits?.users || 0
        const additionalUsers = account?.plan_details?.additional?.users || 0
        if (array) {
            return [users, additionalUsers]
        }
        return users + additionalUsers
    }

    const getLimitLeadsByAccount = (array = false) => {
        const plan = getPlanByPlanId(account?.plan_details?.plan_id)
        const leads = plan?.limits?.leads || 0
        const additionalLeads = account?.plan_details?.additional?.leads || 0
        if (array) {
            return [leads, additionalLeads]
        }
        return leads + additionalLeads
    }

    const getLimitTeamsByAccount = (array = false) => {
        const plan = getPlanByPlanId(account?.plan_details?.plan_id)
        const teams = plan?.limits?.teams || 0
        const additionalTeams = account?.plan_details?.additional?.teams || 0
        if (array) {
            return [teams, additionalTeams]
        }
        return teams + additionalTeams
    }

    const getLimitStorageByAccount = () => {
        const plan = getPlanByPlanId(account?.plan_details?.plan_id)
        const users = getLimitUsersByAccount()
        const storagePerUser = plan?.limits?.storage_per_user || 0
        const storage = users * storagePerUser
        return formatDataSize(storage)
    }

    const getPlanByPlanId = (plan_id) => {
        if (!plan_id) {
            return
        }
        const plan = plans.find(plan => plan._id === plan_id)
        return plan
    }

    return (
        <Modal
            icon={<MdOutlineAccountCircle />}
            title={isNew ? "Cadastro de nova conta" : (editable ? `Editando a conta: ${account.name}` : `Visualizando: ${account.name}`)}
            description={isNew ? "Adicione uma nova conta" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
            fullWidith={true}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, account)}>
                <FormGroupInline>
                    <FormGroup labelText="Nome da conta">
                        {editable ?
                            <Input type="text" placeholder="Nome da conta" value={account.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                            :
                            <span>{account.name}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="E-mail do responsável">
                        {editable && isNew ?
                            <Input type="email" placeholder="E-mail do usuário" value={account.email_owner ?? ""} onChange={(e) => handleChangeValue("email_owner", e.target.value)} />
                            :
                            <span>{account.email_owner}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className={`${styles.editButton} action bg-purple`} onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <BreakForm title="Empresa" showAction={false} />
                <FormGroupInline>
                    <FormGroup labelText="CNPJ/CPF">
                        {editable ?
                            <Input type="document" placeholder="CNPJ/CPF" value={account?.company?.document ?? ""} onChange={(e) => handleChangeCompany("document", e.target.value)} />
                            :
                            <span>{maskDocument(account?.company?.document)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Código do Município (Código TOM)">
                        {editable ?
                            <Input type="text" placeholder="Código do Município" value={account?.company?.cityCode ?? ""} onChange={(e) => handleChangeCompany("cityCode", e.target.value)} />
                            :
                            <span>{account?.company?.cityCode}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Razão social">
                        {editable ?
                            <Input type="text" placeholder="Razão social" value={account?.company?.business_name ?? ""} onChange={(e) => handleChangeCompany("business_name", e.target.value)} />
                            :
                            <span>{account?.company?.business_name}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Nome fantasia">
                        {editable ?
                            <Input type="text" placeholder="Nome fantasia" value={account?.company?.fantasy_name ?? ""} onChange={(e) => handleChangeCompany("fantasy_name", e.target.value)} />
                            :
                            <span>{account?.company?.fantasy_name}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <BreakForm title="Contatos" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={handleAddNewContact} showAction={editable} />
                {contacts && contacts.map((contact, index) => (
                    <div className={styles.contact} key={index}>
                        {index > 0 && editable &&
                            <FormGroupInline style={{ justifyContent: "end", marginBottom: "-2em" }}>
                                <Button className='bg-purple action' onClick={(e) => handleRemoveContact(e, index)}><MdOutlineDelete /></Button>
                            </FormGroupInline>
                        }
                        <FormGroupInline>
                            <FormGroup labelText="Nome">
                                {editable ?
                                    <Input type="text" placeholder="Nome do contato" value={contact?.name ?? ""} onChange={(e) => handleChangeContacts("name", e.target.value, index)} />
                                    :
                                    <span>{contact?.name}</span>
                                }
                            </FormGroup>
                            <FormGroup labelText="Celular">
                                {editable ?
                                    <Input type="phone" placeholder="Celular do contato" value={contact?.phone ?? ""} onChange={(e) => handleChangeContacts("phone", e.target.value, index)} />
                                    :
                                    <span>{contact?.phone}</span>
                                }
                            </FormGroup>
                        </FormGroupInline>
                        <FormGroupInline>
                            <FormGroup labelText="E-mail">
                                {editable ?
                                    <Input type="email" placeholder="E-mail do contato" value={contact?.email ?? ""} onChange={(e) => handleChangeContacts("email", e.target.value, index)} />
                                    :
                                    <span>{contact?.email}</span>
                                }
                            </FormGroup>
                            <FormGroup labelText="Departamento">
                                {editable ?
                                    <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsDepartment} onChange={(value) => handleChangeContacts("department", value, index)} selected={contact?.department ?? []} />
                                    :
                                    <span>{getOptionByOptionList(contact?.department, optionsDepartment)}</span>
                                }
                            </FormGroup>
                        </FormGroupInline>
                    </div>
                ))}
                <BreakForm title="Endereço" showAction={false} />
                <FormGroupInline>
                    <FormGroup labelText="CEP">
                        {editable ?
                            <Input type="zipCode" placeholder="CEP" value={account?.address?.zip_code ?? ""} onChange={(e) => handleChangeAddress("zip_code", e.target.value)} />
                            :
                            <span>{maskZipCode(account?.address?.zip_code)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="País">
                        {editable ?
                            <Input type="text" placeholder="País" value={account?.address?.country ?? ""} onChange={(e) => handleChangeAddress("country", e.target.value)} />
                            :
                            <span>{account?.address?.country}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Estado">
                        {editable ?
                            <Input type="text" placeholder="Estado" value={account?.address?.uf ?? ""} onChange={(e) => handleChangeAddress("uf", e.target.value)} />
                            :
                            <span>{account?.address?.uf}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Cidade">
                        {editable ?
                            <Input type="text" placeholder="Cidade" value={account?.address?.city ?? ""} onChange={(e) => handleChangeAddress("city", e.target.value)} />
                            :
                            <span>{account?.address?.city}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Bairro">
                        {editable ?
                            <Input type="text" placeholder="Bairro" value={account?.address?.district ?? ""} onChange={(e) => handleChangeAddress("district", e.target.value)} />
                            :
                            <span>{account?.address?.district}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Logradouro">
                        {editable ?
                            <Input type="text" placeholder="Logradouro" value={account?.address?.road ?? ""} onChange={(e) => handleChangeAddress("road", e.target.value)} />
                            :
                            <span>{account?.address?.road}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Número">
                        {editable ?
                            <Input type="text" placeholder="Número" value={account?.address?.number ?? ""} onChange={(e) => handleChangeAddress("number", e.target.value)} />
                            :
                            <span>{account?.address?.number}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Complemento">
                        {editable ?
                            <Input type="text" placeholder="Complemento" value={account?.address?.complement ?? ""} onChange={(e) => handleChangeAddress("complement", e.target.value)} />
                            :
                            <span>{account?.address?.complement}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <BreakForm title="Informações do Plano" showAction={false} />
                <FormGroupInline>
                    <FormGroup labelText="Plano">
                        {editable ?
                            <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsPlans || []} onChange={(value) => handleChangePlan("plan_id", value)} selected={account?.plan_details?.plan_id || ''} />
                            :
                            <span>{getOptionByOptionList(account?.plan_details?.plan_id || '', optionsPlans) || '-'}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Valor">
                        <span>{getValueBySelectedPlan()}</span>
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Limite de Usuários">
                        {editable && permissions?.is_admin > 0 ?
                            <Input type="number" placeholder="Limite de Usuários" value={getLimitUsersByAccount()} onChange={(e) => handleChangePlanAdditional("users", e.target.value)} />
                            :
                            <span>{getLimitUsersByAccount()}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Limite de Leads">
                        {editable && permissions?.is_admin > 0 ?
                            <Input type="number" placeholder="Limite de Leads" value={getLimitLeadsByAccount()} onChange={(e) => handleChangePlanAdditional("leads", e.target.value)} />
                            :
                            <span>{getLimitLeadsByAccount()}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Limite de Equipes">
                        {editable && permissions?.is_admin > 0 ?
                            <Input type="number" placeholder="Limite de Equipes" value={getLimitTeamsByAccount()} onChange={(e) => handleChangePlanAdditional("teams", e.target.value)} />
                            :
                            <span>{getLimitTeamsByAccount()}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Limite de Armazenamento">
                        <span>{getLimitStorageByAccount()}</span>
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Desconto">
                        {editable && permissions?.is_admin > 0 ?
                            <Input type="currency" placeholder="Desconto" value={account?.plan_details?.discount} onChange={(e) => handleChangePlan("discount", e.target.value)} />
                            :
                            <span>{formatCurrency(account?.plan_details?.discount || 0)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Método de Pagamento Padrão">
                        {editable ?
                            <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsMethod || []} onChange={(value) => handleChangePlan("default_method", value)} selected={account?.plan_details?.default_method || ''} />
                            :
                            <span>{getOptionByOptionList(account?.plan_details?.default_method || '', optionsMethod) || '-'}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                {permissions?.is_admin > 0 &&
                    <>
                        <BreakForm title="Conta Parceira" showAction={false} />
                        <FormGroupInline>
                            <FormGroup labelText="É uma conta Parceira?">
                                {editable ?
                                    <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsBoolean} onChange={(value) => handleChangeValue("is_partner", value)} selected={+account?.is_partner ?? '0'} />
                                    :
                                    <span>{getOptionByOptionList(+account?.is_partner || '0', optionsBoolean)}</span>
                                }
                            </FormGroup>
                        </FormGroupInline>
                        {Boolean(+account?.is_partner) &&
                            <>
                                <FormGroupInline>
                                    <FormGroup labelText="Pagamento Por">
                                        {editable ?
                                            <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsPaymentFor} onChange={(value) => handleChangeValue("payment_for", value)} selected={account?.payment_for} />
                                            :
                                            <span>{getOptionByOptionList(account?.payment_for, optionsPaymentFor)}</span>
                                        }
                                    </FormGroup>
                                    <FormGroup labelText="Permissões do Parceiro">
                                        {editable ?
                                            <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsPermissionsPartner} onChange={(value) => handleChangeValue("partner_permission", value)} selected={account?.partner_permission} />
                                            :
                                            <span>{getOptionByOptionList(account?.partner_permission, optionsPermissionsPartner)}</span>
                                        }
                                    </FormGroup>
                                </FormGroupInline>
                                {account?.payment_for === "USER" &&
                                    <>
                                        <BreakForm title="Custo por usuário" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={handleAddNewPaymentUser} showAction={editable} />
                                        {paymentByUser && paymentByUser.map((paymentUser, index) => (
                                            <div key={index}>
                                                {index > 0 && editable &&
                                                    <FormGroupInline style={{ justifyContent: "end", marginBottom: "-2em" }}>
                                                        <Button className='bg-purple action' onClick={(e) => handleRemovePaymentUser(e, index)}><MdOutlineDelete /></Button>
                                                    </FormGroupInline>
                                                }
                                                <FormGroupInline>
                                                    <FormGroup labelText="De">
                                                        {editable ?
                                                            <Input type="number" placeholder="Número de usuários" value={index === 0 ? 0 : (paymentByUser[index - 1]?.end_amount ? (parseInt(paymentByUser[index - 1]?.end_amount || 0) + 1) : "")} onChange={(e) => handleChangePaymentUser("start_amount", e.target.value, index)} disabled={true} />
                                                            :
                                                            <span>{index === 0 ? 0 : paymentUser?.start_amount || "-"}</span>
                                                        }
                                                    </FormGroup>
                                                    <FormGroup labelText="Até">
                                                        {editable ?
                                                            <Input type="number" placeholder="Número de usuários" value={paymentUser?.end_amount ?? ""} onChange={(e) => handleChangePaymentUser("end_amount", e.target.value, index)} />
                                                            :
                                                            <span>{paymentUser?.end_amount || "-"}</span>
                                                        }
                                                    </FormGroup>
                                                    <FormGroup labelText="Custo por usuário">
                                                        {editable ?
                                                            <Input type="currency" placeholder="Custo por usuário" value={paymentUser?.value ?? ""} onChange={(e) => handleChangePaymentUser("value", e.target.value, index)} />
                                                            :
                                                            <span>{paymentUser?.value}</span>
                                                        }
                                                    </FormGroup>
                                                </FormGroupInline>
                                            </div>
                                        ))}
                                    </>
                                }
                            </>
                        }
                        <BreakForm title="Conta Responsável" showAction={false} />
                        <FormGroupInline>
                            <FormGroup labelText="Responsável">
                                {editable ?
                                    <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={partnersAccounts} onChange={(value) => handleChangeValue("partner_of", value)} selected={account?.partner_of} />
                                    :
                                    <span>{getOptionByOptionList(account?.partner_of, partnersAccounts) || '-'}</span>
                                }
                            </FormGroup>
                        </FormGroupInline>
                    </>
                }
                <BreakForm title="Módulos Ativos" showAction={false} />
                <FormGroupInline>
                    <FormGroup>
                        {editable ?
                            <Select multiple={true} name="Módulos" placeholder="Buscar..." options={modules} onChange={(value) => handleChangeValue("modules", value)} selected={account.modules ?? []} />
                            :
                            <span>{getOptionByOptionList(account.modules, modules)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                {permissions?.is_admin > 0 &&
                    getOptionByOptionList(account.modules, modules)?.toLowerCase()?.indexOf("voip") !== -1 &&
                    <>
                        <BreakForm title="Configurações VOIP" showAction={false} />
                        <FormGroupInline>
                            <FormGroup labelText="Custo Progressivo?">
                                {editable ?
                                    <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsBoolean} onChange={(value) => handleChangeVoip("is_progressive_cost", value)} selected={+account?.voip?.is_progressive_cost ?? '0'} />
                                    :
                                    <span>{getOptionByOptionList(+account?.voip?.is_progressive_cost || '0', optionsBoolean)}</span>
                                }
                            </FormGroup>
                        </FormGroupInline>
                        {!Boolean(+account?.voip?.is_progressive_cost) &&
                            <FormGroupInline>
                                <FormGroup labelText="Custo por minuto (Fixo)">
                                    {editable ?
                                        <Input type="currency" placeholder="Custo por minuto" value={account?.voip?.cost_per_minute ?? "0"} onChange={(e) => handleChangeVoip("cost_per_minute", e.target.value)} />
                                        :
                                        <span>{maskCurrency(parseFloat(account?.voip?.cost_per_minute).toFixed(2))}</span>
                                    }
                                </FormGroup>
                            </FormGroupInline>
                        }
                        <FormGroupInline>
                            <FormGroup labelText="Transcrição da Chamada?">
                                {editable ?
                                    <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsBoolean} onChange={(value) => handleChangeVoip("has_transcription", value)} selected={+account?.voip?.has_transcription ?? '0'} />
                                    :
                                    <span>{getOptionByOptionList(+account?.voip?.has_transcription || '0', optionsBoolean)}</span>
                                }
                            </FormGroup>
                            <FormGroup labelText="Palavras-chave">
                                {editable ?
                                    <Input type="text" placeholder="Palavras-chave" value={account?.voip?.keywords} onChange={(e) => handleChangeVoip("keywords", e.target.value)} />
                                    :
                                    <span>{account?.voip?.keywords || "-"}</span>
                                }
                            </FormGroup>
                        </FormGroupInline>
                    </>
                }
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalAccount