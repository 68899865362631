import api from "../services/api"
import { toast } from "react-toastify"


const getStages = async (funnel_id, filters = '') => {
    try {
        if (funnel_id) {
            const queryString = new URLSearchParams(filters)?.toString()
            const response = await api.get(`/funnels/${funnel_id}/stages?funnel_id=${funnel_id}&${queryString}`)
            const stages = response.data?.data
            return stages
        } else {
            return []
        }
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getStages }