import React from 'react'
import styles from "./document.module.css"
import Button from '../../../Form/Button'
import { openLinkExternal } from '../../../../utils/utils'

const Document = ({ name, url }) => {
    return (
        <div className={styles.container}>
            <img src="https://oktto-models.s3.sa-east-1.amazonaws.com/files/document-chat.jpg" alt="Documento" />
            <div className={styles.details}>
                <span className={styles.name}>{name || "-"}</span>
            </div>
            <Button onClick={() => openLinkExternal(url, true)}>Visualizar</Button>
        </div>
    )
}

export default Document
