import React, { useEffect, useRef, useState } from "react";
import FormGroupExpand from "../../Form/FormGroupExpand";
import FormGroup from "../../Form/FormGroup";
import Input from "../../Form/Input";
import FormGroupInline from "../../Form/FormGroupInline";
import { BiMove } from "react-icons/bi";
import Button from "../../Form/Button";
import styles from '../modal.module.css'
import { TbSettings, TbSettingsFilled } from 'react-icons/tb';
import { MdOutlineDelete } from "react-icons/md";

const StageModalFunnel = ({ stage, index, handleChangeVisibleStage, handleRemoveStage, handleChangeStage, handleTouchStart, handleTouchMove, handleMoveEnd, handleMouseStart, handleMouseMove, stylesMove }) => {
    const [diffInitial, setDiffInitial] = useState()
    const [initialY, setInitialY] = useState()
    const [currentY, setCurrentY] = useState()
    const refStage = useRef()

    useEffect(() => {
        const onMouseMove = (e) => {
            const boundingBox = refStage.current.getBoundingClientRect()
            const positionY = e?.pageY
            const diffInitialMouse = positionY - initialY

            const transformStyle = window.getComputedStyle(refStage.current).getPropertyValue('transform')
            const transformMatrix = new DOMMatrix(transformStyle)
            const originalTop = boundingBox.y - transformMatrix.m42

            const positionMove = ((initialY - diffInitial) - originalTop) + diffInitialMouse
            setCurrentY(positionY)
            handleMouseMove(stage._id, index, positionY, positionMove)
        }

        const onMouseEnd = () => {
            handleMoveEnd(stage._id)
        }

        if (!stage.ref && refStage.current) {
            handleChangeStage("ref", refStage.current, stage._id)
        }

        if (stage?.moving) {
            document.addEventListener("mousemove", onMouseMove)
            document.addEventListener("mouseup", onMouseEnd)
        } else {
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mouseup", onMouseEnd)
        }

        return () => {
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mouseup", onMouseEnd)
        }
    }, [stage, initialY])

    useEffect(() => {
        handleChangeStage("ref", refStage.current, stage._id)
    }, [refStage])

    useEffect(() => {
        setInitialY(currentY)
    }, [index])

    const onMouseStart = (e) => {
        const boundingBox = refStage.current.getBoundingClientRect()
        setInitialY(e?.pageY)
        setDiffInitial(e?.pageY - boundingBox?.y)
        handleMouseStart(e, stage._id)
    }

    return (
        <div className={`${styles.stage} ${stage?.moving ? styles.moving : ''}`} style={stage?.moving ? stylesMove : {}} ref={refStage}>
            <FormGroupInline btnAction={true} uniqueInput={true}>
                <div className={styles.move} onTouchStart={(e) => handleTouchStart(e, stage._id)} onTouchMove={(e) => handleTouchMove(e, stage._id, index)} onTouchCancel={() => handleMoveEnd(stage._id)} onTouchEnd={() => handleMoveEnd(stage._id)} onMouseDown={onMouseStart}>
                    <BiMove />
                </div>
                <FormGroup>
                    <Input type="text" placeholder="Nome da etapa" value={stage.name || ''} onChange={(e) => handleChangeStage("name", e.target.value, stage._id)} />
                </FormGroup>
                <Button className='bg-orange gray-dark action md' onClick={(e) => handleChangeVisibleStage(e, stage._id)}>{stage.show ? <TbSettingsFilled /> : <TbSettings />}</Button>
                <Button className='bg-purple action md' onClick={(e) => handleRemoveStage(e, stage._id)}><MdOutlineDelete /></Button>
            </FormGroupInline>
            {stage.show &&
                <FormGroup labelText="Dias para esfriar">
                    <Input type="number" className="sm" placeholder="Dias para o lead esfriar" value={stage.time_to_cool || ''} onChange={(e) => handleChangeStage("time_to_cool", e.target.value, stage._id)} />
                </FormGroup>
            }
        </div>
    )
}

export default StageModalFunnel