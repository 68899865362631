import React from "react"
import { useCrm } from "../../../contexts/crmContext"
import ProgressSetup from "./ProgressSetup";
import image from "../../../images/setup/funnel.png"

const initialData = {
    funnel: {
        name: "Comercial",
    },
    stages: [
        { name: "Novo Lead", time_to_cool: 1 },
        { name: "Dia 02", time_to_cool: 1 },
        { name: "Dia 03", time_to_cool: 1 },
        { name: "Dia 04", time_to_cool: 1 },
        { name: "Dia 05", time_to_cool: 1 },
        { name: "Em negociação", time_to_cool: 3 },
        { name: "Proposta", time_to_cool: 2 },
        { name: "Remarketing", time_to_cool: 10 },
    ]
}

const AddFirstFunnel = ({ step = 0, funnel_id, nextStep = () => { } }) => {
    const { openModalFunnel } = useCrm()

    const title = "Configurando seu Funil de Vendas"
    const description = "Personalize seu funil de vendas para refletir os estágios específicos do seu processo de vendas. Isso nos ajudará a manter o controle e otimizar seus negócios."
    const btnText = "Configurar"

    return (
        <ProgressSetup
            step={step}
            title={title}
            description={description}
            srcImage={image}
            altImage="Imagem de boas vindas!"
            btnText={btnText}
            onClick={() => openModalFunnel(!funnel_id, funnel_id, initialData, nextStep)}
        />
    )
}

export default AddFirstFunnel