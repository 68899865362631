import moment from "moment"

const maskCurrency = (value) => {
    value = value ? value?.toString().replace(/[\D]+/g, '') : ''
    if (value.length > 2) {
        value = value.replace(/([0-9]{2})$/g, ",$1")
    }

    if (value.length > 6) {
        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.").replace(/,(\d{2}$)/g, ",$1")
    }

    return value ? `R$ ${value}` : ''
}

const maskPhone = (value) => {
    if (typeof value !== 'string') return ''

    let phone = formatPhoneNumber(value)
    if (phone === 'NaN') return ''

    if (phone.startsWith('+55')) {
        return formatPhoneByBR(phone)
    } else if (phone.startsWith('+1')) {
        return formatPhoneByUS(phone)
    } else if (phone.startsWith('+44')) {
        return formatPhoneByUK(phone)
    } else if (phone.startsWith('+351')) {
        return formatPhoneByPT(phone)
    } else if (phone.startsWith('+244')) {
        return formatPhoneByAO(phone)
    }

    return phone
}

const formatPhoneByBR = (phone) => {
    if (phone.length > 13) {
        const p1 = phone?.substring(0, 3)
        const p2 = phone?.substring(3, 5)
        const p3 = phone?.substring(5, 6)
        const p4 = phone?.substring(6, 10)
        const p5 = phone?.substring(10)
        return `${p1} (${p2}) ${p3} ${p4}-${p5}`
    } else if (phone.length > 9) {
        const p1 = phone?.substring(0, 3)
        const p2 = phone?.substring(3, 5)
        const p3 = phone?.substring(5, 9)
        const p4 = phone?.substring(9)
        return `${p1} (${p2}) ${p3}-${p4}`
    } else if (phone.length > 5) {
        const p1 = phone?.substring(0, 3)
        const p2 = phone?.substring(3, 5)
        const p3 = phone?.substring(5)
        return `${p1} (${p2}) ${p3}`
    } else if (phone.length > 3) {
        const p1 = phone?.substring(0, 3)
        const p2 = phone?.substring(3)
        return `${p1} (${p2}`
    } else if (phone.length > 0) {
        const p1 = phone?.substring(0)
        return `${p1}`
    }
}

const formatPhoneByUS = (phone) => {
    if (phone.length > 8) {
        const p1 = phone.substring(0, 2)
        const p2 = phone.substring(2, 5)
        const p3 = phone.substring(5, 8)
        const p4 = phone.substring(8)
        return `${p1} (${p2}) ${p3}-${p4}`
    } else if (phone.length > 5) {
        const p1 = phone.substring(0, 2)
        const p2 = phone.substring(2, 5)
        const p3 = phone.substring(5)
        return `${p1} (${p2}) ${p3}`
    } else if (phone.length > 2) {
        const p1 = phone.substring(0, 2)
        const p2 = phone.substring(2)
        return `${p1} (${p2}`
    } else if (phone.length > 0) {
        const p1 = phone.substring(0)
        return `${p1}`
    }
}

const formatPhoneByUK = (phone) => {
    if (phone.length > 9) {
        const p1 = phone.substring(0, 3)
        const p2 = phone.substring(3, 5)
        const p3 = phone.substring(5, 9)
        const p4 = phone.substring(9)
        return `${p1} (${p2}) ${p3} ${p4}`
    } else if (phone.length > 5) {
        const p1 = phone.substring(0, 3)
        const p2 = phone.substring(3, 5)
        const p3 = phone.substring(5)
        return `${p1} (${p2}) ${p3}`
    } else if (phone.length > 3) {
        const p1 = phone.substring(0, 3)
        const p2 = phone.substring(3)
        return `${p1} (${p2}`
    } else if (phone.length > 0) {
        const p1 = phone.substring(0)
        return `${p1}`
    }
}

const formatPhoneByPT = (phone) => {
    if (phone.length > 9) {
        const p1 = phone.substring(0, 4)
        const p2 = phone.substring(4, 6)
        const p3 = phone.substring(6, 9)
        const p4 = phone.substring(9)
        return `${p1} (${p2}) ${p3}-${p4}`
    } else if (phone.length > 6) {
        const p1 = phone.substring(0, 4)
        const p2 = phone.substring(4, 6)
        const p3 = phone.substring(6)
        return `${p1} (${p2}) ${p3}`
    } else if (phone.length > 4) {
        const p1 = phone.substring(0, 4)
        const p2 = phone.substring(4)
        return `${p1} (${p2}`
    } else if (phone.length > 0) {
        const p1 = phone.substring(0)
        return `${p1}`
    }
}

const formatPhoneByAO = (phone) => {
    if (phone.length > 7) {
        const p1 = phone.substring(0, 4)
        const p2 = phone.substring(4, 7)
        const p3 = phone.substring(7)
        return `${p1} ${p2} ${p3}`
    } else if (phone.length > 4) {
        const p1 = phone.substring(0, 4)
        const p2 = phone.substring(4)
        return `${p1} ${p2}`
    } else if (phone.length > 0) {
        const p1 = phone.substring(0)
        return `${p1}`
    }
    return ''
}

const maskZipCode = (value) => {
    const zipCode = value ? value?.toString().replace(/[\D]+/g, '') : ''
    if (zipCode === 'NaN') return ''
    if (zipCode.length > 5) {
        const p1 = zipCode?.substring(0, 5)
        const p2 = zipCode?.substring(5, 8)
        return `${p1}-${p2}`
    } else if (zipCode.length > 0) {
        const p1 = zipCode?.substring(0)
        return `${p1}`
    }

    return ''
}

const maskDocument = (value) => {
    const document = value ? value?.toString().replace(/[\D]+/g, '') : ''
    if (document === 'NaN') return ''
    if (document.length > 12) {
        const p1 = document?.substring(0, 2)
        const p2 = document?.substring(2, 5)
        const p3 = document?.substring(5, 8)
        const p4 = document?.substring(8, 12)
        const p5 = document?.substring(12, 14)
        return `${p1}.${p2}.${p3}/${p4}-${p5}`
    } else if (document.length > 11) {
        const p1 = document?.substring(0, 2)
        const p2 = document?.substring(2, 5)
        const p3 = document?.substring(5, 8)
        const p4 = document?.substring(8, 12)
        return `${p1}.${p2}.${p3}/${p4}`
    } else if (document.length > 9) {
        const p1 = document?.substring(0, 3)
        const p2 = document?.substring(3, 6)
        const p3 = document?.substring(6, 9)
        const p4 = document?.substring(9)
        return `${p1}.${p2}.${p3}-${p4}`
    } else if (document.length > 6) {
        const p1 = document?.substring(0, 3)
        const p2 = document?.substring(3, 6)
        const p3 = document?.substring(6)
        return `${p1}.${p2}.${p3}`
    } else if (document.length > 3) {
        const p1 = document?.substring(0, 3)
        const p2 = document?.substring(3)
        return `${p1}.${p2}`
    } else if (document.length > 0) {
        const p1 = document?.substring(0)
        return `${p1}`
    }

    return ''
}

const maskDate = (value, time = false) => {
    const dateFormatted = moment(value).format(`DD/MM/YYYY ${time ? " HH:mm:ss" : ""}`)
    return dateFormatted
}

const currencyToFloat = (value) => {
    const newValue = maskCurrency(value).replace("R$", "").replace(" ", "").replace(".", "").replace(",", ".")
    return newValue
}

const getNumbersByString = (value) => {
    const newValue = value.replace(/[\D]+/g, '')?.toString()
    return newValue
}

const formatPhoneNumber = (phone) => {
    if (typeof phone !== 'string') return ''

    let initialPlus = phone.length > 0 ? '+' : ''

    let cleanedValue = phone.replace(/[^\d]+/g, '')
    const phoneFormatted = initialPlus + cleanedValue
    return phoneFormatted
}

const formatCurrency = (value) => {
    const numericValue = value >= 0 ? parseFloat(value?.toString().replace(/[^\d.-]/g, '')) : ''

    if (isNaN(numericValue)) {
        return '';
    }

    const formattedValue = numericValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    return formattedValue;
}

const formatDataSize = (value) => {
    const numericValue = Number(value)

    if (isNaN(numericValue)) {
        return ''
    }

    let formattedValue;
    if (numericValue >= 1024 * 1024) {
        const tbValue = numericValue / (1024 * 1024);
        formattedValue = tbValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' TB';
    } else if (numericValue >= 1024) {
        const gbValue = numericValue / 1024;
        formattedValue = gbValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' GB';
    } else {
        formattedValue = numericValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' MB';
    }

    return formattedValue
}

const formatDataSizeInKb = (value) => {
    const numericValue = Number(value)

    if (isNaN(numericValue)) {
        return ''
    }

    let formattedValue
    const kbValue = numericValue / 1024
    if (kbValue >= 1024 * 1024) {
        const gbValue = kbValue / 1024;
        formattedValue = gbValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' GB'
    } else if (kbValue >= 1024) {
        const mbValue = kbValue / 1024
        formattedValue = mbValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' MB'
    } else {
        formattedValue = kbValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' KB'
    }

    return formattedValue
}

const formatMsToTime = (durationMs) => {
    const durationSeconds = Math.ceil(durationMs / 1000)
    return formatSecondsToTime(durationSeconds)
}

const formatSecondsToTime = (durationSeconds, showHours = true) => {
    if (Number.isNaN(parseInt(durationSeconds))) {
        return showHours ? "00:00:00" : "0:00"
    }
    const hours = parseInt(durationSeconds / 3600)
    const minutes = parseInt((durationSeconds - (hours * 3600)) / 60)
    const seconds = parseInt(durationSeconds - (hours * 3600) - (minutes * 60))
    return showHours ? `${fixedTwoDigits(hours)}:${fixedTwoDigits(minutes)}:${fixedTwoDigits(seconds)}` : `${minutes > 0 ? fixedTwoDigits(minutes) : 0}:${fixedTwoDigits(seconds)}`
}

const formatFloatToPercent = (value = 0) => {
    return `${value * 100}%`
}

const fixedTwoDigits = (value) => {
    return value > 9 ? value : `0${value}`
}

const formatDate = (date) => {
    return date ? moment(date).format("DD/MM/YYYY HH:mm:ss") : ""
}

const formatTextByElipsis = (text, length) => {
    return text ? (text?.length > length ? `${text?.substring(0, length)}...` : text?.substring(0, length)) : ''
}

export { maskCurrency, maskPhone, maskZipCode, maskDocument, maskDate, currencyToFloat, getNumbersByString, formatCurrency, formatMsToTime, formatSecondsToTime, formatPhoneNumber, formatDataSize, formatDataSizeInKb, formatFloatToPercent, formatDate, formatTextByElipsis }