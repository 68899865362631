import React, { useEffect, useState } from "react"
import styles from "./dashboard.module.css"
import Card from "./Card"
import { AiFillDashboard } from 'react-icons/ai'
import BreakForm from './../../components/Form/BreakForm/index'
import Select from "../../components/Form/Select"
import { BiFilter } from "react-icons/bi"
import { areObjectsEqual } from "../../utils/utils"
import { useAuth } from "../../contexts/authContext"
import api from "../../services/api"
import { toast } from "react-toastify"
import SideFilter from './SideFilter/index'
import FormGroupInline from "../../components/Form/FormGroupInline"
import Button from "../../components/Form/Button"
import DatePicker from "../../components/Form/DatePicker"
import Loading from './../layouts/Loading/index'
import ChartPie from "./Charts/Pie"
import ChartBar from "./Charts/Bar"
import { useData } from "../../contexts/dataContext"
import { getStages } from "../../schemas/Stage"
import moment from 'moment'
import imageFilterDash from "../../images/filter-dash.png"
import BackgroundSelectFilter from "../../components/BackgroundSelectFilter"

const initialFilters = { dateStart: moment().subtract(7, 'd').format("YYYY-MM-DD"), dateEnd: moment().subtract(1, 'd').format("YYYY-MM-DD") }

const Dashboard = () => {
    const { funnels, teams, users, loadCampaignsByOrigins } = useData()
    const [data, setData] = useState({})
    const [stages, setStages] = useState([])
    const [filters, setFilters] = useState(initialFilters)
    const [filtersTemp, setFiltersTemp] = useState(initialFilters)
    const [changedFilters, setChangedFilters] = useState(false)
    const [sideFilterActive, setSideFilterActive] = useState(true)
    const [loading, setLoading] = useState(false)
    const [backgroundFilter, setBackgroundFilter] = useState(true)

    const { permissions } = useAuth()

    let isLoadingDashboard = false

    useEffect(() => {
        setInitialValuesByFilter("funnels", funnels?.map(funnel => { funnel.selected = true; return funnel }))
    }, [funnels])

    useEffect(() => {
        setInitialValuesByFilter("teams", teams?.map(team => { team.selected = true; return team }))
    }, [teams])

    useEffect(() => {
        setInitialValuesByFilter("users", users?.map(user => { user.selected = true; return user }))
    }, [users])

    useEffect(() => {
        handleFiltersChanged()
    }, [filtersTemp])

    useEffect(() => {
        loadCampaignsByOrigins(filtersTemp.origins)
    }, [filtersTemp.origins])

    useEffect(() => {
        setStages([])
        if (filtersTemp?.funnels?.length > 0) {
            updateStagesByFunnelId(filtersTemp.funnels)
        }
    }, [filtersTemp.funnels])

    const getDataByFilter = async (updateFilters = false) => {
        try {
            if (!isLoadingDashboard) {
                isLoadingDashboard = true
                setLoading(true)
                const queryString = new URLSearchParams(filtersTemp)?.toString()
                const response = await api.get(`/leads/dashboard?${queryString}`)
                const responseData = response.data?.data
                setData(responseData)
                if (updateFilters) {
                    setFilters(filtersTemp)
                }
                setChangedFilters(false)
                setBackgroundFilter(false)
                closeSideFilter()
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
            isLoadingDashboard = false
        }
    }

    const updateStagesByFunnelId = (funnels) => {
        funnels?.map(async funnel_id => {
            const listStages = await getStages(funnel_id)
            const newStages = listStages?.map(stage => { return { id: stage._id, value: stage.name } })
            setStages((previousValue) => {
                const previousIds = new Set(previousValue?.map(stage => stage.id))
                const uniqueNewStages = newStages?.filter(newStage => !previousIds.has(newStage.id)) || []
                return [...previousValue, ...uniqueNewStages]
            })
        })
    }

    const setInitialValuesByFilter = (name, array) => {
        if (array?.length > 0) {
            const newValue = array?.filter(el => el.selected)?.map(el => { return el.id })
            setFilters(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
            setFiltersTemp(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
        }
    }

    const handleChangeFilters = (name, value) => {
        setFiltersTemp(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleFiltersChanged = () => {
        setChangedFilters(!areObjectsEqual(filters, filtersTemp))
    }

    const handleChangePeriod = (dateStart, dateEnd) => {
        handleChangeFilters("dateStart", dateStart)
        handleChangeFilters("dateEnd", dateEnd)
    }

    const cleanFilters = () => {
        setFiltersTemp(filters)
    }

    const openSideFilter = () => {
        setSideFilterActive(true)
    }

    const closeSideFilter = () => {
        setSideFilterActive(false)
    }

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <div className={styles.groupLeft}>
                    <Select name="Funil" placeholder="Pesquise um funil" options={funnels || []} selected={filtersTemp.funnels || []} onChange={(value) => handleChangeFilters("funnels", value)} multiple={true} />
                    <DatePicker name="Período" dateStart={filtersTemp.dateStart || ""} dateEnd={filtersTemp.dateEnd || ""} onChange={(dateStart, dateEnd) => handleChangePeriod(dateStart, dateEnd)} multiple={true} />
                    <Select name="Equipe" placeholder="Pesquise uma equipe" options={teams || []} selected={filtersTemp.teams || []} onChange={(value) => handleChangeFilters("teams", value)} multiple={true} />
                    {permissions?.crm > 1 &&
                        <Select name="Responsável" placeholder="Pesquise um responsável" options={users || []} selected={filtersTemp.users || []} onChange={(value) => handleChangeFilters("users", value)} multiple={true} />
                    }
                </div>
                <div className={styles.groupRight}>
                    <div className={styles.rightFilterAction} onClick={openSideFilter}>
                        <BiFilter />
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                {(changedFilters || backgroundFilter) &&
                    <div className={styles.changedFilters}>
                        <FormGroupInline>
                            <Button className='bg-purple' type="submit" onClick={() => getDataByFilter(true)}>Aplicar Filtros</Button>
                            <Button className='bg-pink' type="submit" onClick={cleanFilters}>Limpar</Button>
                        </FormGroupInline>
                    </div>
                }
                {!backgroundFilter &&
                    <>
                        <div className={styles.cards}>
                            {data?.cards?.length > 0 && data.cards.map(item => (
                                <Card
                                    key={item.id}
                                    icon={<AiFillDashboard />}
                                    name={item.name}
                                    value={item.value}
                                    variation={item.variation}
                                    is_positive={item.is_positive}
                                />
                            ))}
                        </div>
                        <div className={styles.charts}>
                            {data?.charts?.length > 0 && data.charts.map(item => (
                                <div className={`${item.type === "pie" ? styles.chartPie : (item.type === "bar" ? styles.chartBar : '')}`} key={item.id}>
                                    {item.type === "pie" &&
                                        <>
                                            <BreakForm title={item.title} showAction={false} border={false} />
                                            <ChartPie
                                                colors={item.colors}
                                                data={item.values}
                                            />
                                        </>
                                    }
                                    {item.type === "bar" &&
                                        <>
                                            <BreakForm title={item.title} showAction={false} border={false} />
                                            <ChartBar
                                                color={item.color}
                                                data={item.values}
                                            />
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                    </>
                }
                {/* <div className={styles.tables}>
                    {data?.tables?.length > 0 && data.tables.map(item => (
                        <div className={styles.groupTable} key={item.id}>
                            <BreakForm title={item.title} showAction={false} border={false} />
                            <Table
                                className={styles.table}
                                headers={item.headers}
                                values={item.values}
                                messageNotRegisters="Nenhum dado para exibir"
                                showIndex={true}
                            />
                        </div>
                    ))}
                </div> */}
                {backgroundFilter &&
                    <BackgroundSelectFilter
                        text="Selecione os filtros desejados e aplique para visualizar os dados!"
                        image={imageFilterDash}
                    />
                }
            </div>
            {sideFilterActive &&
                <SideFilter
                    filters={filtersTemp}
                    stages={stages}
                    handleChange={handleChangeFilters}
                    cleanFilters={cleanFilters}
                    onSubmit={() => getDataByFilter(true)}
                    onClose={closeSideFilter}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Dashboard