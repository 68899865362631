import React from "react";
import Button from "../Button";
import styles from './breakForm.module.css';

const BreakForm = ({ title, iconAction, styleAction, handleAction, border = true, showAction = true }) => {
    return (
        <div className={`${styles.breakForm} ${border ? '' : styles.noBorder}`}>
            <div className={styles.action}>
                <span>{title}</span>
                {showAction &&
                    <Button className={`action ${styleAction}`} onClick={handleAction}>{iconAction}</Button>
                }
            </div>
        </div>
    )
}

export default BreakForm