import React, { useEffect, useState } from "react";
import styles from "./modalImport.module.css"
import Button from "../../Form/Button";
import Select from "../../Form/Select";
import FormGroupInline from './../../Form/FormGroupInline';
import { toast } from "react-toastify";

const VerifyFields = ({ data, fields = [], headers = [], step, handleChangeValue = () => { }, handleChangeStep = () => { }, onSubmit = () => { } }) => {
    const [fieldsFormatted] = useState([{ id: -1, value: "Ignorar campo" }, ...fields])

    useEffect(() => {
        if (headers?.length > 0) {
            setInitialValues()
        }
    }, [headers])

    const setInitialValues = () => {
        headers?.forEach((header, index) => {
            let exactResult = false
            fields?.forEach(field => {
                if (header === field.value) {
                    handleChangeValue(field.id, index)
                    exactResult = true
                }
            })
            if (!exactResult) {
                fields?.forEach(field => {
                    if (header.indexOf(field.value) !== -1) {
                        handleChangeValue(field.id, index)
                    }
                })
            }
        })
    }

    const getValueById = (id) => {
        const [value] = fields?.filter(field => field.id === id)
        return value?.value || "-"
    }

    const handleChangeStepToLast = () => {
        for (let i = 0; i < fields.length; i++) {
            if (!fields[i]?.is_array) {
                const allresults = data?.filter(el => el === fields[i]?.id)
                if (allresults?.length > 1) {
                    return toast.error(`O campo ${fields[i].value} só pode ser combinado uma vez!`)
                }
            }
        }
        handleChangeStep(3)
    }

    return (
        <>
            {headers?.length > 0 && headers.map((header, index) => (
                (step === 2 || (data[index] && data[index] != -1)) &&
                <div key={index}>
                    {step === 2 ?
                        <FormGroupInline>
                            <span className={styles.label}>{header}</span>
                            <Select multiple={false} name="Selecione o campo correspondente" placeholder="Selecione um campo" options={fieldsFormatted || []} selected={data[index] ?? ""} onChange={(value) => handleChangeValue(value, index)} />
                        </FormGroupInline>
                        :
                        <FormGroupInline>
                            <span className={styles.label}>{header}</span>
                            <span className={styles.labelCenter}>{"->"}</span>
                            <span className={styles.label}>{getValueById(data[index])}</span>
                        </FormGroupInline>
                    }
                </div>
            ))}
            <FormGroupInline>
                {step === 2 ?
                    <>
                        <Button className='bg-purple' onClick={() => handleChangeStep(1)}>Editar configurações</Button>
                        <Button className='bg-pink' onClick={handleChangeStepToLast}>Combinar campos</Button>
                    </>
                    :
                    <>
                        <Button className='bg-purple' onClick={() => handleChangeStep(2)}>Editar campos</Button>
                        <Button className='bg-pink' onClick={onSubmit}>Confirmar campos</Button>
                    </>
                }
            </FormGroupInline>
        </>
    )
}

export default VerifyFields