import React, { useEffect, useState } from "react"
import styles from './crm.module.css'
import { MdCall, MdOutlineThumbDown, MdRepeat } from 'react-icons/md'
import { useCrm } from "../../contexts/crmContext"
import { useData } from "../../contexts/dataContext"
import moment from "moment"
import Flag from "../Flag"
import { maskCurrency } from "../../utils/formatter"
import { getProfileImageUserById, getPropertyForItemInArrayById } from "../../utils/utils"

const Card = ({ item, stageId }) => {
    const [responsibles, setResponsibles] = useState(<></>)
    const [valueProducts, setValueProducts] = useState(<></>)
    const [dragging, setDragging] = useState(false)
    const { setCardDrag, setOverCard, setOldStage, openModalLead, handleCallToLead, getStatusLeadByLastActivity, voipActive } = useCrm()
    const { origins, flags, allUsersGroup } = useData()

    useEffect(() => {
        getResponsibles()
        getValueProducts()
    }, [item])

    function dragStart(e) {
        setDragging(true)
        setOldStage(stageId)
        setCardDrag(item)
    }

    function dragEnd() {
        setDragging(false)
        setCardDrag(undefined)
        setOverCard(undefined)
    }

    function dragEnter() {
        setOverCard(item._id)
    }

    const getResponsibles = () => {
        if (item.users?.length > 0) {
            const responsibles = item.users.map((user, index) => (
                <div className={styles.imgProfile} key={index}>
                    {user?.user_id ? getProfileImageUserById(allUsersGroup, user.user_id) : getProfileImageUserById(allUsersGroup, user)}
                </div>
            ))
            setResponsibles(responsibles)
        }
    }

    function onCallToLead(e) {
        e.stopPropagation()
        handleCallToLead(item)
    }

    const getIconByStatusLead = () => {
        const status = getStatusLeadByLastActivity(item.lastActivity, item.stage_id)
        const color = status === "COOL" ? "bg-blue" : (status === "WARM" ? "bg-purple" : "bg-orange")
        const text = status === "COOL" ? "Frio" : (status === "WARM" ? "Esfriando" : "Quente")
        return (
            <div className={`${styles.temperature} ${color}`}>
                <span>{text}</span>
            </div>
        )
    }

    const getValueProducts = () => {
        let sum = 0
        item?.products?.forEach(product => {
            if (!isNaN(product?.value) && parseFloat(product?.value) > 0) {
                sum += parseFloat(product?.value)
            }
        })
        setValueProducts(sum > 0 ? sum.toFixed(2) : 0)
    }

    return (
        <div className={`${styles.card} ${dragging ? styles.dragging : ''}`} draggable={true} onDragStart={dragStart} onDragEnd={dragEnd} onDragEnter={dragEnter} onClick={() => openModalLead(item._id)} >
            <div className={styles.left}>
                <div className={styles.groupName}>
                    <span className={styles.name}>{item.name}</span>
                    {/* Apenas se for um lead repetido, de momento não tem lógica */}
                    {item.repeated &&
                        <div className={styles.repeat}>
                            <MdRepeat />
                        </div>
                    }
                </div>
                <span className={styles.business}>{item?.business?.business_name || '-'}</span>
                <span className={styles.origin}>{getPropertyForItemInArrayById(item.origin_id, origins, "id", "value") || '-'}</span>
                {item?.flags?.length > 0 && <div className={styles.flags}>
                    {item?.flags.map((flag, index) => (
                        <Flag
                            name={getPropertyForItemInArrayById(flag, flags, "_id", "name")}
                            color={getPropertyForItemInArrayById(flag, flags, "_id", "color")}
                            key={index}
                        />
                    ))}
                </div>}
                <div className={styles.value}>
                    <span>{maskCurrency(valueProducts)}</span>
                </div>
                <span className={styles.nextTask}>Próx. tarefa: {item.nextTask && moment(item.nextTask).format("DD/MM/YYYY HH:mm:ss") || '-'}</span>
            </div>
            <div className={styles.right}>
                {getIconByStatusLead()}
                <div className={styles.responsibles}>
                    {responsibles}
                </div>

                <div className={styles.bottom}>
                    {item?.discarted &&
                        <div className={styles.groupDiscarted}>
                            <span className="labelShowInHover">Descartado</span>
                            <div className={styles.discarted}>
                                <MdOutlineThumbDown />
                            </div>
                        </div>
                    }
                    {voipActive &&
                        <div className={styles.call} onClick={onCallToLead}>
                            <MdCall />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Card