import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import OtherRoutes from './OtherRoutes'
import AuthRoutes from './AuthRoutes'
import Loading from '../pages/layouts/Loading'
import { useAuth } from '../contexts/authContext'

const Routes = () => {
    const { loading, logged } = useAuth()

    return (
        <>
            {loading && <Loading fullPage={true} />}
            {logged && !loading &&
                <OtherRoutes />
            }
            {!logged && !loading &&
                <AuthRoutes />
            }
            <ToastContainer />
        </>
    )
}

export default Routes
