import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io"
import { GrClear } from "react-icons/gr"
import styles from "../form.module.css"
import Button from "../Button";
import moment from "moment";

const TimePicker = ({ name, time, onChange, theme }) => {
    const initialHour = time ? time.split(":")[0] : undefined
    const initialMinute = time ? time.split(":")[1] : undefined
    const [tempHour, setTempHour] = useState(initialHour)
    const [tempMinute, setTempMinute] = useState(initialMinute)
    const [active, setActive] = useState(false)

    const [stylesSelect, setStylesSelect] = useState({})
    const inputRef = useRef()
    const hoursRef = useRef()
    const minutesRef = useRef()
    let hourRef, minuteRef

    useEffect(() => {
        if (hoursRef.current && hourRef) {
            const height = hoursRef.current.offsetHeight
            const heightChild = hourRef.offsetHeight
            const positionY = hourRef.offsetTop
            const scrollBy = positionY + (heightChild / 2) - (height / 2)
            hoursRef.current.scrollTo(0, scrollBy)
        }
        if (minutesRef.current && minuteRef) {
            const height = minutesRef.current.offsetHeight
            const heightChild = minuteRef.offsetHeight
            const positionY = minuteRef.offsetTop
            const scrollBy = positionY + (heightChild / 2) - (height / 2)
            minutesRef.current.scrollTo(0, scrollBy)
        }
    }, [active])

    const getTextByInput = () => {
        return time ? time : name
    }

    const getListHours = () => {
        const listHours = []
        for (let i = 0; i < 24; i++) {
            listHours.push(
                { value: i < 10 ? `0${i}` : i, active: i === parseInt(tempHour), now: i === parseInt(moment().format("H")) })
        }

        return listHours.map((hour, index) => (
            <div className={`${styles.hour} ${hour.active ? styles.active : ''} ${hour.now ? styles.now : ''}`} onClick={() => setTempHour(hour.value)} ref={(e) => hour.active ? hourRef = e : (hour.now && !hourRef ? hourRef = e : false)} key={index}>
                <span>{hour.value}</span>
            </div>
        ))
    }

    const getListMinutes = () => {
        const listMinutes = []
        const step = 5
        for (let i = 0; i < 60; i += step) {
            const now = i - parseInt(moment().format("m")) < step && i - parseInt(moment().format("m")) >= 0
            listMinutes.push({ value: i < 10 ? `0${i}` : i, active: i === parseInt(tempMinute), now })
        }

        return listMinutes.map((minute, index) => (
            <div className={`${styles.minute} ${minute.active ? styles.active : ''} ${minute.now ? styles.now : ''}`} onClick={() => setTempMinute(minute.value)} ref={(e) => minute.active ? minuteRef = e : (minute.now && !minuteRef ? minuteRef = e : false)} key={index}>
                <span>{minute.value}</span>
            </div>
        ))
    }

    const handleToogleVisibleOptions = () => {
        setActive(previousActive => {
            if (previousActive) {
                setTempHour(initialHour)
                setTempMinute(initialMinute)
            } else {
                const windowWidth = window.innerWidth
                const details = inputRef.current.getBoundingClientRect()

                const distanceRight = windowWidth - details.right
                const distanceLeft = details.left

                const top = `calc(${details.bottom}px + 0.5em)`
                const left = distanceRight > distanceLeft ? `${details.left}px` : ''
                const right = distanceLeft > distanceRight ? `${distanceRight}px` : ''
                setStylesSelect({
                    top,
                    left,
                    right
                })
            }

            return !previousActive
        })
    }

    const onChangeTime = () => {
        const newTime = tempHour || tempMinute ? `${tempHour || ''}:${tempMinute || ''}` : undefined
        onChange(newTime)
        setActive(false)
    }

    const handleResetTime = () => {
        setTempHour()
        setTempMinute()
        onChange()
        setActive(false)
    }

    const getPositionInput = () => {
        const width = inputRef.current.offsetWidth
        const left = inputRef.current.offsetLeft

        return width + left
    }

    const alignInputInRight = () => {
        const bodyWidth = document.querySelector("body")?.offsetWidth ?? 0
        const position = getPositionInput()

        return position > (bodyWidth / 2) ? true : false
    }

    return (
        <>
            <div className={`${styles.timePicker} ${active && styles.active} ${styles[theme]}`} ref={inputRef}>
                <div className={styles.text} onClick={handleToogleVisibleOptions} onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleToogleVisibleOptions()} tabIndex="0" role="button">
                    <span>
                        {getTextByInput()}
                    </span>
                    <IoMdArrowDropdown />
                </div>
                {active &&
                    <div className={`${styles.groupOptions} ${alignInputInRight() && styles.right}`} style={stylesSelect}>
                        <div className={styles.container}>
                            <div className={styles.header}>
                                <span>Hora</span>
                                <span>Minuto</span>
                            </div>
                            <div className={styles.body}>
                                <div className={styles.hours} ref={hoursRef}>
                                    {getListHours()}
                                </div>
                                <div className={styles.minutes} ref={minutesRef}>
                                    {getListMinutes()}
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.icon} onClick={handleResetTime}>
                                    <GrClear color="#f00" />
                                </div>
                                <div className={styles.selectedPeriod}>
                                    <>
                                        <span>
                                            {tempHour ?? "-"}
                                        </span>
                                        <span>:</span>
                                        <span>
                                            {tempMinute ?? "-"}
                                        </span>
                                    </>
                                </div>
                                <div className={styles.actions}>
                                    <Button className="bg-purple" onClick={handleToogleVisibleOptions}>
                                        Cancelar
                                    </Button>
                                    <Button className="bg-pink" onClick={onChangeTime}>
                                        Selecionar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {active &&
                <div className={styles.backgroundForm} onClick={handleToogleVisibleOptions} />
            }
        </>
    )
}

export default TimePicker