import api from "../services/api"
import { toast } from "react-toastify"

const getUsers = async () => {
    try {
        const response = await api.get(`/users/onlyCrm`)
        const users = response.data?.data
        return users
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

const getAllUsersGroup = async () => {
    try {
        const response = await api.get(`/users/`)
        const users = response.data?.data
        return users
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getUsers, getAllUsersGroup }