import React from "react";
import ProgressSetup from "./ProgressSetup";
import image from "../../../images/setup/welcome.png"

const Welcome = ({ step = 0, nextStep = () => { } }) => {
    const title = "Bem vindo(a) a Oktto!"
    const description = "É um prazer tê-lo(a) aqui. Vamos começar com a configuração inicial para tornar sua experiência incrível."
    const btnText = "Começar"

    return (
        <ProgressSetup
            step={step}
            title={title}
            description={description}
            srcImage={image}
            altImage="Imagem de boas vindas!"
            btnText={btnText}
            onClick={nextStep}
        />
    )
}

export default Welcome