import React from "react";
import styles from "../form.module.css"

const Day = ({ day, handleSelectDates }) => {
    let styleDay = `${styles.day}`
    styleDay += day?.isStart || day?.isEnd ? ` ${styles.active}` : ''
    styleDay += day?.isStart ? ` ${styles.isStart}` : ''
    styleDay += day?.isEnd ? ` ${styles.isEnd}` : ''
    styleDay += day?.inRange ? ` ${styles.inRange}` : ''
    styleDay += day?.disabled ? ` ${styles.disabled}` : ''
    styleDay += day?.firstDay ? ` ${styles.firstDay}` : ''
    styleDay += day?.lastDay ? ` ${styles.lastDay}` : ''
    styleDay += day?.today ? ` ${styles.today}` : ''
    return (
        <div className={styleDay} onClick={() => !day?.disabled && handleSelectDates(day.fullDay)}>
            <span>{day.dayOfMonth}</span>
        </div>
    )
}

export default Day