import React, { useEffect, useState } from "react"
import styles from "./chatIntegration.module.css"
import Button from '../../components/Form/Button'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { MdOutlineWhatsapp } from "react-icons/md"
import FormGroup from '../../components/Form/FormGroup'
import Loading from '../layouts/Loading'
import ModalWhatsAppInbox from "../../components/Modal/ModalWhatsAppInbox"
import ModalNewWhatsAppInbox from "../../components/Modal/ModalWhatsAppInbox/ModalNewWhatsAppInbox"
import { useWebSocketChatContext } from "../../contexts/webSocketChatContext"
import { useData } from "../../contexts/dataContext"

const ChatIntegration = () => {
    const [inbox, setInbox] = useState({})
    const [whatsAppInboxes, setWhatsAppInboxes] = useState([])
    const [showModalInbox, setShowModalInbox] = useState(false)
    const [showModalNewInbox, setShowModalNewInbox] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [chatBots, setChatBots] = useState([])

    const { teams, roles, allUsersGroup } = useData()
    const { lastJsonMessage } = useWebSocketChatContext()

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    useEffect(() => {
        getAllWhatsAppInboxes()
        getAllChatBots()
    }, [])

    useEffect(() => {
        setUsers(allUsersGroup?.map(user => ({ id: user?._id, value: user?.name })))
    }, [allUsersGroup])

    const updateDataByMessageWebSocket = (message) => {
        const event = message?.event
        const content = message?.content
        switch (event) {
            case "UPDATE_INBOX":
                updateInboxByContent(content)
                break;
            default:
                break;
        }
    }

    const updateInboxByContent = (newContent) => {
        const inbox_id = newContent?._id
        if (inbox_id) {
            setWhatsAppInboxes(previousValue => {
                if (previousValue.find(inbox => inbox?._id === inbox_id)) {
                    return previousValue.map(inbox => {
                        if (inbox?._id === inbox_id) {
                            return { ...inbox, ...newContent }
                        }
                        return inbox
                    })
                }
                return previousValue
            })
            if (inbox?._id === inbox_id) {
                setInbox(previousValue => ({ ...previousValue, ...newContent }))
            }
        }
    }

    const getAllWhatsAppInboxes = () => {
        setIsLoading(true)
        api.get("/integrations/whatsapp/inboxes").then(response => {
            const Inboxes = response?.data?.data || []
            setWhatsAppInboxes(Inboxes)
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setIsLoading(false))
    }

    const getQRCodeByConnect = (inboxId) => {
        setIsLoading(true)
        api.get(`/integrations/whatsapp/inboxes/${inboxId}/connect`).then(response => {
            const data = response?.data?.data || []
            if (data?.qrCode?.base64) {
                const expires_in = data?.qrCode?.expires_in || 30000
                setTimeout(() => {
                    setInbox(previousValue => ({ ...previousValue, qrCode: { ...previousValue.qrCode, expired: true } }))
                }, expires_in)
            }
            setInbox(previousValue => ({ ...previousValue, ...data }))
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setIsLoading(false))
    }

    const getAllChatBots = () => {
        setIsLoading(true)
        api.get("/integrations/whatsapp/chatbots").then(response => {
            const data = response?.data?.data || []
            setChatBots(data)
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setIsLoading(false))
    }

    const handleSaveNewInbox = (data) => {
        setIsLoading(true)
        api.post("/integrations/whatsapp/inboxes", data).then(response => {
            const inbox = response.data.data
            addNewInbox(inbox)
            handleCloseModalNewInbox()
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setIsLoading(false))
    }

    const handleUpdateInbox = (data) => {
        setIsLoading(true)
        api.patch(`/integrations/whatsapp/inboxes/${data?._id}`, data).then(response => {
            const inbox = response.data.data
            updateInboxByContent(inbox)
            handleCloseModalInbox()
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setIsLoading(false))
    }

    const addNewInbox = (inbox) => {
        setWhatsAppInboxes(previousValue => ([...previousValue, inbox]))
    }

    const handleShowModalNewInbox = () => {
        setShowModalNewInbox(true)
    }

    const handleCloseModalNewInbox = () => {
        setShowModalNewInbox(false)
    }

    const handleShowModalInbox = (inboxId) => {
        setIsLoading(true)
        api.get(`/integrations/whatsapp/inboxes/${inboxId}`).then(response => {
            const inbox = response?.data?.data || {}
            setInbox(inbox)
            setShowModalInbox(true)
        }).catch(err => toast.error(err?.response?.data?.message))
            .finally(() => setIsLoading(false))
    }

    const handleCloseModalInbox = () => {
        setShowModalInbox(false)
    }

    const sortWhatsAppInboxs = (a, b) => {
        if (a.name > b.name) {
            return 1
        } else if (a.name < b.name) {
            return -1
        } else {
            return 0
        }
    }

    return (
        <>
            <div className={styles.content}>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <MdOutlineWhatsapp />
                        <span>WhatsApp</span>
                        <FormGroup>
                            <Button onClick={handleShowModalNewInbox} className="bg-pink">
                                Adicionar
                            </Button>
                        </FormGroup>
                    </div>
                    <div className={styles.body}>
                        <FormGroup labelText="Caixas de Entrada">
                            {whatsAppInboxes.length > 0 && whatsAppInboxes.sort(sortWhatsAppInboxs).map((inbox, index) => (
                                <div className={styles.groupPage} key={index}>
                                    <div className={styles.left}>
                                        <MdOutlineWhatsapp />
                                    </div>
                                    <div className={styles.center}>
                                        <span>{inbox.name || "-"}</span>
                                        <span className={`${styles.status} ${inbox.status === "connected" ? styles.connected : styles.disconnected}`}>Status: {inbox.status === "connected" ? "Conectado" : "Desconectado"}</span>
                                        {/* <Button className={styles.link} onClick={() => { }}>Excluir caixa de entrada</Button> */}
                                    </div>
                                    <Button className="bg-purple" onClick={() => handleShowModalInbox(inbox._id)}>
                                        Configurar
                                    </Button>
                                </div>
                            ))}
                            {whatsAppInboxes.length === 0 && <span>Nenhuma caixa de entrada conectada até o momento!</span>}
                        </FormGroup>
                    </div>
                </div>
            </div>
            {showModalNewInbox &&
                <ModalNewWhatsAppInbox
                    teams={teams}
                    users={users}
                    closeModal={handleCloseModalNewInbox}
                    onSubmit={handleSaveNewInbox}
                />
            }
            {showModalInbox &&
                <ModalWhatsAppInbox
                    inbox={inbox}
                    teams={teams}
                    roles={roles}
                    users={users}
                    usersWithAuto={[{ id: "auto", value: "Rodízio Automático" }, ...users]}
                    chatBots={chatBots}
                    closeModal={handleCloseModalInbox}
                    getQRCodeByConnect={getQRCodeByConnect}
                    onSubmit={handleUpdateInbox}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </>
    )
}

export default ChatIntegration