import React, { useEffect, useState } from "react"
import styles from "./conversation.module.css"
import { MdMessage, MdOutlineChat, MdOutlineCheck, MdOutlineContactPage, MdOutlineFormatListBulleted, MdOutlineGifBox, MdOutlineGroups, MdOutlineImage, MdOutlineInsertDriveFile, MdOutlineLocationOn, MdOutlineMicNone, MdOutlineNote, MdOutlinePause, MdOutlineTimer, MdOutlineVideocam, MdOutlineWhatsapp } from "react-icons/md"
import { RiInstagramLine, RiMessengerLine } from "react-icons/ri"
import { formatSecondsToTime } from "../../utils/formatter"
import ImageWithFallback from './../ImageWithFallback'
import { getLocalDate, getProfileUserById, getPropertyForItemInArrayById } from "../../utils/utils"
import { useData } from "../../contexts/dataContext"
import { useWebSocketChatContext } from "../../contexts/webSocketChatContext"
import Flag from "../Flag"

let timeoutUpdateComposing

const Conversation = ({ conversation, defaultPicture, onClick = () => { }, getIconMessageByDirectionDeliveredAndRead = () => { }, updateConversationByContent = () => { }, executeFunctionByAction = () => { } }) => {
    const [composing, setComposing] = useState(false)
    const [textComposing, setTextComposing] = useState("")
    const [contact, setContact] = useState({})
    const [showOptions, setShowOptions] = useState(false)
    const [styleAction, setStyleAction] = useState({})
    const { allUsersGroup } = useData()
    const { chatContacts, chatFlags, chatInboxes, getContactByIdAndPlatform } = useWebSocketChatContext()

    useEffect(() => {
        updateComposing(conversation?.presences)
    }, [conversation?.presences])

    useEffect(() => {
        updateComposing({})
    }, [conversation?.last_message])

    useEffect(() => {
        if (chatContacts?.length && conversation?.contact_id) {
            const contactData = getContactByIdAndPlatform(conversation.contact_id, conversation?.platform)
            setContact(contactData)
        }
    }, [conversation?.contact_id, conversation?.platform, chatContacts])

    const getProfileNameByMessage = (message) => {
        if (message.direction === "send") {
            const user = getUserProfileSender(message.user_id)
            return `${user?.name}: `
        } else {
            const contact = getContactProfileSender(message.contact_id)
            return `${contact?.name}: `
        }
    }
    const getUserProfileSender = (user_id) => {
        let user = { name: "Você (Envio externo)" }
        if (user_id) {
            user = getProfileUserById(allUsersGroup, user_id)
        }
        return user
    }
    const getContactProfileSender = (contact_id) => {
        const contactData = getContactByIdAndPlatform(contact_id, conversation?.platform)
        return { name: contactData?.name, image: contactData?.picture ? <ImageWithFallback src={contactData?.picture || defaultPicture} alt="Imagem de Perfil" functionError={() => <img src={defaultPicture} alt="Imagem de Perfil" />} /> : "" }
    }

    const getIconMessageByType = (type) => {
        switch (type) {
            case "image":
                return <MdOutlineImage />
            case "audio":
                return <MdOutlineMicNone />
            case "video":
                return <MdOutlineVideocam />
            case "document":
                return <MdOutlineInsertDriveFile />
            case "location":
                return <MdOutlineLocationOn />
            case "contact":
                return <MdOutlineContactPage />
            case "gif":
                return <MdOutlineGifBox />
            case "sticker":
                return <MdOutlineNote />
            case "poll":
                return <MdOutlineFormatListBulleted />
            case "list":
                return <MdOutlineFormatListBulleted />
            default:
                return ""
        }
    }
    const getIconMessageByOrigin = (platform) => {
        switch (platform) {
            case "whatsapp":
                return <MdOutlineWhatsapp />
            case "direct":
                return <RiInstagramLine />
            case "messenger":
                return <RiMessengerLine />
            default:
                return ""
        }
    }
    const getContentByMessage = (message) => {
        const text = message?.content?.text
        if (!text) {
            switch (message?.type) {
                case "image":
                    return "Imagem"
                case "audio":
                    return message?.content?.seconds > 0 ? formatSecondsToTime(message.content.seconds, false) : "Áudio"
                case "video":
                    return "Vídeo"
                case "document":
                    return message?.content?.name || "Documento"
                case "location":
                    return "Localização"
                case "contact":
                    return "Contato"
                case "gif":
                    return "GIF"
                case "sticker":
                    return "Figurinha"
                case "poll":
                    return "Enquete"
                case "list":
                    return "Lista"
                default:
                    return ""
            }
        }

        return text
    }
    const getTextByDateLastMessage = (dateLastMessage) => {
        if (!dateLastMessage) {
            return "-"
        }
        const date = getLocalDate(dateLastMessage)
        const startToday = getLocalDate().startOf("day")
        if (date.isSameOrAfter(startToday)) {
            return date.format("HH:mm")
        } else {
            const diffDays = startToday.diff(date.startOf("day"), "days")
            return diffDays === 1 ? "Ontem" : date.format("DD/MM/YYYY")
        }
    }
    const updateComposing = (presences) => {
        clearTimeout(timeoutUpdateComposing)
        setComposing(presences?.composing || false)
        setTextComposing(presences?.textComposing || "")
        if (presences?.composing) {
            timeoutUpdateComposing = setTimeout(() => {
                const dataConversation = { _id: conversation._id, presences: { composing: false, textComposing: "" } }
                updateConversationByContent(dataConversation)
            }, 15000)
        }
    }
    const getIconByStatus = (status) => {
        switch (status) {
            case "waiting":
                return <div className={`${styles.status} ${styles.waiting}`}>
                    <MdOutlineTimer />
                </div>
            case "opened":
                return <div className={`${styles.status} ${styles.opened}`}>
                    <MdOutlineChat />
                </div>
            case "paused":
                return <div className={`${styles.status} ${styles.paused}`}>
                    <MdOutlinePause />
                </div>
            case "finalized":
                return <div className={`${styles.status} ${styles.finalized}`}>
                    <MdOutlineCheck />
                </div>
            default:
                return
        }
    }
    const getOptions = () => {
        return (
            <>
                {<li onClick={handleToogleRead}><MdMessage /> {conversation?.messages_no_read ? "Marcar como lida" : "Marcar como não lida"}</li>}
            </>
        )
    }
    const handleToogleShowoptions = (e) => {
        e?.preventDefault()
        e?.stopPropagation()
        setShowOptions(previousValue => {
            if (!previousValue) {
                adjustPositionActions(e)
            }
            return !previousValue
        })
    }
    const adjustPositionActions = (e) => {
        const heightWindow = window.innerHeight
        const x = e?.clientX
        const y = e?.clientY
        if (y > (heightWindow / 2)) {
            const bottom = `${heightWindow - y}px`
            const left = `${x}px`
            setStyleAction({ bottom, left })
        } else {
            const top = `${y}px`
            const left = `${x}px`
            setStyleAction({ top, left })
        }
    }
    const handleToogleRead = () => {
        const action = conversation?.messages_no_read ? "READ_CHAT" : "NO_READ_CHAT"
        executeFunctionByAction(action, conversation)
    }

    return (
        <div className={`${styles.conversation} ${conversation?.is_active ? styles.isActive : ''}`} onClick={onClick} onContextMenu={handleToogleShowoptions}>
            <div className={styles.profileImage}>
                <ImageWithFallback
                    src={contact.picture || defaultPicture}
                    alt="Imagem de Perfil"
                    functionError={() => <img src={defaultPicture} alt="Imagem de Perfil" />}
                />
                {conversation?.is_group &&
                    <div className={styles.isGroup}>
                        <MdOutlineGroups />
                    </div>
                }
                <div className={styles.platform}>
                    {getIconMessageByOrigin(conversation?.platform)}
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.personalInfo}>
                    <span className={styles.profileName}>{contact.name}</span>
                    <span className={styles.dateLastMessage}>{getTextByDateLastMessage(conversation?.last_message.created_at)}</span>
                </div>
                <div className={styles.lastMessage}>
                    {composing ?
                        <span className="pink">{textComposing}</span>
                        :
                        <>
                            {getIconMessageByDirectionDeliveredAndRead(conversation?.last_message.direction, conversation?.last_message.sent, conversation?.last_message.delivered, conversation?.last_message.read, conversation?.last_message.internal, styles)}
                            {getIconMessageByType(conversation?.last_message.type)}
                            <span>{conversation?.is_group && getProfileNameByMessage(conversation?.last_message)}{getContentByMessage(conversation?.last_message)}</span>
                        </>
                    }
                    {(conversation?.messages_no_read || conversation?.number_messages_no_read > 0) &&
                        <div className={styles.noRead}>
                            <span>{conversation?.number_messages_no_read > 0 ? conversation?.number_messages_no_read : ''}</span>
                        </div>
                    }
                    {getIconByStatus(conversation?.status)}
                </div>
                <div className={styles.footer}>
                    <span className={styles.inbox}>{getPropertyForItemInArrayById(conversation?.inbox_id, chatInboxes, "_id", "name")}</span>
                    <div className={styles.groupFlags}>
                        {conversation?.flags?.length > 0 && conversation.flags.map((flag, index) => (
                            <Flag
                                color={getPropertyForItemInArrayById(flag.chat_flag_id, chatFlags, "_id", "color")}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {showOptions &&
                <div className={`${styles.options} ${showOptions ? styles.active : ''}`} onClick={handleToogleShowoptions}>
                    <>
                        <ul style={styleAction}>
                            {getOptions()}
                        </ul>
                        <div className={styles.background}></div>
                    </>
                </div>
            }
        </div>
    )
}

export default Conversation