import React, { useState } from "react"
import Modal from './index'
import Input from '../Form/Input'
import FormGroup from "../Form/FormGroup"
import Button from "../Form/Button"
import FormGroupInline from '../Form/FormGroupInline'
import Select from '../Form/Select'
import { MdOutlineAddCard } from "react-icons/md"

const listTypes = [{ id: "VOIP", value: "Créditos VOIP" }]
const listMethod = [{ id: "BANK_SLIP", value: "Boleto Bancário" }]

const ModalNewRecharge = ({ onSubmit = () => { }, closeModal = () => { } }) => {
    const [transaction, setTransaction] = useState({})

    const handleChangeValue = (name, value) => {
        setTransaction(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        onSubmit(transaction)
    }

    return (
        <Modal
            icon={<MdOutlineAddCard />}
            title={"Nova Recarga"}
            description={"Preencha os campos abaixo e clique em salvar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="Tipo de Recarga">
                    <Select name="Tipo" placeholder="Pesquise um tipo" options={listTypes || []} selected={transaction?.type ?? ""} onChange={(value) => handleChangeValue("type", value)} multiple={false} />
                </FormGroup>
                <FormGroup labelText="Valor (Min. R$ 50,00)">
                    <Input type="currency" placeholder="Valor" value={transaction?.value ?? ""} onChange={(e) => handleChangeValue("value", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Método de Pagamento">
                    <Select name="Método de Pagamento" placeholder="Pesquise um método" options={listMethod || []} selected={transaction?.method ?? ""} onChange={(value) => handleChangeValue("method", value)} multiple={false} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalNewRecharge