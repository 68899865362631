import React, { useState } from 'react'
import styles from "./importData.module.css"
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md'
import { openSelectSingleFile } from '../../utils/utils'
import { toast } from 'react-toastify'
import { read, utils } from 'xlsx';
import Button from '../../components/Form/Button'
import UploadArea from '../../components/UploadArea'
import ModalImport from '../../components/Modal/ModalImport'
import Loading from './../layouts/Loading/index'
import api from '../../services/api'
import { NavLink, useParams } from 'react-router-dom'

const maxRows = 1000

const ImportData = () => {
    const [uploadedFile, setUploadedFile] = useState(null)
    const [dataFile, setDataFile] = useState(null)
    const [modalImport, setModalImport] = useState(false)
    const [isDraggingOver, setIsDraggingOver] = useState(false)
    const [loading, setLoading] = useState(false)

    const { account_id } = useParams()

    const handleFileDrop = (file) => {
        const maxFileSize = 1024 * 1024 * 2
        const fileSize = file?.size
        const fileName = file?.name

        setIsDraggingOver(false)

        if (fileSize > maxFileSize) {
            return toast.error("O tamanho do arquivo não deve ultrapassar 2MB.")
        }
        if (fileName && !fileName.endsWith('.xls') && !fileName.endsWith('.xlsx') && !fileName.endsWith('.csv')) {
            return toast.error("Apenas arquivos .xls, .xlsx e .csv são permitidos!")
        }

        setUploadedFile(file)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDraggingOver(true)
    }

    const handleDragLeave = () => {
        setIsDraggingOver(false)
    }

    const readFileUpload = async (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()
                reader.onload = async function (e) {
                    const data = e.target.result
                    const workbook = read(data)
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
                    const dataFile = utils.sheet_to_json(worksheet, { header: 1 })
                    resolve(dataFile)
                }
                reader.readAsArrayBuffer(file)
            } catch (err) {
                toast.error("Não foi possível ler o arquivo! O formato do arquivo é inválido, ou o arquivo pode estar corrompido. Verifique o arquivo e tente novamente!")
                resolve([])
            }
        })
    }

    const handleOpenModalConfig = async () => {
        try {
            const data = await readFileUpload(uploadedFile)
            if (!Array.isArray(data) || data?.length <= 1) {
                return toast.error("O arquivo enviado está em branco! Verifique o arquivo enviado e tente novamente.")
            }
            if (data?.length > maxRows + 1) {
                return toast.error(`Não é possível cadastrar mais que ${maxRows} leads por vez!`)
            }
            setDataFile(data)
            setModalImport(true)
        } catch (err) {
            toast.error("Não foi possível ler o arquivo! O formato do arquivo é inválido, ou o arquivo está corrompido. Verifique o arquivo enviado e tente novamente!")
        }
    }

    const handleCloseModalConfig = () => {
        setModalImport(false)
    }

    const setInitialValues = () => {
        setUploadedFile(null)
        setDataFile(null)
        setModalImport(false)
        setIsDraggingOver(false)
        setLoading(false)
    }

    const verifyNameIsValid = async (name) => {
        setLoading(true)
        try {
            await api.post("/imports/verify-name", { name })
            return true
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = (dataConfig, dataVerifyFields) => {
        const dataJSON = []
        for (let i = 1; i <= dataFile.length; i++) {
            const row = []
            let isPopulated = false
            dataVerifyFields?.forEach((header, index) => {
                if (header && header != -1) {
                    const value = dataFile[i] && dataFile[i][index] ? dataFile[i][index] : ''
                    if (!isPopulated && value) {
                        isPopulated = true
                    }
                    row.push({ [header]: value })
                }
            })
            if (isPopulated) {
                dataJSON.push(row)
            }
        }
        setLoading(true)
        api.post("/imports/leads", { config: dataConfig, data: dataJSON }).then(response => {
            toast.success(response?.data?.message)
            setInitialValues()
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }

    return (
        <>
            <div className={styles.content} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
                <div className={styles.link}>
                    <NavLink to={`/${account_id}/import/log`}>Acessar histórico de importações</NavLink>
                </div>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <MdOutlineFileUpload />
                        <span>Importar Leads</span>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.groupInline}>
                            <span>Baixar modelo de importação:</span>
                            <a href="https://oktto-models.s3.sa-east-1.amazonaws.com/files/Modelo_Oktto.xlsx" download={true}>
                                <Button className="bg-orange" onClick={() => { }}>
                                    Download
                                    <MdOutlineFileDownload />
                                </Button>
                            </a>
                        </div>
                        <UploadArea
                            value={uploadedFile}
                            isDraggingOver={isDraggingOver}
                            onFileDrop={handleFileDrop}
                        />
                        <div className={styles.maxFileSize}>
                            <span>Tamanho máximo do arquivo 2MB ou {maxRows} registros.</span>
                        </div>
                        <div className={styles.groupInline}>
                            <span>Selecione um arquivo em seu dispositivo:</span>
                            <Button className="bg-gray" onClick={() => openSelectSingleFile(handleFileDrop, ".xls, .xlsx, .csv")}>
                                Selecione um arquivo
                            </Button>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button className="bg-pink" onClick={() => handleOpenModalConfig()} disabled={!uploadedFile}>
                            Enviar
                        </Button>
                    </div>
                </div>
            </div>
            {modalImport &&
                <ModalImport
                    dataFile={dataFile}
                    closeModal={handleCloseModalConfig}
                    verifyNameIsValid={verifyNameIsValid}
                    onSubmit={handleSubmit}
                />
            }
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ImportData