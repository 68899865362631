import { createContext, useContext, useEffect, useState } from "react"
import { getFunnels, getLastFunnel } from "../schemas/Funnel"
import { getAllUsersGroup, getUsers } from "../schemas/User"
import { getReasonsDiscard } from "../schemas/ReasonDiscard"
import { getTypesTask } from "../schemas/TypeTask"
import { getAdditionalFields } from "../schemas/AdditionalField"
import { getFlags } from "../schemas/Flag"
import { getRoles } from "../schemas/Role"
import { getTeams } from "../schemas/Team"
import { getCampaignsByOrigins } from "../schemas/Campaign"
import { getOrigins } from "../schemas/Origins"
import { getProducts } from "../schemas/Products"
import { getAllFields } from "../schemas/Field"
import Loading from "../pages/layouts/Loading"
import { useAuth } from "./authContext"

const DataContext = createContext()

const DataProvider = ({ children }) => {
    const [accountId, setAccountId] = useState()
    const [lastFunnel, setLastFunnel] = useState()
    const [funnels, setFunnels] = useState([])
    const [allUsersGroup, setAllUsersGroup] = useState([])
    const [reasonsDiscard, setReasonsDiscard] = useState([])
    const [typesTask, setTypesTask] = useState([])
    const [additionalFields, setAdditionalFields] = useState([])
    const [teams, setTeams] = useState([])
    const [roles, setRoles] = useState([])
    const [flags, setFlags] = useState([])
    const [flagsByoptions, setFlagsByoptions] = useState([])
    const [users, setUsers] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])
    const [products, setProducts] = useState([])
    const [fields, setFields] = useState([])
    const [countLoading, setCountLoading] = useState(0)

    const { statusAccount, permissions } = useAuth()

    const typeDate = [
        { id: 1, value: "Data do Cadastro", selected: true },
        { id: 2, value: "Data da Tarefa" },
        { id: 3, value: "Data da Venda" },
    ]

    const status = [
        { id: 1, value: "Em Negociação", selected: true },
        { id: 2, value: "Lead Descartado" },
        { id: 3, value: "Venda Realizada" },
    ]

    const addCountLoading = (loading) => {
        if (loading) {
            setCountLoading(previousValue => previousValue + 1)
        }
    }
    const removeCountLoading = (loading) => {
        if (loading) {
            setCountLoading(previousValue => previousValue - 1)
        }
    }
    const loadFunnels = async (loading = false) => {
        try {
            addCountLoading(loading)
            const funnels = await getFunnels()
            setFunnels(funnels)
            removeCountLoading(loading)
            return funnels || []
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadLastFunnel = async (loading = false) => {
        try {
            addCountLoading(loading)
            const funnel = await getLastFunnel()
            setLastFunnel(funnel)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const updateLastFunnel = async (funnel_id) => {
        setLastFunnel(funnel_id)
    }
    const loadUsers = async (loading = false) => {
        try {
            addCountLoading(loading)
            const users = await getUsers()
            setUsers(users?.map(user => { return { id: user._id, value: user.name } }))
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadAllUsersGroup = async (loading = false) => {
        try {
            addCountLoading(loading)
            const users = await getAllUsersGroup()
            setAllUsersGroup(users)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadReasonsDiscard = async (loading = false) => {
        try {
            addCountLoading(loading)
            const reasons = await getReasonsDiscard()
            setReasonsDiscard(reasons)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadTypesTask = async (loading = false) => {
        try {
            addCountLoading(loading)
            const types = await getTypesTask()
            setTypesTask(types)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadAdditionalFields = async (loading = false) => {
        try {
            addCountLoading(loading)
            const fields = await getAdditionalFields()
            setAdditionalFields(fields)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadTeams = async (loading = false) => {
        try {
            addCountLoading(loading)
            const teams = await getTeams()
            setTeams(teams?.map(team => { return { id: team._id, value: team.name, users: team.users } }))
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadRoles = async (loading = false) => {
        try {
            addCountLoading(loading)
            const roles = await getRoles()
            setRoles(roles?.map(role => { return { id: role._id, value: role.name } }))
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadFlags = async (loading = false) => {
        try {
            addCountLoading(loading)
            const flags = await getFlags()
            setFlags(flags)
            setFlagsByoptions(flags?.map(flag => { return { id: flag._id, value: flag.name } }))
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadCampaignsByOrigins = async (origins, loading = false) => {
        try {
            addCountLoading(loading)
            const campaigns = await getCampaignsByOrigins(origins)
            setCampaigns(campaigns?.map(campaign => { return { id: campaign, value: campaign } })?.filter(campaign => campaign.value))
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadOrigins = async (loading = false) => {
        try {
            addCountLoading(loading)
            const origins = await getOrigins()
            setOrigins(origins?.map(origin => { return { id: origin._id, value: origin.name } }))
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadProducts = async (loading = false) => {
        try {
            addCountLoading(loading)
            const products = await getProducts()
            setProducts(products)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const loadFields = async (loading = false) => {
        try {
            addCountLoading(loading)
            const fields = await getAllFields()
            setFields(fields)
            removeCountLoading(loading)
        } catch (err) {
            removeCountLoading(loading)
        }
    }
    const resetAllData = () => {
        setAccountId()
        setLastFunnel()
        setFunnels([])
        setAllUsersGroup([])
        setReasonsDiscard([])
        setTypesTask([])
        setAdditionalFields([])
        setTeams([])
        setFlags([])
        setFlagsByoptions([])
        setUsers([])
        setCampaigns([])
        setOrigins([])
        setProducts([])
        setFields([])
        setCountLoading(0)
    }

    useEffect(() => {
        if (accountId) {
            loadFunnels(true)
            loadTeams(true)
            loadRoles(true)
            loadOrigins(true)
            loadProducts(true)
            loadUsers(true)
            loadCampaignsByOrigins([], true)
            if (statusAccount?.hasPermission || permissions?.is_admin > 0) {
                loadLastFunnel(true)
                loadReasonsDiscard(true)
                loadTypesTask(true)
                loadAdditionalFields(true)
                loadFlags(true)
                loadAllUsersGroup(true)
                loadFields(true)
            }
        }
    }, [accountId, statusAccount?.hasPermission, permissions?.is_admin])

    return (
        <DataContext.Provider value={{ funnels, lastFunnel, allUsersGroup, reasonsDiscard, typesTask, additionalFields, teams, roles, flags, flagsByoptions, fields, users, campaigns, origins, products, typeDate, status, setAccountId, loadFunnels, loadCampaignsByOrigins, updateLastFunnel, resetAllData }}>
            {children}
            {countLoading > 0 && <Loading fullPage={true} initialLoad={true} />}
        </DataContext.Provider>
    )
}

const useData = () => {
    const context = useContext(DataContext)
    return context
}

export { DataProvider, useData }

export default DataProvider