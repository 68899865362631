import React, { useState } from "react"
import Modal from './index'
import Input from '../Form/Input'
import FormGroup from "../Form/FormGroup"
import Button from "../Form/Button"
import FormGroupInline from '../Form/FormGroupInline'
import Select from "../Form/Select"
import { FaPencilAlt } from "react-icons/fa"
import { TbWebhook } from 'react-icons/tb';
import { getOptionByOptionList } from "../../utils/utils"

const ModalWebhook = ({ isNew = false, value = {}, actions = [], editable = false, setEdit, onSubmit, closeModal }) => {
    const [webhook, setWebhook] = useState(value)

    const handleChangeValue = (name, value) => {
        setWebhook(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setWebhook(value)
        }
    }

    return (
        <Modal
            icon={<TbWebhook />}
            title={isNew ? "Cadastro de novo webhook" : (editable ? `Editando o webhook: ${webhook.name}` : `Visualizando: ${webhook.name}`)}
            description={isNew ? "Adicione um novo webhook a sua conta" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, webhook)}>
                <FormGroupInline>
                    <FormGroup labelText="Nome">
                        {editable ?
                            <Input type="text" placeholder="Nome do webhook" value={webhook?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} invalid={true} />
                            :
                            <span>{webhook?.name || "-"}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <FormGroup labelText="URL">
                    {editable ?
                        <Input type="url" placeholder="URL do webhook" value={webhook?.url ?? ""} onChange={(e) => handleChangeValue("url", e.target.value)} invalid={true} />
                        :
                        <span>{webhook?.url || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Ações">
                    {editable ?
                        <Select multiple={true} name="Ações do Webhook" placeholder="Buscar..." options={actions} onChange={(value) => handleChangeValue("actions", value)} selected={webhook?.actions || []} />
                        :
                        <span>{getOptionByOptionList(webhook?.actions, actions)}</span>
                    }
                </FormGroup>
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalWebhook