import moment from "moment"
import { BsFileEarmark, BsFiletypeAi, BsFiletypeBmp, BsFiletypeCsv, BsFiletypeDoc, BsFiletypeDocx, BsFiletypeGif, BsFiletypeJpg, BsFiletypeMov, BsFiletypeMp3, BsFiletypeMp4, BsFiletypePdf, BsFiletypePng, BsFiletypePpt, BsFiletypePptx, BsFiletypePsd, BsFiletypeSvg, BsFiletypeTiff, BsFiletypeTxt, BsFiletypeXls, BsFiletypeXlsx, BsFiletypeXml } from "react-icons/bs"
import { getNumbersByString } from "./formatter"
import Logo from "../logo.svg"
import ImageWithFallback from "../components/ImageWithFallback"

const verifyIfDateIsValid = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/
    if (!regex.test(dateString)) return false

    const date = new Date(dateString)
    return !isNaN(date) && date?.toISOString()?.slice(0, 10) === dateString
}

const getOptionByOptionList = (value, optionList) => {
    let selected = []
    optionList?.forEach(option => {
        if (Array.isArray(value)) {
            value?.forEach(item => {
                if (option?.id?.toString() === item?.toString()) {
                    selected.push(option.value)
                }
            })
        } else {
            if (option?.id?.toString() === value?.toString()) {
                selected.push(option.value)
            }
        }
    })

    return selected.join(", ")
}

const getIconByExtensionFile = (name) => {
    const nameSplit = name.split(".")
    const ext = nameSplit[nameSplit.length - 1]?.toLowerCase()
    switch (ext) {
        case "ai":
            return <BsFiletypeAi />
        case "bmp":
            return <BsFiletypeBmp />
        case "csv":
            return <BsFiletypeCsv />
        case "doc":
            return <BsFiletypeDoc />
        case "docx":
            return <BsFiletypeDocx />
        case "gif":
            return <BsFiletypeGif />
        case "jpg":
            return <BsFiletypeJpg />
        case "jpeg":
            return <BsFiletypeJpg />
        case "mov":
            return <BsFiletypeMov />
        case "mp3":
            return <BsFiletypeMp3 />
        case "mp4":
            return <BsFiletypeMp4 />
        case "pdf":
            return <BsFiletypePdf />
        case "png":
            return <BsFiletypePng />
        case "ppt":
            return <BsFiletypePpt />
        case "pptx":
            return <BsFiletypePptx />
        case "psd":
            return <BsFiletypePsd />
        case "svg":
            return <BsFiletypeSvg />
        case "tiff":
            return <BsFiletypeTiff />
        case "txt":
            return <BsFiletypeTxt />
        case "xls":
            return <BsFiletypeXls />
        case "xlsx":
            return <BsFiletypeXlsx />
        case "xml":
            return <BsFiletypeXml />
        default:
            return <BsFileEarmark />
    }
}

const openSelectSingleFile = (handleChange = () => { }, accept = "*") => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", accept)
    input.addEventListener("change", setFile)
    input.click()

    function setFile(e) {
        const file = e.target.files[0]
        handleChange(file)
    }
}

const areObjectsEqual = (obj1, obj2) => {
    const stringifiedObj1 = JSON.stringify(obj1)
    const stringifiedObj2 = JSON.stringify(obj2)
    return stringifiedObj1 === stringifiedObj2
}

const getAddressByZipCode = async (zipCode) => {
    try {
        const zipCodeonlyNumbers = getNumbersByString(zipCode)
        if (zipCodeonlyNumbers.length >= 8) {
            const url = `https://viacep.com.br/ws/${zipCodeonlyNumbers}/json/`
            const response = await fetch(url)
            const data = await response.json()
            const newAddress = {
                country: "Brasil",
                uf: data.uf,
                city: data.localidade,
                district: data.bairro,
                road: data.logradouro,
            }
            return newAddress
        }
        return {}
    } catch (err) {
        console.log(err)
        return {}
    }
}

const showNotification = (title, description, link) => {
    if ('Notification' in window) {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                const notification = new Notification(title, {
                    body: description,
                    icon: Logo,
                })
                if (link) {
                    notification.onclick = () => {
                        window.open(link, '_blank')
                    }
                }
            }
        })
    }
}

const getUniqueId = () => {
    return Math.random()?.toString(16).slice(2) + new Date().getTime() + Math.random()?.toString(16).slice(2)
}

const adjustOptionsProduct = async (arrayProducts) => {
    return new Promise(resolve => {
        const productList = arrayProducts?.filter(product => product.last_child)?.map(product => {
            if (product.father) {
                product = getFatherByProduct(arrayProducts, product)
                return product
            } else {
                return product
            }
        })
        resolve(productList)
    })
}

const getFatherByProduct = (arrayProducts, item) => {
    let father = arrayProducts?.filter(product => product._id === item.father)
    if (father) {
        father.child = item
        father = { ...father[0], child: father.child }
        return father.father ? getFatherByProduct(arrayProducts, father) : father
    }
}

const getIdByProduct = (product) => {
    return product.child ? getIdByProduct(product.child) : product._id
}

const getNameByProduct = (product) => {
    let name = product.name ? (product.last_child ? product.name : `${product.name?.substring(0, 4)}.->`) : ''
    if (product.child) {
        name += getNameByProduct(product.child)
    }
    return name
}

const getNameFunnelById = (funnel_id, funnels) => {
    const funnel = getItemForArrayById(funnels, "id", funnel_id)
    return funnel?.value || ''
}

const getIdsUsersByTeamId = (team_id, teams) => {
    if (team_id) {
        const [usersTeam] = teams?.filter(team => team.id === team_id)?.map(team => { return team.users })
        const idsUsersTeam = usersTeam?.map(user => { return user.user_id })
        return idsUsersTeam
    }
    return []
}

const getUsersByTeamId = (team_id, users, teams, initialData = [], orCompare = () => { }) => {
    const idsUsersTeam = getIdsUsersByTeamId(team_id, teams)
    const listUsers = users?.filter(user => orCompare(user) || idsUsersTeam?.indexOf(user.id) !== -1) || []
    return [...initialData, ...listUsers]
}

const getUsersByTeams = (selectedTeams, users, teams, initialData = [], orCompare = () => { }) => {
    let listIdsUsers = []
    const filteredTeams = teams?.filter(team => orCompare(team) || selectedTeams?.indexOf(team.id) !== -1) || []
    filteredTeams?.forEach(team => {
        const idsUsersTeam = getIdsUsersByTeamId(team?.id, teams)
        const filteredIdsUsers = idsUsersTeam?.filter(user => listIdsUsers?.indexOf(user) === -1) || []
        listIdsUsers.push(...filteredIdsUsers)
    })
    const filteredUsers = users?.filter(user => orCompare(user) || listIdsUsers?.indexOf(user.id) !== -1) || []
    return [...initialData, ...filteredUsers]
}

const getItemForArrayById = (array, propertyCompare, propertySearch) => {
    const [el] = array?.filter(el => el[propertyCompare] === propertySearch)
    return el
}

const getPropertyForItemInArrayById = (search, array, propertyCompare, propertySearch) => {
    const [el] = array?.filter(el => el[propertyCompare] === search)
    return el ? el[propertySearch] || '' : ''
}

const isValidAccountId = (accountId) => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(accountId);
}

const openLinkExternal = (link_external, download = false) => {
    const link = document.createElement("a")
    link.setAttribute("href", link_external)
    link.setAttribute("target", "_blank")
    link.setAttribute("rel", "noopener noreferrer")
    if (download) {
        link.setAttribute("download", "true")
    }
    link.click()
}

const adjustTextByIndentation = (text) => {
    const listText = text?.split("\n")
    let currentIndentLevel = 0

    const parseFormattedText = (line) => {
        const elements = []
        let remainingText = line
        let match

        const regex = /(\*\*(.*?)\*\*)|(_(.*?)_)|(\*(.*?)\*)|(\[(.*?)\]\((.*?)\))/g

        while ((match = regex.exec(remainingText)) !== null) {
            const beforeText = remainingText.slice(0, match.index)
            if (beforeText) {
                elements.push(beforeText)
            }

            if (match[2]) {
                elements.push(<strong key={match.index}>{match[2]}</strong>)
            } else if (match[4]) {
                elements.push(<em key={match.index}>{match[4]}</em>)
            } else if (match[6]) {
                elements.push(<em key={match.index}>{match[6]}</em>)
            } else if (match[8] && match[9]) {
                elements.push(<a href={match[9]} key={match.index}>{match[8]}</a>)
            }

            remainingText = remainingText.slice(match.index + match[0].length)
        }

        if (remainingText) {
            elements.push(remainingText)
        }

        return elements
    }

    return listText?.map((line, index) => {
        const formattedLine = line?.replace("\r", "").trim()

        if (formattedLine === "") {
            return <br key={index} />
        }

        if (formattedLine.replace(/^[-*]\s/, "").startsWith("**")) {
            currentIndentLevel = 0
        }

        if (formattedLine.startsWith("**")) {
            return (
                <p key={index}>
                    {parseFormattedText(formattedLine)}
                </p>
            )
        }

        if (/^[-*]\s/.test(formattedLine)) {
            currentIndentLevel = formattedLine.replace(/^[-*]\s/, "").startsWith("**") ? 0 : 1
            const childListClass = `list-level-${currentIndentLevel}`
            return (
                <ul key={index} className={childListClass}>
                    <li>{parseFormattedText(formattedLine.replace(/^[-*]\s/, ""))}</li>
                </ul>
            )
        }

        currentIndentLevel = 0

        return (
            <p key={index} style={{ paddingLeft: "0px" }}>
                {parseFormattedText(formattedLine)}
            </p>
        )
    })
}

function getImgProfileByName(nameResponsible) {
    const nameArray = nameResponsible?.split(" ")
    const initials = `${nameArray[0][0]}${nameArray[nameArray.length - 1][0]}`
    return (
        <div className="imgResponsible">{initials}</div>
    )
}

function getProfileImageUserById(allUsersGroup, user_id) {
    const [user] = allUsersGroup?.filter(el => el._id === user_id)
    return (
        <>
            {user?.name && <span className="labelShowInHover">{user.name}</span>}
            {user?.image &&
                <ImageWithFallback
                    src={user?.image}
                    alt="Responsável pelo Lead"
                    functionError={() => getImgProfileByName(user?.name)}
                />
            }
            {!user?.image && user?.name && getImgProfileByName(user.name)}
        </>
    )
}

function getProfileUserById(allUsersGroup, user_id) {
    const [user] = allUsersGroup?.filter(el => el._id === user_id)
    const image = getProfileImageUserById(allUsersGroup, user_id)
    return { name: user?.name, image }
}

function getNameUserById(allUsersGroup, user_id) {
    const [user] = allUsersGroup?.filter(el => el._id === user_id)
    return user?.name
}

function resizeImage(file, maxWidth, maxHeight, callback) {
    const img = new Image()
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const reader = new FileReader()

    reader.onload = (e) => {
        img.src = e.target.result
    }

    img.onload = () => {
        let width = img.width
        let height = img.height

        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width
                width = maxWidth
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height
                height = maxHeight
            }
        }

        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)

        canvas.toBlob((blob) => {
            callback(blob)
        }, file.type)
    }

    reader.readAsDataURL(file)
}

function getLocalDate(date, format = "YYYY-MM-DD HH:mm:ss") {
    return date ? moment.utc(date, format).local() : moment.utc().local()
}

async function getCoordinates(address) {
    const encodedAddress = encodeURIComponent(address)
    const apiKey = process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK') {
            const location = data.results[0].geometry.location
            const formatted_address = data.results[0].formatted_address
            return { latitude: location.lat, longitude: location.lng, address: formatted_address }
        } else {
            console.error('Geocoding error:', data.status)
        }
    } catch (error) {
        console.error('Request failed:', error)
    }
}

async function getBlobByLocation(latitude, longitude) {
    const url = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=100x100&markers=color:red%7C${latitude},${longitude}&format=jpg&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    try {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        const blob = await response.blob()
        return blob
    } catch (err) {
        console.error('Failed to fetch the blob:', err)
        throw err
    }
}

async function blobToBase64(blob) {
    try {
        const reader = new FileReader()
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1]
                resolve(base64String)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
        });
    } catch (err) {
        console.error('Failed to convert blob to base64:', err)
        throw err
    }
}

async function getThumbnailByLocation(latitude, longitude) {
    try {
        const blob = await getBlobByLocation(latitude, longitude)
        const base64 = await blobToBase64(blob)
        return base64
    } catch (err) {
        console.error('Failed to get thumbnail by location:', err)
    }
}

function sortValuesByPropertyAndDirection(a, b, property, direction = "ASC") {
    if (a[property] > b[property]) {
        return direction === "ASC" ? 1 : -1
    } else if (a[property] < b[property]) {
        return direction === "ASC" ? -1 : 1
    } else {
        return 0
    }
}

function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
}

export { verifyIfDateIsValid, getOptionByOptionList, getIconByExtensionFile, openSelectSingleFile, areObjectsEqual, getAddressByZipCode, showNotification, getUniqueId, adjustOptionsProduct, getIdByProduct, getNameByProduct, getNameFunnelById, getIdsUsersByTeamId, getUsersByTeamId, getUsersByTeams, getItemForArrayById, getPropertyForItemInArrayById, isValidAccountId, openLinkExternal, adjustTextByIndentation, getProfileImageUserById, getProfileUserById, getNameUserById, resizeImage, getCoordinates, getThumbnailByLocation, getLocalDate, sortValuesByPropertyAndDirection, capitalizeFirstLetter }