import React, { useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { MdOutlinePublishedWithChanges } from "react-icons/md";

const ModalChangePassword = ({ onSubmit, closeModal }) => {
    const [user, setUser] = useState({})

    const handleChangeValue = (name, value) => {
        setUser(previousValue => { return { ...previousValue, [name]: value } })
    }

    return (
        <Modal
            icon={<MdOutlinePublishedWithChanges />}
            title="Alteração de senha"
            description="Defina abaixo uma nova senha de acesso para o seu usuário"
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, user)}>
                <FormGroup labelText="Senha atual">
                    <Input type="password" placeholder="Senha atual" value={user.currentPassword ?? ""} onChange={(e) => handleChangeValue("currentPassword", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Nova senha">
                    <Input type="password" placeholder="Nova senha" value={user.newPassword ?? ""} onChange={(e) => handleChangeValue("newPassword", e.target.value)} />
                </FormGroup>
                <FormGroup labelText="Confirmar nova senha">
                    <Input type="password" placeholder="Confirmar nova senha" value={user.confirmNewPassword ?? ""} onChange={(e) => handleChangeValue("confirmNewPassword", e.target.value)} />
                </FormGroup>
                <Button className='bg-pink' type="submit">Confirmar</Button>
            </form>
        </Modal>
    )
}

export default ModalChangePassword