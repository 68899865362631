import React from "react";
import styles from './loading.module.css'
import Logo from '../../../logo.svg'

const Loading = ({ fullPage = false, initialLoad = false }) => {
    return (
        <div className={`${initialLoad ? styles.initialLoading : styles.loading} ${fullPage ? styles.fullPage : ''}`}>
            <img src={Logo} alt="Logo Oktto" />
            {initialLoad && <span>Carregando...</span>}
        </div>
    )
}

export default Loading