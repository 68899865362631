import React from "react";
import styles from "../form.module.css"
import Day from "./Day";

const Week = ({ week, handleSelectDates }) => {
    return (
        <div className={styles.week}>
            {week.map((day, index) => (
                <Day day={day} key={index} handleSelectDates={handleSelectDates} />
            ))}
        </div>
    )
}

export default Week