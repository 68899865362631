import React, { useEffect, useState } from "react"
import Table from "../../components/Table"
import styles from "./plans.module.css"
import ModalPlan from "../../components/Modal/ModalPlan"
import api from "../../services/api"
import Button from "../../components/Form/Button"
import Input from "../../components/Form/Input"
import ModalDelete from "../../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { toast } from "react-toastify"
import Loading from "../layouts/Loading"
import { formatCurrency } from "../../utils/formatter"

const Plans = () => {
    const [modalPlan, setModalPlan] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [plans, setPlans] = useState([])
    const [partnersAccounts, setPartnersAccounts] = useState([])
    const [plan, setPlan] = useState({})
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [planIdDelete, setPlanIdDelete] = useState('')
    const [loading, setLoading] = useState(false)

    let isLoadingPlan = false

    const headers = [
        "#",
        "Nome",
        "Valor",
        "Pagamento para Parceiro",
        "Status",
        ""
    ]

    useEffect(() => {
        getAllPlans()
        getAllPartners()
    }, [])

    const getAllPlans = async () => {
        try {
            if (!isLoadingPlan) {
                isLoadingPlan = true
                setLoading(true)
                const response = await api.get("/plans")
                const listPlans = response.data?.data
                setPlans(listPlans)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
            isLoadingPlan = false
        }
    }

    const getAllPartners = () => {
        api.get(`/accounts/allPartners`).then(response => {
            const partners = response.data?.data || []
            setPartnersAccounts(partners?.map(partner => { return { id: partner._id, value: partner.name } }))
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getPlanById = async (id) => {
        try {
            setLoading(true)
            const response = await api.get(`/plans/${id}`)
            const plan = response.data?.data
            if (plan) {
                plan.plan_id = plan._id
            }
            setPlan(plan)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const openModalPlan = async (isNew = false, edit = false, id = undefined) => {
        setEdit(edit)
        setIsNew(isNew)
        setPlan({})
        if (!isNew && id) {
            await getPlanById(id)
        }
        setModalPlan(true)
    }

    const closeModalPlan = () => {
        setModalPlan(false)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const handleSavePlan = (e, isNew, plan) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewPlan(plan)
        } else {
            handleUpdatePlan(plan)
        }
    }

    const handleSaveNewPlan = (plan) => {
        setLoading(true)
        api.post("/plans", plan).then(response => {
            const newPlan = response.data?.data
            if (newPlan) {
                setPlans(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(
                        newPlan
                    )

                    return newValue
                })
            }
            toast.success(response.data.message)
            closeModalPlan()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getDataNewPlan = (newPlan) => {
        return {
            id: newPlan._id,
            name: newPlan.name,
            value: [
                newPlan?.name || "-",
                formatCurrency(newPlan?.value ?? 0),
                newPlan?.payment_by_partner ? "Sim" : "Não",
                +newPlan?.is_active ? "Ativo" : "Inativo",
            ],
            actions: [
                {
                    label: "Editar",
                    onClick: (id) => openModalPlan(false, true, id)
                },
                {
                    label: "Excluir",
                    onClick: handleDeletePlan
                }
            ]
        }
    }

    const handleUpdatePlan = (plan) => {
        setLoading(true)
        api.patch(`/plans/${plan._id}`, plan).then(response => {
            setPlans(previousValue => {
                return previousValue?.map(lastPlan => {
                    if (lastPlan._id === plan._id) {
                        lastPlan = plan
                    }
                    return lastPlan
                })
            })
            toast.success(response.data.message)
            setEdit(false)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleDeletePlan = (id) => {
        setPlanIdDelete(id)
        setModalDelete(true)
    }

    const deletePlan = (id) => {
        setLoading(true)
        api.delete(`/plans/${id}`).then(response => {
            setPlans(previousValue => {
                return previousValue?.filter(lastPlan => lastPlan._id !== id)
            })
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getFilteredValues = () => {
        const values = plans
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map((plan) => getDataNewPlan(plan))
        return textSearch && filteredValues ? filteredValues?.filter(row => {
            for (let i = 0; i < row.value.length; i++) {
                if (row?.value[i] && row.value[i]?.toString()?.toLowerCase().indexOf(textSearch?.toLowerCase()) !== -1) {
                    return true
                }
            }
            return false
        }) : filteredValues
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Button className="bg-pink" onClick={() => openModalPlan(true, true)}>Adicionar Plano</Button>
                <Input type="search" placeholder="Buscar..." value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhum plano encontrado"
                onClick={(id) => openModalPlan(false, false, id)}
                showIndex={true}
                showCount={true}
                count={getFilteredValues()?.length}
            />
            {modalPlan &&
                <ModalPlan
                    closeModal={closeModalPlan}
                    onSubmit={handleSavePlan}
                    isNew={isNew}
                    setEdit={setEdit}
                    value={plan}
                    partnersAccounts={partnersAccounts}
                    editable={edit}
                />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deletePlan}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de equipe"
                    description="Deseja realmente excluir a equipe?"
                    id={planIdDelete}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Plans