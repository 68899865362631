import React, { useEffect, useState } from "react"
import styles from "./dashboard.module.css"
import Loading from "../layouts/Loading"
import Card from "./Card"
import { AiFillDashboard } from "react-icons/ai"
import Table from "../../components/Table"
import api from './../../services/api'
import { toast } from 'react-toastify'
import BreakForm from './../../components/Form/BreakForm/index'

const DashboardPartner = () => {
    const [values, setValues] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getDataByDashboard()
    }, [])

    const getDataByDashboard = () => {
        setLoading(true)
        api.get("/partners/dashboard").then(response => {
            setValues(response.data?.data)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <div className={styles.cards}>
                    {values?.cards?.length > 0 && values.cards.map(card => (
                        <Card
                            key={card.key}
                            icon={<AiFillDashboard />}
                            name={card.name}
                            value={card.value}
                            variation={card.variation}
                            is_positive={card.is_positive}
                        />
                    ))}
                    <div className={styles.tables}>
                        {values?.tables?.length > 0 && values.tables.map((table, index) => (
                            <div className={`${styles.groupTable} ${styles.fullWidth}`} key={index}>
                                <BreakForm title={table.title} showAction={false} />
                                <Table
                                    headers={table.headers}
                                    values={table.values}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default DashboardPartner