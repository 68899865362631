import React, { useEffect, useState } from "react"
import Table from "../../components/Table"
import styles from "./roles.module.css"
import ModalRole from "../../components/Modal/ModalRole"
import api from "../../services/api"
import Button from "../../components/Form/Button"
import Input from "../../components/Form/Input"
import ModalDelete from "../../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { toast } from "react-toastify"
import Loading from "../layouts/Loading"

const Roles = () => {
    const [modalRole, setModalRole] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [headers, setHeaders] = useState([])
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState({})
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [roleIdDelete, setRoleIdDelete] = useState('')
    const [loading, setLoading] = useState(false)

    let isLoadingRole = false

    const initialHeaders = [
        "#",
        "Nome",
        "Usuários",
        "Rodízio",
        ""
    ]

    useEffect(() => {
        setHeaders(initialHeaders)
        getAllRoles()
    }, [])

    const getAllRoles = async () => {
        try {
            if (!isLoadingRole) {
                isLoadingRole = true
                setLoading(true)
                const response = await api.get("/roles")
                const listRoles = response.data?.data
                setRoles(listRoles)
                setLoading(false)
                isLoadingRole = false
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
            setLoading(false)
            isLoadingRole = false
        }
    }

    const getRoleById = async (id) => {
        try {
            setLoading(true)
            const response = await api.get(`/roles/${id}`)
            const role = response.data?.data
            if (role) {
                role.role_id = role._id
            }
            setRole(role)
            setModalRole(true)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const openModalRole = async (isNew = false, edit = false, id = undefined) => {
        setEdit(edit)
        setIsNew(isNew)
        setRole({})
        if (!isNew && id) {
            await getRoleById(id)
        }
        setModalRole(true)
    }

    const closeModalRole = () => {
        setModalRole(false)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const handleSaveRole = (e, isNew, role) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewRole(role)
        } else {
            handleUpdateRole(role)
        }
    }

    const handleSaveNewRole = (role) => {
        setLoading(true)
        api.post("/roles", role).then(response => {
            const newRole = response.data?.data
            if (newRole) {
                setRoles(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(
                        newRole
                    )

                    return newValue
                })
            }
            toast.success(response.data.message)
            closeModalRole()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getDataNewRole = (newRole) => {
        return {
            id: newRole._id,
            name: newRole.name,
            value: [
                newRole.name,
                newRole.users ?? "0",
                newRole.rodizio ? "Sim" : "Não",
            ],
            actions: [
                {
                    label: "Editar",
                    onClick: (id) => openModalRole(false, true, id)
                },
                {
                    label: "Excluir",
                    onClick: handleDeleteRole
                }
            ]
        }
    }

    const handleUpdateRole = (role) => {
        setLoading(true)
        api.patch(`/roles/${role._id}`, role).then(response => {
            setRoles(previousValue => {
                return previousValue?.map(lastRole => {
                    if (lastRole._id === role._id) {
                        lastRole = { ...lastRole, name: role.name, rodizio: !!Number(role.rodizio) }
                    }
                    return lastRole
                })
            })
            toast.success(response.data.message)
            closeModalRole()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleDeleteRole = (id) => {
        setRoleIdDelete(id)
        setModalDelete(true)
    }

    const deleteRole = (id) => {
        setLoading(true)
        api.delete(`/roles/${id}`).then(response => {
            setRoles(previousValue => {
                return previousValue?.filter(lastRole => lastRole._id !== id)
            })
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getFilteredValues = () => {
        const values = roles
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map((role) => getDataNewRole(role))
        return textSearch && filteredValues ? filteredValues?.filter(row => {
            for (let i = 0; i < row.value.length; i++) {
                if (row?.value[i] && `${row?.value[i]}`?.toLowerCase().indexOf(`${textSearch}`?.toLowerCase()) !== -1) {
                    return true
                }
            }
            return false
        }) : filteredValues
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Button className="bg-pink" onClick={() => openModalRole(true, true)}>Adicionar Cargo</Button>
                <Input type="search" placeholder="Buscar..." value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhum cargo encontrado"
                onClick={(id) => openModalRole(false, false, id)}
                showIndex={true}
                showCount={true}
                count={getFilteredValues()?.length}
            />
            {modalRole &&
                <ModalRole
                    closeModal={closeModalRole}
                    onSubmit={handleSaveRole}
                    isNew={isNew}
                    setEdit={setEdit}
                    value={role}
                    editable={edit}
                />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deleteRole}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de cargo"
                    description="Deseja realmente excluir o cargo?"
                    id={roleIdDelete}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Roles