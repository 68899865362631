import React, { useEffect, useState } from 'react'
import styles from "./location.module.css"

const Location = ({ latitude, longitude, quotedMessage = false }) => {
    const [staticMapUrl, setStaticMapUrl] = useState("")
    const [googleMapsLink, setGoogleMapsLink] = useState("")

    useEffect(() => {
        setStaticMapUrl(`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=600x400&markers=color:red%7C${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
        setGoogleMapsLink(`https://www.google.com/maps?q=${latitude},${longitude}`)
    }, [latitude, longitude])

    return (
        quotedMessage ?
            <div className={styles.link}>
                <img className={styles.link} src={staticMapUrl} alt="Google Maps" />
            </div>
            :
            <a className={styles.link} href={googleMapsLink} target="_blank" rel="noreferrer">
                <img className={styles.link} src={staticMapUrl} alt="Google Maps" />
            </a>
    )
}

export default Location
