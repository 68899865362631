import React, { useEffect, useState } from "react"
import BreakForm from "../../Form/BreakForm"
import styles from "./sales.module.css"
import CardSale from "./CardSale"
import { toast } from "react-toastify"
import moment from "moment"
import api from '../../../services/api'
import DatePicker from '../../Form/DatePicker/index'
import Select from '../../Form/Select'
import { useWebSocketContext } from "../../../contexts/webSocketContext"
import Loading from './../../../pages/layouts/Loading/index'
import CircleLoading from "../../CircleLoading"

const Sales = ({ lead_id }) => {
    const [sales, setSales] = useState([])
    const [filters, setFilters] = useState({})
    const [usersFilters, setUsersFilters] = useState([])
    const [updateFiltersSeleted, setUpdateFiltersSeleted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)

    const { lastJsonMessage } = useWebSocketContext()

    useEffect(() => {
        getAllSales()
    }, [])

    useEffect(() => {
        if (sales?.length > 0) {
            updateUsersFilters()
        }
    }, [sales])

    useEffect(() => {
        if (updateFiltersSeleted) {
            setFilters(previousValue => {
                const usersSelected = usersFilters?.map(user => { return user.id })
                return { ...previousValue, users: usersSelected }
            })
            setUpdateFiltersSeleted(false)
        }
    }, [usersFilters])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "ADD_SALE_LEAD") {
            const leadId = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (leadId === lead_id) {
                setSales(previousValue => {
                    return [...previousValue, dataUpdate]
                })
            }
        } else if (action === "DELETE_SALE_LEAD") {
            const leadId = message?.data?.lead_id
            const saleId = message?.data?.sale_id
            if (leadId === lead_id) {
                setSales(previousValue => {
                    const newSales = previousValue?.filter(sale => sale._id !== saleId)
                    return newSales
                })
            }
        }
    }

    const getAllSales = () => {
        setInitialLoading(true)
        api.get(`/leads/${lead_id}/sales`).then(response => {
            const sales = response?.data?.data
            setSales(sales || [])
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setInitialLoading(false))
    }

    const updateUsersFilters = () => {
        if (!filters?.users?.length || usersFilters.length === filters?.users?.length) {
            setUpdateFiltersSeleted(true)
        }
        const users = []
        sales?.forEach(sale => {
            const [userExists] = users?.filter(user => user.id === sale.user_id)
            if (!userExists) {
                users.push({
                    id: sale.user_id, value: sale.user_created
                })
            }
        })
        users.sort((a, b) => {
            if (a.value > b.value) {
                return 1
            } else if (a.value < b.value) {
                return -1
            }
            return 0
        })
        setUsersFilters(users)
    }

    const orderSales = (a, b) => {
        if (moment(a.created_at).unix() < moment(b.created_at).unix()) {
            return 1
        } else if (moment(a.created_at).unix() > moment(b.created_at).unix()) {
            return -1
        }
        return 0
    }

    const filterSales = (sale) => {
        const dateSale = moment(sale.created_at).format("YYYY-MM-DD")
        const validPeriod = (!filters.dateStart || moment(filters.dateStart).isSameOrBefore(dateSale)) && (!filters.dateEnd || moment(filters.dateEnd).isSameOrAfter(dateSale))
        const validUsers = !filters.users || filters.users.indexOf(sale.user_id) !== -1
        return validPeriod && validUsers
    }

    const getFilteredSales = () => {
        const listSales = sales?.filter(filterSales)
        return listSales
    }

    const onChangeFilters = (name, value) => {
        if (name === "period") {
            setFilters(previousValue => {
                return { ...previousValue, dateStart: value.dateStart, dateEnd: value.dateEnd }
            })
        } else {
            setFilters(previousValue => {
                return { ...previousValue, [name]: value }
            })
        }
    }

    const handleDeleteSale = (id) => {
        setLoading(true)
        api.delete(`/leads/${lead_id}/sales/${id}`).then(response => {
            setSales(previousValue => {
                return previousValue?.filter(lastTeam => lastTeam._id !== id)
            })
            toast.success(response.data.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    return (
        <div className={styles.sales}>
            <div className={styles.historySales}>
                <BreakForm title="Histórico de vendas" showAction={false} />
                {sales?.length > 0 &&
                    <div className={styles.filters}>
                        <DatePicker name="Período" dateStart={filters.dateStart} dateEnd={filters.dateEnd} onChange={(dateStart, dateEnd) => onChangeFilters("period", { dateStart, dateEnd })} multiple={true} />
                        <Select name="Responsável" placeholder="Selecione o responsável" selected={filters.users || []} options={usersFilters} onChange={(value) => onChangeFilters("users", value)} multiple={true} />
                    </div>
                }
                <div className={styles.body}>
                    {sales?.length > 0 && getFilteredSales().sort(orderSales).map(el => (
                        <CardSale
                            key={el._id}
                            value={el}
                            onDelete={handleDeleteSale}
                        />
                    ))}
                    {!initialLoading && !sales?.length && <span>Nenhuma venda registrada até o momento!</span>}
                    {!initialLoading && (sales?.length > 0 && !getFilteredSales().length > 0) && <span>Nenhum resultado para sua busca!</span>}
                    {initialLoading && <CircleLoading />}
                </div>
            </div>
            {loading && <Loading fullPage={true} />}
        </div >
    )
}

export default Sales