import React from "react"
import { BiLoaderAlt } from "react-icons/bi"

const CircleLoading = ({ className }) => {
    return (
        <div className={`loading ${className}`}>
            <BiLoaderAlt />
        </div>
    )
}

export default CircleLoading