import React, { useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import Select from './../../Form/Select'
import FormGroupInline from '../../Form/FormGroupInline'
import { MdOutlineWhatsapp } from "react-icons/md"

const listoptions = [
    { id: 1, value: "Sim" },
    { id: 0, value: "Não" },
]

const ModalNewWhatsAppInbox = ({ teams = [], users = [], onSubmit = () => { }, closeModal = () => { } }) => {
    const [inbox, setInbox] = useState({})

    const handleChangeValue = (name, value) => {
        setInbox(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        onSubmit(inbox)
    }

    return (
        <Modal
            icon={<MdOutlineWhatsapp />}
            title={"Cadastro de nova caixa de entrada WhatsApp"}
            description={"Preencha os campos abaixo e clique em salvar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="Nome">
                    <Input type="text" placeholder="Nome da caixa de entrada" value={inbox?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                </FormGroup>
                {/* <FormGroup labelText="Sincronizar Histórico de Conversas">
                    <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={listoptions || []} selected={inbox?.sync_full_history ?? ""} onChange={(value) => handleChangeValue("sync_full_history", value)} multiple={false} />
                </FormGroup>
                <FormGroup labelText="Ignorar Grupos">
                    <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={listoptions || []} selected={inbox?.groups_ignore ?? ""} onChange={(value) => handleChangeValue("groups_ignore", value)} multiple={false} />
                </FormGroup> */}
                <FormGroup labelText="Equipes">
                    <Select name="Equipes" placeholder="Pesquise uma opção" options={teams || []} selected={inbox?.teams ?? ""} onChange={(value) => handleChangeValue("teams", value)} multiple={true} />
                </FormGroup>
                <FormGroup labelText="Usuários">
                    <Select name="Usuários" placeholder="Pesquise uma opção" options={users || []} selected={inbox?.users ?? ""} onChange={(value) => handleChangeValue("users", value)} multiple={true} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalNewWhatsAppInbox