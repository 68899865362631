import api from "../services/api"
import { toast } from "react-toastify"

const getAdditionalFields = async () => {
    try {
        const response = await api.get("/additionalFields")
        const fields = response?.data?.data?.filter(field => field?.is_visible)
        return fields
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getAdditionalFields }