import React from "react";
import FormGroup from "../../Form/FormGroup";
import Button from "../../Form/Button";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import { toast } from 'react-toastify';

const ConfigSend = ({ data, funnels = [], stages = [], teams = [], users = [], flags = [], origins = [], handleChangeValue = () => { }, handleChangeStep = () => { } }) => {
    const onChangeStep = () => {
        if (!data?.name) {
            return toast.error("O nome da importação é obrigatório!")
        }
        handleChangeStep(2)
    }

    return (
        <>
            <FormGroup labelText="Nome">
                <Input placeholder="Nome da base de leads" value={data?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
            </FormGroup>
            <FormGroup labelText="Funil">
                <Select multiple={false} name="Funil" placeholder="Selecione um funil" options={funnels || []} selected={data?.funnel_id ?? ""} onChange={(value) => handleChangeValue("funnel_id", value)} />
            </FormGroup>
            <FormGroup labelText="Etapa do Funil">
                <Select multiple={false} name="Etapa do Funil" placeholder="Selecione uma etapa do funil" options={stages || []} selected={data?.stage_id ?? ""} onChange={(value) => handleChangeValue("stage_id", value)} />
            </FormGroup>
            <FormGroup labelText="Origem">
                <Select multiple={false} name="Origem" placeholder="Selecione uma origem" options={origins || []} selected={data?.origin_id ?? ""} onChange={(value) => handleChangeValue("origin_id", value)} />
            </FormGroup>
            <FormGroup labelText="Equipe">
                <Select multiple={false} name="Equipe" placeholder="Selecione uma equipe" options={teams || []} selected={data?.team_id ?? ""} onChange={(value) => handleChangeValue("team_id", value)} />
            </FormGroup>
            <FormGroup labelText="Usuários">
                <Select multiple={true} name="Usuários" placeholder="Selecione um usuário" options={users || []} selected={data?.users ?? []} onChange={(value) => handleChangeValue("users", value)} />
            </FormGroup>
            <FormGroup labelText="Etiquetas">
                <Select multiple={true} name="Etiquetas" placeholder="Pesquise uma etiqueta" options={flags} selected={data?.flags || []} onChange={(value) => handleChangeValue("flags", value)} />
            </FormGroup>
            <FormGroup>
                <Button className='bg-pink' onClick={onChangeStep}>Avançar</Button>
            </FormGroup>
        </>
    )
}

export default ConfigSend