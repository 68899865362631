import React, { useEffect, useState } from "react"
import styles from "./importError.module.css"
import api from "../../services/api"
import { toast } from "react-toastify"
import Table from '../../components/Table/index'
import Loading from "../layouts/Loading"
import { NavLink, useParams } from "react-router-dom"

const ImportError = () => {
    const [errorsImports, setErrorsImports] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const { account_id, import_id } = useParams()

    const headers = [
        "#",
        "Identificação do Lead",
        "Mensagem de Erro",
    ]

    useEffect(() => {
        getAllErrorsImports()
    }, [])

    const getAllErrorsImports = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/imports/${import_id}/errors`)
            const imports = response.data?.data || []
            setErrorsImports(imports)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setIsLoading(false)
        }
    }

    const getDataNewImport = (newImport) => {
        if (newImport?.type === "LEADS") {
            return {
                id: newImport._id,
                value: [
                    newImport?.data?.lead?.name ?? "-",
                    newImport?.error ?? "-",
                ],
            }
        } else if (newImport?.type === "LEADS_ARRAY") {
            return {
                id: newImport._id,
                value: [
                    `Leads: ${newImport?.data?.leads?.length ?? "-"}`,
                    newImport?.error ?? "-",
                ],
            }
        }
    }

    const getFilteredValues = () => {
        const values = errorsImports
        const filteredValues = values?.map((newImport) => getDataNewImport(newImport))
        return filteredValues
    }

    return (
        <div className={styles.container}>
            <div className={styles.link}>
                <NavLink to={`/${account_id}/import/log`}>Acessar histórico de importações</NavLink>
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhum registro encontrado"
                showIndex={true}
            />
            {isLoading && <Loading fullPage={true} />}
        </div>
    )
}

export default ImportError