import React, { useEffect, useState } from "react";
import styles from "./configAdditionalFields.module.css"
import BreakForm from "../Form/BreakForm";
import { HiPencil, HiPlus } from "react-icons/hi";
import FormGroupInline from "../Form/FormGroupInline";
import Button from "../Form/Button";
import FormGroup from "../Form/FormGroup";
import Input from "../Form/Input";
import Select from './../Form/Select';
import { MdOutlineDelete } from "react-icons/md";
import { getOptionByOptionList } from "../../utils/utils"

const listTypes = [
    { id: "phone", value: "Celular / Telefone" },
    { id: "document", value: "CPF / CNPJ" },
    { id: "zipCode", value: "Código Postal" },
    { id: "email", value: "E-mail" },
    { id: "currency", value: "Moeda BRL (R$)" },
    { id: "date", value: "Data (dd/mm/YYYY)" },
    { id: "text", value: "Texto" },
]

const listStatus = [
    { id: true, value: "Ativo" },
    { id: false, value: "Inativo" },
]

const ConfigAdditionalFields = ({ editable, value = [{}], setEdit = () => { }, onSubmit = () => { }, onCancel = () => { } }) => {
    const [additionalFields, setAdditionalFields] = useState(value)

    useEffect(() => {
        setAdditionalFields(value)
    }, [value])

    useEffect(() => {
        if (editable && additionalFields?.length === 0) {
            handleAddField()
        }
    }, [editable])

    const handleChangeValue = (key, value, index) => {
        setAdditionalFields(previusValue => {
            const newValue = [...previusValue]
            newValue[index] = { ...newValue[index], [key]: value }
            return newValue
        })
    }

    const handleAddField = (e) => {
        e?.preventDefault()
        setAdditionalFields(previusValue => {
            const newValue = [...previusValue]
            newValue.push({ is_visible: true })
            return newValue
        })
    }

    const handleRemoveField = (e, index) => {
        e.preventDefault()
        setAdditionalFields(previusValue => {
            const newValue = [...previusValue]
            newValue.splice(index, 1)
            return newValue
        })
    }

    const handleCancelChanges = (e) => {
        setAdditionalFields(value)
        onCancel(e)
    }

    return (
        <form onSubmit={(e) => onSubmit(e, additionalFields)}>
            <BreakForm
                title="Campos Adicionais"
                iconAction={editable ? <HiPlus /> : <HiPencil />}
                styleAction={editable ? "bg-pink" : "bg-purple"}
                handleAction={editable ? handleAddField : setEdit}
            />
            <div className={styles.fields}>
                {additionalFields?.length > 0 && additionalFields.map((field, index) => (
                    <div className={styles.groupField} key={index}>
                        {(editable && !field._id) &&
                            <div className={styles.removeBtn}>
                                <Button className="bg-purple action" onClick={(e) => handleRemoveField(e, index)} >
                                    <MdOutlineDelete />
                                </Button>
                            </div>
                        }
                        <FormGroup labelText="Título do campo">
                            {editable ?
                                <Input type="text" placeholder="Nome do campo" value={field?.name || ""} onChange={(e) => handleChangeValue("name", e.target.value, index)} />
                                :
                                <span>{field?.name || "-"}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Tipo do campo">
                            {editable ?
                                <Select name="Tipo do campo" placeholder="Pesquise um tipo" multiple={false} options={listTypes || []} selected={field?.type ?? ""} onChange={(value) => handleChangeValue("type", value, index)} />
                                :
                                <span>{getOptionByOptionList(field?.type || "", listTypes)}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Status do campo">
                            {editable ?
                                <Select name="Status do campo" placeholder="Pesquise um status" multiple={false} options={listStatus || []} selected={field?.is_visible ?? ""} onChange={(value) => handleChangeValue("is_visible", value, index)} />
                                :
                                <span>{getOptionByOptionList(field?.is_visible || false, listStatus)}</span>
                            }
                        </FormGroup>
                    </div>
                ))}
                {(additionalFields?.length === 0 && !editable) &&
                    <span>Nenhum campo adicional cadastrado até o momento!</span>
                }
            </div>
            {editable &&
                <div className={styles.footer}>
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancelChanges}>Cancelar</Button>
                    </FormGroupInline>
                </div>
            }
        </form>
    )
}

export default ConfigAdditionalFields