import React, { useEffect, useState } from "react"
import Table from "../../components/Table"
import styles from "./teams.module.css"
import ModalTeam from "../../components/Modal/ModalTeam"
import api from "../../services/api"
import Button from "../../components/Form/Button"
import Input from "../../components/Form/Input"
import ModalDelete from "../../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { toast } from "react-toastify"
import Loading from "../layouts/Loading"

const Teams = () => {
    const [modalTeam, setModalTeam] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [teams, setTeams] = useState([])
    const [team, setTeam] = useState({})
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [teamIdDelete, setTeamIdDelete] = useState('')
    const [loading, setLoading] = useState(false)

    let isLoadingTeam = false

    const headers = [
        "#",
        "Nome",
        "Páginas",
        "Leads",
        "Usuários",
        ""
    ]

    useEffect(() => {
        getAllTeams()
    }, [])

    const getAllTeams = async () => {
        try {
            if (!isLoadingTeam) {
                isLoadingTeam = true
                setLoading(true)
                const response = await api.get("/teams/all")
                const listTeams = response.data?.data
                setTeams(listTeams)
                setLoading(false)
                isLoadingTeam = false
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
            setLoading(false)
            isLoadingTeam = false
        }
    }

    const getTeamById = async (id) => {
        try {
            setLoading(true)
            const response = await api.get(`/teams/${id}`)
            const team = response.data?.data
            if (team) {
                team.team_id = team._id
            }
            setTeam(team)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }

    const openModalTeam = async (isNew = false, edit = false, id = undefined) => {
        setEdit(edit)
        setIsNew(isNew)
        setTeam({})
        if (!isNew && id) {
            await getTeamById(id)
        }
        setModalTeam(true)
    }

    const closeModalTeam = () => {
        setModalTeam(false)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const handleSaveTeam = (e, isNew, team) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewTeam(team)
        } else {
            handleUpdateTeam(team)
        }
    }

    const handleSaveNewTeam = (team) => {
        setLoading(true)
        api.post("/teams", team).then(response => {
            const newTeam = response.data?.data
            if (newTeam) {
                setTeams(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(
                        newTeam
                    )

                    return newValue
                })
            }
            toast.success(response.data.message)
            closeModalTeam()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getDataNewTeam = (newTeam) => {
        return {
            id: newTeam._id,
            name: newTeam.name,
            value: [
                newTeam.name,
                newTeam.pages ?? "0",
                newTeam.leads ?? "0",
                newTeam.users?.length ?? "0",
            ],
            actions: [
                {
                    label: "Editar",
                    onClick: (id) => openModalTeam(false, true, id)
                },
                {
                    label: "Excluir",
                    onClick: handleDeleteTeam
                }
            ]
        }
    }

    const handleUpdateTeam = (team) => {
        setLoading(true)
        api.patch(`/teams/${team._id}`, team).then(response => {
            setTeams(previousValue => {
                return previousValue?.map(lastTeam => {
                    if (lastTeam._id === team._id) {
                        lastTeam = team
                    }
                    return lastTeam
                })
            })
            toast.success(response.data.message)
            closeModalTeam()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleDeleteTeam = (id) => {
        setTeamIdDelete(id)
        setModalDelete(true)
    }

    const deleteTeam = (id) => {
        setLoading(true)
        api.delete(`/teams/${id}`).then(response => {
            setTeams(previousValue => {
                return previousValue?.filter(lastTeam => lastTeam._id !== id)
            })
            toast.success(response.data.message)
            closeModalDelete()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getFilteredValues = () => {
        const values = teams
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map((team) => getDataNewTeam(team))
        return textSearch && filteredValues ? filteredValues?.filter(row => {
            for (let i = 0; i < row.value.length; i++) {
                if (row?.value[i] && row.value[i]?.toString()?.toLowerCase().indexOf(textSearch?.toLowerCase()) !== -1) {
                    return true
                }
            }
            return false
        }) : filteredValues
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <Button className="bg-pink" onClick={() => openModalTeam(true, true)}>Adicionar Equipe</Button>
                <Input type="search" placeholder="Buscar..." value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhuma equipe encontrada"
                onClick={(id) => openModalTeam(false, false, id)}
                showIndex={true}
                showCount={true}
                count={getFilteredValues()?.length}
            />
            {modalTeam &&
                <ModalTeam
                    closeModal={closeModalTeam}
                    onSubmit={handleSaveTeam}
                    isNew={isNew}
                    setEdit={setEdit}
                    value={team}
                    editable={edit}
                />
            }
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={deleteTeam}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de equipe"
                    description="Deseja realmente excluir a equipe?"
                    id={teamIdDelete}
                />
            }
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default Teams