import React from "react";
import styles from "./groupDataEdit.module.css"
import { MdOutlineCheck, MdOutlineClose, MdOutlineEdit } from "react-icons/md";

const GroupDataEdit = ({ name, value, edit = false, borderTop = true, onChangeEdit = () => { }, onCancelEdit = () => { }, onUpdate = () => { }, showActions = true, children }) => {
    return (
        <div className={`${styles.groupItem} ${borderTop ? styles.borderTop : ''}`}>
            {showActions &&
                <div className={styles.actions}>
                    {edit ?
                        <>
                            <MdOutlineCheck onClick={() => onUpdate(name, { [name]: value })} />
                            <MdOutlineClose onClick={() => onCancelEdit(name)} />
                        </>
                        :
                        <MdOutlineEdit onClick={() => onChangeEdit(name, true)} />
                    }

                </div>
            }
            {children}
        </div>
    )
}

export default GroupDataEdit