import React, { useEffect, useState } from 'react'
import styles from "./myAccount.module.css"
import api from './../../services/api'
import { FaPencilAlt } from 'react-icons/fa'
import { HiOutlineClipboardCheck } from 'react-icons/hi'
import { MdAccountBalance } from 'react-icons/md'
import { TbFileDollar } from "react-icons/tb"
import { TfiReload } from "react-icons/tfi"
import Loading from '../layouts/Loading/index'
import FormGroup from '../../components/Form/FormGroup'
import { maskDocument, maskZipCode } from '../../utils/formatter'
import BreakForm from '../../components/Form/BreakForm'
import FormGroupInline from '../../components/Form/FormGroupInline'
import { getOptionByOptionList, getPropertyForItemInArrayById } from '../../utils/utils'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import ModalNewRecharge from '../../components/Modal/ModalNewRecharge'
import ModalConfirm from "../../components/Modal/ModalConfirm"
import Button from '../../components/Form/Button'
import Select from '../../components/Form/Select'

const listTypes = [
    { id: "SUBSCRIPTION", value: "Mensalidade" },
    { id: "VOIP", value: "Créditos VOIP" },
]

const listStatus = [
    { id: "PENDING", value: "Aguardando Emissão" },
    { id: "OPENED", value: "Em Aberto" },
    { id: "APPROVED", value: "Aprovado" },
    { id: "CANCELED", value: "Cancelado" },
]

const optionsAutomaticRenovation = [
    { id: '1', value: "Ativada" },
    { id: '0', value: "Desativada" }
]

const listMethod = [
    { id: "BANK_SLIP", value: "Boleto Bancário" },
    // { id: "CREDIT_CARD", value: "Cartão de Crédito" },
    // { id: "DEBIT_CARD", value: "Cartão de Débito" },
    // { id: "PIX", value: "PIX" },
]

const MyAccount = () => {
    const [account, setAccount] = useState([])
    const [transactions, setTransactions] = useState([])
    const [modalNewRecharge, setModalNewRecharge] = useState(false)
    const [dataPlan, setDataPlan] = useState({})
    const [editPlan, setEditPlan] = useState(false)
    const [modalChangePlan, setModalChangePlan] = useState(false)
    const [titleChangePlan, setTitleChangePlan] = useState('')
    const [descriptionChangePlan, setDescriptionChangePlan] = useState('')
    const [textChangePlan, setTextChangePlan] = useState('')
    const [loading, setLoading] = useState(false)

    const { account_id } = useParams()

    useEffect(() => {
        if (account_id) {
            getDetailsByAccount()
            getTransactionsByAccount()
        }
    }, [account_id])

    useEffect(() => {
        setDataPlan(account?.plan || {})
    }, [account])

    const getDetailsByAccount = async () => {
        try {
            setLoading(true)
            const response = await api.get(`/accounts/${account_id}/details`)
            setAccount(response.data?.data || {})
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const getTransactionsByAccount = async () => {
        try {
            setLoading(true)
            const response = await api.get(`/accounts/${account_id}/transactions/details`)
            setTransactions(response.data?.data || [])
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleCreateNewRecharge = async (transaction) => {
        try {
            setLoading(true)
            const response = await api.post(`/accounts/${account_id}/transactions/recharges`, transaction)
            const newTransaction = response.data?.data
            setTransactions(previousValue => {
                const newValue = [...previousValue]
                const transactionExists = newValue.map(transaction => transaction._id).includes(newTransaction._id)
                if (!transactionExists) {
                    newValue.unshift(newTransaction)
                }
                return newValue
            })
            closeModalNewRecharge()
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const openModalNewRecharge = () => {
        setModalNewRecharge(true)
    }

    const closeModalNewRecharge = () => {
        setModalNewRecharge(false)
    }

    const handleChangePlan = (name, value) => {
        setDataPlan(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleCancelEditPlan = () => {
        setDataPlan(account?.plan || {})
        setEditPlan(false)
    }

    const handleConfirmChangePlan = () => {
        const new_automatic_renovation = Number(dataPlan?.automatic_renovation)
        if (account?.plan?.automatic_renovation !== new_automatic_renovation) {
            let title, description, text = ""
            if (new_automatic_renovation) {
                title = "Ativação de renovação automática"
                description = "Deseja realmente ativar a renovação automática?"
                text = "Ao ativar a renovação automática, as cobranças passarão a ser emitidas automaticamente para que a licença seja renovada!"
            } else {
                title = "Desativação da renovação automática"
                description = "Deseja realmente desativar a renovação automática?"
                text = "Ao desativar a renovação automática, as cobranças não serão mais emitidas automaticamente!"
                if (!account?.plan?.payment_by_partner) {
                    text += ` Sua licença permanecerá ativa até ${account?.plan?.expires_in || "-"}`
                }
            }
            setTitleChangePlan(title)
            setDescriptionChangePlan(description)
            setTextChangePlan(text)
            setModalChangePlan(true)
        } else {
            handleCancelEditPlan()
        }
    }

    const handleUpdatePlan = () => {
        setLoading(true)
        api.patch(`/accounts/${account_id}/plan`, dataPlan).then(response => {
            setAccount(previousValue => {
                return { ...previousValue, plan: dataPlan }
            })
            handleCloseModalPlan()
            handleCancelEditPlan()
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleCloseModalPlan = () => {
        setModalChangePlan(false)
    }

    return (
        <>
            <div className={styles.content} >
                <div className={styles.card}>
                    <div className={styles.header}>
                        <MdAccountBalance />
                        <span>Minha Conta</span>
                    </div>
                    <div className={styles.body}>
                        <BreakForm title="Dados da Conta" showAction={false} />
                        <FormGroup labelText="Empresa">
                            <FormGroupInline>
                                <FormGroup labelText="CNPJ/CPF">
                                    <span>{maskDocument(account?.company?.document) || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Razão social">
                                    <span>{account?.company?.business_name || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                            <FormGroupInline>
                                <FormGroup labelText="Nome fantasia">
                                    <span>{account?.company?.fantasy_name || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="E-mail do responsável">
                                    <span>{account?.email_owner || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                        </FormGroup>
                        <FormGroup labelText="Endereço">
                            <FormGroupInline>
                                <FormGroup labelText="CEP">
                                    <span>{maskZipCode(account?.address?.zip_code) || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="País">
                                    <span>{account?.address?.country || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                            <FormGroupInline>
                                <FormGroup labelText="Estado">
                                    <span>{account?.address?.uf || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Cidade">
                                    <span>{account?.address?.city || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                            <FormGroupInline>
                                <FormGroup labelText="Bairro">
                                    <span>{account?.address?.district || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Logradouro">
                                    <span>{account?.address?.road || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                            <FormGroupInline>
                                <FormGroup labelText="Número">
                                    <span>{account?.address?.number || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Complemento">
                                    <span>{account?.address?.complement || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                        </FormGroup>
                        <BreakForm title="Plano" showAction={!account?.plan?.payment_by_partner && !editPlan} iconAction={<FaPencilAlt />} styleAction={`bg-purple`} handleAction={() => setEditPlan(true)} />
                        <FormGroup>
                            <FormGroupInline>
                                <FormGroup labelText="Licença">
                                    <span>{account?.plan?.name || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Expira em">
                                    <span>{account?.plan?.expires_in || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                            <FormGroupInline>
                                <FormGroup labelText="Valor">
                                    <span>{account?.plan?.value || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Renovação automática">
                                    {editPlan ?
                                        <Select multiple={false} name="Selecione uma opção..." placeholder="Buscar..." options={optionsAutomaticRenovation || []} onChange={(value) => handleChangePlan("automatic_renovation", value)} selected={dataPlan?.automatic_renovation?.toString() || ''} />
                                        :
                                        <span>{getOptionByOptionList(account?.plan?.automatic_renovation?.toString() || '', optionsAutomaticRenovation) || '-'}</span>
                                    }
                                </FormGroup>
                            </FormGroupInline>
                            {editPlan &&
                                <FormGroupInline>
                                    <Button className='bg-pink' onClick={handleConfirmChangePlan}>Salvar</Button>
                                    <Button className='bg-purple' onClick={handleCancelEditPlan}>Cancelar</Button>
                                </FormGroupInline>
                            }
                        </FormGroup>
                        <BreakForm title="Consumo" showAction={false} />
                        <FormGroup>
                            <FormGroupInline>
                                <FormGroup labelText="Usuários">
                                    <span>{account?.plan?.usage_users || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Leads">
                                    <span>{account?.plan?.usage_leads || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                            <FormGroupInline>
                                <FormGroup labelText="Armazenamento">
                                    <span>{account?.plan?.usage_storage || "-"}</span>
                                </FormGroup>
                            </FormGroupInline>
                        </FormGroup>
                        <BreakForm title="Módulos Contratados" showAction={false} />
                        <FormGroup>
                            {account?.modules && account.modules.reduce((groups, module, index) => {
                                const groupIndex = Math.floor(index / 2)
                                if (!groups[groupIndex]) {
                                    groups[groupIndex] = []
                                }
                                groups[groupIndex].push(module)
                                return groups
                            }, []).map((group, groupIndex) => (
                                <FormGroupInline key={groupIndex}>
                                    {group.map((module, index) => (
                                        <FormGroup key={index} labelText={module.name}>
                                            <span>{module.is_active ? "Sim" : "Não"}</span>
                                        </FormGroup>
                                    ))}
                                </FormGroupInline>
                            ))}
                        </FormGroup>
                        <BreakForm title="Histórico de Faturas" showAction={true} iconAction="Nova Recarga" styleAction="bg-pink fit-content" handleAction={() => openModalNewRecharge()} />
                        {transactions.length > 0 && transactions.map((transaction, index) => (
                            <div className={styles.groupTransaction} key={index}>
                                <div className={`${styles.left} ${transaction?.status === "APPROVED" ? styles.approved : ''}`}>
                                    <span>{getPropertyForItemInArrayById(transaction?.type, listTypes, "id", "value") || '-'}</span>
                                    <span>{transaction?.due_date || '-'}</span>
                                </div>
                                <div className={`${styles.center} ${transaction?.status === "APPROVED" ? styles.approved : ''}`}>
                                    <span>{getPropertyForItemInArrayById(transaction?.status, listStatus, "id", "value") || '-'}</span>
                                </div>
                                <div className={styles.center}>
                                    <span>{getPropertyForItemInArrayById(transaction?.method, listMethod, "id", "value") || '-'}</span>
                                    <span>{transaction?.value || '-'}</span>
                                </div>
                                <div className={styles.right}>
                                    {transaction?.link_charge &&
                                        <a href={transaction.link_charge} target="_blank" rel="noreferrer">
                                            <TbFileDollar />
                                        </a>
                                    }
                                    {transaction?.link_invoice &&
                                        <a href={transaction.link_invoice} target="_blank" rel="noreferrer">
                                            <HiOutlineClipboardCheck />
                                        </a>
                                    }
                                </div>
                            </div>
                        ))}
                        {transactions.length === 0 && <span>Nenhuma fatura encontrada!</span>}
                        {modalNewRecharge &&
                            <ModalNewRecharge
                                onSubmit={handleCreateNewRecharge}
                                closeModal={closeModalNewRecharge}
                            />
                        }
                    </div>
                </div>
            </div>
            {!account?.plan?.payment_by_partner && modalChangePlan &&
                <ModalConfirm
                    closeModal={handleCloseModalPlan}
                    onSubmit={handleUpdatePlan}
                    icon={<TfiReload />}
                    title={titleChangePlan}
                    description={descriptionChangePlan}
                    text={textChangePlan}
                />
            }
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default MyAccount