import React, { useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { FiUsers } from "react-icons/fi";
import FormGroupInline from '../Form/FormGroupInline';
import { FaPencilAlt } from "react-icons/fa";

const ModalTeam = ({ isNew = false, value = {}, editable = false, setEdit, onSubmit, closeModal }) => {
    const [team, setTeam] = useState(value)

    const handleChangeValue = (name, value) => {
        setTeam(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setTeam(value)
        }
    }

    return (
        <Modal
            icon={<FiUsers />}
            title={isNew ? "Cadastro de nova equipe" : (editable ? `Editando a equipe: ${team.name}` : `Visualizando: ${team.name}`)}
            description={isNew ? "Adicione uma nova equipe a sua conta" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, team)}>
                <FormGroupInline>
                    <FormGroup labelText="Nome">
                        {editable ?
                            <Input type="text" placeholder="Nome da equipe" value={team.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} invalid={true} textInvalid="Preencha o nome da equipe" />
                            :
                            <span>{team.name}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalTeam