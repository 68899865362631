import React, { useState } from "react"
import Modal from './index'
import Button from "../Form/Button"
import BreakForm from './../Form/BreakForm/index'
import FormGroup from "../Form/FormGroup"
import Input from '../Form/Input'
import Select from './../Form/Select'
import FormGroupInline from '../Form/FormGroupInline'
import { FaPencilAlt } from "react-icons/fa"
import { BiPackage } from "react-icons/bi"
import { getOptionByOptionList } from "../../utils/utils"
import { formatCurrency, formatDataSize, formatFloatToPercent } from "../../utils/formatter"

const ModalPlan = ({ isNew = false, value = {}, partnersAccounts = [], editable = false, setEdit, onSubmit, closeModal }) => {
    const [plan, setPlan] = useState(value)

    const optionsStatus = [
        { id: 1, value: "Ativo" },
        { id: 0, value: "Inativo" },
    ]

    const optionsPayment = [
        { id: 1, value: "Sim" },
        { id: 0, value: "Não" },
    ]

    const handleChangeValue = (name, value) => {
        setPlan(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleChangeLimits = (name, value) => {
        const newValue = { ...plan?.limits, [name]: value }
        handleChangeValue("limits", newValue)
    }

    const handleChangeAdditionalCost = (name, value) => {
        const newValue = { ...plan?.additional_cost, [name]: value }
        handleChangeValue("additional_cost", newValue)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setPlan(value)
        }
    }

    return (
        <Modal
            icon={<BiPackage />}
            title={isNew ? "Cadastro de novo plano" : (editable ? `Editando o plano: ${plan.name}` : `Visualizando: ${plan.name}`)}
            description={isNew ? "Adicione um novo plano" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, plan)}>
                <FormGroupInline>
                    <FormGroup labelText="Nome">
                        {editable ?
                            <Input type="text" placeholder="Nome do plano" value={plan?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                            :
                            <span>{plan?.name}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <FormGroup labelText="Descrição">
                    {editable ?
                        <Input type="text" placeholder="Descrição do plano" value={plan?.description ?? ""} onChange={(e) => handleChangeValue("description", e.target.value)} />
                        :
                        <span>{plan?.description}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Valor">
                    {editable ?
                        <Input type="currency" placeholder="Valor do plano" value={plan?.value ?? ""} onChange={(e) => handleChangeValue("value", e.target.value)} />
                        :
                        <span>{formatCurrency(plan?.value)}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Porcentagem de Comissão">
                    {editable ?
                        <Input type="number" placeholder="Porcentagem de Comissão do plano" value={plan?.percentage_transfer ?? ""} onChange={(e) => handleChangeValue("percentage_transfer", e.target.value)} />
                        :
                        <span>{formatFloatToPercent(plan?.percentage_transfer)}</span>
                    }
                </FormGroup>
                <BreakForm title="Limites" showAction={false} />
                <FormGroupInline>
                    <FormGroup labelText="Usuários">
                        {editable ?
                            <Input type="number" placeholder="Limite de Usuários" value={plan?.limits?.users ?? ""} onChange={(e) => handleChangeLimits("users", e.target.value)} />
                            :
                            <span>{plan?.limits?.users}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Leads">
                        {editable ?
                            <Input type="number" placeholder="Limite de Leads" value={plan?.limits?.leads ?? ""} onChange={(e) => handleChangeLimits("leads", e.target.value)} />
                            :
                            <span>{plan?.limits?.leads}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Equipes">
                        {editable ?
                            <Input type="number" placeholder="Limite de Equipes" value={plan?.limits?.teams ?? ""} onChange={(e) => handleChangeLimits("teams", e.target.value)} />
                            :
                            <span>{plan?.limits?.teams}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Armazenamento MB (Por Usuário)">
                        {editable ?
                            <Input type="number" placeholder="Limite de Armazenamento em MB" value={plan?.limits?.storage_per_user ?? ""} onChange={(e) => handleChangeLimits("storage_per_user", e.target.value)} />
                            :
                            <span>{formatDataSize(plan?.limits?.storage_per_user || 0)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <BreakForm title="Custo Adicional" showAction={false} />
                <FormGroupInline>
                    <FormGroup labelText="Usuários">
                        {editable ?
                            <Input type="currency" placeholder="Custo por usuário adicional" value={plan?.additional_cost?.users ?? ""} onChange={(e) => handleChangeAdditionalCost("users", e.target.value)} />
                            :
                            <span>{formatCurrency(plan?.additional_cost?.users)}</span>
                        }
                    </FormGroup>
                    <FormGroup labelText="Leads">
                        {editable ?
                            <Input type="currency" placeholder="Custo por lead adicional" value={plan?.additional_cost?.leads ?? ""} onChange={(e) => handleChangeAdditionalCost("leads", e.target.value)} />
                            :
                            <span>{formatCurrency(plan?.additional_cost?.leads)}</span>
                        }
                    </FormGroup>
                </FormGroupInline>
                <FormGroup labelText="Pagamento para Parceiro">
                    {editable ?
                        <Select multiple={false} name="Pagamento para Parceiro" placeholder="Buscar..." options={optionsPayment} onChange={(value) => handleChangeValue("payment_by_partner", value)} selected={+plan?.payment_by_partner ?? ""} />
                        :
                        <span>{getOptionByOptionList(+plan?.payment_by_partner, optionsPayment)}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Status">
                    {editable ?
                        <Select multiple={false} name="Status" placeholder="Buscar..." options={optionsStatus} onChange={(value) => handleChangeValue("is_active", value)} selected={+plan?.is_active ?? ""} />
                        :
                        <span>{getOptionByOptionList(+plan?.is_active, optionsStatus)}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Contas">
                    {editable ?
                        <Select multiple={true} name="Contas" placeholder="Buscar..." options={partnersAccounts} onChange={(value) => handleChangeValue("accounts", value)} selected={plan?.accounts ?? []} />
                        :
                        <span>{getOptionByOptionList(plan?.accounts, partnersAccounts) || "-"}</span>
                    }
                </FormGroup>
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalPlan