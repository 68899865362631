import React from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Tooltip } from 'recharts'
import styles from "../charts.module.css"

const ChartBar = ({ data, color }) => {
    const allDataZero = data.every(item => item.value === 0)

    const CustomLabel = (props) => {
        const { x, y, width, height, value } = props
        if (width > 20) {
            return (
                <text x={x + width / 2} y={y + height / 2} fill="white" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            )
        }

        return null
    }

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip}>
                    <p>{`${payload[0].payload.name} : ${payload[0].payload.formattedValue}`}</p>
                </div>
            )
        }

        return null
    }

    const formatText = (text) => {
        const words = text.split(" ")

        const abbreviatedWords = words.map((word, index) => {
            if (index !== words.length - 1 && word.length > 3) {
                return word.substring(0, 3) + '.'
            }
            return word
        })

        return abbreviatedWords.join(" ")
    }

    return (
        allDataZero ? (
            <div className={styles.emptyChartMessage}>
                <p>Nenhum dado para exibir</p>
            </div>
        ) : (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart layout="vertical" data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                    <XAxis type="number" dataKey="value" hide={true} />
                    <YAxis type="category" dataKey="name" width={200} tickFormatter={(value) => formatText(value)} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        dataKey="value"
                        fill={color}
                    >
                        <LabelList dataKey="formattedValue" position="inside" content={<CustomLabel />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        )
    )
}

export default ChartBar
