import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"
import ConfigTextListItems from "../../components/ConfigTextListItems"
import Loading from "../layouts/Loading"

const FunnelsSettings = ({ account_id }) => {
    const [funnels, setFunnels] = useState([])
    const [funnelsEditable, setFunnelsEditable] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (account_id) {
            getAllFunnels()
        }
    }, [account_id])

    const getAllFunnels = () => {
        setLoading(true)
        api.get("/funnels/settings").then(response => {
            const listFunnels = response?.data?.data || []
            setFunnels(listFunnels)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateFunnels = (e, _, funnels) => {
        e.preventDefault()
        setLoading(true)
        api.patch("/funnels/all", { funnels }).then(response => {
            const newFunnels = response?.data?.data
            if (newFunnels) {
                setFunnels(newFunnels)
            }
            handleToogleEditFunnels(e)
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleToogleEditFunnels = (e) => {
        e.preventDefault()
        setFunnelsEditable(previousValue => !previousValue)
    }

    return (
        <>
            <ConfigTextListItems
                title="Funis"
                placeholder="Funil"
                textNotItems="Nenhum funil cadastrado até o momento!"
                value={funnels}
                onSubmit={handleUpdateFunnels}
                onCancel={handleToogleEditFunnels}
                editable={funnelsEditable}
                setEdit={handleToogleEditFunnels}
                showAction={false}
            />
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default FunnelsSettings