import React from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import Loading from "../pages/layouts/Loading";
import sharedRoutes from "./SharedRoutes";

const AuthRoutes = () => {
    const listRoutes = [
        {
            path: "/reset-password",
            element: <ResetPassword />,
            errorElement: <Loading />,
        },
        {
            path: "/login",
            element: <Login />,
            errorElement: <Loading />,
        },
    ]
    listRoutes.push(...sharedRoutes)
    listRoutes.push({
        path: "*",
        element: <Navigate to="/login" />,
    })

    const router = createBrowserRouter(listRoutes)

    return (
        <RouterProvider router={router} />
    )
}

export default AuthRoutes