import React from "react"
import styles from './header.module.css'
import Logo from '../../../logo.svg'

const HeaderAuth = () => {
    return (
        <>
            <header className={styles.header}>
                <div className={styles.info}>
                    <img src={Logo} alt="Logo Oktto" />
                </div>
            </header>
        </>
    )
}

export default HeaderAuth