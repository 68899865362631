import React from 'react'
import styles from "./image.module.css"

const Image = ({ url, width, height, showPreview, openPreview = () => { } }) => {
    const style = showPreview ? { minWidth: width > 0 && width < 350 ? `${width}px` : "350px", minHeight: height > 0 && height < 350 ? `${height}px` : "300px" } : {}
    return (
        <div className={styles.container} onClick={() => openPreview(showPreview)}>
            <img
                src={url}
                alt="Imagem"
                loading="lazy"
                style={style}
            />
        </div>
    )
}

export default Image
