import React from "react";
import Day from "./Day";
import styles from "../form.module.css"
import Week from "./Week";
import moment from "moment";
import "moment/locale/pt-br"

moment.locale("pt-br")

const Month = ({ month, handleSelectDates }) => {
    const monthString = moment(month.firstDayOfMonth).format("MMMM")
    const yearString = moment(month.firstDayOfMonth).format("YYYY")

    const getWeekDays = () => (
        <>
            <span>Dom</span>
            <span>Seg</span>
            <span>Ter</span>
            <span>Qua</span>
            <span>Qui</span>
            <span>Sex</span>
            <span>Sáb</span>
        </>
    )

    return (
        <div className={styles.month}>
            <div className={styles.header}>
                <span><strong>{monthString} de</strong> {yearString}</span>
                <div className={styles.weekDays}>
                    {getWeekDays()}
                </div>
            </div>
            {month.days.map((week, index) => (
                <Week week={week} key={index} handleSelectDates={handleSelectDates} />
            ))}
        </div>
    )
}

export default Month