import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io"
import { BsCheckLg } from "react-icons/bs"
import Month from "./Month";
import styles from "../form.module.css"
import moment from "moment"
import Button from './../Button';

const DatePicker = ({ name, dateStart, dateEnd, onChange, min, max, multiple, theme }) => {
    const initialDate = multiple ? (dateEnd ? dateEnd : (dateStart ? dateStart : moment())) : dateStart ? dateStart : moment()
    const [tempDateStart, setTempDateStart] = useState(dateStart)
    const [tempDateEnd, setTempDateEnd] = useState(dateEnd)
    const [selectedYear, setSelectedYear] = useState(moment(initialDate, "YYYY-MM-DD").format("YYYY"))
    const [selectedMonth, setSelectedMonth] = useState(moment(initialDate, "YYYY-MM-DD").format("M"))
    const [listPresets, setListPresets] = useState([])
    const [active, setActive] = useState(false)
    const [stylesSelect, setStylesSelect] = useState({})
    const inputRef = useRef()

    useEffect(() => {
        if (multiple) {
            loadListPresets()
        }
    }, [min, max])

    const getTextByDates = () => {
        let titleInput = name
        if (multiple) {
            if (dateStart && dateEnd) {
                titleInput = `${moment(dateStart).format("DD/MM/YYYY")} a ${moment(dateEnd).format("DD/MM/YYYY")}`
            }
        } else {
            if (dateStart) {
                titleInput = moment(dateStart).format("DD/MM/YYYY")
            }
        }
        return titleInput
    }

    const handleToogleVisibleOptions = () => {
        setActive(previousActive => {
            if (previousActive) {
                setTempDateStart(dateStart)
                setTempDateEnd(dateEnd)
                setSelectedYear(moment(initialDate).format("YYYY"))
                setSelectedMonth(moment(initialDate).format("M"))
            } else {
                const windowWidth = window.innerWidth
                const details = inputRef.current.getBoundingClientRect()

                const distanceRight = windowWidth - details.right
                const distanceLeft = details.left

                const top = `calc(${details.bottom}px + 0.5em)`
                const left = distanceRight > distanceLeft ? `${details.left}px` : ''
                const right = distanceLeft > distanceRight ? `${distanceRight}px` : ''
                setStylesSelect({
                    top,
                    left,
                    right
                })
            }

            return !previousActive
        })
    }

    const onChangeDates = () => {
        if (!tempDateEnd) {
            setTempDateEnd(tempDateStart)
            onChange(tempDateStart, tempDateStart)
        } else {
            onChange(tempDateStart, tempDateEnd)
        }
        setActive(false)
    }

    const getDaysByMonth = (year, month) => {
        const firstDayOfMonth = moment(`${year}-${month}-01`, "YYYY-MM-DD").format("YYYY-MM-DD")
        const lastDayOfMonth = parseInt(moment(firstDayOfMonth, "YYYY-MM-DD").endOf("month").format("D"))
        const daysUntilSunday = moment(firstDayOfMonth, "YYYY-MM-DD").format("d")
        const days = []
        let week = []

        for (let i = 0; i < daysUntilSunday; i++) {
            week.push({ dayOfMonth: null, dayOfWeek: null, inRange: false, active: false, disabled: true })
        }
        for (let i = 1; i <= lastDayOfMonth; i++) {
            const fullDay = moment(`${year}-${month}-${i}`, "YYYY-MM-DD").format("YYYY-MM-DD")
            const dayOfWeek = moment(fullDay, "YYYY-MM-DD").format("d")
            const inRange = multiple ? tempDateStart && tempDateEnd ? moment(fullDay, "YYYY-MM-DD").isBetween(tempDateStart, tempDateEnd) : false : false
            const isStart = moment(fullDay, "YYYY-MM-DD").isSame(tempDateStart)
            const isEnd = multiple ? moment(fullDay, "YYYY-MM-DD").isSame(tempDateEnd) : false
            const today = moment(fullDay, "YYYY-MM-DD").isSame(moment().format("YYYY-MM-DD"))
            const minIsOk = min ? moment(fullDay, "YYYY-MM-DD").isSameOrAfter(min) : true
            const maxIsOk = max ? moment(fullDay, "YYYY-MM-DD").isSameOrBefore(max) : true
            const disabled = !(minIsOk && maxIsOk)
            const firstDay = i === 1
            const lastDay = i === lastDayOfMonth
            week.push({ dayOfMonth: i, dayOfWeek: dayOfWeek, fullDay, inRange, isStart, isEnd, today, disabled, firstDay, lastDay })

            if (parseInt(dayOfWeek) === 6) {
                days.push(week)
                week = []
            }
        }
        if (week.length > 0) {
            days.push(week)
        }

        return { firstDayOfMonth, days }
    }

    const getMonthsByDays = () => {
        const listDays = multiple ? [getDaysByMonth(selectedMonth > 1 ? selectedYear : selectedYear - 1, selectedMonth > 1 ? selectedMonth - 1 : 12), getDaysByMonth(selectedYear, selectedMonth)] : [getDaysByMonth(selectedYear, selectedMonth)]
        return (
            listDays.map((month, index) => (
                <Month month={month} key={index} handleSelectDates={handleSelectDates} />
            ))
        )
    }

    const decrementMonthSelected = () => {
        setSelectedMonth(prevMonth => {
            const newMonth = moment(`${selectedYear}-${prevMonth}-10`, "YYYY-MM-DD").subtract(1, 'M').format("M")
            return newMonth
        })
        setSelectedYear(prevYear => {
            const newYear = moment(`${prevYear}-${selectedMonth}-10`, "YYYY-MM-DD").subtract(1, 'M').format("YYYY")
            return newYear
        })
    }

    const incrementMonthSelected = () => {
        setSelectedMonth(prevMonth => {
            const newMonth = moment(`${selectedYear}-${prevMonth}-10`, "YYYY-MM-DD").add(1, 'M').format("M")
            return newMonth
        })
        setSelectedYear(prevYear => {
            const newYear = moment(`${prevYear}-${selectedMonth}-10`, "YYYY-MM-DD").add(1, 'M').format("YYYY")
            return newYear
        })
    }

    const handleSelectDates = (newDate) => {
        if (multiple) {
            if ((!tempDateStart && !tempDateEnd) || (tempDateStart && tempDateEnd)) {
                setTempDateStart(newDate)
                setTempDateEnd(undefined)
            } else {
                if (moment(tempDateStart).isAfter(newDate)) {
                    setTempDateEnd(tempDateStart)
                    setTempDateStart(newDate)
                } else {
                    setTempDateEnd(newDate)
                }
            }
        } else {
            setTempDateStart(newDate)
        }
    }

    const getPositionInput = () => {
        const width = inputRef.current.offsetWidth
        const left = inputRef.current.offsetLeft

        return width + left
    }

    const alignInputInRight = () => {
        const bodyWidth = document.querySelector("body")?.offsetWidth ?? 0
        const position = getPositionInput()

        return position > (bodyWidth / 2) ? true : false
    }

    const loadListPresets = () => {
        const weekDayToday = parseInt(moment().format("d"))
        const monthDayToday = parseInt(moment().format("D"))
        const today = moment().format("YYYY-MM-DD")
        const yesterday = moment().subtract(1, 'd').format("YYYY-MM-DD")
        const last7Days = moment().subtract(7, 'd').format("YYYY-MM-DD")
        const last14Days = moment().subtract(14, 'd').format("YYYY-MM-DD")
        const last30Days = moment().subtract(30, 'd').format("YYYY-MM-DD")
        const startWeek = moment().subtract(weekDayToday, 'd').format("YYYY-MM-DD")
        const startLastWeek = moment().subtract(weekDayToday + 7, 'd').format("YYYY-MM-DD")
        const endLastWeek = moment().subtract(weekDayToday + 1, 'd').format("YYYY-MM-DD")
        const startMonth = moment().startOf('M').format("YYYY-MM-DD")
        const endMonth = moment().endOf('M').isSameOrBefore(max) ? moment().endOf('M').format("YYYY-MM-DD") : today
        const startLastMonth = moment(startMonth, "YYYY-MM-DD").subtract(1, 'M').format("YYYY-MM-DD")
        const endLastMonth = moment().subtract(monthDayToday, 'd').format("YYYY-MM-DD")

        setListPresets([
            { name: 'all', title: "Todo o período", required: true },
            { name: 'today', title: "Hoje", dateStart: today, dateEnd: today },
            { name: 'yesterday', title: "Ontem", dateStart: yesterday, dateEnd: yesterday },
            { name: 'last7Days', title: "Últimos 7 dias", dateStart: last7Days, dateEnd: yesterday },
            { name: 'last14Days', title: "Últimos 14 dias", dateStart: last14Days, dateEnd: yesterday },
            { name: 'last30Days', title: "Últimos 30 dias", dateStart: last30Days, dateEnd: yesterday },
            { name: 'thisWeek', title: "Esta semana", dateStart: startWeek, dateEnd: today },
            { name: 'lastWeek', title: "Semana passada", dateStart: startLastWeek, dateEnd: endLastWeek },
            { name: 'thisMonth', title: "Este mês", dateStart: startMonth, dateEnd: endMonth },
            { name: 'lastMonth', title: "Mês passado", dateStart: startLastMonth, dateEnd: endLastMonth },
        ])
    }

    const getPresetsByCalendar = () => {
        return listPresets?.map((preset, index) => {
            const active = (!preset.dateStart && !dateStart && !preset.dateEnd && !dateEnd) || (moment(preset.dateStart, "YYYY-MM-DD").isSame(dateStart) && moment(preset.dateEnd, "YYYY-MM-DD").isSame(dateEnd))

            if (preset.required || ((!min || moment(preset.dateStart, "YYYY-MM-DD").isSameOrAfter(min)) && (!max || moment(preset.dateEnd, "YYYY-MM-DD").isSameOrBefore(max)))) {
                return (
                    <div key={index} className={`${active && styles.active}`} onClick={() => handlePresetSelection(preset.dateStart, preset.dateEnd)}>
                        {active && <BsCheckLg />}
                        <span>{preset.title}</span>
                    </div>
                )
            }
        })
    }

    const handlePresetSelection = (dateStart, dateEnd) => {
        setTempDateStart(dateStart)
        setTempDateEnd(dateEnd)
        onChange(dateStart, dateEnd)
        setActive(false)
    }

    return (
        <>
            <div className={`${styles.datePicker} ${active && styles.active} ${styles[theme]}`} ref={inputRef}>
                <div className={styles.text} onClick={handleToogleVisibleOptions} onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && handleToogleVisibleOptions()} tabIndex="0" role="button">
                    <span>
                        {getTextByDates()}
                    </span>
                    <IoMdArrowDropdown />
                </div>
                {active &&
                    <div className={`${styles.groupOptions} ${alignInputInRight() ? styles.right : styles.left}`} style={stylesSelect}>
                        <div className={styles.calendar}>
                            <div className={styles.top}>
                                <div className={styles.header}>
                                    <div className={styles.arrows}>
                                        <IoMdArrowDropleft className={styles.arrow} onClick={decrementMonthSelected} />
                                        <IoMdArrowDropright className={styles.arrow} onClick={incrementMonthSelected} />
                                    </div>
                                </div>
                                <div className={styles.body}>
                                    {getMonthsByDays()}
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.selectedPeriod}>
                                    {tempDateStart &&
                                        <span>
                                            {moment(tempDateStart).format("DD [de] MMM [de] YYYY")}
                                        </span>
                                    }
                                    {multiple && tempDateEnd &&
                                        <>
                                            <span>a</span>
                                            <span>
                                                {moment(tempDateEnd).format("DD [de] MMM [de] YYYY")}
                                            </span>
                                        </>
                                    }
                                </div>
                                <div className={styles.actions}>
                                    <Button className="bg-purple" onClick={handleToogleVisibleOptions}>
                                        Cancelar
                                    </Button>
                                    <Button className="bg-pink" onClick={onChangeDates}>
                                        Selecionar
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {multiple &&
                            <div className={styles.panelRight}>
                                <span>Períodos</span>
                                <div className={styles.presets}>
                                    {getPresetsByCalendar()}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            {active &&
                <div className={styles.backgroundForm} onClick={handleToogleVisibleOptions} />
            }
        </>
    )
}

export default DatePicker