import React from "react"
import styles from "./backgroundSelectFilter.module.css"

const BackgroundSelectFilter = ({ text, image }) => {
    return (
        <div className={styles.container}>
            <span>{text}</span>
            <img src={image} alt="Dashboard" />
        </div>
    )
}

export default BackgroundSelectFilter