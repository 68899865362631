import React from "react"
import styles from "./userNotPermission.module.css"
import { NavLink } from "react-router-dom"
import imageBlock from "../../images/block.png"

const UserNotPermission = ({ blocked = false, paymentByPartner = false, permissions = {}, account_id }) => {
    return (
        <div className={styles.body}>
            {blocked &&
                <>
                    <span className={styles.title}>Sua conta não possui uma licença ativa, e por isso seus recursos foram momentaneamente bloqueados!</span>
                    {paymentByPartner &&
                        <span>Para reativar sua conta, entre em contato com o parceiro que lhe forneceu a ultima licença.</span>
                    }
                    {!paymentByPartner && permissions?.finance > 0 &&
                        <div className={styles.groupLink}>
                            <span>Precisando reativar sua conta?</span>
                            <NavLink to={`/${account_id}/myaccount`}>
                                Para visualizar os dados da sua conta ou renovar sua assinatura, clique aqui!
                            </NavLink>
                        </div>
                    }
                    <div className={styles.icon}>
                        {blocked && <img alt="Conta bloqueada" src={imageBlock} />}
                    </div>
                    {permissions?.reports > 0 &&
                        <div className={styles.groupLink}>
                            <span className={styles.title}>Seus dados estão a salvo!</span>
                            <NavLink to={`/${account_id}/reports`}>
                                Você ainda pode extrair sua base de leads e seus relatórios clicando aqui!
                            </NavLink>
                        </div>
                    }

                </>

            }
            {!blocked &&
                <>
                    <div className={styles.icon}>
                        {blocked && <img alt="Conta bloqueada" src={imageBlock} />}
                        {!blocked && <img alt="Úsuário não possui permissão de acesso" src={imageBlock} />}
                    </div>
                    <div className={styles.message}>
                        <span>Você não possui permissão de acesso a essa página!</span>
                    </div>
                </>
            }
        </div>
    )
}

export default UserNotPermission