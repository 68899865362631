import React from "react"
import styles from "./apiIntegration.module.css"
import Button from "../../components/Form/Button"
import Table from "../../components/Table"
import { TbForms, TbListDetails, TbLockAccess } from "react-icons/tb"
import { useNavigate, useParams } from 'react-router-dom';
import { openLinkExternal } from "../../utils/utils"
import api from './../../services/api'
import { toast } from 'react-toastify'

const ApiIntegration = () => {
    const { account_id } = useParams()
    const navigate = useNavigate()

    const headers = [
        "#",
        "Nome",
        "Descrição",
        "",
        "",
    ]

    const getLinkDocumentationAPI = async () => {
        try {
            const response = await api.get("/integrations/api/docs")
            const link = response?.data?.data
            return link
        } catch (err) {
            toast.error("Não foi possível obter o link da documentação!")
        }
    }

    const data = [
        {
            id: 1,
            value: [
                "Tokens",
                "Gerencie seus tokens para realizar chamadas via API",
                <TbLockAccess className="icon-md" />,
                <Button className="bg-pink">Configurar</Button>,
            ],
            path: `/${account_id}/integrations/api/tokens`,
        },
        {
            id: 2,
            value: [
                "Formulários",
                "Conecte seus sites e landing pages e receba os cadastros em tempo real",
                <TbForms className="icon-md" />,
                <Button className="bg-pink">Configurar</Button>,
            ],
            path: `/${account_id}/integrations/api/forms`,
        },
        {
            id: 3,
            value: [
                "Documentação da API",
                "Acesse a documentação da API e consulte as chamadas disponíveis",
                <TbListDetails className="icon-md" />,
                <Button className="bg-pink">Acessar</Button>,
            ],
            path: getLinkDocumentationAPI(),
            external: true
        },
    ]

    const handleNavigateToPage = async (id) => {
        data?.forEach(async el => {
            if (el.id === id) {
                if (el?.external) {
                    const link = await el.path
                    openLinkExternal(link)
                } else {
                    navigate(el.path)
                }
            }
        })
    }

    return (
        <div className={styles.container}>
            <Table
                headers={headers}
                values={data}
                onClick={(id) => handleNavigateToPage(id)}
                messageNotRegisters="Nenhum registro encontrado"
                showIndex={true}
            />
        </div>
    )
}

export default ApiIntegration