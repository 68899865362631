import React, { useEffect, useState } from "react"
import { BiTransfer } from "react-icons/bi"
import { toast } from "react-toastify"
import Modal from '../index'
import Button from "../../Form/Button"
import FormGroup from "../../Form/FormGroup"
import FormGroupInline from '../../Form/FormGroupInline'
import Select from "../../Form/Select"
import { listAssignTo } from "../../../schemas/Chat"
import { useData } from "../../../contexts/dataContext"
import { useWebSocketChatContext } from "../../../contexts/webSocketChatContext"

const ModalAssign = ({ value, inbox_id, onSubmit = () => { }, closeModal = () => { } }) => {
    const [owner, setOwner] = useState({})
    const [listTeams, setListTeams] = useState([])
    const [listUsers, setListUsers] = useState([])

    const { teams, roles, users } = useData()
    const { chatInboxes } = useWebSocketChatContext()

    useEffect(() => {
        if (value && Object.keys(value).length > 0) {
            const users = value?.users?.map(user => user.user_id)
            setOwner({ ...value, users })
        }
    }, [value])

    useEffect(() => {
        const inbox = chatInboxes.find(inbox => inbox?._id === inbox_id)
        if (inbox) {
            const inboxTeamsIds = inbox?.teams?.map(team => team?.team_id) || []
            const inboxUsersIds = inbox?.users?.map(user => user?.user_id) || []
            setListTeams(inboxTeamsIds?.length > 0 ? teams.filter(team => inboxTeamsIds.includes(team.id)) : teams)
            setListUsers(inboxUsersIds?.length > 0 ? users.filter(user => inboxUsersIds.includes(user.id)) : users)
        }
    }, [teams, users, chatInboxes])

    const handleChangeValue = (name, value) => {
        setOwner(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        if (owner.assignTo && ((owner.assignTo === "teamAndRole" && (owner.team_id || owner.role_id)) || (owner.assignTo === "users" && owner.users?.length > 0))) {
            onSubmit(owner)
            closeModal()
        } else {
            toast.error("Preencha todos os campos para prosseguir!")
        }
    }

    return (
        <Modal
            icon={<BiTransfer />}
            title={"Atribuir atendimento"}
            description={"Defina quem terá acesso ao atendimento"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="Atribuir para">
                    <Select name="Selecione uma opção..." placeholder="Pesquisar..." options={listAssignTo} selected={owner?.assignTo ?? ""} multiple={false} onChange={(value) => handleChangeValue("assignTo", value)} />
                </FormGroup>
                {owner?.assignTo === "teamAndRole" &&
                    <>
                        <FormGroup labelText="Equipe">
                            <Select name="Selecione uma opção..." placeholder="Pesquisar..." options={listTeams} selected={owner?.team_id ?? ""} multiple={false} onChange={(value) => handleChangeValue("team_id", value)} />
                        </FormGroup>
                        <FormGroup labelText="Cargo">
                            <Select name="Selecione uma opção..." placeholder="Pesquisar..." options={roles} selected={owner?.role_id ?? ""} multiple={false} onChange={(value) => handleChangeValue("role_id", value)} />
                        </FormGroup>
                    </>
                }
                {owner?.assignTo === "users" &&
                    <FormGroup labelText="Usuários">
                        <Select name="Usuários" placeholder="Pesquisar..." options={listUsers} selected={owner?.users ?? []} multiple={true} onChange={(value) => handleChangeValue("users", value)} />
                    </FormGroup>
                }
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalAssign