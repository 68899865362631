import React from "react"
import Modal from './index'

const ModalInfo = ({ icon, title = "", description = "", content = "", closeModal }) => {
    return (
        <Modal
            icon={icon}
            title={title}
            description={description}
            closeModal={closeModal}
        >
            {content}
        </Modal>
    )
}

export default ModalInfo