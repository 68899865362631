import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import styles from './auth.module.css'

import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import { toast } from "react-toastify";
import HeaderAuth from "./HeaderAuth";
import FormGroup from './../../components/Form/FormGroup';

const Login = () => {
    const [user, setUser] = useState({})
    const navigate = useNavigate()

    const { login } = useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await login(user)
            navigate("/")
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    return (
        <>
            <HeaderAuth />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.groupForm}>
                        <h1>Entre em sua conta</h1>
                        <form onSubmit={handleSubmit}>
                            <FormGroup labelText="E-mail">
                                <Input
                                    type="email"
                                    placeholder="E-mail"
                                    value={user?.email || ""}
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup labelText="Senha">
                                <Input
                                    type="password"
                                    placeholder="Senha"
                                    value={user?.password || ""}
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                />
                            </FormGroup>
                            <Button className="bg-pink" disabled={!user?.email || !user?.password}>Entrar</Button>
                        </form>
                    </div>
                    <div className={styles.actions}>
                        <Link to="/reset-password">Esqueceu sua senha?</Link>
                        <Link to="/politica-privacidade">Política de Privacidade</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login