import React, { useEffect, useState } from "react"
import api from "../../services/api"

import styles from './crm.module.css'
import Stage from "../../components/Crm/Stage"
import Select from "../../components/Form/Select"
import Button from './../../components/Form/Button'
import Input from "../../components/Form/Input"
import FormGroupInline from "../../components/Form/FormGroupInline"
import SideFilter from "./SideFilter"

import { useLocation, useNavigate, useParams } from "react-router-dom"
import { BiFilter } from "react-icons/bi"
import { FaPencilAlt } from "react-icons/fa"
import { HiPlus } from "react-icons/hi"
import { TfiReload } from "react-icons/tfi"
import { RiListSettingsLine } from "react-icons/ri"
import { toast } from "react-toastify"
import { useCrm } from "../../contexts/crmContext"
import { useData } from "../../contexts/dataContext"
import { useAuth } from "../../contexts/authContext"
import { areObjectsEqual, getItemForArrayById, getUsersByTeams, showNotification } from "../../utils/utils"
import Loading from './../layouts/Loading/index'
import ModalNewLead from "../../components/Modal/ModalLead/ModalNewLead"
import ModalManagementLead from "../../components/Modal/ModalManagementLead"
import { changeLastFunnel } from "../../schemas/Funnel"
import CircleLoading from "../../components/CircleLoading"
import { saveNewLead } from "../../schemas/Lead"

const Crm = () => {
    const { account_id, funnel_id } = useParams()
    const { funnels, teams, users, products, loadCampaignsByOrigins, updateLastFunnel } = useData()
    const { stages, changeStages, loadingStages, leads, setLeads, currentLeads, totalLeads, setTotalLeads, filters, setFilters, setInitialFilters, setFilteredDataLead, handleUpdateLead, reload, setReload, updateLeadById, verifyFiltersByLead, removeLeadById, permissionIsOk, modalNewLead, closeModalNewLead, newLead, setNewLead, openModalLead, openModalFunnel, modalManagement, openLeadsManagement, closeModalLeadsManagement } = useCrm()
    const [funnel, setFunnel] = useState(funnel_id)
    const [filtersTemp, setFiltersTemp] = useState(filters)
    const [changedFilters, setChangedFilters] = useState(false)
    const [sideFilterActive, setSideFilterActive] = useState(false)
    const [loading, setLoading] = useState(false)

    const { permissions } = useAuth()

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const openModal = queryParams.get('openModal')
        const leadId = queryParams.get('leadId')
        if (openModal === '1' && leadId) {
            openModalLead(leadId)
            queryParams.delete('openModal')
            queryParams.delete('leadId')
            setTimeout(() => {
                navigate({ pathname: location.pathname, search: queryParams.toString() }, { replace: true })
            }, 500)
        }
    }, [location.search])

    useEffect(() => {
        if (account_id && funnel_id) {
            getInitialLeads()
        }
    }, [account_id, funnel_id])

    useEffect(() => {
        if (permissionIsOk && funnel_id) {
            updateStages(funnel_id)
        }
    }, [filters, funnel_id, permissionIsOk])

    useEffect(() => {
        if (account_id && funnel) {
            navigate(`/${account_id}/crm/${funnel}`)
            changeLastFunnel(funnel).then(funnel_id => updateLastFunnel(funnel_id))
        }
    }, [funnel])

    useEffect(() => {
        setInitialValuesByFilter("teams", teams?.map(team => { team.selected = true; return team }))
    }, [teams])

    useEffect(() => {
        setInitialValuesByFilter("users", users?.map(user => { user.selected = true; return user }))
    }, [users])

    useEffect(() => {
        handleFiltersChanged()
    }, [filtersTemp])

    useEffect(() => {
        loadCampaignsByOrigins(filtersTemp.origins)
    }, [filtersTemp.origins])

    const setInitialValuesByFilter = (name, array) => {
        if (array?.length > 0) {
            const newValue = array?.filter(el => el.selected)?.map(el => { return el.id })
            setInitialFilters(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
            setFilters(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
            setFiltersTemp(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
        }
    }

    const getInitialLeads = async () => {
        if (!loading) {
            getLeadsByFunnelAndFilter()
        }
    }

    const getLeadsByFunnelAndFilter = (updateFilters = false) => {
        setLoading(true)
        const queryString = new URLSearchParams(filtersTemp)?.toString()
        api.get(`/leads?funnel_id=${funnel_id}&${queryString}`).then(async response => {
            const newLeads = response.data?.data?.leads || []
            const totalLeads = response.data?.data?.totalLeads || 0
            setLeads(newLeads)
            if (updateFilters) {
                setFilters(filtersTemp)
            }
            setTotalLeads(totalLeads)
            setChangedFilters(false)
            setFilteredDataLead(previousValue => ++previousValue)
            closeSideFilter()
            setReload(false)
            setLoading(false)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }

    const handleChangeFilters = (name, value) => {
        setFiltersTemp(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleFiltersChanged = () => {
        setChangedFilters(!areObjectsEqual(filters, filtersTemp))
    }

    const cleanFilters = () => {
        setFiltersTemp(filters)
    }

    const openSideFilter = () => {
        setSideFilterActive(true)
    }

    const closeSideFilter = () => {
        setSideFilterActive(false)
    }

    const updateStages = (funnel_id) => {
        const funnelIsValid = getItemForArrayById(funnels, "_id", funnel_id)
        if (!funnelIsValid) {
            updateLastFunnel(undefined)
            return navigate(`/${account_id}/crm`)
        }
        changeStages(funnel_id)
    }

    const handleSaveNewLead = (e, open = false) => {
        e.preventDefault()
        setLoading(true)
        saveNewLead(newLead).then(data => {
            if (data) {
                addNewLead(data)
                if (open) {
                    openModalLead(data._id)
                }
            }
            changeStages(funnel_id)
            closeModalNewLead()
        }).catch(err => { }).finally(() => setLoading(false))
    }

    const addNewLead = (newLead) => {
        setLeads(previousValue => {
            const leadAlreadyExists = previousValue?.some(lead => lead._id === newLead._id)
            const filtersOk = verifyFiltersByLead(newLead)

            if (leadAlreadyExists) {
                if (filtersOk) {
                    updateLeadById(newLead, newLead._id)
                } else {
                    removeLeadById(newLead._id)
                }
            } else {
                showNotification("Um novo lead foi cadastrado!", "Entre em contato o mais breve possível, para que ele não esfrie!")
            }

            return !leadAlreadyExists && filtersOk ? [newLead, ...previousValue] : previousValue
        })
    }

    // Ajustes
    const onChangeStage = (card, overCardId, oldStageId, stage, update = true) => {
        setLeads(previousValue => {
            const newValue = previousValue?.map((lead) => {
                if (lead._id === card._id) {
                    lead.stage_id = update ? stage._id : oldStageId
                }
                return lead
            })

            if (update) {
                handleUpdateLead({ "stage_id": stage._id, funnel_id }, card._id).catch(() => onChangeStage(card, overCardId, oldStageId, stage, false))
            }

            // Reordenar os cards
            // Criar ordenação no Stage
            // Ordena pela próxima tarefa
            // Se não houver uma tarefa agendada pela data de entrada
            if (overCardId) {
                const index = previousValue.findIndex((lead) => {
                    return lead._id == overCardId
                })
                if (index >= 0) {
                    // previousValue.splice(index, 0, lead)
                }
            }

            return newValue
        })
    }

    return (
        <>
            <div className={styles.filters}>
                <div className={styles.groupLeft}>
                    <Select name="Funil" placeholder="Pesquise um funil" options={funnels || []} selected={funnel} onChange={setFunnel} multiple={false} />
                    {permissions?.edit_funnels > 0 &&
                        <>
                            <Button className="action bg-orange" onClick={() => openModalFunnel(true)}>
                                <HiPlus />
                            </Button>
                            <Button className="action bg-purple" onClick={() => openModalFunnel(false, funnel_id)}>
                                <FaPencilAlt />
                            </Button>
                        </>
                    }
                </div>
                <div className={styles.groupCenter}>
                    <Select name="Equipe" placeholder="Pesquise uma equipe" options={teams || []} selected={filtersTemp.teams || []} onChange={(value) => handleChangeFilters("teams", value)} multiple={true} />
                    {permissions?.crm > 1 &&
                        <Select name="Responsável" placeholder="Pesquise um responsável" options={getUsersByTeams(filtersTemp?.teams, users, teams) || []} selected={filtersTemp.users || []} onChange={(value) => handleChangeFilters("users", value)} multiple={true} />
                    }
                </div>
                <div className={styles.groupRight}>
                    {reload &&
                        <div className={styles.reload} onClick={() => getLeadsByFunnelAndFilter(true)}>
                            <TfiReload />
                        </div>
                    }
                    <div className={styles.numberLeads}>
                        <span>{currentLeads} / {totalLeads}</span>
                    </div>
                    <Input
                        type="search"
                        placeholder="Buscar"
                        theme="light"
                        value={filtersTemp.search || ""}
                        onChange={(e) => handleChangeFilters("search", e.target.value)}
                    />
                    {permissions?.settings > 0 &&
                        <div className={styles.settings} onClick={() => openLeadsManagement()}>
                            <RiListSettingsLine className="orange" />
                        </div>
                    }
                    <div className={styles.rightFilterAction} onClick={openSideFilter}>
                        <BiFilter />
                    </div>
                </div>
            </div>
            <div className={styles.funnel}>
                {changedFilters &&
                    <div className={styles.changedFilters}>
                        <FormGroupInline>
                            <Button className='bg-purple' type="submit" onClick={() => getLeadsByFunnelAndFilter(true)}>Aplicar Filtros</Button>
                            <Button className='bg-pink' type="submit" onClick={cleanFilters}>Limpar</Button>
                        </FormGroupInline>
                    </div>
                }
                <div className={`${styles.stages} ${loadingStages ? styles.loading : ''}`}>
                    {loadingStages && <CircleLoading />}
                    {!loadingStages && stages?.length > 0 && stages.map(stage => (
                        <Stage key={stage._id} id={stage._id} stage={stage} leads={leads?.filter(lead => lead.stage_id === stage._id)} onChangeStage={onChangeStage} />
                    ))}
                </div>
            </div>
            {sideFilterActive &&
                <SideFilter
                    filters={filtersTemp}
                    handleChange={handleChangeFilters}
                    onSubmit={() => getLeadsByFunnelAndFilter(true)}
                    onClose={closeSideFilter}
                    cleanFilters={cleanFilters}
                />
            }
            {modalNewLead &&
                <ModalNewLead
                    value={newLead}
                    onChange={setNewLead}
                    onSubmit={handleSaveNewLead}
                    listStages={stages?.map(stage => { return { id: stage._id, value: stage.name } })}
                    listProducts={products?.map(product => { return { id: product._id, value: product.name } })}
                    closeModal={closeModalNewLead}
                />
            }
            {permissions?.settings > 0 && modalManagement &&
                <ModalManagementLead
                    closeModal={closeModalLeadsManagement}
                />
            }
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default Crm