import React from 'react'
import styles from "./video.module.css"
import { MdPlayArrow } from 'react-icons/md'

const Video = ({ url, width, height, showPreview, quotedMessage, openPreview = () => { } }) => {
    const style = showPreview ? { minWidth: width > 0 && width < 350 ? `${width}px` : "350px", minHeight: height > 0 && height < 350 ? `${height}px` : "300px" } : {}
    return (
        <div className={styles.container} onClick={() => openPreview(showPreview)}>
            <video src={url} alt="Vídeo" controls={!showPreview && !quotedMessage} style={style} />
            {showPreview && !quotedMessage &&
                <div className={styles.arrowPlay}>
                    <MdPlayArrow />
                </div>
            }
        </div>
    )
}

export default Video
