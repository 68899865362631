import { createContext, useContext, useEffect, useState } from "react";
import api from "../services/api";
import { toast } from "react-toastify";

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [permissions, setPermissions] = useState({})
    const [modules, setModules] = useState({})
    const [statusAccount, setStatusAccount] = useState({ hasPermission: false, payment_by_partner: false })
    const [account, setAccount] = useState({})
    const [token, setToken] = useState()
    const [logged, setLogged] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        verifyUserLogged()
    }, [])

    const setUserAndToken = (token, user) => {
        if (token && user) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`
            localStorage.setItem("@oktto:token", token)
            localStorage.setItem("@oktto:user", JSON.stringify(user))
            setToken(token)
            setUser(user)
            setLogged(true)
        } else {
            setUser({})
            setToken()
            localStorage.removeItem("@oktto:token")
            localStorage.removeItem("@oktto:user")
            api.defaults.headers.common['Authorization'] = undefined
            setLogged(false)
        }

    }

    async function login(data) {
        try {
            if (!data?.email) {
                toast.error("O e-mail é obrigatório!")
                return false
            }
            if (!data?.password) {
                toast.error("A senha é obrigatória!")
                return false
            }

            setLoading(true)
            const response = await api.post("/users/login", { "username": data.email, "password": data.password })
            const tokenUser = response?.data?.data?.token
            const user = response?.data?.data?.user
            setUserAndToken(tokenUser, user)
            setLoading(false)
            toast.success(response.data.message)
        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }

    async function finishWelcome(data, token) {
        try {
            if (!data?.name) {
                toast.error("O nome é obrigatório!")
                return false
            }
            if (!data?.password || !data?.confirmPassword) {
                toast.error("A senha e a confirmação de senha são obrigatórias!")
                return false
            }
            if (data?.password !== data?.confirmPassword) {
                toast.error("A senha e a confirmação de senha devem ser iguais!")
                return false
            }
            setLoading(true)
            const response = await api.post(`/users/validate-user/${token}`, data)
            const tokenUser = response?.data?.data?.token
            const user = response?.data?.data?.user
            setUserAndToken(tokenUser, user)
            setLoading(false)
            toast.success(response?.data?.message)
            return true
        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message)
            return false
        }
    }

    async function resetPassword(data, token) {
        try {
            if (!token) {
                if (data?.email) {
                    setLoading(true)
                    const response = await api.post("/users/reset-password", { email: data.email })
                    setLoading(false)
                    toast.success(response?.data?.message)
                }
            } else {
                if (!data?.email) {
                    toast.error("O e-mail é obrigatório!")
                    return false
                }
                if (!data?.password || !data?.confirmPassword) {
                    toast.error("A senha e a confirmação de senha são obrigatórias!")
                    return false
                }
                if (data?.password !== data?.confirmPassword) {
                    toast.error("A senha e a confirmação de senha devem ser iguais!")
                    return false
                }

                setLoading(true)
                const response = await api.post(`/users/reset-password/${token}`, data)
                const tokenUser = response?.data?.data?.token
                const user = response?.data?.data?.user
                setUserAndToken(tokenUser, user)
                setLoading(false)
                toast.success(response?.data?.message)
            }
            return true
        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.message)
            return false
        }
    }

    function logout() {
        setLoading(true)
        setUserAndToken()
        setLoading(false)
        return true
    }

    function verifyUserLogged(logout = false) {
        setLoading(true)
        const storagedUser = localStorage.getItem("@oktto:user")
        const storagedToken = localStorage.getItem("@oktto:token")
        if (storagedUser && storagedToken) {
            api.defaults.headers.common['Authorization'] = `Bearer ${storagedToken}`
            api.get("/users/verify").then(response => {
                setToken(storagedToken)
                setUser(response?.data?.data || JSON.parse(storagedUser))
                setLogged(true)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                if (logout) {
                    setUser({})
                    setLogged(false)
                    toast.error("Sua sessão expirou!")
                }
            })
        } else {
            setLoading(false)
        }
    }

    async function getPermissions(account_id) {
        try {
            const response = await api.get("/users/permissions", { headers: { "x-api-key": account_id } })
            const permissions = response?.data?.data?.permissions
            const modules = response?.data?.data?.modules
            const status_account = response?.data?.data?.status_account
            setPermissions(permissions)
            setModules(modules)
            setStatusAccount(status_account)
            return permissions
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    async function verifyIfUserHasPermission(namePermission, account_id) {
        const permissions = await getPermissions(account_id)
        if (permissions && permissions[namePermission] >= 1) {
            return true;
        } else {
            return false;
        }
    }

    function getAccount(account_id) {
        api.get(`/accounts/${account_id}`).then(response => {
            const account = response?.data?.data
            setAccount(account)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    function verifyIfModuleIsActive(action) {
        return modules?.length > 0 && modules.some(module => module.action === action);
    }

    function updateNameUser(name) {
        setUser(previousValue => { return { ...previousValue, name } })
    }

    function updateEmailUser(email) {
        setUser(previousValue => { return { ...previousValue, email } })
    }

    function updateImageUser(image) {
        if (image && user?.image) {
            setUser(previousValue => { return { ...previousValue, image: false } })
        }
        setTimeout(() => {
            setUser(previousValue => { return { ...previousValue, image } })
        }, 100)
    }

    return (
        <AuthContext.Provider value={{ loading, logged, user, updateNameUser, updateEmailUser, updateImageUser, token: btoa(token), permissions, getPermissions, verifyIfUserHasPermission, modules, statusAccount, account, getAccount, verifyIfModuleIsActive, login, logout, verifyUserLogged, finishWelcome, resetPassword }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    return context
}

export default AuthContext