import React, { useEffect, useState } from "react"
import styles from "./reportLog.module.css"
import api from "../../services/api"
import { toast } from "react-toastify"
import Table from '../../components/Table/index'
import Loading from "../layouts/Loading"
import { NavLink, useParams } from "react-router-dom"
import { TfiReload } from 'react-icons/tfi'

const ExportLog = () => {
    const [reports, setReports] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const { account_id } = useParams()

    const headers = [
        "#",
        "Data",
        "Identificação",
        "Tipo",
        "Solicitante",
        "Link",
    ]

    useEffect(() => {
        getAllReports()
    }, [])

    const getAllReports = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/reports`)
            const reports = response.data?.data || []
            setReports(reports)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }

    const getDataNewReport = (newReport) => {
        return {
            id: newReport._id,
            value: [
                newReport.created_at ?? "-",
                newReport.name ?? "-",
                newReport.type ?? "-",
                newReport.user_created ?? "-",
                newReport.url ? <a href={newReport.url} target="_blank" className={`${styles.link} pink`} rel="noreferrer">Download</a> :
                    <div className={styles.groupReload}>
                        <span>Em processamento...</span>
                        <div className={styles.reload} onClick={() => getAllReports()}>
                            <TfiReload />
                        </div>
                    </div>,
            ]
        }
    }

    const getFilteredValues = () => {
        const values = reports
        const filteredValues = values?.map((report) => getDataNewReport(report))
        return filteredValues
    }

    return (
        <div className={styles.container}>
            <div className={styles.link}>
                <NavLink to={`/${account_id}/reports`}>Criar uma nova exportação</NavLink>
            </div>
            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhum registro encontrado"
                showIndex={true}
            />
            {isLoading && <Loading fullPage={true} />}
        </div>
    )
}

export default ExportLog