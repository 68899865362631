import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdMoreHoriz } from "react-icons/md"
import styles from "./modal.module.css"
import { useModal } from "../../contexts/modalContext";

const Modal = ({ icon, title, description, actions = [], closeModal, fitContent = false, fullWidith = false, closeOnEsc = true, children }) => {
    const [modalId, setModalId] = useState(null)
    const [showActions, setShowActions] = useState(false)
    const [styleAction, setStyleAction] = useState({})

    const refAction = useRef()

    const { addCloseModalByList, closeModalById } = useModal()

    useEffect(() => {
        if (closeOnEsc && closeModal) {
            const id = addCloseModalByList(closeModal)
            setModalId(previousValue => !previousValue ? id : previousValue)
        }
    }, [])

    const handleCloseModal = () => {
        if (closeOnEsc && closeModal) {
            closeModalById(modalId)
        } else {
            closeModal()
        }
    }

    const handleToogleShowActions = (e) => {
        e.stopPropagation()
        setShowActions(previousValue => {
            if (!previousValue) {
                adjustPositionActions()
            }
            return !previousValue
        })
    }

    const adjustPositionActions = () => {
        const heightWindow = window.innerHeight
        const positions = refAction.current.getBoundingClientRect()
        if ((heightWindow - (positions.top + positions.height)) > heightWindow / 3) {
            const top = `calc(${positions.height}px + ${positions.top}px + 0.5em - 6px)`
            const right = `calc(100vw - ${positions.right}px)`
            setStyleAction({ top, right })
        } else {
            const bottom = `calc(${heightWindow}px - ${positions.bottom}px + ${positions.height}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px)`
            setStyleAction({ bottom, right })
        }
    }

    return (
        <div className={styles.modalBackground}>
            <div className={`${styles.modal} ${fitContent ? styles.md : ''} ${fullWidith ? styles.xl : ''}`}>
                <div className={styles.header}>
                    {icon && <div className={styles.icon}>{icon}</div>}
                    <div className={styles.text}>
                        {title && <span className={styles.title}>{title}</span>}
                        {description && <span className={styles.description}>{description}</span>}
                    </div>
                    {actions.length > 0 &&
                        <div className={styles.actions} onClick={handleToogleShowActions} ref={refAction}>
                            <MdMoreHoriz />
                            {showActions &&
                                <div className={styles.background}>
                                    <ul style={styleAction}>
                                        {actions.map((action, index) => (
                                            <li key={index} onClick={() => action.onClick()}>{action.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                    <div className={styles.close} onClick={handleCloseModal}>
                        <MdClose />
                    </div>
                </div>
                <div className={styles.body}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal