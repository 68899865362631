import React, { useState } from "react"
import styles from "./modalWhatsAppInbox.module.css"
import Modal from '../index'
import BreakForm from '../../Form/BreakForm/index'
import Button from "../../Form/Button"
import FormGroup from "../../Form/FormGroup"
import FormGroupInline from '../../Form/FormGroupInline'
import Input from '../../Form/Input'
import Select from '../../Form/Select'
import { FaPencilAlt } from "react-icons/fa"
import { MdOutlineWhatsapp } from "react-icons/md"
import { TbReload } from "react-icons/tb"
import { getOptionByOptionList, getPropertyForItemInArrayById } from "../../../utils/utils"
import { listAssignTo } from "../../../schemas/Chat"

const listOptions = [
    { id: 1, value: "Sim" },
    { id: 0, value: "Não" },
]
const chatBotOptions = [
    { id: "okttoBotAI", value: "Oktto AI" },
]
const contactExistsOptions = [
    { id: "addToOwnerLead", value: "Atribuir aos responsáveis pelo lead" },
    { id: "default", value: "Seguir atribuição padrão" },
]
const finishedConversationOptions = [
    { id: "resetOwner", value: "Redefinir responsáveis" },
    { id: "keepAsIs", value: "Manter como está" },
]

const ModalWhatsAppInbox = ({ inbox = {}, teams = [], users = [], usersWithAuto = [], roles = [], chatBots = [], onSubmit = () => { }, getQRCodeByConnect = () => { }, closeModal = () => { } }) => {
    const [newInbox, setNewInbox] = useState(inbox)
    const [editable, setEditable] = useState(false)

    const handleChangeValue = (name, value) => {
        setNewInbox(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setEditable(false)
        setNewInbox(inbox)
    }

    const handleSave = (e) => {
        e.preventDefault()
        onSubmit(newInbox)
    }

    const getQRCode = (e) => {
        e.preventDefault()
        getQRCodeByConnect(inbox._id)
    }

    const refreshQrCode = () => {
        getQRCodeByConnect(inbox._id)
    }

    return (
        <Modal
            icon={<MdOutlineWhatsapp />}
            title={"Caixa de entrada WhatsApp"}
            description={"Configure sua caixa de entrada"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroupInline>
                    <FormGroup labelText="Nome">
                        {editable ?
                            <Input type="text" placeholder="Nome da caixa de entrada" value={newInbox?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                            :
                            <span>{newInbox.name ?? "-"}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className={`${styles.editButton} action bg-purple`} onClick={() => setEditable(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                {/* <FormGroup labelText="Sincronizar Histórico de Conversas">
                    {editable ?
                        <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={listOptions || []} selected={newInbox?.sync_full_history ?? ""} onChange={(value) => handleChangeValue("sync_full_history", value)} multiple={false} />
                        :
                        <span>{getPropertyForItemInArrayById(+newInbox.sync_full_history, listOptions, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Ignorar Grupos">
                    {editable ?
                        <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={listOptions || []} selected={newInbox?.groups_ignore ?? ""} onChange={(value) => handleChangeValue("groups_ignore", value)} multiple={false} />
                        :
                        <span>{getPropertyForItemInArrayById(+newInbox.groups_ignore, listOptions, "id", "value") || "-"}</span>
                    }
                </FormGroup> */}
                <FormGroup labelText="Equipes">
                    {editable ?
                        <Select name="Equipes" placeholder="Pesquise uma opção" options={teams || []} selected={newInbox?.teams ?? ""} onChange={(value) => handleChangeValue("teams", value)} multiple={true} />
                        :
                        <span>{getOptionByOptionList(newInbox.teams, teams, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Usuários">
                    {editable ?
                        <Select name="Usuários" placeholder="Pesquise uma opção" options={users || []} selected={newInbox?.users ?? ""} onChange={(value) => handleChangeValue("users", value)} multiple={true} />
                        :
                        <span>{getOptionByOptionList(newInbox.users, users, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                <BreakForm title="Chatbot" showAction={false} />
                <FormGroup labelText="Ativar">
                    {editable ?
                        <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={listOptions || []} selected={+newInbox?.is_active_bot ?? ""} onChange={(value) => handleChangeValue("is_active_bot", value)} multiple={false} />
                        :
                        <span>{getPropertyForItemInArrayById(+newInbox.is_active_bot, listOptions, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                {Boolean(+newInbox.is_active_bot) &&
                    <>
                        <FormGroup labelText="Tipo">
                            {editable ?
                                <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={chatBotOptions || []} selected={newInbox?.integration ?? ""} onChange={(value) => handleChangeValue("integration", value)} multiple={false} />
                                :
                                <span>{getPropertyForItemInArrayById(newInbox.integration, chatBotOptions, "id", "value") || "-"}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Modelo">
                            {editable ?
                                <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={chatBots?.filter(chatbot => chatbot?.is_active && chatbot?.type === newInbox?.integration)?.map(chatbot => ({ id: chatbot._id, value: chatbot?.name })) || []} selected={newInbox?.chat_bot_id ?? ""} onChange={(value) => handleChangeValue("chat_bot_id", value)} multiple={false} />
                                :
                                <span>{getPropertyForItemInArrayById(newInbox.chat_bot_id, chatBots, "_id", "name") || "-"}</span>
                            }
                        </FormGroup>
                    </>
                }
                <BreakForm title="Conversas" showAction={false} />
                <FormGroup labelText="Atribuição de novas conversas">
                    {editable ?
                        <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={listAssignTo || []} selected={newInbox?.assignNewConversation ?? ""} onChange={(value) => handleChangeValue("assignNewConversation", value)} multiple={false} />
                        :
                        <span>{getPropertyForItemInArrayById(newInbox.assignNewConversation, listAssignTo, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                {newInbox?.assignNewConversation === "teamAndRole" &&
                    <>
                        <FormGroup labelText="Equipe">
                            {editable ?
                                <Select name="Selecione uma opção..." placeholder="Pesquisar..." options={teams} selected={newInbox?.team_id ?? ""} multiple={false} onChange={(value) => handleChangeValue("team_id", value)} />
                                :
                                <span>{getPropertyForItemInArrayById(newInbox.team_id, teams, "id", "value") || "-"}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Cargo">
                            {editable ?
                                <Select name="Selecione uma opção..." placeholder="Pesquisar..." options={roles} selected={newInbox?.role_id ?? ""} multiple={false} onChange={(value) => handleChangeValue("role_id", value)} />
                                :
                                <span>{getPropertyForItemInArrayById(newInbox.role_id, roles, "id", "value") || "-"}</span>
                            }
                        </FormGroup>
                    </>
                }
                {newInbox?.assignNewConversation === "users" &&
                    <FormGroup labelText="Usuários">
                        {editable ?
                            <Select name="Usuários" placeholder="Pesquisar..." options={usersWithAuto} selected={newInbox?.usersOwner ?? []} multiple={true} onChange={(value) => handleChangeValue("usersOwner", value)} />
                            :
                            <span>{getOptionByOptionList(newInbox.usersOwner, usersWithAuto, "id", "value") || "-"}</span>
                        }
                    </FormGroup>
                }
                <FormGroup labelText="O que fazer quando um contato existe em um lead">
                    {editable ?
                        <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={contactExistsOptions || []} selected={newInbox?.leadContactExists ?? ""} onChange={(value) => handleChangeValue("leadContactExists", value)} multiple={false} />
                        :
                        <span>{getPropertyForItemInArrayById(newInbox.leadContactExists, contactExistsOptions, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="O que fazer quando a conversa for finalizada">
                    {editable ?
                        <Select name="Selecione uma opção..." placeholder="Pesquise uma opção" options={finishedConversationOptions || []} selected={newInbox?.finishedConversation ?? ""} onChange={(value) => handleChangeValue("finishedConversation", value)} multiple={false} />
                        :
                        <span>{getPropertyForItemInArrayById(newInbox.finishedConversation, finishedConversationOptions, "id", "value") || "-"}</span>
                    }
                </FormGroup>
                {!editable &&
                    <>
                        <BreakForm title="Conexão" showAction={false} />
                        <FormGroup labelText="Status">
                            <span>{inbox?.status === "disconnected" ? "Desconectado" : "Conectado"}</span>
                        </FormGroup>
                        {inbox?.status === "disconnected" && (
                            inbox?.qrCode?.base64 ?
                                <div className={styles.groupQrCode}>
                                    <div className={styles.qrCode}>
                                        {inbox?.qrCode?.expired ?
                                            <TbReload className={styles.reload} onClick={refreshQrCode} />
                                            :
                                            <img src={inbox?.qrCode?.base64} alt="QR Code" />
                                        }
                                    </div>
                                    <span>Abra seu WhatsApp e escaneie o QR Code para conectar a sua conta!</span>
                                </div>
                                :
                                <Button className='bg-pink' onClick={getQRCode}>Conectar</Button>
                        )}
                    </>
                }
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalWhatsAppInbox