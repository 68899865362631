import React, { useEffect, useState } from "react"
import Input from "../../components/Form/Input"
import styles from './selectAccount.module.css'
import api from "../../services/api"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Table from "../../components/Table"
import Button from "../../components/Form/Button"
import Loading from "../layouts/Loading"

const SelectAccount = () => {
    const [textSearch, setTextSearch] = useState("")
    const [accounts, setAccounts] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        getListAccounts()
    }, [])

    function getListAccounts() {
        setLoading(true)
        api.get("/accounts").then(response => {
            if (response?.data?.data) {
                setAccounts(response.data.data)
            }
            setLoading(false)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }

    const getFilteredValues = () => {
        const values = accounts
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map(account => getDataNewAccount(account))
        return textSearch && filteredValues ? filteredValues?.filter(row => {
            for (let i = 0; i < row.value.length; i++) {
                if (row?.value[i] && row.value[i]?.toString()?.toLowerCase().indexOf(textSearch?.toLowerCase()) !== -1) {
                    return true
                }
            }
            return false
        }) : filteredValues
    }

    const getDataNewAccount = (newAccount) => {
        return {
            id: newAccount._id,
            name: newAccount.name,
            value: [
                newAccount.name,
                <Button className={`${styles.button} ${!newAccount?.data_user?.accepted ? "bg-purple" : ""}`}>{newAccount?.data_user?.accepted ? "Acessar" : "Aceitar Convite"}</Button>
            ]
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p>Selecione uma conta abaixo:</p>
                <div className={styles.formGroup}>
                    <Input
                        type="text"
                        placeholder="Digite o nome da conta..."
                        value={textSearch}
                        onChange={(e) => setTextSearch(e.target.value)}
                        autoFocus={true}
                    />
                </div>
            </div>
            <Table
                values={getFilteredValues()}
                onClick={(account_id) => navigate(`/${account_id}/crm`)}
            />
            {loading && <Loading fullPage={true} />}
        </div>
    )
}

export default SelectAccount