import React, { useEffect, useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import { MdOutlineContactPage } from "react-icons/md"
import { toast } from "react-toastify"
import Select from "../../Form/Select"
import { sortValuesByPropertyAndDirection } from "../../../utils/utils"

const ModalStartChat = ({ value = {}, inboxes = [], contacts = [], onSubmit = () => { }, closeModal = () => { } }) => {
    const [listContacts, setListContacts] = useState([])
    const [contact, setContact] = useState(value)

    useEffect(() => {
        setContactsBylist(contacts?.sort((a, b) => sortValuesByPropertyAndDirection(a, b, "name")))
    }, [contacts])

    useEffect(() => {
        if (contact?.phone) {
            handleChangeValue("contact_id", undefined)
        }
    }, [contact?.phone])

    const setContactsBylist = (contacts) => {
        setListContacts(contacts.map(contact => {
            const name = `${contact.name} ${!contact.is_group && contact?.whatsapp?.number ? `(${contact?.whatsapp?.number})` : ''}`
            return {
                id: contact._id,
                value: name
            }
        }))
    }

    const handleChangeValue = (name, value) => {
        setContact(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        if (contact.inbox_id && (contact.contact_id || contact.phone)) {
            onSubmit(contact)
        } else {
            toast.error("Preencha todos os campos para prosseguir!")
        }
    }

    return (
        <Modal
            icon={<MdOutlineContactPage />}
            title={"Iniciar Conversa"}
            description={"Preencha os campos abaixo e clique em iniciar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                {!value?.contact_id && !contact?.contact_id &&
                    <FormGroup labelText="Celular">
                        <Input type="phone" placeholder="+55 99 9 9999-9999" value={contact?.phone ?? ""} onChange={(e) => handleChangeValue("phone", e.target.value)} disabled={value?.contact_id ? true : false} />
                    </FormGroup>
                }
                {!value?.contact_id && !value?.phone && !contact?.phone &&
                    <FormGroup labelText="Contatos">
                        <Select name="Selecione uma opção..." placeholder="Buscar uma caixa de entrada" multiple={false} options={listContacts} selected={contact?.contact_id ?? ""} onChange={(value) => handleChangeValue("contact_id", value)} />
                    </FormGroup>
                }
                <FormGroup labelText="Caixa de Entrada">
                    <Select name="Selecione uma opção..." placeholder="Buscar uma caixa de entrada" multiple={false} options={inboxes} selected={contact?.inbox_id ?? ""} onChange={(value) => handleChangeValue("inbox_id", value)} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Iniciar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalStartChat