import React, { useEffect, useRef, useState } from "react"
import styles from "./cardTask.module.css"
import { MdBlock, MdMoreHoriz, MdOutlineAlarm, MdOutlineCalendarToday, MdOutlineCheck, MdOutlineCheckCircleOutline, MdOutlineCircle, MdOutlineEdit, MdOutlineKeyboardArrowRight } from "react-icons/md"
import FormGroup from "../../../Form/FormGroup"
import Select from "../../../Form/Select"
import Button from "../../../Form/Button"
import Input from "../../../Form/Input"
import TimePicker from "../../../Form/TimePicker"
import DatePicker from "../../../Form/DatePicker"
import moment from "moment"
import Textarea from "../../../Form/Textarea"
import { useData } from "../../../../contexts/dataContext"
import { adjustTextByIndentation, getPropertyForItemInArrayById, verifyIfDateIsValid } from "../../../../utils/utils"

const CardTask = ({ value = {}, onSubmit = () => { }, onCancel = () => { }, onUpdate = () => { } }) => {
    const [data, setData] = useState(value)
    const [edit, setEdit] = useState(data.isNew || false)
    const [editHour, setEditHour] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const [styleAction, setStyleAction] = useState({})

    const { typesTask } = useData()

    const refAction = useRef()

    useEffect(() => {
        if (value?.scheduled_date) {
            value.date = moment(value.scheduled_date).format("YYYY-MM-DD")
            value.time = moment(value.scheduled_date).format("HH:mm")
        }
        setData(value)
    }, [value])

    const handleToogleShowActions = (e) => {
        e.stopPropagation()
        setShowActions(previousValue => {
            if (!previousValue) {
                adjustPositionActions()
            }
            return !previousValue
        })
    }

    const adjustPositionActions = () => {
        const heightWindow = window.innerHeight
        const positions = refAction.current.getBoundingClientRect()
        if ((heightWindow - (positions.top + positions.height)) > heightWindow / 3) {
            const top = `calc(${positions.height}px + ${positions.top}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px + 16px)`
            setStyleAction({ top, right })
        } else {
            const bottom = `calc(${heightWindow}px - ${positions.bottom}px + ${positions.height}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px + 16px)`
            setStyleAction({ bottom, right })
        }
    }

    const onToogleExpanded = () => {
        setExpanded(previousValue => !previousValue)
    }

    const onChangeValues = (name, value) => {
        setData(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleSubmit = () => {
        setEdit(false)
        setEditHour(false)
        if (data.isNew) {
            onSubmit(data).catch(() => {
                setEdit(true)
            })
        } else {
            onUpdate(data._id, data).catch(() => {
                setEdit(true)
            })
        }
    }

    const handleCancel = () => {
        setData(value)
        setEdit(false)
        setEditHour(false)
        onCancel()
    }

    const handleEdit = () => {
        setEdit(true)
        setExpanded(true)
    }

    const handleEditHour = () => {
        setEditHour(true)
        setExpanded(true)
    }

    const handleFinish = () => {
        const canceled = data.canceled
        onChangeValues("finished", true)
        onChangeValues("canceled", false)
        onUpdate(data._id, { finished: true }).catch(() => {
            onChangeValues("finished", false)
            onChangeValues("canceled", canceled)
        })
    }

    const handleCanceled = () => {
        const finished = data.finished
        onChangeValues("finished", false)
        onChangeValues("canceled", true)
        onUpdate(data._id, { canceled: true }).catch(() => {
            onChangeValues("finished", finished)
            onChangeValues("canceled", false)
        })
    }

    const handleActive = () => {
        const canceled = data.canceled
        const finished = data.finished
        onChangeValues("finished", false)
        onChangeValues("canceled", false)
        onUpdate(data._id, { finished: false, canceled: false }).catch(() => {
            onChangeValues("finished", finished)
            onChangeValues("canceled", canceled)
        })
    }

    const actions = [
        {
            label: "Concluir",
            onClick: handleFinish
        },
        {
            label: "Editar",
            onClick: handleEdit
        },
        {
            label: "Remarcar",
            onClick: handleEditHour
        },
        {
            label: "Cancelar",
            onClick: handleCanceled
        }
    ]

    return (
        <div className={`${styles.card} ${data.canceled ? styles.canceled : ''} ${data.finished ? styles.finished : ''}`}>
            <div className={`${styles.header} ${styles.bottomBorder}`}>
                <div className={styles.left}>
                    {data.isNew ?
                        <span className={styles.name}>Nova Tarefa</span>
                        :
                        <>
                            <MdOutlineKeyboardArrowRight className={`${styles.expand} ${expanded ? styles.rotate : ''}`} onClick={onToogleExpanded} />
                            {!data.finished && !data.canceled ?
                                <>
                                    <span>Criado por: </span><span className={styles.name}>{data.user_created}</span>
                                </>
                                :
                                <>
                                    <span>{data.finished ? "Concluído" : "Cancelado"} por: </span><span className={styles.name}>{data.user_done}</span>
                                </>
                            }
                        </>
                    }
                </div>
                <div className={styles.right}>
                    <MdOutlineCalendarToday />
                    {!data.finished && !data.canceled ?
                        (edit || editHour ?
                            <>
                                <DatePicker name="Data" theme="noBorder" min={moment().format("YYYY-MM-DD")} dateStart={data.date} onChange={(value) => verifyIfDateIsValid(value) && onChangeValues("date", new Date(`${value} `).toISOString())} />
                                <TimePicker name="Hora" theme="noBorder" time={data.time} onChange={(value) => onChangeValues("time", value)} />
                            </>
                            :
                            <span>{moment(data.date).format("DD/MM/YYYY") || "xx/xx/xxxx"} {data.time || "xx:xx"}</span>)
                        :
                        <span>{moment(data.done_in).format("DD/MM/YYYY HH:mm:ss") || "xx/xx/xxxx xx:xx"}</span>
                    }
                    {!edit && !expanded && !data.finished && !data.canceled &&
                        <div className={styles.actions} ref={refAction} onClick={handleToogleShowActions}>
                            <MdMoreHoriz className={styles.expand} />
                            {showActions &&
                                <div className={styles.background}>
                                    <ul style={styleAction}>
                                        {actions && actions.map((action, index) => (
                                            <li key={index} onClick={action.onClick}>{action.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomBorder}`}>
                {(!edit && !editHour) &&
                    <div className={styles.action}>
                        {data.finished ?
                            <MdOutlineCheckCircleOutline className={styles.finished} onClick={handleActive} />
                            :
                            (data.canceled ?
                                <MdBlock className={styles.canceled} onClick={handleActive} />
                                :
                                <MdOutlineCircle className={styles.finish} onClick={handleFinish} />
                            )}

                    </div>
                }
                <div className={styles.content}>
                    {edit ?
                        <>
                            <FormGroup labelText="Título">
                                <Input placeholder="Título da tarefa" theme="noBorder" value={data.title || ''} onChange={(e) => onChangeValues("title", e.target.value)} />
                            </FormGroup>
                            <FormGroup labelText="Descrição">
                                <Textarea placeholder="Descrição da tarefa" rows={3} theme="noBorder" value={data.description || ''} onChange={(e) => onChangeValues("description", e.target.value)} />
                            </FormGroup>
                        </>
                        :
                        <>
                            <span className={styles.title}>{data.title || ''}</span>
                            <span className={styles.description}>{adjustTextByIndentation(data.description) || ''}</span>
                        </>
                    }

                </div>
            </div>
            {(expanded || data.isNew) &&
                <div className={styles.footer}>
                    <div className={`${styles.inlineGroup} ${styles.bottomBorder}`}>
                        <FormGroup labelText="Tipo">
                            {edit ?
                                <Select multiple={false} name="Tipo" placeholder="Selecione um tipo" options={typesTask} selected={data.type_task_id} onChange={(value) => onChangeValues("type_task_id", value)} theme="noBorder" />
                                :
                                <span>{getPropertyForItemInArrayById(data.type_task_id, typesTask, "id", "value")}</span>
                            }
                        </FormGroup>
                    </div>
                    <div className={`${styles.inlineGroup} ${styles.bottomBorder}`}>
                        {data.finished || data.canceled ?
                            <div className={styles.message}>
                                <span>Criado em: {moment(data.created_at).format("DD/MM/YYYY HH:mm:ss")} por {data.user_created}</span>
                                <p>Agendado para: {moment(data.date).format("DD/MM/YYYY")} {data.time}</p>
                            </div>
                            :
                            edit || editHour ?
                                <>
                                    <Button className="bg-pink" onClick={handleSubmit}>Salvar <MdOutlineCheck /></Button>
                                    <Button className="bg-purple" onClick={handleCancel}>Cancelar <MdBlock /></Button>
                                </>
                                :
                                <>
                                    <Button className="bg-pink" onClick={handleFinish}>Concluir <MdOutlineCheck /></Button>
                                    <Button className="bg-orange" onClick={handleEdit}>Editar <MdOutlineEdit /></Button>
                                    <Button className="bg-purple" onClick={handleEditHour}>Remarcar <MdOutlineAlarm /></Button>
                                </>
                        }
                    </div>
                </div >
            }
        </div >
    )
}

export default CardTask