import React, { useState } from "react";
import { MdOutlineList } from "react-icons/md";
import Modal from './index';
import ConfigProducts from '../ConfigProducts/index';

const ModalProducts = ({ isNew = false, value = [{ name: "", childs: [] }], editable = false, setEdit, onSubmit, closeModal }) => {
    const [products, setProducts] = useState(value)

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setProducts(value)
        }
    }

    return (
        <Modal
            icon={<MdOutlineList />}
            title={
                isNew
                    ? "Cadastro de Produtos"
                    : editable
                        ? `Editando os Produtos`
                        : `Visualizando os Produtos`
            }
            description={
                isNew
                    ? "Adicione os produtos a sua conta"
                    : editable
                        ? "Altere as informações abaixo e clique no botão salvar"
                        : `Caso queira editar as informações clique no ícone de edição`
            }
            closeModal={closeModal}
        >
            <ConfigProducts
                value={products}
                onSubmit={onSubmit}
                onCancel={handleCancel}
                editable={editable}
                isNew={isNew}
            />
        </Modal>
    )
}

export default ModalProducts