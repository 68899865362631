import api from "../services/api"
import { toast } from "react-toastify"

const getReasonsDiscard = async () => {
    try {
        const response = await api.get(`/reasonDiscards`)
        const reasons = response.data?.data?.map(reason => { return { id: reason._id, value: reason.name } })
        return reasons
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getReasonsDiscard }