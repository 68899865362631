import React from "react";
import Modal from './index';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import FormGroupInline from '../Form/FormGroupInline';

const ModalConfirm = ({ icon, title = "", description = "", text = "", onSubmit, closeModal }) => {
    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }

    return (
        <Modal
            icon={icon}
            title={title}
            description={description}
            closeModal={closeModal}
        >
            <form onSubmit={handleSubmit}>
                <FormGroupInline>
                    <FormGroup>
                        <span>{text}</span>
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Confirmar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalConfirm