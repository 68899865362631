import React, { useEffect, useState } from "react"
import styles from "./transactions.module.css"
import { useParams } from 'react-router-dom'
import Loading from "../../layouts/Loading"
import { toast } from "react-toastify"
import api from "../../../services/api"
import StatusPaid from "./StatusPaid"

const BankSlip = () => {
    const [loading, setLoading] = useState(true)
    const [paid, setPaid] = useState(false)
    const [invalid, setInvalid] = useState(false)

    const { transaction_id, charge_id } = useParams()

    useEffect(() => {
        if (transaction_id && charge_id) {
            getBankSlip()
        }
    }, [transaction_id, charge_id])

    const getBankSlip = async () => {
        try {
            const response = await api.get(`/accounts/transactions/${transaction_id}/${charge_id}/bank-slip`).finally(() => setLoading(false))
            if (response?.data?.data?.paid) {
                setPaid(true)
            } else if (response?.data?.data?.invalid) {
                setInvalid(true)
            } else if (response?.data?.data?.link) {
                const link = response?.data?.data?.link
                const a = document.createElement("a")
                a.setAttribute("href", link)
                a.click()
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    return (
        <div className={styles.container}>
            {(paid || invalid) && <StatusPaid paid={paid} invalid={invalid} />}
            {loading && <Loading fullPage={true} initialLoad={true} />}
        </div>
    )
}

export default BankSlip