import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Routes from './routes'
import { AuthProvider } from './contexts/authContext'
import { ModalProvider } from './contexts/modalContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ModalProvider>
        <Routes />
      </ModalProvider>
    </AuthProvider>
  </React.StrictMode>
)
