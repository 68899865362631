import React, { useEffect, useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import Button from "../../Form/Button"
import FormGroup from "../../Form/FormGroup"
import FormGroupInline from '../../Form/FormGroupInline'
import Contact from "../../Contact"
import { MdOutlineEmail, MdOutlinePhone, MdOutlineWhatsapp, MdOutlineEdit, MdOutlineCheck, MdOutlineClose, MdOutlineThumbUp, MdOutlineThumbDown, MdOutlinePermContactCalendar, MdFormatListBulleted, MdOutlineBusiness } from "react-icons/md"
import { useCrm } from "../../../contexts/crmContext"
import styles from './modalLead.module.css'
import cool from "../../../images/cool.svg"
import warm from "../../../images/warm.svg"
import hot from "../../../images/hot.svg"
import Address from "../../Address"
import GroupDataEdit from "./GroupDataEdit/GroupDataEdit"
import Product from "../../Product"
import api from "../../../services/api"
import { toast } from "react-toastify"
import Select from "../../Form/Select"
import Utm from "../../Utm"
import Historic from "../../Crm/Historic"
import Interations from "../../Crm/Interations"
import Notes from "../../Crm/Notes"
import Sales from "../../Crm/Sales"
import Tasks from "../../Crm/Tasks"
import ModalSale from "../ModalSale"
import { maskCurrency, maskDate, maskDocument, maskPhone, maskZipCode } from "../../../utils/formatter"
import ModalDiscard from "../ModalDiscard"
import { useWebSocketContext } from "../../../contexts/webSocketContext.js"
import { getIdsUsersByTeamId, getNameFunnelById, getOptionByOptionList, getPropertyForItemInArrayById, getUniqueId, getUsersByTeamId } from "../../../utils/utils"
import DatePicker from "../../Form/DatePicker/index.js"
import Flag from "../../Flag/index.js"
import { useData } from "../../../contexts/dataContext.js"
import { getStages } from "../../../schemas/Stage.js"
import Loading from "../../../pages/layouts/Loading/index.js"
import MenuToogle from "../../MenuToogle/index.js"
import { useParams } from "react-router-dom"

const ModalLead = ({ value = {}, permissions = {}, setValue = () => { }, updateLeadById = () => { }, closeModal }) => {
    const [activeMenuInfos, setActiveMenuInfos] = useState(1)
    const [activeMenuCenter, setActiveMenuCenter] = useState(1)
    const [lead, setLead] = useState(value)
    const [editLead, setEditLead] = useState({})
    const [listStages, setListStages] = useState([])
    const [listFunnels, setListFunnels] = useState([])
    const [modalSale, setModalSale] = useState(false)
    const [modalDiscard, setModalDiscard] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [loading, setLoading] = useState(false)

    const { account_id } = useParams()

    const { handleUpdateLead, handleCallToLead, handleMailToLead, handleWhatsAppToLead, changeStages, getStatusLeadByLastActivity, voipActive, handleDeleteLead } = useCrm()
    const { funnels, additionalFields, teams, users, flagsByoptions, origins, flags } = useData()
    const { lastJsonMessage } = useWebSocketContext()

    const actions = permissions?.crm > 1 ? [
        {
            label: "Excluir",
            onClick: handleDeleteLead
        }
    ] : []

    useEffect(() => {
        updateLeadById({ contacts: lead.contacts }, lead._id)
        setValue(previousValue => { return { ...previousValue, contacts: lead.contacts } })
    }, [lead?.contacts])

    useEffect(() => {
        setValue(previousValue => { return { ...previousValue, addresses: lead.addresses } })
    }, [lead?.addresses])

    useEffect(() => {
        setValue(previousValue => { return { ...previousValue, products: lead.products } })
    }, [lead?.products])

    useEffect(() => {
        getStagesByFunnelId()
    }, [lead.funnel_id])

    useEffect(() => {
        setListFunnels(funnels?.map(funnel => { return { id: funnel._id, value: funnel.name } }))
    }, [funnels])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "UPDATE_CONTACT_LEAD") {
            const lead_id = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (lead?._id === lead_id) {
                setLead(previousValue => {
                    return { ...previousValue, contacts: dataUpdate }
                })
            }
        } else if (action === "UPDATE_ADDRESS_LEAD") {
            const lead_id = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (lead?._id === lead_id) {
                setLead(previousValue => {
                    return { ...previousValue, addresses: dataUpdate }
                })
            }
        } else if (action === "UPDATE_PRODUCT_LEAD") {
            const lead_id = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (lead?._id === lead_id) {
                setLead(previousValue => {
                    return { ...previousValue, products: dataUpdate }
                })
            }
        }
    }

    const menuInfos = [
        { id: 1, value: "Contatos" },
        { id: 2, value: "Endereço" },
    ]

    const menuCenter = [
        { id: 1, value: "Tarefas" },
        { id: 2, value: "Anotações" },
        { id: 3, value: "Interações" },
        { id: 4, value: "Histórico" },
        { id: 5, value: "Vendas" },
    ]

    const getStagesByFunnelId = async () => {
        const stages = await getStages(lead.funnel_id)
        setListStages(stages?.map(stage => { return { id: stage._id, value: stage.name } }))
    }

    const handleContactPrimary = (id, update = true) => {
        let [oldContactPrimary] = lead.contacts?.filter(lead => lead.is_primary)
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.contacts = newValue.contacts?.map(contact => {
                if (contact._id === id) {
                    contact.is_primary = true
                } else {
                    contact.is_primary = false
                }
                return contact
            })
            return newValue
        })

        if (update) {
            const updatedContact = lead.contacts.find(contact => contact._id === id)
            if (updatedContact) {
                updatedContact.is_primary = true
                api.patch(`/leads/${lead._id}/contacts/${updatedContact._id}`, updatedContact).then(response => {
                    const newContact = response?.data?.data
                    if (newContact) {
                        handleUpdateContactById(newContact, updatedContact._id)
                    }
                }).catch(err => {
                    toast.error(err?.response?.data?.message || "Algo deu errado!")
                    handleContactPrimary(oldContactPrimary?._id, false)
                })
            }
        }
    }

    const handleAddNewContact = () => {
        const newValue = { ...lead }
        const _id = getUniqueId()
        if (!Array.isArray(newValue.contacts)) {
            newValue.contacts = []
        }
        newValue.contacts.push({ _id, isNew: true })
        setLead(newValue)
    }

    const handleUpdateContactById = (newContact, id, changeStatusNew = true) => {
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.contacts = newValue.contacts?.map(contact => {
                if (contact._id === id) {
                    contact = { ...contact, _id: newContact._id, name: newContact.name, phone: newContact.phone, email: newContact.email }
                    if (changeStatusNew) {
                        contact.isNew = false
                    }
                }
                return contact
            })
            return newValue
        })
    }

    const handleRemoveContact = (id) => {
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.contacts = newValue.contacts?.filter(el => el._id !== id)
            return newValue
        })
    }

    const handleDeleteContact = (id) => {
        setLoading(true)
        api.delete(`/leads/${lead._id}/contacts/${id}`).then(response => {
            handleRemoveContact(id)
            toast.success(response.data.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateContact = (contact) => {
        setLoading(true)
        if (contact.isNew) {
            api.post(`/leads/${lead._id}/contacts`, contact).then(response => {
                const newContact = response?.data?.data
                if (newContact) {
                    handleUpdateContactById(newContact, contact._id)
                }
            }).catch(err => toast.error(err?.response?.data?.message || "Algo deu errado!")).finally(() => setLoading(false))
        } else {
            api.patch(`/leads/${lead._id}/contacts/${contact._id}`, contact).then(response => {
                const newContact = response?.data?.data
                if (newContact) {
                    handleUpdateContactById(newContact, contact._id)
                }
            }).catch(err => toast.error(err?.response?.data?.message || "Algo deu errado!")).finally(() => setLoading(false))
        }
    }

    const handleRemoveAddress = (id) => {
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.addresses = newValue.addresses?.filter(el => el._id !== id)
            return newValue
        })
    }

    const handleDeleteAddress = (id) => {
        setLoading(true)
        api.delete(`/leads/${lead._id}/addresses/${id}`).then(response => {
            handleRemoveAddress(id)
            toast.success(response.data.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateAddress = (address) => {
        setLoading(true)
        if (address.isNew) {
            api.post(`/leads/${lead._id}/addresses`, address).then(response => {
                const newAddress = response?.data?.data
                if (newAddress) {
                    handleUpdateAddressById(newAddress, address._id)
                }
            }).catch(err => toast.error(err?.response?.data?.message || "Algo deu errado!")).finally(() => setLoading(false))
        } else {
            api.patch(`/leads/${lead._id}/addresses/${address._id}`, address).then(response => {
                const newAddress = response?.data?.data
                if (newAddress) {
                    handleUpdateAddressById(newAddress, address._id)
                }
            }).catch(err => toast.error(err?.response?.data?.message || "Algo deu errado!")).finally(() => setLoading(false))
        }
    }

    const handleUpdateAddressById = (newAddress, id) => {
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.addresses = newValue.addresses?.map(address => {
                if (address._id === id) {
                    address = { ...address, _id: newAddress._id, city: newAddress.city, complement: newAddress.complement, country: newAddress.country, district: newAddress.district, number: newAddress.number, road: newAddress.road, uf: newAddress.uf, zip_code: newAddress.zip_code, isNew: false }
                }
                return address
            })
            return newValue
        })
    }

    const handleAddNewAddress = () => {
        const newValue = { ...lead }
        const _id = getUniqueId()
        if (!Array.isArray(newValue.addresses)) {
            newValue.addresses = []
        }
        newValue.addresses.push({ _id, isNew: true })
        setLead(newValue)
    }

    const handleRemoveProduct = (id) => {
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.products = newValue.products?.filter(el => el._id !== id)
            return newValue
        })
    }

    const handleDeleteProduct = (id) => {
        setLoading(true)
        api.delete(`/leads/${lead._id}/products/${id}`).then(response => {
            handleRemoveProduct(id)
            changeStages(lead.funnel_id)
            toast.success(response.data.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateProduct = (product) => {
        setLoading(true)
        if (product.isNew) {
            api.post(`/leads/${lead._id}/products`, product).then(response => {
                const newProduct = response?.data?.data
                if (newProduct) {
                    handleUpdateProductById(newProduct, product._id)
                }
                changeStages(lead.funnel_id)
            }).catch(err => toast.error(err?.response?.data?.message || "Algo deu errado!")).finally(() => setLoading(false))
        } else {
            api.patch(`/leads/${lead._id}/products/${product._id}`, product).then(response => {
                const newProduct = response?.data?.data
                if (newProduct) {
                    handleUpdateProductById(newProduct, product._id)
                }
                changeStages(lead.funnel_id)
            }).catch(err => toast.error(err?.response?.data?.message || "Algo deu errado!")).finally(() => setLoading(false))
        }
    }

    const handleUpdateProductById = (newProduct, id) => {
        setLead(previousValue => {
            const newValue = { ...previousValue }
            newValue.products = newValue.products?.map(product => {
                if (product._id === id) {
                    product = { ...product, _id: newProduct._id, product_id: newProduct.product_id, value: newProduct.value, description: newProduct.description, isNew: false }
                }
                return product
            })
            return newValue
        })
    }

    const handleAddNewProduct = () => {
        const newValue = { ...lead }
        const _id = getUniqueId()
        if (!Array.isArray(newValue.products)) {
            newValue.products = []
        }
        newValue.products.push({ _id, isNew: true })
        setLead(newValue)
    }

    const onChangeLead = (name, value) => {
        setLead(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const onChangeBusinessLead = (name, value, address = false) => {
        if (address) {
            setLead(previousValue => {
                return { ...previousValue, business: { ...previousValue?.business, address: { ...previousValue?.business?.address, [name]: value } } }
            })
        } else {
            setLead(previousValue => {
                return { ...previousValue, business: { ...previousValue.business, [name]: value } }
            })
        }
    }

    const onChangeAdditionalFieldLead = (additional_field_id, value) => {
        setLead(previousValue => {
            const additionalFields = [...(previousValue?.additional_fields || [])]
            const fieldExists = Array.isArray(additionalFields) && additionalFields.some(field => field.additional_field_id === additional_field_id)

            let additional_fields_update
            if (fieldExists) {
                additional_fields_update = additionalFields?.map(field => {
                    if (field.additional_field_id === additional_field_id) {
                        field.value = value
                    }
                    return field
                })
            } else {
                additionalFields.push({ additional_field_id, value })
                additional_fields_update = additionalFields
            }

            return { ...previousValue, additional_fields: additional_fields_update }
        })
    }

    const onChangeEditLead = (name, value) => {
        setEditLead(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const onCancelEditLead = (name) => {
        if (name === "team_users") {
            setLead(previousValue => {
                return { ...previousValue, "team_id": value.team_id, "users": value.users }
            })
        } else if (name === "funnel_stage") {
            setLead(previousValue => {
                return { ...previousValue, "funnel_id": value.funnel_id, "stage_id": value.stage_id }
            })
        } else {
            setLead(previousValue => {
                return { ...previousValue, [name]: value[name] }
            })
        }
        onChangeEditLead(name, false)
    }

    const onUpdateLead = (name, data) => {
        let dataUpdate = data
        if (name === "team_users") {
            const users = getUsersSelected()
            dataUpdate = { team_id: lead.team_id, users }
        } else if (name === "funnel_stage") {
            dataUpdate = { funnel_id: lead.funnel_id, stage_id: lead.stage_id }
        }
        handleUpdateLead(dataUpdate, lead._id).then(() => {
            onChangeEditLead(name, false)
            setValue(previousValue => { return { ...previousValue, ...dataUpdate } })
            if (name === "team_users") {
                setLead(previousValue => { return { ...previousValue, users: dataUpdate.users } })
            }
        }).catch(() => { })
    }

    const getUsersSelected = () => {
        const idsUsersTeam = getIdsUsersByTeamId(lead.team_id, teams)
        return lead?.users?.filter(user => idsUsersTeam.indexOf(user) !== -1) || []
    }

    const getValueByProducts = () => {
        const value = lead?.products?.reduce((sum, product) => sum + parseFloat(product.value || 0), 0) || 0
        return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    const showModalSale = () => {
        setModalSale(true)
    }

    const closeModalSale = () => {
        setModalSale(false)
    }

    const saveNewSale = (e, data) => {
        e.preventDefault()
        setLoading(true)
        api.post(`/leads/${lead._id}/sales`, data).then(() => {
            closeModalSale()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const showModalDiscard = () => {
        setModalDiscard(true)
    }

    const closeModalDiscard = () => {
        setModalDiscard(false)
    }

    const saveNewDiscard = (e, data) => {
        e.preventDefault()
        setLoading(true)
        api.post(`/leads/${lead._id}/discards`, data).then(() => {
            onChangeLead("discarted", true)
            closeModalDiscard()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const switchToNegotiation = () => {
        setLoading(true)
        api.post(`/leads/${lead._id}/switchToNegotiation`).then(() => {
            onChangeLead("discarted", false)
            closeModalDiscard()
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const getIconByStatusLead = () => {
        const status = getStatusLeadByLastActivity(value.lastActivity, value.stage_id)
        return status === "COOL" ? cool : (status === "WARM" ? warm : hot)
    }

    const getValueByAdditionalField = (additionalFieldId, type = undefined) => {
        const additionalFields = lead?.additional_fields

        if (Array.isArray(additionalFields)) {
            const [fieldExists] = additionalFields?.filter(field => field?.additional_field_id === additionalFieldId) || []
            if (fieldExists) {
                const value = fieldExists?.value
                if (type === "phone") {
                    return maskPhone(value)
                } else if (type === "document") {
                    return maskDocument(value)
                } else if (type === "zipCode") {
                    return maskZipCode(value)
                } else if (type === "currency") {
                    return maskCurrency(value)
                } else if (type === "date") {
                    return maskDate(value)
                }
                return value
            }
        }

        return ""
    }

    const handleStartWhatsAppByLead = (lead) => {
        handleWhatsAppToLead({ ...lead, account_id })
    }

    return (
        <>
            <Modal closeModal={closeModal} fullWidith={true} actions={actions}>
                <div className={`${styles.modalLead} ${activeTab === 2 ? styles.bgGray : ''}`}>
                    <div className={`${styles.left} ${activeTab === 1 ? styles.active : ''}`}>
                        <div className={styles.personalInfos}>
                            <div className={styles.status}>
                                <img src={getIconByStatusLead()} alt="Status do Lead" />
                            </div>
                            <div className={styles.data}>
                                <FormGroupInline>
                                    <div className={styles.name}>
                                        {editLead.name ?
                                            <>
                                                <Input placeholder="Nome" value={lead.name} onChange={(e) => onChangeLead("name", e.target.value)} theme="noBorder" />
                                                <MdOutlineCheck onClick={() => onUpdateLead("name", { "name": lead.name })} />
                                                <MdOutlineClose onClick={() => onCancelEditLead("name")} />
                                            </>
                                            :
                                            <>
                                                <span>{lead.name}</span>
                                                <MdOutlineEdit onClick={() => onChangeEditLead("name", true)} />
                                            </>
                                        }
                                    </div>
                                </FormGroupInline>
                                <FormGroupInline>
                                    {editLead.origin_id ?
                                        <>
                                            <Select multiple={false} name="Origem" options={origins} placeholder="Selecione uma origem" selected={lead.origin_id} onChange={(value) => onChangeLead("origin_id", value)} theme="noBorder" />
                                            <MdOutlineCheck onClick={() => onUpdateLead("origin_id", { "origin_id": lead.origin_id })} />
                                            <MdOutlineClose onClick={() => onCancelEditLead("origin_id")} />
                                        </>
                                        :
                                        <>
                                            <span>{getPropertyForItemInArrayById(lead.origin_id, origins, "id", "value")}</span>
                                            <MdOutlineEdit onClick={() => onChangeEditLead("origin_id", true)} />
                                        </>
                                    }
                                </FormGroupInline>
                            </div>
                            <div className={styles.actions}>
                                <div className={styles.groupCenter}>
                                    <Button onClick={() => handleCallToLead(lead)} disabled={!voipActive}>
                                        <MdOutlinePhone />
                                    </Button>
                                    <span>Ligação</span>
                                </div>
                                <div className={styles.groupCenter}>
                                    <Button onClick={() => handleMailToLead(lead)}>
                                        <MdOutlineEmail />
                                    </Button>
                                    <span>E-mail</span>
                                </div>
                                <div className={styles.groupCenter}>
                                    <Button onClick={() => handleStartWhatsAppByLead(lead)}>
                                        <MdOutlineWhatsapp />
                                    </Button>
                                    <span>WhatsApp</span>
                                </div>
                                {/* <div className={styles.groupCenter}>
                                    <Button>
                                        <MdMoreHoriz />
                                    </Button>
                                    <span>Mais</span>
                                </div> */}
                            </div>
                            <div className={styles.call}>
                                <Button onClick={() => handleCallToLead(lead)} disabled={!voipActive}>
                                    <span>Ligar</span>
                                    <MdOutlinePhone />
                                </Button>
                            </div>
                            <GroupDataEdit name="funnel_stage" edit={editLead.funnel_stage} onChangeEdit={onChangeEditLead} onCancelEdit={onCancelEditLead} onUpdate={onUpdateLead}>
                                <FormGroup labelText="Funil">
                                    {editLead.funnel_stage ?
                                        <Select multiple={false} name="Funil" options={listFunnels} placeholder="Selecione um funil" selected={lead.funnel_id} onChange={(value) => onChangeLead("funnel_id", value)} theme="noBorder" />
                                        :
                                        <span>{getNameFunnelById(lead.funnel_id, listFunnels)}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Etapa">
                                    {editLead.funnel_stage ?
                                        <Select multiple={false} name="Etapa" options={listStages} placeholder="Selecione uma etapa" selected={lead.stage_id} onChange={(value) => onChangeLead("stage_id", value)} theme="noBorder" />
                                        :
                                        <span>{getPropertyForItemInArrayById(lead.stage_id, listStages, "id", "value")}</span>
                                    }
                                </FormGroup>
                            </GroupDataEdit>
                        </div>
                        <div className={styles.groupToogleData}>
                            <MenuToogle
                                options={menuInfos}
                                activeOption={activeMenuInfos}
                                setActiveOption={setActiveMenuInfos}
                            />
                            <div className={styles.body}>
                                {activeMenuInfos && activeMenuInfos === 1 &&
                                    <>
                                        <Button onClick={handleAddNewContact}>Adicionar contato</Button>
                                        {lead.contacts && lead.contacts.map(contact => (
                                            <Contact
                                                key={contact._id}
                                                value={contact}
                                                handleRemove={handleRemoveContact}
                                                handleDelete={handleDeleteContact}
                                                handleFavorite={handleContactPrimary}
                                                handleUpdate={handleUpdateContact}
                                                voipActive={voipActive}
                                                handleCallToLead={handleCallToLead}
                                                handleWhatsAppToLead={handleStartWhatsAppByLead}
                                                handleMailToLead={handleMailToLead}
                                                lead_id={lead._id}
                                                lead_name={lead.name}
                                            />
                                        ))}
                                    </>
                                }
                                {activeMenuInfos && activeMenuInfos === 2 &&
                                    <>
                                        <Button onClick={handleAddNewAddress}>Adicionar Endereço</Button>
                                        {lead.addresses && lead.addresses.map(address => (
                                            <Address
                                                key={address._id}
                                                value={address}
                                                handleRemove={handleRemoveAddress}
                                                handleDelete={handleDeleteAddress}
                                                handleUpdate={handleUpdateAddress}
                                            />
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.center} ${activeTab === 2 ? styles.active : ''}`}>
                        <div className={styles.quickActions}>
                            <Button className="bg-pink" onClick={showModalSale}>Registrar venda <MdOutlineThumbUp /></Button>
                            <Button className="bg-purple" onClick={showModalDiscard}>Descartar lead <MdOutlineThumbDown /></Button>
                        </div>
                        {lead?.discarted &&
                            <div className={styles.divDiscarted}>
                                <div className={styles.discarted}>
                                    <span>Esse lead foi descartado!</span>
                                    <Button onClick={switchToNegotiation}>Reverter Descarte</Button>
                                </div>
                            </div>
                        }
                        <div className={styles.menuToogle}>
                            <div className={styles.menu}>
                                {menuCenter && menuCenter.map(item => (
                                    <span key={item.id} className={item.id === activeMenuCenter ? styles.active : ''} onClick={() => { setActiveMenuCenter(item.id) }}>{item.value}</span>
                                ))}
                            </div>
                        </div>
                        <div className={styles.body}>
                            {activeMenuCenter && activeMenuCenter === 1 &&
                                <Tasks
                                    lead_id={lead._id}
                                />
                            }
                            {activeMenuCenter && activeMenuCenter === 2 &&
                                <Notes
                                    lead_id={lead._id}
                                />
                            }
                            {activeMenuCenter && activeMenuCenter === 3 &&
                                <Interations
                                    lead_id={lead._id}
                                />
                            }
                            {activeMenuCenter && activeMenuCenter === 4 &&
                                <Historic
                                    lead_id={lead._id}
                                />
                            }
                            {activeMenuCenter && activeMenuCenter === 5 &&
                                <Sales
                                    lead_id={lead._id}
                                    onlyFiles={true}
                                />
                            }
                        </div>
                    </div>
                    <div className={`${styles.right} ${activeTab === 3 ? styles.active : ''}`}>
                        <GroupDataEdit name="team_users" edit={editLead.team_users} onChangeEdit={onChangeEditLead} onCancelEdit={onCancelEditLead} onUpdate={onUpdateLead}>
                            <FormGroup labelText="Equipe">
                                {editLead.team_users ?
                                    <Select multiple={false} name="Equipe" options={teams} placeholder="Selecione uma equipe" selected={lead.team_id} onChange={(value) => onChangeLead("team_id", value)} theme="noBorder" />
                                    :
                                    <span>{getPropertyForItemInArrayById(lead?.team_id, teams, "id", "value") || "-"}</span>
                                }
                            </FormGroup>
                            <FormGroup labelText="Responsáveis">
                                {editLead.team_users ?
                                    <Select multiple={true} name="Responsáveis" options={getUsersByTeamId(lead?.team_id, users, teams)} placeholder="Selecione os responsáveis" selected={getUsersSelected()} onChange={(value) => onChangeLead("users", value)} theme="noBorder" />
                                    :
                                    <span>{getOptionByOptionList(lead?.users, users) || "-"}</span>
                                }
                            </FormGroup>
                        </GroupDataEdit>
                        <GroupDataEdit showActions={false}>
                            <FormGroup labelText="Produtos" rightContent={<span>{getValueByProducts()}</span>}>
                                <Button onClick={handleAddNewProduct}>Adicionar produto</Button>
                                {lead.products && lead.products.map(product => (
                                    <Product
                                        key={product._id}
                                        value={product}
                                        handleRemove={handleRemoveProduct}
                                        handleDelete={handleDeleteProduct}
                                        handleUpdate={handleUpdateProduct}
                                    />
                                ))}
                            </FormGroup>
                        </GroupDataEdit>
                        <GroupDataEdit name="flags" value={lead.flags} edit={editLead.flags} onChangeEdit={onChangeEditLead} onCancelEdit={onCancelEditLead} onUpdate={onUpdateLead}>
                            <FormGroup labelText="Etiquetas">
                                <FormGroup inside={true}>
                                    {editLead.flags ?
                                        <Select multiple={true} name="Etiqueta" options={flagsByoptions} placeholder="Selecione as etiquetas" selected={lead.flags} onChange={(value) => onChangeLead("flags", value)} theme="noBorder" />
                                        :
                                        <div className={styles.flags}>
                                            {lead?.flags.map((flag, index) => (
                                                <Flag
                                                    name={getPropertyForItemInArrayById(flag, flags, "_id", "name")}
                                                    color={getPropertyForItemInArrayById(flag, flags, "_id", "color")}
                                                    key={index}
                                                />
                                            ))}
                                        </div>
                                    }
                                </FormGroup>
                            </FormGroup>
                        </GroupDataEdit>
                        <GroupDataEdit name="campaign" value={lead.campaign} edit={editLead.campaign} onChangeEdit={onChangeEditLead} onCancelEdit={onCancelEditLead} onUpdate={onUpdateLead}>
                            <FormGroup labelText="Campanha">
                                {editLead.campaign ?
                                    <Input placeholder="Campanha" value={lead.campaign || ''} onChange={(e) => onChangeLead("campaign", e.target.value)} theme="noBorder" />
                                    :
                                    <span>{lead.campaign}</span>
                                }
                            </FormGroup>
                        </GroupDataEdit>
                        <GroupDataEdit showActions={false}>
                            <FormGroup labelText="Meta Ads">
                                <FormGroup labelText="Ad Name" inside={true}>
                                    <span>{lead?.meta_ads?.ad_name || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Adset Name" inside={true}>
                                    <span>{lead?.meta_ads?.adset_name || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Campaign Name" inside={true}>
                                    <span>{lead?.meta_ads?.campaign_name || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Form Name" inside={true}>
                                    <span>{lead?.meta_ads?.form_name || "-"}</span>
                                </FormGroup>
                                <FormGroup labelText="Page Name" inside={true}>
                                    <span>{lead?.meta_ads?.page_name || "-"}</span>
                                </FormGroup>
                            </FormGroup>
                        </GroupDataEdit>
                        <GroupDataEdit name="additional_fields" value={lead.additional_fields} edit={editLead.additional_fields} onChangeEdit={onChangeEditLead} onCancelEdit={onCancelEditLead} onUpdate={onUpdateLead}>
                            <FormGroup labelText="Campos Adicionais">
                                {additionalFields?.length > 0 && additionalFields.map((field, index) => (
                                    <FormGroup labelText={field?.name || "-"} inside={true} key={index}>
                                        {editLead.additional_fields ?
                                            (field?.type === "date" ?
                                                <DatePicker name={field?.name || "dd/mm/YYYY"} dateStart={getValueByAdditionalField(field?._id)} onChange={(value) => onChangeAdditionalFieldLead(field?._id, value)} multiple={false} theme="noBorder" />
                                                :
                                                <Input type={field?.type || "text"} placeholder={field?.name || "Digite aqui..."} value={getValueByAdditionalField(field?._id)} onChange={(e) => onChangeAdditionalFieldLead(field?._id, e.target.value)} theme="noBorder" />
                                            )
                                            :
                                            <span>{getValueByAdditionalField(field?._id, field?.type) || "-"}</span>
                                        }
                                    </FormGroup>
                                ))}
                                {!additionalFields?.length && <span>Nenhum campo adicional cadastrado até o momento!</span>}
                            </FormGroup>
                        </GroupDataEdit>
                        <GroupDataEdit name="business" value={lead.business} edit={editLead.business} onChangeEdit={onChangeEditLead} onCancelEdit={onCancelEditLead} onUpdate={onUpdateLead}>
                            <FormGroup labelText="Empresa">
                                <FormGroup labelText="Razão Social" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite a Razão Social" value={lead?.business?.business_name || ''} onChange={(e) => onChangeBusinessLead("business_name", e.target.value)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.business_name || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Nome Fantasia" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o Nome Fantasia" value={lead?.business?.fantasy_name || ''} onChange={(e) => onChangeBusinessLead("fantasy_name", e.target.value)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.fantasy_name || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="CPF / CNPJ" inside={true}>
                                    {editLead.business ?
                                        <Input type="document" placeholder="Digite o CPF / CNPJ" value={lead?.business?.document || ''} onChange={(e) => onChangeBusinessLead("document", e.target.value)} theme="noBorder" />
                                        :
                                        <span>{maskDocument(lead?.business?.document) || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="CEP" inside={true}>
                                    {editLead.business ?
                                        <Input type="zipCode" placeholder="Digite o CEP" value={lead?.business?.address?.zip_code || ''} onChange={(e) => onChangeBusinessLead("zip_code", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{maskZipCode(lead?.business?.address?.zip_code) || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="País" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o País" value={lead?.business?.address?.country || ''} onChange={(e) => onChangeBusinessLead("country", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.country || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Estado" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o Estado" value={lead?.business?.address?.uf || ''} onChange={(e) => onChangeBusinessLead("uf", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.uf || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Cidade" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite a Cidade" value={lead?.business?.address?.city || ''} onChange={(e) => onChangeBusinessLead("city", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.city || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Bairro" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o Bairro" value={lead?.business?.address?.district || ''} onChange={(e) => onChangeBusinessLead("district", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.district || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Logradouro" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o Logradouro" value={lead?.business?.address?.road || ''} onChange={(e) => onChangeBusinessLead("road", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.road || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Número" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o Número" value={lead?.business?.address?.number || ''} onChange={(e) => onChangeBusinessLead("number", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.number || '-'}</span>
                                    }
                                </FormGroup>
                                <FormGroup labelText="Complemento" inside={true}>
                                    {editLead.business ?
                                        <Input placeholder="Digite o Complemento" value={lead?.business?.address?.complement || ''} onChange={(e) => onChangeBusinessLead("complement", e.target.value, true)} theme="noBorder" />
                                        :
                                        <span>{lead?.business?.address?.complement || '-'}</span>
                                    }
                                </FormGroup>
                            </FormGroup>
                        </GroupDataEdit>
                        <Utm
                            value={lead.utm}
                            handleUpdate={onUpdateLead}
                            onChangeEdit={onChangeEditLead}
                            edit={editLead.utm}
                        />
                    </div>
                    <div className={styles.navMovile}>
                        <div className={`${styles.item} ${activeTab === 1 ? styles.active : ''}`} onClick={() => setActiveTab(1)}><MdOutlinePermContactCalendar /></div>
                        <div className={`${styles.item} ${activeTab === 2 ? styles.active : ''}`} onClick={() => setActiveTab(2)}><MdFormatListBulleted /></div>
                        <div className={`${styles.item} ${activeTab === 3 ? styles.active : ''}`} onClick={() => setActiveTab(3)}><MdOutlineBusiness /></div>
                    </div>
                </div>
                {modalSale &&
                    <ModalSale
                        isNew={true}
                        value={{ products: lead?.products || [] }}
                        onSubmit={saveNewSale}
                        closeModal={closeModalSale}
                    />
                }
                {modalDiscard &&
                    <ModalDiscard
                        isNew={true}
                        onSubmit={saveNewDiscard}
                        closeModal={closeModalDiscard}
                    />
                }
            </Modal>
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ModalLead