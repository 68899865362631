import React, { useEffect, useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { MdOutlineMailLock } from "react-icons/md";
import FormGroupInline from "../Form/FormGroupInline";

const ModalChangeEmail = ({ waitCode = false, returnStep, onSubmit, closeModal }) => {
    const [data, setData] = useState({})

    useEffect(() => {
        setData(previousValue => { return { ...previousValue, code: undefined } })
    }, [waitCode])

    const handleChangeValue = (name, value) => {
        setData(previousValue => { return { ...previousValue, [name]: value } })
    }

    return (
        <Modal
            icon={<MdOutlineMailLock />}
            title="Alteração de e-mail"
            description={waitCode ? "Preencha o campo abaixo com o código enviado em seu e-mail" : "Defina abaixo seu novo e-mail de acesso"}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, data)}>
                {waitCode ?
                    <>
                        <FormGroup labelText="Digite o código de validação enviado no seu e-mail">
                            <Input type="text" placeholder="Código de validação" value={data.code ?? ""} onChange={(e) => handleChangeValue("code", e.target.value)} />
                        </FormGroup>
                        <FormGroup>

                            <FormGroupInline>
                                <Button className='bg-purple' onClick={returnStep}>Voltar</Button>
                                <Button className='bg-pink' type="submit">Confirmar</Button>
                            </FormGroupInline>
                        </FormGroup>
                    </>
                    :
                    <>
                        <FormGroup labelText="Novo e-mail">
                            <Input type="email" placeholder="Novo e-mail" value={data.email ?? ""} onChange={(e) => handleChangeValue("email", e.target.value)} />
                        </FormGroup>
                        <Button className='bg-pink' type="submit">Avançar</Button>
                    </>
                }
            </form>
        </Modal>
    )
}

export default ModalChangeEmail