import React, { useEffect, useState } from "react";
import styles from "./product.module.css"
import FormGroup from '../Form/FormGroup';
import { MdOutlineEdit, MdOutlineDelete, MdOutlineCheck, MdOutlineClose, MdOutlineDeleteOutline } from "react-icons/md";
import ModalDelete from "../Modal/ModalDelete";
import Input from "../Form/Input";
import Select from "../Form/Select";
import { formatCurrency } from "../../utils/formatter";
import { useData } from "../../contexts/dataContext";
import { getPropertyForItemInArrayById } from "../../utils/utils";

const Product = ({ value = {}, handleRemove = () => { }, handleDelete = () => { }, handleUpdate = () => { } }) => {
    const [edit, setEdit] = useState(value?.isNew)
    const [product, setProduct] = useState(value)
    const [modalDelete, setModalDelete] = useState(false)

    const { products } = useData()

    useEffect(() => {
        setEdit(value?.isNew)
        setProduct(value)
    }, [value])

    const onChangeValues = (name, value) => {
        setProduct(previousValue => {
            return {
                ...previousValue,
                [name]: value
            }
        })
    }

    const cancelEdit = () => {
        if (value?.isNew) {
            handleRemove(product._id)
        } else {
            setProduct(value)
            setEdit(false)
        }
    }

    const openModalDelete = () => {
        setModalDelete(true)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    return (
        <div className={styles.groupProduct}>
            <div className={styles.actions}>
                {!edit ?
                    <>
                        <MdOutlineEdit onClick={() => setEdit(true)} />
                        <MdOutlineDelete onClick={openModalDelete} />
                    </>
                    :
                    <>
                        <MdOutlineCheck onClick={() => handleUpdate(product)} />
                        <MdOutlineClose onClick={cancelEdit} />
                    </>
                }
            </div>
            <div className={styles.data}>
                <FormGroup labelText="Produto">
                    {edit ?
                        <Select multiple={false} name="Produto" placeholder="Selecione um produto" options={products || []} selected={product?.product_id ?? ""} onChange={(value) => onChangeValues("product_id", value)} theme="noBorder" />
                        :
                        <span>{getPropertyForItemInArrayById(product.product_id, products, "id", "value")}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Valor">
                    {edit ?
                        <Input type="currency" placeholder="Digite o Valor" value={product?.value || ''} onChange={(e) => onChangeValues("value", e.target.value)} theme="noBorder" />
                        :
                        <span>{formatCurrency(product?.value) || '-'}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Descrição">
                    {edit ?
                        <Input placeholder="Digite o Descrição" value={product?.description || ''} onChange={(e) => onChangeValues("description", e.target.value)} theme="noBorder" />
                        :
                        <span>{product?.description || '-'}</span>
                    }
                </FormGroup>
            </div>
            {
                modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={handleDelete}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de produto"
                    description="Deseja realmente excluir o produto?"
                    id={product._id}
                />
            }
        </div>
    )
}

export default Product