import { createContext, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import WebPhone from "../components/WebPhone"
import { useAuth } from "./authContext"
import api from "../services/api"
import { useParams } from "react-router-dom"
import { useWebSocketContext } from "./webSocketContext"

const VoipContext = createContext()

const VoipProvider = ({ children }) => {
    const [webphone, setWebphone] = useState()
    const [active, setActive] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [startCall, setStartCall] = useState(false)
    const [callConnected, setCallConnected] = useState(false)
    const [statusCall, setStatusCall] = useState(0)
    const [leadId, setLeadId] = useState()
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [credits, setCredits] = useState(false)
    const [calling, setCalling] = useState(false)
    const [endedCall, setEndedCall] = useState(false)
    const [open, setOpen] = useState(true)
    const [volume, setVolume] = useState(100)
    const [mute, setMute] = useState(false)
    const [hold, setHold] = useState(false)
    const [time, setTime] = useState(0)
    const [inputDevices, setInputDevices] = useState([])
    const [inputSelected, setInputSelected] = useState()
    const [outputDevices, setOutputDevices] = useState([])
    const [outputSelected, setOutputSelected] = useState()
    const [currentCall, setCurrentCall] = useState()
    const [credentials, setCredentials] = useState()
    const [permissionStatus, setPermissionStatus] = useState('')

    const { account_id } = useParams()
    const { verifyIfModuleIsActive } = useAuth()
    const { lastJsonMessage } = useWebSocketContext()

    useEffect(() => {
        return () => {
            handleEndCall()
            if (webphone) {
                webphone.offAny()
                webphone.getUserAgent()?.stop()
            }
            return () => { }
        }
    }, [webphone])

    useEffect(() => {
        if (verifyIfModuleIsActive("VOIP") && account_id) {
            getCredentialsByUser()
            if (credits === false) {
                getCredits()
            }
        }
    }, [verifyIfModuleIsActive, account_id])

    useEffect(() => {
        if (webphone?.getUserAgent()) {
            updateDataByMessageWebSocket(lastJsonMessage)
        }
    }, [lastJsonMessage])

    useEffect(() => {
        if (callConnected && (!active || !calling || connecting || statusCall < 1) && !endedCall) {
            setActive(true)
            setCalling(true)
            setConnecting(false)
            setEndedCall(false)
            setStatusCall(2)
        }
    }, [callConnected])

    useEffect(() => {
        if (startCall && !connecting && !callConnected) {
            if (webphone?.getUserAgent()) {
                if (!webphone.getUserAgent()?.isReady()) {
                    return initWebphone()
                }
                setTime(0)
                setConnecting(true)
                setStatusCall(1)
                api.post("/voip/call", {
                    phone,
                    lead_id: leadId
                }).then(() => {
                    setActive(true)
                    setCalling(true)
                    setConnecting(false)
                    setEndedCall(false)
                    setStatusCall(2)
                    setCallConnected(true)
                }).catch(err => {
                    setConnecting(false)
                    setStatusCall(0)
                    toast.error(err?.response?.data?.message)
                })
            } else {
                if (permissionStatus === "denied") {
                    toast.error("Para utilizar a funcionalidade VoIP conceda permissão de acesso ao microfone!")
                } else {
                    toast.error("Não foi possível completar a chamada!")
                }
            }
        }
    }, [startCall])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "CHANGE_VOIP_CREDITS") {
            const newCredits = message?.data ?? 0
            setCredits(newCredits)
        }
    }

    const getCredentialsByUser = () => {
        api.get("/voip/credentials", { headers: { "x-api-key": account_id } }).then(response => {
            const newCredentials = response?.data?.data || {}
            setCredentials(newCredentials)
        }).catch(err => console.log(err?.message))
    }

    const getCredits = () => {
        api.get("/voip/credits", { headers: { "x-api-key": account_id } }).then(response => {
            const credits = response?.data?.data || 0
            setCredits(credits)
        }).catch(err => console.log(err?.message))
    }

    const getInstanceId = () => {
        function generateRandomHexDigit() {
            return Math.floor(Math.random() * 16)?.toString(16);
        }

        let instanceId = '';
        for (let i = 0; i < 32; i++) {
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                instanceId += '-';
            }
            instanceId += generateRandomHexDigit();
        }

        return instanceId;
    }

    const initWebphone = () => {
        const DOMAIN = credentials.domain
        const EXTENSION = credentials.extension
        const PASSWORD = credentials.password
        const webphone = new window.libwebphone({
            audioContext: {
                renderTargets: ["audio_context"],
            },
            dialpad: {
                renderTargets: ["dialpad"],
                keys: {
                    enter: {
                        action: () => { },
                    },
                },
            },
            mediaDevices: {
                videoinput: {
                    enabled: false,
                }
            },
            userAgent: {
                renderTargets: ['user_agent'],
                transport: {
                    sockets: ['wss://' + DOMAIN + ':6443'],
                    recovery_max_interval: 30,
                    recovery_min_interval: 2,
                },
                authentication: {
                    username: EXTENSION,
                    password: PASSWORD,
                    realm: DOMAIN,
                },
                user_agent: {
                    instance_id: getInstanceId(),
                    no_answer_timeout: 30,
                    register: true,
                    register_expires: 3600,
                    user_agent: 'oktto-libwebphone',
                },
            },
        })

        webphone.on("userAgent.recieved.notify", (lwp, userAgent, data) => {
            let request = data.request
            console.log("userAgent.recieved.notify", request.body)
        })
        webphone.on("mediaDevices.render.rendered", () => {
            const mediaDevices = webphone.getMediaDevices()
            const data = {}
            data.loaded = mediaDevices._loaded
            Object.keys(mediaDevices._availableDevices)?.forEach((deviceKind) => {
                const devices = mediaDevices._availableDevices[deviceKind].slice(0)
                devices.sort((a, b) => {
                    return (a.displayOrder || 0) - (b.displayOrder || 0)
                })
                if (!data[deviceKind]) {
                    data[deviceKind] = {}
                }
                data[deviceKind].devices = devices
            })
            const input = data?.audioinput?.devices
            const inputValues = input?.map(device => {
                if (device.selected) {
                    setInputSelected(device.id)
                }
                return { id: device.id, value: device.name }
            })
            setInputDevices(inputValues || [])

            const output = data?.audiooutput?.devices
            const outputValues = output?.map(device => {
                if (device.selected) {
                    setOutputSelected(device.id)
                }
                return { id: device.id, value: device.name }
            })
            setOutputDevices(outputValues || [])
        })
        webphone.on("mediaDevices.getUserMedia.error", (lwp, mediaDevices, error) => {
            toast.error(error.message)
        })
        webphone.on("userAgent.configuration.error", (lwp, userAgent, error) => {
            toast.error(error.message)
        })
        webphone.on("userAgent.call.invalid", (lwp, userAgent, number, ready) => {
            toast.error("Não foi possível completar a chamada, verifique o número discado e tente novamente!")
        })
        webphone.on("userAgent.call.failed", (lwp, userAgent, error) => {
            toast.error("Não foi possível completar a chamada!")
        })
        webphone.on("call.timeupdate", (...data) => {
            const durationMs = data[3]
            setTime(durationMs || 0)
        })
        webphone.on('call.created', (lwp, currentCall) => {
            if (!currentCall.isPrimary()) {
                currentCall.reject()
            } else if (currentCall?.answer) {
                setCurrentCall(currentCall)
                currentCall.answer()
            }
        })
        webphone.on("call.terminated", () => {
            handleEndCall()
        })
        webphone.onAny((event, ...data) => {
            if (
                event === "call.primary.local.audio.timeupdate"
                || event === "call.primary.remote.audio.timeupdate"
                || event === "call.local.audio.timeupdate"
                || event === "call.timeupdate"
            ) {
                setCallConnected(true)
            }
        })

        if (webphone.getUserAgent()) {
            webphone.getUserAgent().start(EXTENSION, PASSWORD, DOMAIN)
        }

        return webphone
    }

    const handleInitWebPhone = () => {
        if (!webphone && credentials?.domain && credentials?.extension && credentials?.password) {
            const handlePermissionChange = (permissionStatus) => {
                setPermissionStatus(permissionStatus.state)
                if (permissionStatus.state === 'denied') {
                    toast.error("Para utilizar a funcionalidade VoIP conceda permissão de acesso ao microfone!")
                    setWebphone(undefined)
                } else if (!webphone) {
                    setWebphone(initWebphone())
                }
            }

            navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
                handlePermissionChange(permissionStatus)
                permissionStatus.onchange = () => {
                    handlePermissionChange(permissionStatus)
                }
            }).catch(() => toast.error("Para utiilizar a funcionalidade VoIP conceda permissão de acesso ao microfone!"))
        }
    }

    const handleToogleMute = () => {
        setMute(previousValue => {
            if (previousValue) {
                currentCall.unmute()
            } else {
                currentCall.mute()
            }
            return !previousValue
        })
    }

    const handleToogleHold = () => {
        setHold(previousValue => {
            if (previousValue) {
                currentCall.unhold()
            } else {
                currentCall.hold()
            }
            return !previousValue
        })
    }

    const handleToogleOpen = () => {
        setOpen(previousValue => !previousValue)
    }

    const changeInputSelected = (deviceId) => {
        webphone.getMediaDevices().changeDevice('audioinput', deviceId)
    }

    const changeOutputSelected = (deviceId) => {
        webphone.getMediaDevices().changeDevice('audiooutput', deviceId)
    }

    const changeVolumeOutput = (e) => {
        const newVolume = parseInt(e.target.value)
        setVolume(newVolume)

        const audioContext = webphone?.getAudioContext()
        audioContext.changeVolume("master", newVolume / 100)
    }

    const handleStartCall = () => {
        setCallConnected(false)
        setStartCall(true)
    }

    const handleEndCall = () => {
        if (currentCall && currentCall?.isInProgress()) {
            currentCall.reject()
        } else if (currentCall && currentCall?.isEstablished()) {
            currentCall.terminate()
        } else if (currentCall) {
            currentCall.cancel()
        } else {
            currentCall?.reject()
            currentCall?.terminate()
            currentCall?.cancel()
        }
        if (callConnected || calling || statusCall > 0) {
            setEndedCall(true)
        }
        setCallConnected(false)
        setCalling(false)
        setStatusCall(0)
        setTimeout(() => {
            setCallConnected(false)
            setStartCall(false)
        }, 500)
    }

    const onClose = () => {
        if (connecting || calling) {
            handleToogleOpen()
        } else {
            setActive(false)
            handleEndCall()
        }
    }

    const prepareCall = (lead_id, name, phone) => {
        if (!webphone || !webphone?.getUserAgent() || !webphone.getUserAgent()?.isReady()) {
            handleInitWebPhone()
        }
        setLeadId(lead_id)
        setName(name)
        setPhone(phone)
        setActive(true)
        setCalling(false)
        setStatusCall(0)
        setTime(0)
        setEndedCall(false)
        setConnecting(false)
        setMute(false)
        setHold(false)
    }

    return (
        <VoipContext.Provider value={{ prepareCall, statusCall }}>
            {children}
            {active &&
                <WebPhone
                    name={name}
                    phone={phone}
                    credits={credits}
                    calling={calling}
                    endedCall={endedCall}
                    connecting={connecting}
                    callConnected={callConnected}
                    statusCall={statusCall}
                    open={open}
                    volume={volume}
                    mute={mute}
                    hold={hold}
                    time={time}
                    inputDevices={inputDevices}
                    inputSelected={inputSelected}
                    outputDevices={outputDevices}
                    outputSelected={outputSelected}
                    handleToogleMute={handleToogleMute}
                    handleToogleHold={handleToogleHold}
                    handleToogleOpen={handleToogleOpen}
                    changeInputSelected={changeInputSelected}
                    changeOutputSelected={changeOutputSelected}
                    changeVolumeOutput={changeVolumeOutput}
                    handleStartCall={handleStartCall}
                    handleEndCall={handleEndCall}
                    onClose={onClose}
                />
            }
        </VoipContext.Provider>
    )
}

const useVoip = () => {
    const context = useContext(VoipContext)

    return context
}

export { useVoip }

export default VoipProvider