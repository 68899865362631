import React from "react"
import styles from "./message.module.css"
import { MdClose } from "react-icons/md"

const Message = ({ text = "", closeMessage = () => { } }) => {
    return (
        <div className={styles.container}>
            <span>{text}</span>
            <div className={styles.close} onClick={closeMessage}>
                <MdClose />
            </div>
        </div>
    )
}

export default Message