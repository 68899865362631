import React from "react"
import styles from "./cardChat.module.css"
import { MdOutlineCalendarToday } from "react-icons/md"
import moment from "moment"
import FormGroup from "../../../Form/FormGroup"
import FormGroupExpand from "../../../Form/FormGroupExpand"
import { listAssignTo } from "../../../../schemas/Chat"
import { adjustTextByIndentation, getNameUserById, getPropertyForItemInArrayById } from "../../../../utils/utils"
import { NavLink } from "react-router-dom"
import Button from "../../../Form/Button"

const CardChat = ({ data = {}, listUsers = [], teams = [], roles = [] }) => {
    const getResponsiblesByConversation = (owner) => {
        let content = ""
        if (owner?.assignTo === "teamAndRole") {
            content = `- **Equipe**: ${getPropertyForItemInArrayById(owner?.team_id, teams, "id", "value")}\n- **Cargo**: ${getPropertyForItemInArrayById(owner?.role_id, roles, "id", "value")}`
        } else if (owner?.assignTo === "users") {
            const usersNames = owner?.users?.map(user => getNameUserById(listUsers, user?.user_id)).join(", ")
            content = `- **Responsáveis**: ${usersNames}`
        }
        return adjustTextByIndentation(`- **Atribuído para**: ${getPropertyForItemInArrayById(owner?.assignTo, listAssignTo, "id", "value")}\n${content}`)
    }

    return (
        <div className={styles.card}>
            <div className={`${styles.header} ${styles.bottomBorder}`}>
                <div className={styles.left}>
                    <span>Plataforma: </span><span className={styles.name}>{data.platform === "whatsapp" ? "WhatsApp" : "-"}</span>
                </div>
                <div className={styles.right}>
                    <MdOutlineCalendarToday />
                    <span>{moment(data.created_at).format("DD/MM/YYYY HH:mm:ss") || "xx/xx/xxxx xx:xx"}</span>
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomBorder}`}>
                <div className={styles.content}>
                    <FormGroupExpand labelText={<span className={styles.title}>Atribuição</span>} startShow={true}>
                        <div className={styles.description}>{getResponsiblesByConversation(data?.owner)}</div>
                    </FormGroupExpand>
                    <FormGroupExpand labelText={<span className={styles.title}>Caixa de Entrada</span>} startShow={true}>
                        <div className={styles.description}>{adjustTextByIndentation(data.inbox_name) || ''}</div>
                    </FormGroupExpand>
                    <FormGroup labelText={<span className={styles.title}>Oktto Chat</span>}>
                        <NavLink className={`${styles.button}`} to={`/${data?.account_id}/chat/${data._id}`} target="_blank">
                            <Button className="bg-pink">Visualizar</Button>
                        </NavLink>
                    </FormGroup>
                </div>
            </div>
        </div >
    )
}

export default CardChat