import React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts'
import styles from "../charts.module.css"

const RADIAN = Math.PI / 180

const ChartPie = ({ data, colors }) => {
    const allDataZero = data.every(item => item.value === 0)
    const total = data.reduce((acc, item) => acc + item.value, 0)

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)

        const value = data[index].value
        const precisePercent = (value / total * 100).toFixed(2)

        return (
            <>
                {precisePercent > 0 &&
                    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "middle"} dominantBaseline="central" alignmentBaseline="central">
                        {`${getPercentage(value)}`}
                    </text>
                }
            </>
        )
    }

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.tooltip} style={{ backgroundColor: payload[0]?.payload?.fill || '' }}>
                    <p>{`${payload[0].name} : ${payload[0].value}`}</p>
                </div>
            )
        }

        return null
    }

    const getPercentage = (value) => {
        return ((total > 0 ? value / total : 0) * 100).toFixed(2) + '%'
    }

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry
        return (
            <span style={{ color }}>{value} - {getPercentage(data.find(item => item.name === value).value)}</span>
        )
    }

    return (
        allDataZero ? (
            <div className={styles.emptyChartMessage}>
                <p>Nenhum dado para exibir</p>
            </div>
        ) : (
            <ResponsiveContainer width="100%" height="100%" minHeight={150} minWidth={150}>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip
                        content={<CustomTooltip />}
                    />
                    <Legend
                        verticalAlign="bottom"
                        align="center"
                        iconSize={10}
                        formatter={renderColorfulLegendText}
                    />
                </PieChart>
            </ResponsiveContainer>
        )
    )
}

export default ChartPie
