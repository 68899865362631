import React from "react"
import styles from "./cardHistoric.module.css"
import { MdOutlineCalendarToday } from "react-icons/md"
import moment from "moment"
import { adjustTextByIndentation } from "../../../../utils/utils"

const CardHistoric = ({ value = {} }) => {
    return (
        <div className={styles.card}>
            <div className={`${styles.header} ${styles.bottomBorder}`}>
                <div className={styles.left}>
                    <span>Responsável: </span><span className={styles.name}>{value.user_created || '-'}</span>
                </div>
                <div className={styles.right}>
                    <MdOutlineCalendarToday />
                    <span>{moment(value.created_at).format("DD/MM/YYYY HH:mm:ss") || "xx/xx/xxxx xx:xx"}</span>
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomBorder}`}>
                <div className={styles.content}>
                    <span className={styles.title}>{value.title || ''}</span>
                    <div className={styles.description}>{adjustTextByIndentation(value.description) || ''}</div>
                </div>
            </div>
        </div >
    )
}

export default CardHistoric