import React, { useEffect, useState } from "react"
import Modal from './index'
import FormGroup from "../Form/FormGroup"
import Button from "../Form/Button"
import FormGroupInline from '../Form/FormGroupInline'
import { FaPencilAlt, FaWpforms } from "react-icons/fa"
import Select from "../Form/Select"
import api from "../../services/api"
import { toast } from "react-toastify"
import { getOptionByOptionList, getUsersByTeamId } from "../../utils/utils"

const ModalApiForm = ({ value = {}, funnels = [], stages = [], teams = [], users = [], flags = [], fields = [], editable = false, setEdit = () => { }, onSubmit = () => { }, closeModal = () => { } }) => {
    const [form, setForm] = useState(value)
    const [teamsForm, setTeamsForm] = useState([])
    const [usersForm, setUsersForm] = useState([])
    const [funnelsForm, setFunnelsForm] = useState([])
    const [stagesForm, setStagesForm] = useState([])
    const [flagsForm, setFlagsForm] = useState([])

    useEffect(() => {
        if (funnels.length > 0) {
            setFunnelsForm(funnels)
        }
    }, [funnels])

    useEffect(() => {
        if (stages.length > 0) {
            setStagesForm(stages)
        }
    }, [stages])

    useEffect(() => {
        if (teams.length > 0) {
            setTeamsForm(teams)
        }
    }, [teams])

    useEffect(() => {
        if (users.length > 0) {
            setUsersForm(users)
        }
    }, [users])

    useEffect(() => {
        if (flags.length > 0) {
            setFlagsForm(flags?.map(flag => { return { id: flag._id, value: flag.name } }))
        }
    }, [flags])

    useEffect(() => {
        getStages(form?.funnel_id)
    }, [form?.funnel_id])

    const getStages = async (funnel_id) => {
        if (funnel_id) {
            api.get(`/funnels/${funnel_id}/stages`).then(response => {
                const stages = response.data?.data?.map(stage => { return { id: stage._id, value: stage.name } })
                setStagesForm(stages)
            }).catch(err => toast.error(err?.response?.data?.message))
        }
    }

    const getTeamsByFunnelId = (funnel_id) => {
        const idsTeamsFunnel = getIdsTeamsByFunnelId(funnel_id)
        return idsTeamsFunnel ? teamsForm?.filter(team => idsTeamsFunnel?.indexOf(team.id) !== -1) : [{ id: "default", value: "Utilizar padrão da página" }]
    }

    const getIdsTeamsByFunnelId = (funnel_id) => {
        if (funnel_id) {
            const [idsTeamsFunnel] = funnelsForm?.filter(funnel => funnel.id === funnel_id)?.map(funnel => { return funnel.teams })
            return idsTeamsFunnel
        }
        return []
    }

    const handleChangeData = (name, value) => {
        setForm(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangeFieldForm = (field_id, index) => {
        setForm(previousValue => {
            const newValue = { ...previousValue }
            newValue.fields[index].field_id = field_id
            return newValue
        })
    }

    const handleUpdateForm = (e) => {
        e.preventDefault()
        onSubmit(form)
    }

    const setEditable = (e, newValue) => {
        e.preventDefault()
        setEdit(newValue)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setEdit(false)
        setForm(value)
    }

    const handleCloseModal = () => {
        setEdit(false)
        closeModal()
    }

    return (
        <Modal
            icon={<FaWpforms />}
            title={editable ? `Editando o formulário: ${form.url}` : `Visualizando: ${form.url}`}
            description={editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`}
            closeModal={handleCloseModal}
        >
            <form onSubmit={handleUpdateForm}>
                <FormGroupInline>
                    <FormGroup labelText="Funil que receberá os cadastros">
                        {editable ?
                            <Select name="Funil" placeholder="Pesquise um funil" multiple={false} selected={form?.funnel_id || ""} onChange={(value) => handleChangeData("funnel_id", value)} options={funnelsForm} />
                            :
                            <span>{getOptionByOptionList(form?.funnel_id, funnelsForm) || "-"}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={(e) => setEditable(e, true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <FormGroup labelText="Etapa que receberá os cadastros">
                    {editable ?
                        <Select name="Etapa" placeholder="Pesquise um etapa" multiple={false} selected={form?.stage_id || ""} onChange={(value) => handleChangeData("stage_id", value)} options={stagesForm} />
                        :
                        <span>{getOptionByOptionList(form?.stage_id, stagesForm) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Equipe que receberá os cadastros">
                    {editable ?
                        <Select name="Equipe" placeholder="Pesquise uma equipe" multiple={false} selected={form?.team_id || ""} onChange={(value) => handleChangeData("team_id", value)} options={form?.funnel_id ? getTeamsByFunnelId(form?.funnel_id) : teamsForm} />
                        :
                        <span>{getOptionByOptionList(form?.team_id, teamsForm) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Usuários que receberão os cadastros">
                    {editable ?
                        <Select name="Usuários" placeholder="Pesquise um usuário" multiple={true} selected={form?.users || []} onChange={(value) => handleChangeData("users", value)} options={form?.team_id ? getUsersByTeamId(form?.team_id, usersForm, teamsForm, [{ id: "auto", value: "Rodízio automático" }]) : usersForm} />
                        :
                        <span>{getOptionByOptionList(form?.users, [{ id: "auto", value: "Rodízio automático" }, ...usersForm]) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Etiquetas">
                    {editable ?
                        <Select name="Etiquetas" placeholder="Pesquise uma etiqueta" multiple={true} selected={form?.flags || []} onChange={(value) => handleChangeData("flags", value)} options={flagsForm} />
                        :
                        <span>{getOptionByOptionList(form?.flags, flagsForm) || "-"}</span>
                    }
                </FormGroup>
                {form?.fields?.length > 0 && form.fields.map((field, index) => (
                    <div key={index}>
                        <FormGroup labelText={field.name}>
                            {editable ?
                                <Select multiple={false} name="Selecione o campo correspondente" placeholder="Selecione um campo" selected={form?.fields[index].field_id ?? ""} onChange={(value) => handleChangeFieldForm(value, index)} options={fields || []} />
                                :
                                <span>{getOptionByOptionList(form?.fields[index].field_id, fields) || "-"}</span>
                            }
                        </FormGroup>
                    </div>
                ))}
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalApiForm