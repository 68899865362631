import { createContext, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import moment from "moment"
import api from "../services/api"
import { useVoip } from "./voipContext"
import { useAuth } from "./authContext"
import { useWebSocketContext } from "./webSocketContext"
import { formatPhoneNumber } from "../utils/formatter"
import { getStages } from "../schemas/Stage"
import ModalLead from "../components/Modal/ModalLead"
import ModalFunnel from "../components/Modal/ModalFunnel"
import { useData } from "./dataContext"
import Loading from './../pages/layouts/Loading/index'
import ModalDelete from "../components/Modal/ModalDelete"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { openLinkExternal } from "../utils/utils"

const CrmContext = createContext()

const CrmProvider = ({ children }) => {
    const { funnel_id } = useParams()
    const [cardDrag, setCardDrag] = useState({})
    const [overCard, setOverCard] = useState({})
    const [oldStage, setOldStage] = useState({})
    const [stages, setStages] = useState([])
    const [leads, setLeads] = useState([])
    const [voipActive, setVoipActive] = useState([])
    const [chatActive, setChatActive] = useState([])
    const [permissionIsOk, setPermissionIsOk] = useState(false)
    const [filteredDataLead, setFilteredDataLead] = useState(0)
    const [currentLeads, setCurrentLeads] = useState(0)
    const [totalLeads, setTotalLeads] = useState(0)
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingStages, setLoadingStages] = useState(false)

    const [modalNewLead, setModalNewLead] = useState(false)
    const [newLead, setNewLead] = useState({})
    const [modalLead, setModalLead] = useState(false)
    const [dataLead, setDataLead] = useState({})
    const [modalFunnel, setModalFunnel] = useState(false)
    const [dataModalFunnel, setDataModalFunnel] = useState({})
    const [modalManagement, setModalManagement] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [leadIdDelete, setLeadIdDelete] = useState('')

    const [initialFilters, setInitialFilters] = useState({ search: '', typeDate: 1, status: [1] })
    const [filters, setFilters] = useState(initialFilters)

    const { prepareCall } = useVoip()
    const { permissions, modules, verifyIfModuleIsActive } = useAuth()
    const { loadFunnels } = useData()

    const { lastJsonMessage } = useWebSocketContext()

    useEffect(() => {
        setPermissionIsOk(permissions?.crm >= 1)
    }, [permissions])

    useEffect(() => {
        if (permissionIsOk) {
            setVoipActive(verifyIfModuleIsActive("VOIP"))
            setChatActive(verifyIfModuleIsActive("CHAT"))
        }
    }, [modules, verifyIfModuleIsActive, permissionIsOk])

    useEffect(() => {
        if (permissionIsOk) {
            updateDataByMessageWebSocket(lastJsonMessage)
        }
    }, [lastJsonMessage])

    const resetFilters = () => {
        setFilters(initialFilters)
    }

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "UPDATE_LEAD") {
            const lead_id = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (lead_id && dataUpdate) {
                updateLeadById(dataUpdate, lead_id)
            }
            if (modalLead && dataLead?._id === lead_id) {
                setDataLead(previousValue => { return { ...previousValue, ...dataUpdate } })
            }
        } else if (action === "UPDATE_PRODUCT_LEAD") {
            const lead_id = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (lead_id && dataUpdate) {
                updateLeadById({ products: dataUpdate }, lead_id)
            }
        } else if (action === "ADD_LEAD" || action === "ADD_LEADS_ARRAY" || action === "UPDATE_LEADS_ARRAY" || action === "DELETE_LEADS_ARRAY") {
            setReload(true)
        } else if (action === "DELETE_LEAD") {
            const lead_id = message?.data?.lead_id
            if (lead_id) {
                removeLeadById(lead_id)
            }
        }
    }

    const getNextTaskByLead = async (lead_id) => {
        try {
            const response = await api.get(`/leads/${lead_id}/tasks/nextTask`)
            const nextTask = response.data?.data
            setDataLead(previousValue => {
                return { ...previousValue, nextTask }
            })
            updateLeadById({ nextTask }, lead_id)
            getLastActivityByLead(lead_id)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    const getLastActivityByLead = async (lead_id) => {
        try {
            const response = await api.get(`/leads/${lead_id}/lastActivity`)
            const lastActivity = response.data?.data
            setDataLead(previousValue => {
                return { ...previousValue, lastActivity }
            })
            updateLeadById({ lastActivity }, lead_id)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    const changeStages = async (funnel_id) => {
        if (!stages?.length || stages[0]?.funnel_id !== funnel_id) {
            setLoadingStages(true)
        }
        getStages(funnel_id, filters).then(stages => {
            let totalLeads = 0
            stages?.forEach(stage => {
                totalLeads += stage?.leadCount || 0
            })
            setCurrentLeads(totalLeads)
            setStages(stages)
        }).catch(() => { }).finally(() => {
            setLoadingStages(false)
        })
    }

    const verifyFiltersByLead = (newLead) => {
        const filtersUsers = newLead?.users?.some(user => filters?.users?.indexOf(user?.user_id) !== -1)
        const filtersTeams = filters?.teams?.some(team => newLead?.team_id === team)
        const filtersSearch = !filters?.search || (newLead?.name && newLead?.name?.toLowerCase().includes(filters?.search?.toLowerCase()))

        return filtersUsers && filtersTeams && filtersSearch
    }

    const handleUpdateLead = (data, lead_id) => {
        return new Promise((resolve, reject) => {
            if (!data?.stage_id) {
                setLoading(true)
            }
            api.patch(`/leads/${lead_id}`, data).then(() => {
                updateLeadById(data, lead_id)
                changeStages(funnel_id)
                resolve(data, lead_id)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                reject(err?.response?.data?.message)
            }).finally(() => setLoading(false))
        })
    }

    const updateLeadById = (data, lead_id) => {
        setLeads(previousValue => {
            const newValue = previousValue?.map(el => {
                if (el._id === lead_id) {
                    el = { ...el, ...data }
                }
                return el
            })
            return newValue
        })
    }

    const handleDeleteLead = () => {
        setLeadIdDelete(dataLead?._id)
        setModalDelete(true)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const deleteLeadById = (lead_id) => {
        setLoading(true)
        api.delete(`/leads/${lead_id}`).then(response => {
            removeLeadById(lead_id)
            closeModalDelete()
            closeModalLead()
            toast.success(response.data.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const removeLeadById = (lead_id) => {
        setLeads(previousValue => previousValue?.filter(el => el._id !== lead_id))
    }

    const adjustTextByIndentation = (text) => {
        const listText = text?.split("\n")
        return listText?.map((text, index) => (
            <div key={index}>
                {text === "\r" ? <br /> : <p>{text}</p>}
            </div>
        ))
    }

    const formatValueByCurrency = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    const getPrimaryContactByLead = (lead) => {
        const [contact] = lead?.contacts?.filter(contact => contact.is_primary) || []
        if (!contact && lead?.contacts.length > 0) {
            return lead?.contacts[0] || []
        }
        return contact
    }

    const handleCallToLead = (lead) => {
        if (voipActive) {
            const contact = getPrimaryContactByLead(lead)
            prepareCall(lead._id, lead.name, formatPhoneNumber(contact.phone))
        }
    }

    const handleMailToLead = (lead) => {
        const contact = getPrimaryContactByLead(lead)
        const url = `mailto:${contact.email}`
        openLinkExternal(url)
    }

    const handleWhatsAppToLead = (lead) => {
        const contact = getPrimaryContactByLead(lead)
        const formattedPhone = formatPhoneNumber(contact.phone)
        const url = chatActive ? `/${lead?.account_id}/chat?action=startChat&phone=${formattedPhone}` : `https://wa.me/${formattedPhone}`
        openLinkExternal(url)
    }

    const getDaysToCoolByStage = (stage_id) => {
        const [stage] = stages?.filter(stage => stage._id === stage_id)
        const daysToCool = stage?.time_to_cool || 0
        return daysToCool
    }

    const getStatusLeadByLastActivity = (lastActivity, stage_id) => {
        const hoursToCool = 24 * getDaysToCoolByStage(stage_id)
        const hoursToWarm = parseInt(hoursToCool / 3)
        const dayCool = moment().subtract(hoursToCool, "hours")
        const dayWarm = moment().subtract(hoursToWarm, "hours")
        const dateLastActivity = moment(lastActivity)
        const cool = dateLastActivity.isSameOrBefore(dayCool)
        const warm = dateLastActivity.isSameOrBefore(dayWarm)
        return cool ? "COOL" : (warm ? "WARM" : "HOT")
    }

    const getLeadsStageAndPage = async (stage_id, page) => {
        try {
            const queryString = new URLSearchParams(filters)?.toString()
            const response = await api.get(`/leads?funnel_id=${funnel_id}&${queryString}&stage_id=${stage_id}&page=${page}`)
            const newLeads = response.data?.data?.leads || []
            setLeads((previousValue) => {
                const previousIds = new Set(previousValue?.map(lead => lead._id))
                const uniqueNewLeads = newLeads?.filter(newLead => !previousIds.has(newLead._id))
                return [...previousValue, ...uniqueNewLeads];
            })
            setReload(false)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    const openModalNewLead = (stage_id = null) => {
        setModalNewLead(true)
        setNewLead({ stage_id })
    }

    const closeModalNewLead = () => {
        setModalNewLead(false)
    }

    const openModalLead = (id) => {
        setLoading(true)
        api.get(`/leads/${id}`).then(response => {
            const lead = response?.data?.data
            setDataLead(lead || {})
            setModalLead(true)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const closeModalLead = () => {
        setModalLead(false)
        setDataLead({})
    }

    const openModalFunnel = (isNew = false, funnel_id = undefined, initialData = undefined, callbackFunction = () => { }) => {
        setDataModalFunnel({ isNew, funnel_id, initialData, callbackFunction })
        setModalFunnel(true)
    }

    const closeModalFunnel = () => {
        setModalFunnel(false)
    }

    const openLeadsManagement = () => {
        setModalManagement(true)
    }

    const closeModalLeadsManagement = () => {
        setModalManagement(false)
    }

    const saveFunnel = async (funnel, stages) => {
        try {
            setLoading(true)
            if (verifyIdDataFunnelAndStageIsValid(funnel, stages)) {
                if (funnel) {
                    const callbackFunction = dataModalFunnel.callbackFunction
                    let funnelId
                    if (funnel?._id) {
                        funnelId = funnel._id
                        await api.patch("/funnels", {
                            name: funnel.name,
                            teams: funnel.teams,
                            funnel_id: funnelId
                        })
                        await loadFunnels()
                    } else {
                        const response = await api.post("/funnels", {
                            name: funnel.name,
                            teams: funnel.teams
                        })
                        funnelId = response?.data?.data?._id
                        await loadFunnels()
                        setDataModalFunnel({ isNew: false, funnel_id: funnelId })
                    }
                    if (funnelId) {
                        const listStages = stages?.map((stage, index) => {
                            return {
                                stage_id: stage._id,
                                name: stage.name,
                                sequence: index + 1,
                                time_to_cool: stage.time_to_cool,
                                isNew: stage.isNew
                            }

                        })

                        await api.patch("/stages", {
                            stages: listStages,
                            funnel_id: funnelId
                        })
                        if (funnel_id) {
                            changeStages(funnel_id)
                        }
                        closeModalFunnel()
                        callbackFunction()
                    }
                }
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const verifyIdDataFunnelAndStageIsValid = (funnel, stages) => {
        if (!funnel?.name) {
            toast.error("O nome do funil é obrigatório!")
            return false
        }
        if (!funnel?.teams || !Array.isArray(funnel?.teams) || !funnel?.teams?.length) {
            toast.error("A equipe informada é inválida!")
            return false
        }
        for (let i = 0; i < stages?.length; i++) {
            if (!stages[i]?.name) {
                toast.error("O nome da etapa é obrigatório!")
                return false
            }
            if (!stages[i]?.time_to_cool) {
                toast.error(`O tempo para o lead esfriar da etapa ${stages[i]?.name} é obrigatório!`)
                return false
            }
        }
        return true
    }

    return (
        <CrmContext.Provider value={{ cardDrag, setCardDrag, overCard, setOverCard, oldStage, setOldStage, stages, setStages, changeStages, loadingStages, leads, setLeads, currentLeads, totalLeads, setTotalLeads, getLeadsStageAndPage, updateLeadById, handleDeleteLead, removeLeadById, filters, setFilters, setInitialFilters, resetFilters, verifyFiltersByLead, getNextTaskByLead, getStatusLeadByLastActivity, filteredDataLead, setFilteredDataLead, reload, setReload, handleUpdateLead, adjustTextByIndentation, formatValueByCurrency, handleCallToLead, handleMailToLead, handleWhatsAppToLead, voipActive, permissionIsOk, modalNewLead, setModalNewLead, closeModalNewLead, newLead, setNewLead, openModalNewLead, modalLead, openModalLead, closeModalLead, dataLead, setDataLead, modalFunnel, openModalFunnel, closeModalFunnel, dataModalFunnel, setDataModalFunnel, modalManagement, openLeadsManagement, closeModalLeadsManagement }}>
            {permissionIsOk &&
                <>
                    {children}
                    {modalLead &&
                        <ModalLead
                            value={dataLead}
                            setValue={setDataLead}
                            updateLeadById={updateLeadById}
                            permissions={permissions}
                            closeModal={closeModalLead}
                        />
                    }
                    {permissions?.edit_funnels > 0 && modalFunnel &&
                        <ModalFunnel
                            onSubmit={saveFunnel}
                            closeModal={closeModalFunnel}
                            {...dataModalFunnel}
                        />
                    }
                    {permissions?.crm > 1 && modalDelete &&
                        <ModalDelete
                            closeModal={closeModalDelete}
                            onSubmit={deleteLeadById}
                            icon={<MdOutlineDeleteOutline />}
                            title="Exclusão de lead"
                            description="Deseja realmente excluir o lead?"
                            text={<><p>Todos os dados do lead serão excluídos, incluindo as tarefas, anotações, vendas, históricos, etc...</p>
                                <p>Os leads excluídos permanecem na lixeira por 30 dias, sendo removidos completamente após esse período!</p></>}
                            id={leadIdDelete}
                        />
                    }
                    {loading && <Loading fullPage={true} />}
                </>
            }
            {permissions?.crm === 0 && <>Você não possui permissão de acesso a essa página</>}
        </CrmContext.Provider>
    )
}

const useCrm = () => {
    const context = useContext(CrmContext)
    return context
}

export { CrmProvider, useCrm }

export default CrmProvider