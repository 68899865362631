import React, { useEffect, useRef } from "react"
import styles from "./table.module.css"
import Tr from "./Tr"
import CircleLoading from "../CircleLoading"

const Table = ({ headers = [], values = [], count = 0, loadMore, messageNotRegisters = "", className = "", showIndex = false, showCount = false, onClick = () => { }, onScroll = () => { } }) => {
    const containerRef = useRef(null)

    useEffect(() => {
        const containerElement = containerRef.current

        const scrollHandler = () => {
            if (containerRef.current) {
                const { scrollTop, clientHeight, scrollHeight } = containerRef.current
                if ((scrollTop + clientHeight >= (scrollHeight * 0.8))) {
                    onScroll()
                }
            }
        }

        if (containerElement) {
            containerElement.addEventListener('scroll', scrollHandler)
        }

        return () => {
            if (containerElement) {
                containerElement.removeEventListener('scroll', scrollHandler)
            }
        }
    }, [onScroll])

    return (
        <>
            {showCount &&
                <div className={styles.count}>
                    <span>Registros: {count}</span>
                </div>
            }
            <div className={`${styles.container} ${className}`} ref={containerRef}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            {headers && headers.map((value, index) => (
                                <th key={index}>{value}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {values?.length > 0 && values.map((row, index) => (
                            <Tr key={row.id} row={row} indexRow={showIndex ? (index + 1 < 10 ? `0${index + 1}` : `${index + 1}`) : false} onClick={() => onClick(row.id)} />
                        ))}
                    </tbody>
                </table>
                {!values?.length &&
                    <div className={styles.messageNotRegisters}>
                        {messageNotRegisters}
                    </div>
                }
                {loadMore && <CircleLoading />}
            </div>
        </>
    )
}

export default Table