import React, { useCallback, useEffect, useState } from "react"
import { toast } from 'react-toastify'
import styles from "./trash.module.css"
import api from '../../services/api'
import Loading from "../layouts/Loading"
import Table from "../../components/Table"
import BreakForm from "../../components/Form/BreakForm"
import Select from "../../components/Form/Select"
import DatePicker from "../../components/Form/DatePicker"
import Button from "../../components/Form/Button"
import ModalConfirm from './../../components/Modal/ModalConfirm'
import ModalDelete from './../../components/Modal/ModalDelete'
import BackgroundSelectFilter from "../../components/BackgroundSelectFilter"
import imageFilterTask from "../../images/filter-task.png"
import { useAuth } from "../../contexts/authContext"
import { useData } from "../../contexts/dataContext"
import { getStages } from "../../schemas/Stage"
import { getOptionByOptionList } from "../../utils/utils"
import { MdOutlineDeleteOutline, MdOutlineRestoreFromTrash } from "react-icons/md"

const headers = [
    "#",
    "Data da Exclusão",
    "Nome do Lead",
    "Celular",
    "E-mail",
    "Funil",
    "Etapa",
    "Equipe",
    "Responsáveis",
    "Expira em",
    "Excluído Por",
    "",
]

const TrashLead = () => {
    const [trashLeads, setTrashLeads] = useState([])
    const [stages, setStages] = useState([])
    const [maxTrash, setMaxTrash] = useState(0)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState({})
    const [tempFilters, setTempFilters] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [modalRestore, setModalRestore] = useState(false)
    const [typeRestore, setTypeRestore] = useState()
    const [idRestore, setIdRestore] = useState()
    const [modalDelete, setModalDelete] = useState(false)
    const [typeDelete, setTypeDelete] = useState()
    const [idDelete, setIdDelete] = useState()
    const [backgroundFilter, setBackgroundFilter] = useState(true)

    const { permissions } = useAuth()
    const { funnels, teams, users } = useData()

    let isLoadingTrash = false

    useEffect(() => {
        setInitialValuesByFilter("funnels", funnels?.map(funnel => { funnel.selected = true; return funnel }))
    }, [funnels])

    useEffect(() => {
        setInitialValuesByFilter("teams", teams?.map(team => { team.selected = true; return team }))
    }, [teams])

    useEffect(() => {
        setInitialValuesByFilter("users", users?.map(user => { user.selected = true; return user }))
    }, [users])

    useEffect(() => {
        if (loadMore) {
            getAllTrashLeadsByFilters()
        }
    }, [loadMore])

    useEffect(() => {
        setStages([])
        if (filters?.funnels?.length > 0) {
            updateStagesByFunnelId(filters.funnels)
        }
    }, [filters.funnels])

    const getAllTrashLeadsByFilters = async (newFind = false) => {
        try {
            if (!isLoadingTrash) {
                isLoadingTrash = true
                if (newFind) {
                    setIsLoading(true)
                }
                const queryString = new URLSearchParams(tempFilters)?.toString()
                const response = await api.get(`/trashs/leads?${queryString}&page=${newFind ? 1 : page}`)
                const leads = response?.data?.data?.leads || []
                if (newFind) {
                    const maxTrash = response.data?.data?.maxTrash || 0
                    setTrashLeads(leads)
                    setMaxTrash(maxTrash)
                } else {
                    setTrashLeads(previousValue => {
                        return [...previousValue, ...leads]
                    })
                }
                setFilters(tempFilters)
                setPage(prev => newFind ? 2 : prev + 1)
                setBackgroundFilter(false)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setIsLoading(false)
            setLoadMore(false)
            isLoadingTrash = false
        }
    }

    const getFilteredValues = () => {
        const values = trashLeads
        const filteredValues = values?.map((trashLead) => getDataNewTrashLead(trashLead))
        return filteredValues
    }

    const getDataNewTrashLead = (newTrashLead) => {
        const data = {
            id: newTrashLead?._id,
            value: [
                newTrashLead?.created_at || "-",
                newTrashLead?.lead?.name || "-",
                newTrashLead?.lead?.phone || "-",
                newTrashLead?.lead?.email || "-",
                newTrashLead?.lead?.funnel || "-",
                newTrashLead?.lead?.stage || "-",
                newTrashLead?.lead?.team || "-",
                getOptionByOptionList(newTrashLead?.lead?.users, users) || "-",
                newTrashLead?.expire_in || "-",
                newTrashLead?.user_delete || "-",
            ],
            actions: [
                {
                    label: "Restaurar",
                    onClick: (id) => openModalRestore("unique", id)
                },
                {
                    label: "Excluir Definitivamente",
                    onClick: (id) => openModalDelete("unique", id)
                }
            ]
        }
        return data
    }

    const updateStagesByFunnelId = (funnels) => {
        funnels?.map(async funnel_id => {
            const listStages = await getStages(funnel_id)
            const newStages = listStages?.map(stage => { return { id: stage._id, value: stage.name } })
            setStages((previousValue) => {
                const previousIds = new Set(previousValue?.map(stage => stage.id))
                const uniqueNewStages = newStages?.filter(newStage => !previousIds.has(newStage.id)) || []
                return [...previousValue, ...uniqueNewStages]
            })
        })
    }

    const openModalRestore = (type, id = undefined) => {
        setTypeRestore(type)
        setIdRestore(id)
        setModalRestore(true)
    }

    const closeModalRestore = () => {
        setTypeRestore(undefined)
        setIdRestore(undefined)
        setModalRestore(false)
    }

    const handleRestore = () => {
        if (typeRestore === "unique") {
            handleRestoreTrashLead(idRestore)
        } else {
            handleRestoreTrashLeadByFilters()
        }
    }

    const handleRestoreTrashLead = (trash_lead_id) => {
        setIsLoading(true)
        api.patch(`/trashs/leads/${trash_lead_id}/restore`).then(response => {
            setTrashLeads(previousValue => previousValue?.filter(trash => trash._id !== trash_lead_id))
            toast.success(response?.data?.message)
            closeModalRestore()
            setMaxTrash(previousValue => previousValue - 1)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setIsLoading(false))
    }

    const handleRestoreTrashLeadByFilters = () => {
        setIsLoading(true)
        const queryString = new URLSearchParams(filters)?.toString()
        api.patch(`/trashs/leads/restore?${queryString}`).then(response => {
            setTrashLeads([])
            toast.success(response?.data?.message)
            closeModalRestore()
            setMaxTrash(0)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setIsLoading(false))
    }

    const openModalDelete = (type, id = undefined) => {
        setTypeDelete(type)
        setIdDelete(id)
        setModalDelete(true)
    }

    const closeModalDelete = () => {
        setTypeDelete(undefined)
        setIdDelete(undefined)
        setModalDelete(false)
    }

    const handleDelete = () => {
        if (typeDelete === "unique") {
            handleDeleteTrashLead(idDelete)
        } else {
            handleDeleteTrashLeadByFilters()
        }
    }

    const handleDeleteTrashLead = (trash_lead_id) => {
        setIsLoading(true)
        api.patch(`/trashs/leads/${trash_lead_id}`).then(response => {
            setTrashLeads(previousValue => previousValue?.filter(trash => trash._id !== trash_lead_id))
            toast.success(response?.data?.message)
            closeModalDelete()
            setMaxTrash(previousValue => previousValue - 1)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setIsLoading(false))
    }

    const handleDeleteTrashLeadByFilters = () => {
        setIsLoading(true)
        const queryString = new URLSearchParams(filters)?.toString()
        api.delete(`/trashs/leads?${queryString}`).then(response => {
            setTrashLeads([])
            toast.success(response?.data?.message)
            closeModalDelete()
            setMaxTrash(0)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setIsLoading(false))
    }

    const setInitialValuesByFilter = (name, array) => {
        if (array?.length > 0) {
            const newValue = array?.filter(el => el.selected)?.map(el => { return el.id })
            setFilters(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
            setTempFilters(previousValue => {
                return {
                    ...previousValue,
                    [name]: newValue
                }
            })
        }
    }

    const handleChangeFilters = (name, value) => {
        setTempFilters(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangePeriod = (dateStart, dateEnd) => {
        handleChangeFilters("dateStart", dateStart)
        handleChangeFilters("dateEnd", dateEnd)
    }

    const handleScrollTable = useCallback(() => {
        if (!loadMore && maxTrash > trashLeads.length) {
            setLoadMore(true)
        }
    }, [maxTrash, trashLeads, loadMore])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <BreakForm title="Leads na Lixeira" showAction={false} />
                        <span>Aqui é possível restaurar os leads excluídos. Após 30 dias na lixeira o lead será excluído definitivamente!</span>
                    </div>
                    {!backgroundFilter && maxTrash > 0 &&
                        <div className={styles.actions}>
                            <Button className="bg-purple" onClick={() => openModalRestore("multiple")} disabled={isLoading || !maxTrash}>Restaurar os {maxTrash} leads filtrados</Button>
                            <Button className="bg-pink" onClick={() => openModalDelete("multiple")} disabled={isLoading || !maxTrash}>Excluir definitivamente os {maxTrash} leads filtrados</Button>
                        </div>
                    }
                </div>
                <div className={styles.filters}>
                    <Select name="Funil" placeholder="Pesquise um funil" options={funnels || []} selected={tempFilters?.funnels || []} onChange={(value) => handleChangeFilters("funnels", value)} multiple={true} />
                    <Select name="Etapa" placeholder="Pesquise uma etapa" options={stages || []} selected={tempFilters?.stages || []} onChange={(value) => handleChangeFilters("stages", value)} multiple={true} />
                    <Select name="Equipe" placeholder="Pesquise uma equipe" options={teams || []} selected={tempFilters?.teams || []} onChange={(value) => handleChangeFilters("teams", value)} multiple={true} />
                    {permissions?.crm > 1 &&
                        <Select name="Responsável" placeholder="Pesquise um responsável" options={users || []} selected={tempFilters?.users || []} onChange={(value) => handleChangeFilters("users", value)} multiple={true} />
                    }
                    <DatePicker name="Período de Exclusão" dateStart={tempFilters?.dateStart || ""} dateEnd={tempFilters?.dateEnd || ""} onChange={(dateStart, dateEnd) => handleChangePeriod(dateStart, dateEnd)} multiple={true} />
                    <Button className="bg-pink" onClick={getAllTrashLeadsByFilters} disabled={isLoading}>Filtrar</Button>
                </div>
                {!backgroundFilter &&
                    <Table
                        headers={headers}
                        values={getFilteredValues()}
                        onScroll={handleScrollTable}
                        loadMore={loadMore}
                        messageNotRegisters="Nenhum registro encontrado"
                        showIndex={true}
                        showCount={true}
                        count={maxTrash}
                    />
                }
                {backgroundFilter &&
                    <BackgroundSelectFilter
                        text="Selecione os filtros desejados e aplique para visualizar os dados!"
                        image={imageFilterTask}
                    />
                }
                {modalRestore &&
                    <ModalConfirm
                        closeModal={closeModalRestore}
                        onSubmit={handleRestore}
                        icon={<MdOutlineRestoreFromTrash />}
                        title={`Restaurar ${typeRestore === "unique" ? "lead" : "leads"}`}
                        description={`Deseja realmente restaurar ${typeRestore === "unique" ? "o lead" : `os ${maxTrash} leads selecionados`}?`}
                        text="Ao restaurar um lead, ele retorna para o estado em que estava antes da exclusão. Essa ação não pode ser desfeita!"
                    />
                }
                {modalDelete &&
                    <ModalDelete
                        closeModal={closeModalDelete}
                        onSubmit={handleDelete}
                        icon={<MdOutlineDeleteOutline />}
                        title={`Excluir ${typeDelete === "unique" ? "lead" : "leads"}`}
                        description={`Deseja realmente excluir definitivamente ${typeDelete === "unique" ? "o lead" : `os ${maxTrash} leads selecionados`}?`}
                        text="Ao excluir definitivamente um lead, todos os seus dados serão perdidos0. Essa ação não pode ser desfeita!"
                    />
                }
            </div>
            {isLoading && <Loading fullPage={true} />}
        </>
    )
}

export default TrashLead