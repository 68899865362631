import React from "react";
import styles from './form.module.css'

const Textarea = ({ placeholder, value, onChange, className, theme, rows, required, autoFocus = false }) => {
    return (
        <textarea
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`${styles.textarea} ${styles[className]} ${styles[theme]}`}
            required={required}
            autoFocus={autoFocus}
            rows={rows}
            tabIndex={1}
        >{value}</textarea>
    )
}

export default Textarea