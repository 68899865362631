import React, { useEffect, useState } from "react";

const ImageWithFallback = ({ src, alt, functionError = () => { } }) => {
    const [imageSrc, setImageSrc] = useState(src)
    const [error, setError] = useState(false)

    useEffect(() => {
        setImageSrc(src)
        setError(false)
    }, [src])

    const handleImageError = () => {
        setImageSrc('')
        setError(true)
    }

    return (
        <div>
            {error ? (
                functionError()
            ) : (
                <img
                    src={imageSrc}
                    onError={handleImageError}
                    alt={alt}
                />
            )}
        </div>
    )
}

export default ImageWithFallback