import React, { useEffect, useState } from 'react'
import styles from "./reportDataLead.module.css"
import { MdOutlineFileDownload } from 'react-icons/md'
import { toast } from 'react-toastify'
import Button from '../../components/Form/Button'
import Loading from '../layouts/Loading/index'
import api from '../../services/api'
import FormGroup from '../../components/Form/FormGroup'
import Input from '../../components/Form/Input'
import DatePicker from '../../components/Form/DatePicker'
import Select from '../../components/Form/Select'
import { useAuth } from '../../contexts/authContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useData } from '../../contexts/dataContext'
import { getCampaignsByOrigins } from '../../schemas/Campaign'

const ReportDataLead = () => {
    const [filters, setFilters] = useState({})
    const [loading, setLoading] = useState(false)

    const { account_id } = useParams()
    const { funnels, teams, users, products, origins, campaigns, typeDate, status } = useData()

    const { permissions } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        getCampaignsByOrigins(filters.origins)
    }, [filters.origins])

    const handleChange = (name, value) => {
        setFilters(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleChangePeriod = (dateStart, dateEnd) => {
        handleChange("dateStart", dateStart)
        handleChange("dateEnd", dateEnd)
    }

    const handleSubmit = () => {
        setLoading(true)
        const keys = Object.keys(filters)
        const filtersFormatted = { ...filters }
        keys?.forEach(key => {
            if (Array.isArray(filtersFormatted[key])) {
                filtersFormatted[key] = filtersFormatted[key].join(",")
            }
        })
        api.post("/reports/leads", filtersFormatted).then(response => {
            toast.success(response?.data?.message)
            navigate(`/${account_id}/reports/log`)
            setLoading(false)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }

    return (
        <>
            <div className={styles.content}>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <MdOutlineFileDownload />
                        <span>Exportar Relatório de Leads</span>
                    </div>
                    <div className={styles.link}>
                        <NavLink to={`/${account_id}/reports/log`}>Acessar histórico de exportações</NavLink>
                    </div>
                    <div className={styles.body}>
                        <FormGroup labelText="Funil">
                            <Select name="Funil" placeholder="Pesquise um funil" options={funnels || []} selected={filters.funnel_id || []} onChange={(value) => handleChange("funnel_id", value)} multiple={false} />
                        </FormGroup>
                        <FormGroup labelText="Busca por texto">
                            <Input type="search" placeholder="Buscar" value={filters.search || ""} onChange={(e) => handleChange("search", e.target.value)} />
                        </FormGroup>
                        <FormGroup labelText="Período">
                            <DatePicker name="Período" dateStart={filters.dateStart || ""} dateEnd={filters.dateEnd || ""} onChange={(dateStart, dateEnd) => handleChangePeriod(dateStart, dateEnd)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Filtro por">
                            <Select name="Filtro por data" placeholder="Pesquise uma opção" options={typeDate || ""} selected={filters.typeDate || []} onChange={(value) => handleChange("typeDate", value)} multiple={false} />
                        </FormGroup>
                        <FormGroup labelText="Produto">
                            <Select name="Produtos" placeholder="Pesquise um produto" options={products || []} selected={filters.products || []} onChange={(value) => handleChange("products", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Origem">
                            <Select name="Origens" placeholder="Pesquise uma origem" options={origins || []} selected={filters.origins || []} onChange={(value) => handleChange("origins", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Campanha">
                            <Select name="Campanhas" placeholder="Pesquise uma campanha" options={campaigns || []} selected={filters.campaigns || []} onChange={(value) => handleChange("campaigns", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Status">
                            <Select name="Status" placeholder="Pesquise um status" options={status || []} selected={filters.status || []} onChange={(value) => handleChange("status", value)} multiple={true} />
                        </FormGroup>
                        <FormGroup labelText="Equipe">
                            <Select name="Equipes" placeholder="Pesquise uma equipe" options={teams || []} selected={filters.teams || []} onChange={(value) => handleChange("teams", value)} multiple={true} />
                        </FormGroup>
                        {permissions?.crm > 1 &&
                            <FormGroup labelText="Responsável">
                                <Select name="Responsáveis" placeholder="Pesquise um responsável" options={users || []} selected={filters.users || []} onChange={(value) => handleChange("users", value)} multiple={true} />
                            </FormGroup>
                        }
                    </div>
                    <div className={styles.footer}>
                        <Button className="bg-pink" onClick={handleSubmit} disabled={loading}>
                            Solicitar Relatório
                        </Button>
                    </div>
                </div>
            </div>
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ReportDataLead