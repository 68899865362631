import React, { useState } from "react"
import ProgressSetup from "./ProgressSetup";
import image from "../../../images/setup/discard.png"
import ModalReasonsDiscard from "../../../components/Modal/ModalReasonsDiscard";
import api from "../../../services/api";
import { toast } from "react-toastify";

const initialData = [
    { name: "Contato Inválido" },
    { name: "Falha no atendimento" },
    { name: "Financeiro" },
    { name: "Produto não ofertado" },
    { name: "Sem interesse no momento" },
    { name: "Outro" },
]

const AddReasonsDiscard = ({ step = 0, nextStep = () => { } }) => {
    const [modalReasons, setModalReasons] = useState(false)
    const title = "Configurando Motivos de Descarte dos Leads"
    const description = "Defina os motivos pelos quais alguns leads não se convertem em clientes. Isso ajudará a melhorar suas estratégias de conversão. Ex: Falha no atendimento, Financeiro (Situação financeira do lead, valor do produto), Produto não ofertado, etc..."
    const btnText = "Configurar"

    const openModalReasons = () => {
        setModalReasons(true)
    }

    const closeModalReasons = () => {
        setModalReasons(false)
    }

    const saveReasonsDiscard = (e, _, reasons) => {
        e.preventDefault()
        api.post("/reasonDiscards", { reasons }).then(() => {
            nextStep()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    return (
        <>
            <ProgressSetup
                step={step}
                title={title}
                description={description}
                srcImage={image}
                altImage="Motivos de Descarte dos Leads!"
                btnText={btnText}
                onClick={openModalReasons}
            />
            {modalReasons &&
                <ModalReasonsDiscard
                    closeModal={closeModalReasons}
                    isNew={true}
                    value={initialData}
                    onSubmit={saveReasonsDiscard}
                />
            }
        </>
    )
}

export default AddReasonsDiscard