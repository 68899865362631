import React, { useState } from "react"
import ProgressSetup from "./ProgressSetup";
import image from "../../../images/setup/products.png"
import ModalProducts from "../../../components/Modal/ModalProducts";
import api from "../../../services/api";
import { toast } from "react-toastify";

const AddProducts = ({ step = 0, setDefaultStep = () => { }, changeToFinishPage = () => { } }) => {
    const [modalProducts, setModalProducts] = useState(false)
    const title = "Registrando Produtos Oferecidos"
    const description = "Liste os produtos que sua empresa oferece. Isso facilitará o acompanhamento dos seus leads e a gestão de suas vendas."
    const btnText = "Configurar"
    const btnAltText = "Ignorar por enquanto"

    const openModalProducts = () => {
        setModalProducts(true)
    }

    const closeModalProducts = () => {
        setModalProducts(false)
    }

    const saveProducts = (e, _, products) => {
        e.preventDefault()
        api.post("/products", { products }).then(() => {
            changeToFinishPage()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    return (
        <>
            <ProgressSetup
                step={step}
                title={title}
                description={description}
                srcImage={image}
                altImage="Cadastro de Produtos!"
                btnText={btnText}
                btnAltText={btnAltText}
                onClick={openModalProducts}
                onClickAlt={() => setDefaultStep(4)}
            />
            {modalProducts &&
                <ModalProducts
                    closeModal={closeModalProducts}
                    isNew={true}
                    onSubmit={saveProducts}
                />
            }
        </>
    )
}

export default AddProducts