import React, { useEffect, useRef, useState } from 'react'
import styles from "./gif.module.css"

const Gif = ({ url, width, height, quotedMessage = false }) => {
    const [playGif, setPlayGif] = useState(false)

    const videoRef = useRef(null)

    const style = quotedMessage ? {} : { minWidth: width > 0 && width < 350 ? `${width}px` : "350px", minHeight: height > 0 && height < 350 ? `${height}px` : "300px" }

    useEffect(() => {
        if (!quotedMessage) {
            if (playGif) {
                videoRef?.current?.play()
            } else {
                videoRef?.current?.pause()
            }
        }
    }, [playGif, quotedMessage])

    const tooglePlayGif = () => {
        if (!quotedMessage) {
            setPlayGif(previousValue => !previousValue)
        }
    }

    return (
        <div className={styles.container} onClick={tooglePlayGif}>
            {!quotedMessage &&
                !playGif &&
                <div className={styles.playGif}>
                    GIF
                </div>

            }
            <video src={url} alt="Vídeo" controls={false} autoPlay={false} muted={true} loop={true} ref={videoRef} style={style} />
        </div>
    )
}

export default Gif
