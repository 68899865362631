import React, { useEffect, useState } from "react";
import Modal from './../index';
import FormGroup from "../../Form/FormGroup";
import FormGroupInline from './../../Form/FormGroupInline';
import Button from "../../Form/Button";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Select from './../../Form/Select';
import { FaPencilAlt } from "react-icons/fa";
import { getOptionByOptionList, getUsersByTeamId } from "../../../utils/utils";
import ModalForms from "./ModalForms";
import Loading from "../../../pages/layouts/Loading";

const statusPage = [
    { id: '1', value: "Sim" },
    { id: '0', value: "Não" },
]

const ModalMetaPage = ({ icon, title = "", description = "", data = {}, funnels = [], stages = [], teams = [], users = [], flags = [], allFields = [], getStages, editable = false, setEditable, onSubmit, closeModal }) => {
    const [page, setPage] = useState(data)
    const [forms, setForms] = useState([])
    const [pagingForms, setPagingForms] = useState({})
    const [showModalForms, setShowModalForms] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getStages(page?.funnel_id)
    }, [page?.funnel_id])

    const handleCancel = (e) => {
        e.preventDefault()
        setPage(data)
        setEditable(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(page._id, page)
    }

    const getTeamsByFunnelId = (funnel_id) => {
        const idsTeamsFunnel = getIdsTeamsByFunnelId(funnel_id)
        return teams?.filter(team => idsTeamsFunnel?.indexOf(team.id) !== -1) || []
    }

    const getIdsTeamsByFunnelId = (funnel_id) => {
        if (funnel_id) {
            const [idsTeamsFunnel] = funnels?.filter(funnel => funnel.id === funnel_id)?.map(funnel => { return funnel.teams })
            return idsTeamsFunnel
        }
        return []
    }

    const onChangePage = (name, value) => {
        setPage(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const showModalMetaForms = async (e) => {
        e.preventDefault()
        await getFormsByPage()
        setShowModalForms(true)
    }

    const getFormsByPage = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/integrations/meta/accounts/${page._id}/forms?after=${pagingForms.after || ''}`)
            if (response?.data?.data?.error) {
                toast.error("Não foi possível buscar os formulários da página. Realize a autenticação com o Facebook novamente e confira as permissões da página!")
                setIsLoading(false)
                return
            }
            const results = response?.data?.data || []
            const forms = results?.data
            const paging = results?.paging?.next ? results?.paging?.cursors : false
            setForms(previousValue => {
                const formsAdd = forms?.filter(form => !previousValue.some(el => el.id === form.id)) || []
                const newValue = [...previousValue, ...formsAdd]
                return newValue
            })
            setPagingForms(paging)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }

    const closeModalMetaForms = () => {
        setForms([])
        setPagingForms({})
        setShowModalForms(false)
    }

    return (
        <Modal
            icon={icon}
            title={title}
            description={description}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => handleSubmit(e)}>
                <FormGroupInline>
                    <FormGroup labelText="Os leads dessa página devem ser importados por padrão?">
                        {editable ?
                            <Select name="Selecione uma opção" placeholder="Pesquise uma opção" multiple={false} selected={page?.status || '1'} onChange={(value) => onChangePage("status", value)} options={statusPage} />
                            :
                            <span>{getOptionByOptionList(page?.status || '1', statusPage) || "-"}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEditable(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                <FormGroup labelText="Funil que receberá os cadastros">
                    {editable ?
                        <Select name="Funil" placeholder="Pesquise um funil" multiple={false} selected={page.funnel_id || ""} onChange={(value) => onChangePage("funnel_id", value)} options={funnels} />
                        :
                        <span>{getOptionByOptionList(page?.funnel_id, funnels) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Etapa que receberá os cadastros">
                    {editable ?
                        <Select name="Etapa" placeholder="Pesquise um etapa" multiple={false} selected={page.stage_id || ""} onChange={(value) => onChangePage("stage_id", value)} options={stages} />
                        :
                        <span>{getOptionByOptionList(page?.stage_id, stages) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Equipe que receberá os cadastros">
                    {editable ?
                        <Select name="Equipe" placeholder="Pesquise uma equipe" multiple={false} selected={page.team_id || ""} onChange={(value) => onChangePage("team_id", value)} options={page?.funnel_id ? getTeamsByFunnelId(page?.funnel_id) : teams} />
                        :
                        <span>{getOptionByOptionList(page?.team_id, teams) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Usuários que receberão os cadastros">
                    {editable ?
                        <Select name="Usuários" placeholder="Pesquise um usuário" multiple={true} selected={page.users || []} onChange={(value) => onChangePage("users", value)} options={page?.team_id ? getUsersByTeamId(page?.team_id, users, teams, [{ id: "auto", value: "Rodízio automático" }], (user) => { return user.id === "auto" }) : users} />
                        :
                        <span>{getOptionByOptionList(page?.users, [{ id: "auto", value: "Rodízio automático" }, ...users]) || "-"}</span>
                    }
                </FormGroup>
                <FormGroup labelText="Etiquetas">
                    {editable ?
                        <Select name="Etiquetas" placeholder="Pesquise uma etiqueta" multiple={true} selected={page.flags || []} onChange={(value) => onChangePage("flags", value)} options={flags} />
                        :
                        <span>{getOptionByOptionList(page?.flags, flags) || "-"}</span>
                    }
                </FormGroup>
                {editable ?
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                    :
                    <FormGroup>
                        <Button className='bg-orange' onClick={showModalMetaForms}>Configurar formulários</Button>
                    </FormGroup>
                }
            </form>
            {showModalForms &&
                <ModalForms
                    icon={icon}
                    title={title}
                    description="Configurar Formulários"
                    listForms={forms}
                    pagingForms={pagingForms}
                    funnels={funnels}
                    stages={stages}
                    teams={teams}
                    users={users}
                    flags={flags}
                    allFields={allFields}
                    loadMore={getFormsByPage}
                    closeModal={closeModalMetaForms}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </Modal>
    )
}

export default ModalMetaPage