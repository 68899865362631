import React from "react"
import { useParams } from "react-router-dom"
import styles from './menu.module.css'
import Item from "./Item"
import { useAuth } from "../../../contexts/authContext"
import { useHeader } from "../../../contexts/headerContext"
import { BiPackage } from "react-icons/bi"
import { BsFunnel } from "react-icons/bs"
import { MdCall, MdOutlineAccountTree, MdOutlineAttachMoney, MdOutlineChatBubbleOutline, MdOutlineDashboard, MdOutlineGroups, MdOutlineStickyNote2, MdOutlineTaskAlt, MdPersonOutline, MdWorkOutline } from "react-icons/md"
import { FaRegHandshake } from "react-icons/fa"

const Sidebar = () => {
    const { account_id, funnel_id } = useParams()
    const { permissions, verifyIfModuleIsActive } = useAuth()
    const { showMenu } = useHeader()

    return (
        <div className={`${styles.sidebar} ${showMenu ? styles.active : ''}`}>
            {permissions?.crm >= 1 && <Item icon={<BsFunnel />} text="CRM" to={`/${account_id}/crm/${funnel_id || ''}`} />}
            {permissions?.dashboard >= 1 && <Item icon={<MdOutlineDashboard />} text="Dashboard" to={`/${account_id}/dashboard`} />}
            {permissions?.manage_accounts >= 1 && <Item icon={<FaRegHandshake />} text="Dashboard do Parceiro" to={`/${account_id}/dashboard-partner`} />}
            {permissions?.tasks >= 1 && <Item icon={<MdOutlineTaskAlt />} text="Lista de Tarefas" to={`/${account_id}/tasks/log`} />}
            {permissions?.notes >= 1 && <Item icon={<MdOutlineStickyNote2 />} text="Histórico de anotações" to={`/${account_id}/notes/log`} />}
            {permissions?.call_log >= 1 && <Item icon={<MdCall />} text="Registro de Chamadas" to={`/${account_id}/calls/log`} />}
            {verifyIfModuleIsActive("CHAT") && permissions?.chat >= 1 && <Item icon={<MdOutlineChatBubbleOutline />} text="Chat" to={`/${account_id}/chat/`} />}
            {permissions?.manage_roles >= 1 && <Item icon={<MdWorkOutline />} text="Gerenciar Cargos" to={`/${account_id}/roles`} />}
            {permissions?.manage_teams >= 1 && <Item icon={<MdOutlineGroups />} text="Gerenciar Equipes" to={`/${account_id}/teams`} />}
            {permissions?.manage_users >= 1 && <Item icon={<MdPersonOutline />} text="Gerenciar Usuários" to={`/${account_id}/users`} />}
            {permissions?.manage_accounts >= 1 && <Item icon={<MdOutlineAccountTree />} text="Gerenciar Contas" to={`/${account_id}/accounts`} />}
            {permissions?.is_admin >= 1 && <Item icon={<BiPackage />} text="Gerenciar Planos" to={`/${account_id}/plans`} />}
            {permissions?.is_admin >= 1 && <Item icon={<MdOutlineAttachMoney />} text="Controle Financeiro" to={`/${account_id}/transactions`} />}
        </div>
    )
}

export default Sidebar