import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"
import ConfigTextListItems from "../../components/ConfigTextListItems"
import Loading from "../layouts/Loading"

const ReasonsDiscardSettings = ({ account_id }) => {
    const [reasonsDiscard, setReasonsDiscard] = useState([])
    const [reasonsDiscardEditable, setReasonsDiscardEditable] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (account_id) {
            getAllReasonsDiscard()
        }
    }, [account_id])

    const getAllReasonsDiscard = () => {
        setLoading(true)
        api.get("/reasonDiscards").then(response => {
            const listReasons = response?.data?.data || []
            setReasonsDiscard(listReasons)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateReasons = (e, _, reasons) => {
        e.preventDefault()
        setLoading(true)
        api.patch("/reasonDiscards", { reasons }).then(response => {
            const newReasons = response?.data?.data
            if (newReasons) {
                setReasonsDiscard(newReasons)
            }
            handleToogleEditReasons(e)
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleToogleEditReasons = (e) => {
        e.preventDefault()
        setReasonsDiscardEditable(previousValue => !previousValue)
    }

    return (
        <>
            <ConfigTextListItems
                title="Motivos de Descarte"
                placeholder="Motivo de Descarte"
                textNotItems="Nenhuma origem cadastrada até o momento!"
                value={reasonsDiscard}
                onSubmit={handleUpdateReasons}
                onCancel={handleToogleEditReasons}
                editable={reasonsDiscardEditable}
                setEdit={handleToogleEditReasons}
            />
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ReasonsDiscardSettings