import { useEffect, useState } from "react"
import { useAuth } from "../contexts/authContext"
import UserNotPermission from "./UserNotPermission"
import { useParams } from "react-router-dom"
import { isValidAccountId } from "../utils/utils"

const RenderIfUserHasPermission = ({ namePermission, children }) => {
    const [loadedPermissions, setLoadedPermissions] = useState(false)
    const [userHasPermission, setUserHasPermission] = useState(false)
    const { permissions, verifyIfUserHasPermission, statusAccount } = useAuth()
    const { account_id } = useParams()

    useEffect(() => {
        setLoadedPermissions(false)
        verifyPermissionByUser()
    }, [account_id, namePermission])

    const verifyPermissionByUser = async () => {
        const hasPermission = isValidAccountId(account_id) ? await verifyIfUserHasPermission(namePermission, account_id) : false
        if (namePermission) {
            setUserHasPermission(hasPermission)
        } else {
            setUserHasPermission(true)
        }
        setLoadedPermissions(true)
    }

    const verifyIfPageIsFree = (namePermission) => {
        return !namePermission || namePermission === "finance" || namePermission === "reports"
    }

    return (
        <>
            {loadedPermissions && (
                !permissions?.is_admin > 0 && !verifyIfPageIsFree(namePermission) && !statusAccount?.hasPermission ?
                    <UserNotPermission
                        blocked={true}
                        paymentByPartner={statusAccount?.payment_by_partner}
                        permissions={permissions}
                        account_id={account_id}
                    />
                    :
                    (userHasPermission ?
                        children
                        :
                        <UserNotPermission />)
            )}
        </>
    )
}

export default RenderIfUserHasPermission