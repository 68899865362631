import React, { useState } from "react"
import Modal from './index'
import Input from '../Form/Input'
import FormGroup from "../Form/FormGroup"
import Button from "../Form/Button"
import { FiUser } from "react-icons/fi"
import FormGroupInline from '../Form/FormGroupInline'
import { FaPencilAlt } from "react-icons/fa"
import Select from './../Form/Select'
import { getOptionByOptionList } from "../../utils/utils"

const ModalUser = ({ isNew = false, value = {}, optionsRoles = [], optionsTeams = [], optionsStatus = [], editable = false, setEdit, onSubmit, closeModal }) => {
    const [user, setUser] = useState(value)

    const handleChangeValue = (name, value) => {
        setUser(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        if (isNew) {
            closeModal()
        } else {
            setEdit(false)
            setUser(value)
        }
    }

    return (
        <Modal
            icon={<FiUser />}
            title={isNew ? "Cadastro de novo usuário" : (editable ? `Editando o usuário: ${user.email}` : `Visualizando: ${user.email}`)}
            description={isNew ? "Adicione um novo usuário a sua conta" : (editable ? "Altere as informações abaixo e clique no botão salvar" : `Caso queira editar as informações clique no ícone de edição`)}
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, isNew, user)}>
                <FormGroupInline>
                    <FormGroup labelText="E-mail">
                        {editable && isNew ?
                            <Input type="email" placeholder="E-mail do usuário" value={user.email ?? ""} onChange={(e) => handleChangeValue("email", e.target.value)} invalid={true} textInvalid="Preencha o e-mail do usuário" />
                            :
                            <span>{user.email}</span>
                        }
                    </FormGroup>
                    {!editable &&
                        <Button className="action bg-purple" onClick={() => setEdit(true)}>
                            <FaPencilAlt />
                        </Button>
                    }
                </FormGroupInline>
                {!isNew && user.name &&
                    <FormGroup labelText="Nome">
                        <span>{user.name}</span>
                    </FormGroup>
                }
                <FormGroup labelText="Cargo">
                    {editable ?
                        <Select multiple={false} name="Cargo" placeholder="Buscar..." options={optionsRoles} onChange={(value) => handleChangeValue("role_id", value)} selected={user.role_id ?? ""} />
                        :
                        <span>{getOptionByOptionList(user.role_id, optionsRoles)}</span>
                    }
                </FormGroup>
                {user.accepted &&
                    <FormGroup labelText="Status">
                        {editable ?
                            <Select multiple={false} name="Status" placeholder="Buscar..." options={optionsStatus} onChange={(value) => handleChangeValue("is_active", value)} selected={+user.is_active ?? ""} />
                            :
                            <span>{getOptionByOptionList(+user.is_active, optionsStatus)}</span>
                        }
                    </FormGroup>
                }
                <FormGroup labelText="Equipes">
                    {editable ?
                        <Select multiple={true} name="Equipes" placeholder="Buscar..." options={optionsTeams} onChange={(value) => handleChangeValue("teams", value)} selected={user.teams ?? []} />
                        :
                        <span>{getOptionByOptionList(user.teams, optionsTeams)}</span>
                    }
                </FormGroup>
                {editable &&
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                }
            </form>
        </Modal>
    )
}

export default ModalUser