import api from "../services/api"
import { toast } from "react-toastify"

const getFlags = async () => {
    try {
        const response = await api.get(`/flags`)
        const flags = response.data?.data
        return flags
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getFlags }