import React from "react";
import styles from './form.module.css'
import TextareaAutosize from 'react-textarea-autosize'

const TextareaAutoResize = ({ placeholder, value, onChange, className, theme, style, minRows, maxRows, required, elementRef, autoFocus = false, onKeyDown = () => { } }) => {
    return (
        <TextareaAutosize
            ref={elementRef}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`${styles.textarea} ${styles[className]} ${styles[theme]}`}
            style={style}
            required={required}
            autoFocus={autoFocus}
            minRows={minRows}
            maxRows={maxRows}
            onKeyDown={onKeyDown}
            tabIndex={1}
        >{value}</TextareaAutosize>
    )
}

export default TextareaAutoResize