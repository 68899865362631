import React, { useRef, useState } from "react";
import styles from "./cardSale.module.css"
import { MdMoreHoriz, MdOutlineCalendarToday, MdOutlineDeleteOutline } from "react-icons/md";
import FormGroup from "../../../Form/FormGroup";
import moment from "moment";
import { useCrm } from "../../../../contexts/crmContext";
import ModalDelete from "../../../Modal/ModalDelete";
import { useData } from "../../../../contexts/dataContext";
import { adjustTextByIndentation, getPropertyForItemInArrayById } from "../../../../utils/utils";

const CardSale = ({ value = {}, onDelete = () => { } }) => {
    const [data] = useState(value)
    const [showActions, setShowActions] = useState(false)
    const [styleAction, setStyleAction] = useState({})
    const [modalDelete, setModalDelete] = useState(false)

    const { formatValueByCurrency } = useCrm()
    const { products } = useData()

    const refAction = useRef()

    const handleToogleShowActions = (e) => {
        e.stopPropagation()
        setShowActions(previousValue => {
            if (!previousValue) {
                adjustPositionActions()
            }
            return !previousValue
        })
    }

    const adjustPositionActions = () => {
        const heightWindow = window.innerHeight
        const positions = refAction.current.getBoundingClientRect()
        if ((heightWindow - (positions.top + positions.height)) > heightWindow / 3) {
            const top = `calc(${positions.height}px + ${positions.top}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px + 16px)`
            setStyleAction({ top, right })
        } else {
            const bottom = `calc(${heightWindow}px - ${positions.bottom}px + ${positions.height}px + 0.5em - 16px)`
            const right = `calc(100vw - ${positions.right}px + 16px)`
            setStyleAction({ bottom, right })
        }
    }

    const getValueByProducts = () => {
        const value = data.products.reduce((sum, product) => sum + parseFloat(product.value || 0), 0)
        return formatValueByCurrency(value)
    }

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const actions = [
        {
            label: "Excluir",
            onClick: () => setModalDelete(true)
        }
    ]

    return (
        <div className={styles.card}>
            <div className={`${styles.header} ${styles.bottomBorder}`}>
                <div className={styles.left}>
                    <span>Criado por: </span><span className={styles.name}>{data.user_created}</span>
                </div>
                <div className={styles.right}>
                    <MdOutlineCalendarToday />
                    <span>{moment(data.created_at).format("DD/MM/YYYY HH:mm:ss") || "xx/xx/xxxx xx:xx"}</span>
                    <div className={styles.actions} ref={refAction} onClick={handleToogleShowActions}>
                        <MdMoreHoriz className={styles.expand} />
                        {showActions &&
                            <div className={styles.background}>
                                <ul style={styleAction}>
                                    {actions && actions.map((action, index) => (
                                        <li key={index} onClick={action.onClick}>{action.label}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomBorder}`}>
                <div className={styles.content}>
                    {data.description &&
                        <div className={styles.description}>{adjustTextByIndentation(data.description)}</div>
                    }
                    {data?.products?.length > 0 &&
                        <FormGroup labelText="Produtos">
                            <div className={styles.products}>
                                {data.products.map((product, index) => (
                                    <div className={styles.product} key={index}>
                                        <span>{getPropertyForItemInArrayById(product.product_id, products, "id", "value")}</span>
                                        <span>{formatValueByCurrency(product.value)}</span>
                                    </div>
                                ))}
                                <div className={styles.totalValue}>
                                    <span>Total</span>
                                    <span>{getValueByProducts()}</span>
                                </div>
                            </div>
                        </FormGroup>
                    }
                </div>
            </div>
            {modalDelete &&
                <ModalDelete
                    closeModal={closeModalDelete}
                    onSubmit={onDelete}
                    icon={<MdOutlineDeleteOutline />}
                    title="Exclusão de venda"
                    description="Deseja realmente excluir a venda?"
                    id={data._id}
                />
            }
        </div >
    )
}

export default CardSale