import React, { useCallback } from 'react'
import styles from './uploadArea.module.css'
import { LuFilePlus } from "react-icons/lu"
import { getIconByExtensionFile, openSelectSingleFile } from '../../utils/utils'
import Button from './../Form/Button';
import { MdOutlineDelete } from 'react-icons/md';

const UploadArea = ({ value, isDraggingOver, onFileDrop }) => {
    const handleDrop = useCallback((e) => {
        e.preventDefault()
        const file = e.dataTransfer.files[0]
        onFileDrop(file)
    }, [onFileDrop])

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleRemoveFile = (e) => {
        e.stopPropagation()
        onFileDrop(null)
    }

    const handleClick = (e) => {
        openSelectSingleFile(onFileDrop, ".xls, .xlsx, .csv")
    }

    return (
        <div
            className={`${styles.uploadArea} ${isDraggingOver ? styles.draggingOver : ''}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleClick}
        >
            {value &&
                <div className={styles.removeFile}>
                    <Button onClick={handleRemoveFile}>
                        Remover
                        <MdOutlineDelete />
                    </Button>
                </div>
            }
            {value && value?.name ? getIconByExtensionFile(value?.name) : <LuFilePlus />}
            <span>
                {value && value?.name ? value?.name : "Arraste um arquivo aqui..."}
            </span>
        </div>
    )
}

export default UploadArea
