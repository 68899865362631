import React, { useEffect, useState } from "react"
import styles from "./apiIntegration.module.css"
import Button from "../../components/Form/Button"
import Table from "../../components/Table"
import FormGroup from "../../components/Form/FormGroup"
import BreakForm from "../../components/Form/BreakForm"
import FormGroupInline from "../../components/Form/FormGroupInline"
import api from '../../services/api'
import { toast } from 'react-toastify'
import ModalApiDomain from "../../components/Modal/ModalApiDomain"
import ModalApiForm from "../../components/Modal/ModalApiForm"
import { useData } from "../../contexts/dataContext"

const headersDomains = [
    "#",
    "Identificação",
    "Domínio",
    "Status",
    "",
]

const headersForms = [
    "#",
    "Domínio",
    "URL",
    "Cadastros",
    "Aguardando",
    "Último Cadastro",
    "Status",
]

const ApiForm = () => {
    const [domains, setDomains] = useState([])
    const [forms, setForms] = useState([])
    const [showModalDomain, setShowModalDomain] = useState(false)
    const [domain, setDomain] = useState({})
    const [editDomain, setEditDomain] = useState(false)
    const [isNewDomain, setIsNewDomain] = useState(false)
    const [showModalForm, setShowModalForm] = useState(false)
    const [form, setForm] = useState({})
    const [editForm, setEditForm] = useState(false)
    const [allFields, setAllFields] = useState([])

    const { funnels, teams, users, flags, fields } = useData()

    useEffect(() => {
        getAllDomains()
        getAllForms()
    }, [])

    useEffect(() => {
        setAllFields([{ id: -1, value: "Ignorar campo" }, ...fields])
    }, [fields])

    const getAllDomains = () => {
        api.get("/integrations/api/domains").then(response => {
            const domains = response?.data?.data
            setDomains(domains)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getAllForms = () => {
        api.get("/integrations/api/forms").then(response => {
            const forms = response?.data?.data
            setForms(forms)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getFilteredValuesDomains = () => {
        const values = domains
        values.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return - 1
            } else {
                return 0
            }
        })
        const filteredValues = values?.map((domain) => getDataNewDomains(domain))
        return filteredValues
    }

    const getDataNewDomains = (newDomain) => {
        const data = {
            id: newDomain._id,
            value: [
                newDomain.name,
                newDomain.domain,
                (newDomain.is_active ? "Ativo" : "Bloqueado"),
            ],
            actions: [
                {
                    label: "Editar",
                    onClick: (id) => handleShowModalDomain(false, true, id)
                },
                {
                    label: "Excluir",
                    onClick: handleDeleteDomain
                }
            ]
        }
        return data
    }

    const getFilteredValuesForms = () => {
        const values = forms
        values.sort((a, b) => {
            if (a.domain > b.domain) {
                return 1
            } else if (a.domain < b.domain) {
                return - 1
            } else {
                return 0
            }
        })
        const filteredValues = values?.map((form) => getDataNewForms(form))
        return filteredValues
    }

    const getDataNewForms = (newForm) => {
        const data = {
            id: newForm._id,
            value: [
                newForm?.domain,
                newForm?.url?.length > 60 ? `${newForm.url.substr(0, 60)}...` : newForm?.url?.substr(0, 60),
                newForm?.sends,
                newForm?.pending,
                newForm?.last_send,
                newForm?.config_finish ? "Configurado" : "Pendente",
            ],
        }
        return data
    }

    const handleShowModalDomain = async (isNew = false, edit = false, id = undefined) => {
        setEditDomain(edit)
        setIsNewDomain(isNew)
        setDomain({})
        if (!isNew && id) {
            await getDomainById(id)
        }
        setShowModalDomain(true)
    }

    const handleCloseModalDomain = () => {
        setShowModalDomain(false)
        setDomain({})
    }

    const handleSaveDomain = (e, isNew, data) => {
        e.preventDefault()
        if (isNew) {
            handleSaveNewDomain(data)
        } else {
            handleUpdateDomain(data)
        }
    }

    const handleSaveNewDomain = (data) => {
        api.post("/integrations/api/domains", data).then(response => {
            const newDomain = response.data?.data
            if (newDomain) {
                setDomains(previousValue => {
                    const newValue = [...previousValue]
                    newValue.push(
                        newDomain
                    )

                    return newValue
                })
                handleShowModalDomain(false, false, newDomain._id)
            }
            toast.success(response?.data?.message)
            handleCloseModalDomain()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const handleUpdateDomain = (data) => {
        api.patch(`/integrations/api/domains/${data._id}`, data).then(response => {
            setDomains(previousValue => {
                return previousValue?.map(el => {
                    if (el._id === data._id) {
                        el = data
                    }
                    return el
                })
            })
            toast.success(response?.data?.message)
            handleCloseModalDomain()
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const handleDeleteDomain = (domain_id) => {
        api.delete(`/integrations/api/domains/${domain_id}`).then(response => {
            setDomains(previousValue => previousValue?.filter(domain => domain._id !== domain_id))
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getDomainById = (domain_id) => {
        return new Promise(resolve => {
            api.get(`/integrations/api/domains/${domain_id}`).then(response => {
                const domain = response.data?.data
                setDomain(domain)
                resolve(1)
            }).catch(err => toast.error(err?.response?.data?.message))
        })
    }

    const handleShowModalForm = async (form_id) => {
        await getFormById(form_id)
        setShowModalForm(true)
    }

    const handleCloseModalForm = () => {
        setShowModalForm(false)
        setForm({})
    }

    const handleSaveForm = (data) => {
        api.patch(`/integrations/api/forms/${data._id}`, data).then(response => {
            const newForm = response?.data?.data
            if (newForm) {
                setForms(previousValue => {
                    return previousValue?.map(el => {
                        if (el._id === newForm._id) {
                            el = newForm
                        }
                        return el
                    })
                })
            }
            toast.success(response?.data?.message)
            setEditForm(false)
        }).catch(err => toast.error(err?.response?.data?.message))
    }

    const getFormById = (form_id) => {
        return new Promise(resolve => {
            api.get(`/integrations/api/forms/${form_id}`).then(response => {
                const form = response.data?.data
                setForm(form)
                resolve(1)
            }).catch(err => toast.error(err?.response?.data?.message))
        })
    }

    return (
        <>
            <div className={styles.container}>
                <FormGroup>
                    <FormGroupInline>
                        <div className={styles.header}>
                            <BreakForm title="Domínios Permitidos" showAction={false} />
                            <span>Essa é a lista de domínios que possuem permissão para enviar dados para seu CRM.</span>
                        </div>
                        <Button className="bg-pink" onClick={() => handleShowModalDomain(true, true)}>Conectar um novo domínio</Button>
                    </FormGroupInline>
                    <Table
                        headers={headersDomains}
                        values={getFilteredValuesDomains()}
                        messageNotRegisters="Nenhum registro encontrado"
                        onClick={(id) => handleShowModalDomain(false, false, id)}
                        showIndex={true}
                    />
                </FormGroup>
                <FormGroup>
                    <FormGroupInline>
                        <div className={styles.header}>
                            <BreakForm title="Lista de Formulários" showAction={false} />
                            <span>Esses são os formulários que já enviaram cadastros para seu CRM. Clique sobre um item para configurar os campos.</span>
                        </div>
                    </FormGroupInline>
                    <Table
                        headers={headersForms}
                        values={getFilteredValuesForms()}
                        messageNotRegisters="Nenhum registro encontrado"
                        onClick={handleShowModalForm}
                        showIndex={true}
                    />
                </FormGroup>
            </div>
            {showModalDomain && <ModalApiDomain closeModal={handleCloseModalDomain} onSubmit={handleSaveDomain} isNew={isNewDomain} setEdit={setEditDomain} value={domain} editable={editDomain} />}
            {showModalForm && <ModalApiForm closeModal={handleCloseModalForm} onSubmit={handleSaveForm} value={form} funnels={funnels} teams={teams} users={users} flags={flags} fields={allFields} editable={editForm} setEdit={setEditForm} />}
        </>
    )
}

export default ApiForm