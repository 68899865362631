import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import styles from "./aside.module.css"
import Button from "../../../Form/Button"
import { MdClose } from "react-icons/md"
import ImageWithFallback from "../../../ImageWithFallback"
import FormGroupInline from './../../../Form/FormGroupInline'
import Table from './../../../Table/index'
import { useCrm } from "../../../../contexts/crmContext"
import CircleLoading from './../../../CircleLoading'
import { getAllLeads, getAllParticipants, listAssignTo } from "../../../../schemas/Chat"
import { toast } from "react-toastify"
import { capitalizeFirstLetter, getItemForArrayById, getLocalDate, getNameUserById, getPropertyForItemInArrayById, sortValuesByPropertyAndDirection } from "../../../../utils/utils"
import { useData } from "../../../../contexts/dataContext"
import { useWebSocketChatContext } from "../../../../contexts/webSocketChatContext"
import Contact from "../Contact"
import Input from "../../../Form/Input"
import GroupDataEdit from "../../../Modal/ModalLead/GroupDataEdit/GroupDataEdit"
import Select from "../../../Form/Select"
import FormGroup from "../../../Form/FormGroup"
import Flag from "../../../Flag"

const headerLeads = [
    "Nome",
    "Funil",
    "Etapa",
    "Equipe",
    "Responsáveis",
    "Status",
    "Data de Cadastro",
    "",
]

const Aside = forwardRef(({ conversation, contact, defaultPicture, handleClose = () => { }, handleExecuteFunctionByAction = () => { } }, ref) => {
    const [listLeads, setListLeads] = useState([])
    const [listParticipants, setListParticipants] = useState([])
    const [loadingLeadsOrParticipants, setLoadingLeadsOrParticipants] = useState(false)
    const [flags, setFlags] = useState([])
    const [dataContact, setDataContact] = useState({})
    const [editContact, setEditContact] = useState(false)

    const { teams, roles, allUsersGroup } = useData()
    const { openModalLead } = useCrm()
    const { chatContacts, chatFlags } = useWebSocketChatContext()

    useEffect(() => {
        if (conversation?._id) {
            if (!conversation?.is_group) {
                getAllLeadsByConversation()
            } else {
                getAllParticipantsByConversation()
            }
        }
    }, [conversation?.is_group, conversation?._id])

    useEffect(() => {
        setInitialDataByContact()
    }, [contact])

    useEffect(() => {
        setFlags(chatFlags.map(flag => ({ id: flag._id, value: flag.name })))
    }, [chatFlags])

    useImperativeHandle(ref, () => ({
        handleGetAllLeadsByConversation() {
            getAllLeadsByConversation()
        }
    }))

    const setInitialDataByContact = () => {
        const name = contact?.name || ""
        const flags = conversation?.flags?.map(flag => flag.chat_flag_id) || []
        setDataContact({ name, flags })
    }

    const getAllLeadsByConversation = async () => {
        try {
            setLoadingLeadsOrParticipants(true)
            const leads = await getAllLeads(conversation._id)
            setListLeads(leads)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoadingLeadsOrParticipants(false)
        }
    }

    const getAllParticipantsByConversation = async () => {
        try {
            setLoadingLeadsOrParticipants(true)
            const participants = await getAllParticipants(conversation._id)
            setListParticipants(participants)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoadingLeadsOrParticipants(false)
        }
    }

    const handleOpenLead = (leadId) => {
        openModalLead(leadId)
    }

    const getFormattedLeadsByTable = () => {
        const formattedLeads = listLeads.map(lead => (
            {
                id: lead._id,
                value: [
                    lead?.name || "-",
                    lead?.funnel_name || "-",
                    lead?.stage_name || "-",
                    lead?.team_name || "-",
                    lead?.users_names || "-",
                    lead?.discarted ? "Descartado" : "Em negociação",
                    lead?.created_at ? getLocalDate(lead?.created_at).format("DD/MM/YYYY HH:mm:ss") : "-",
                ],
                actions: [
                    {
                        label: "Abrir Lead",
                        onClick: handleOpenLead
                    },
                ]
            }
        ))
        return formattedLeads
    }

    const getResponsiblesByConversation = () => {
        let content = <></>
        if (conversation?.owner?.assignTo === "teamAndRole") {
            content = <>
                <span>Equipe: {getPropertyForItemInArrayById(conversation?.owner?.team_id, teams, "id", "value")}</span>
                <span>Cargo: {getPropertyForItemInArrayById(conversation?.owner?.role_id, roles, "id", "value")}</span>
            </>
        } else if (conversation?.owner?.assignTo === "users") {
            const usersNames = conversation?.owner?.users?.map(user => getNameUserById(allUsersGroup, user?.user_id)).join(", ")
            content = <span>Responsáveis: {usersNames}</span>
        }
        return <>
            <span>Atribuído para: {getPropertyForItemInArrayById(conversation?.owner?.assignTo, listAssignTo, "id", "value")}</span>
            {content}
        </>
    }

    const getParticipantsByConversation = () => {
        const listContacts = listParticipants.map(participant => {
            const contact = getItemForArrayById(chatContacts, "_id", participant.contact_id)
            if (conversation?.platform === "whatsapp") {
                const data = [
                    {
                        details: {
                            name: contact?.name,
                            phone: contact?.whatsapp?.number,
                            picture: contact?.whatsapp?.picture,
                            contact_id: participant?.contact_id,
                        }
                    }
                ]
                return data
            }
        })

        return <>
            {listParticipants?.length > 0 && listContacts?.sort((a, b) => sortValuesByPropertyAndDirection(a[0]?.details, b[0]?.details, "name"))?.map((contact, index) => (
                <Contact
                    key={index}
                    contacts={contact}
                    defaultPicture={defaultPicture}
                    showHeader={false}
                />
            ))}
            {listParticipants?.length === 0 && <span>Nenhum participante encontrado!</span>}
        </>
    }

    const handleChange = (name, value) => {
        setDataContact(previousValue => ({ ...previousValue, [name]: value }))
    }

    const handleChangeEditContact = () => {
        setEditContact(true)
    }

    const handleCancelEditContact = () => {
        setInitialDataByContact()
        setEditContact(false)
    }

    const handleUpdateContact = (e) => {
        if (!dataContact?.name) {
            return toast.error("O nome é obrigatório!")
        }
        handleExecuteFunctionByAction(e, "UPDATE_CONTACT", dataContact)
        setEditContact(false)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.close} onClick={handleClose}>
                    <MdClose />
                </div>
                <hr />
                <div className={styles.contactInfo}>
                    <GroupDataEdit edit={editContact} borderTop={false} onChangeEdit={handleChangeEditContact} onCancelEdit={handleCancelEditContact} onUpdate={handleUpdateContact}>
                        <ImageWithFallback
                            src={contact?.picture || defaultPicture}
                            alt="Imagem de Perfil"
                            functionError={() => <img src={defaultPicture} alt="Imagem de Perfil" />}
                        />
                        {editContact && !conversation?.is_group ?
                            <FormGroup labelText="Nome do Contato">
                                <Input placeholder="Nome do contato" onChange={(e) => handleChange("name", e.target.value)} value={dataContact?.name} />
                            </FormGroup>
                            :
                            <span className={styles.name}>{contact?.name || "-"}</span>
                        }
                        {!editContact && (conversation?.is_group ?
                            <>
                                <span>Grupo</span>
                            </>
                            :
                            <span className={styles.identifier}>{contact?.identifier || "-"}</span>)
                        }
                        <div className={styles.flags}>
                            {editContact ?
                                <FormGroup labelText="Etiquetas">
                                    <Select name="Etiquetas" placeholder="Selecione uma ou mais opções..." multiple={true} options={flags} onChange={value => handleChange("flags", value)} selected={dataContact?.flags} />
                                </FormGroup>
                                :
                                <>
                                    <span className={styles.title}>Etiquetas</span>
                                    <div className={styles.groupFlags}>
                                        {conversation?.flags?.length > 0 && conversation.flags.map((flag, index) => (
                                            <Flag
                                                name={getPropertyForItemInArrayById(flag.chat_flag_id, chatFlags, "_id", "name")}
                                                color={getPropertyForItemInArrayById(flag.chat_flag_id, chatFlags, "_id", "color")}
                                                key={index}
                                            />
                                        ))}
                                    </div>
                                    {(!conversation?.flags || conversation?.flags?.length === 0) && <span>Nenhuma etiqueta aplicada!</span>}
                                </>
                            }
                        </div>
                    </GroupDataEdit>
                </div>
                <hr />
                <div className={styles.options}>
                    <FormGroupInline>
                        {conversation?.status === "finalized" ?
                            <Button className="bg-orange" onClick={(e) => handleExecuteFunctionByAction(e, "OPEN_CHAT")}>Reabrir atendimento</Button>
                            :
                            <Button className="bg-pink" onClick={(e) => handleExecuteFunctionByAction(e, "END_CHAT")}>Encerrar atendimento</Button>
                        }
                        {!conversation?.is_group &&
                            <Button className="bg-purple" onClick={(e) => handleExecuteFunctionByAction(e, "ADD_LEAD")}>Criar um novo lead</Button>
                        }
                    </FormGroupInline>
                    <FormGroupInline>
                        <Button className="bg-green" onClick={(e) => handleExecuteFunctionByAction(e, "ASSIGN_CHAT")}>Atribuir atendimento</Button>
                    </FormGroupInline>
                </div>
                <hr />
                <div className={styles.responsibles}>
                    <span className={styles.title}>Atendimento</span>
                    {getResponsiblesByConversation()}
                </div>
                {Object.keys(conversation?.metadata || {})?.length > 0 &&
                    <>
                        <hr />
                        <div className={styles.responsibles}>
                            <span className={styles.title}>Dados capturados pelo chatbot</span>
                            {Object.keys(conversation.metadata)?.map(key => (
                                <span key={key}>{capitalizeFirstLetter(key)}: {conversation.metadata[key]}</span>
                            ))}
                        </div>
                    </>
                }
                <hr />
                <div className={styles.leadsOrParticipantsList}>
                    {conversation?.is_group &&
                        <>
                            <span className={styles.title}>Lista de Participantes</span>
                            <div className={styles.groupLeadsOrParticipants}>
                                {loadingLeadsOrParticipants ?
                                    <CircleLoading />
                                    :
                                    getParticipantsByConversation()
                                }
                            </div>
                        </>
                    }
                    {!conversation?.is_group &&
                        <>
                            <span className={styles.title}>Leads Correspondentes</span>
                            {loadingLeadsOrParticipants ?
                                <CircleLoading />
                                :
                                <>
                                    {listLeads?.length > 0 ?
                                        <Table
                                            headers={headerLeads}
                                            messageNotRegisters="Nenhum lead encontrado!"
                                            onClick={handleOpenLead}
                                            values={getFormattedLeadsByTable()}
                                        />
                                        :
                                        <>
                                            <span>Nenhum lead encontrado!</span>
                                            <Button className="bg-purple" onClick={(e) => handleExecuteFunctionByAction(e, "ADD_LEAD")}>Criar um novo lead</Button>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
})

export default Aside