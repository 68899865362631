import React, { useEffect, useState } from 'react';
import styles from "./profile.module.css"
import { MdOutlineAddAPhoto, MdOutlineCheck, MdOutlineClose, MdOutlineEdit, MdOutlineMailLock, MdOutlinePublishedWithChanges } from 'react-icons/md';
import { BsPersonGear } from 'react-icons/bs';
import ImageWithFallback from './../../components/ImageWithFallback';
import FormGroup from './../../components/Form/FormGroup';
import FormGroupInline from '../../components/Form/FormGroupInline';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import { useAuth } from '../../contexts/authContext';
import api from '../../services/api';
import { toast } from 'react-toastify';
import ModalChangePassword from '../../components/Modal/ModalChangePassword';
import ModalChangeEmail from '../../components/Modal/ModalChangeEmail';
import { openSelectSingleFile } from '../../utils/utils';

const Profile = () => {
    const [name, setName] = useState("")
    const [image, setImage] = useState()
    const [showModalChangePassword, setShowModalChangePassword] = useState(false)
    const [showModalChangeEmail, setShowModalChangeEmail] = useState(false)
    const [editable, setEditable] = useState(false)
    const [waitCode, setWaitCode] = useState(false)
    const [showOptionsEditImage, setShowOptionsEditImage] = useState(false)
    const { user, updateNameUser, updateEmailUser, updateImageUser, account } = useAuth()

    useEffect(() => {
        if (user?.name) {
            setName(user.name)
        }
    }, [user])

    const onCancelEditname = () => {
        setEditable(false)
        setName(user?.name)
    }

    const onChangeName = () => {
        api.patch(`/users/profile`, { name }).then(response => {
            setEditable(false)
            updateNameUser(name)
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    const onChangePassword = (e, user) => {
        e.preventDefault()
        api.patch(`/users/changePassword`, user).then(response => {
            setShowModalChangePassword(false)
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    const onChangeEmail = (e, data) => {
        e.preventDefault()
        const url = waitCode ? `/users/changeEmail/validate` : `/users/changeEmail`
        api.patch(url, data).then(response => {
            if (waitCode) {
                const newEmail = response?.data?.data || "-"
                updateEmailUser(newEmail)
                setShowModalChangeEmail(false)
                setWaitCode(false)
            } else {
                setWaitCode(true)
            }
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    const onReturnChange = (e) => {
        e.preventDefault()
        setWaitCode(false)
    }

    const handleToogleShowOptionsImage = () => {
        setShowOptionsEditImage(previousValue => !previousValue)
    }

    const handleUpdateImage = () => {
        const formData = new FormData()
        formData.append("image", image)
        api.patch("users/changeImage", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
            const newLinkImage = response?.data?.data
            updateImageUser(newLinkImage)
            setImage(undefined)
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    const handleDeleteImage = () => {
        api.delete("users/changeImage").then(response => {
            updateImageUser(undefined)
            setImage(undefined)
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        })
    }

    const handleCancelChangeImage = () => {
        setImage(null)
    }

    return (
        <div className={styles.content}>
            <div className={styles.card}>
                <div className={styles.header}>
                    <BsPersonGear />
                    <span>Perfil</span>
                </div>
                <div className={styles.body}>
                    <div className={styles.groupImage}>
                        <div className={styles.image}>
                            {image &&
                                <ImageWithFallback
                                    src={URL.createObjectURL(image)}
                                    alt="Imagem de Perfil"
                                    functionError={() => { }}
                                />
                            }
                            {!image && user?.image &&
                                <ImageWithFallback
                                    src={user.image}
                                    alt="Imagem de Perfil"
                                    functionError={() => { }}
                                />
                            }
                            {image ?
                                <>
                                    <FormGroupInline>
                                        <div className={`${styles.changeImage} ${styles.confirm}`}>
                                            <div className={`${styles.actionButton} bg-pink`} onClick={handleUpdateImage}>
                                                <MdOutlineCheck />
                                            </div>
                                            <div className={`${styles.actionButton} bg-purple`} onClick={handleCancelChangeImage}>
                                                <MdOutlineClose />
                                            </div>
                                        </div>
                                    </FormGroupInline>
                                </>
                                :
                                <div className={styles.changeImage} onClick={handleToogleShowOptionsImage}>
                                    <div className={styles.actionButton}>
                                        <MdOutlineAddAPhoto />
                                    </div>
                                    {showOptionsEditImage &&
                                        <>
                                            <ul>
                                                <li onClick={() => openSelectSingleFile(setImage, "image/*")}>
                                                    Editar
                                                </li>
                                                <li onClick={handleDeleteImage}>Remover</li>
                                            </ul>
                                            <div className={styles.background} />
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <FormGroupInline >
                        <FormGroup labelText="Nome">
                            {editable ?
                                <Input placeholder="Nome" value={name || ""} onChange={(e) => setName(e.target.value)} theme="noBorder" />
                                :
                                <span>{user?.name}</span>
                            }
                        </FormGroup>
                        {editable ?
                            <>
                                <MdOutlineCheck onClick={() => onChangeName()} />
                                <MdOutlineClose onClick={() => onCancelEditname()} />
                            </>
                            :
                            <MdOutlineEdit onClick={() => setEditable(true)} />
                        }
                    </FormGroupInline>
                    <FormGroup labelText="E-mail">
                        <span>{user.email || "-"}</span>
                    </FormGroup>
                    <FormGroup labelText="Conta atual">
                        <span>{account?.name || "-"}</span>
                    </FormGroup>
                </div>
                <div className={styles.footer}>
                    <Button className="bg-pink" onClick={() => setShowModalChangeEmail(true)}>
                        <FormGroupInline>
                            Alterar e-mail
                            <MdOutlineMailLock />
                        </FormGroupInline>
                    </Button>
                    <Button className="bg-orange" onClick={() => setShowModalChangePassword(true)}>
                        <FormGroupInline>
                            <span>Alterar senha</span>
                            <MdOutlinePublishedWithChanges />
                        </FormGroupInline>
                    </Button>
                </div>
            </div>
            {showModalChangePassword &&
                <ModalChangePassword
                    onSubmit={onChangePassword}
                    closeModal={() => setShowModalChangePassword(false)}
                />
            }
            {showModalChangeEmail &&
                <ModalChangeEmail
                    waitCode={waitCode}
                    returnStep={onReturnChange}
                    onSubmit={onChangeEmail}
                    closeModal={() => setShowModalChangeEmail(false)}
                />
            }
        </div>
    )
}

export default Profile