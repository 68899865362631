import React, { useState } from "react";
import Modal from './index';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import FormGroupInline from '../Form/FormGroupInline';
import Select from "../Form/Select";
import { MdOutlinePersonOff } from "react-icons/md";
import Textarea from "../Form/Textarea";
import { useData } from "../../contexts/dataContext";

const ModalDiscard = ({ isNew = false, value = {}, onSubmit, closeModal }) => {
    const [data, setData] = useState(value)

    const { reasonsDiscard } = useData()

    const handleChangeValue = (name, value) => {
        setData(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    return (
        <Modal
            icon={<MdOutlinePersonOff />}
            title="Descarte de lead"
            description="Preencha as informações abaixo e clique no botão salvar"
            closeModal={closeModal}
        >
            <form onSubmit={(e) => onSubmit(e, data, isNew)}>
                <FormGroupInline>
                    <FormGroup labelText="Motivo">
                        <Select multiple={false} name="Motivo" placeholder="Selecione um motivo" options={reasonsDiscard || []} selected={data?.reason_discard_id ?? ""} onChange={(value) => handleChangeValue("reason_discard_id", value)} invalid={true} textInvalid="Selecione um motivo" />
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <FormGroup labelText="Descrição">
                        <Textarea placeholder="Descrição do motivo" value={data.description ?? ""} onChange={(e) => handleChangeValue("description", e.target.value)} rows={3} />
                    </FormGroup>
                </FormGroupInline>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalDiscard