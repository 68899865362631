import React, { useEffect, useState } from "react"
import styles from "./importLog.module.css"
import api from "../../services/api"
import { toast } from "react-toastify"
import Table from '../../components/Table/index'
import Loading from "../layouts/Loading"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import ModalConfirm from './../../components/Modal/ModalConfirm'
import { openLinkExternal } from "../../utils/utils"
import { MdDeleteOutline } from "react-icons/md"

const ImportLog = () => {
    const [imports, setImports] = useState([])
    const [modalDiscardImport, setModalDiscardImport] = useState(false)
    const [idDiscardImport, setIdDiscardImport] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { account_id } = useParams()

    const navigate = useNavigate()

    const headers = [
        "#",
        "Data",
        "Identificação",
        "Tipo",
        "Registros",
        "Responsável",
        "Status",
        "",
    ]

    useEffect(() => {
        getAllImports()
    }, [])

    const getAllImports = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/imports`)
            const imports = response.data?.data || []
            setImports(imports)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setIsLoading(false)
        }
    }

    const getDataNewImport = (newImport) => {
        const actions = [
            {
                label: "Dados Enviados",
                onClick: handleViewImport
            }
        ]
        if (!newImport?.discarted) {
            if (newImport?.error) {
                actions.push({
                    label: "Falhas",
                    onClick: openErrorByImport
                })
            }
            if (newImport?.discard_is_valid) {
                actions.push({
                    label: "Descartar",
                    onClick: openModalDiscardImport
                })
            }
        }
        return {
            id: newImport._id,
            value: [
                newImport?.created_at ?? "-",
                newImport?.name ?? "-",
                newImport?.type ?? "-",
                newImport?.count ?? "-",
                newImport?.user_created ?? "-",
                newImport?.discarted ? "Importação Descartada" : (newImport?.error ? "Falha na Importação" : (newImport.imported ? "Importados" : "Em processamento")),
            ],
            actions
        }
    }

    const updateDataImportById = (data, import_id) => {
        setImports(previousValue => {
            return previousValue.map(importData => {
                if (importData._id === import_id) {
                    return { ...importData, ...data }
                }
                return importData
            })
        })
    }

    const getFilteredValues = () => {
        const values = imports
        const filteredValues = values?.map((newImport) => getDataNewImport(newImport))
        return filteredValues
    }

    const openModalDiscardImport = (id) => {
        setIdDiscardImport(id)
        setModalDiscardImport(true)
    }

    const closeModalDiscardImport = () => {
        setIdDiscardImport(null)
        setModalDiscardImport(false)
    }

    const handleViewImport = (import_id) => {
        setIsLoading(true)
        api.get(`/imports/${import_id}/view`).then(response => {
            const link = response.data.data
            openLinkExternal(link)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => setIsLoading(false))
    }

    const openErrorByImport = (import_id) => {
        navigate(`/${account_id}/import/${import_id}/error`)
    }

    const handleDiscardImport = () => {
        setIsLoading(true)
        api.patch(`/imports/${idDiscardImport}/discard`).then(response => {
            updateDataImportById({ discarted: true }, idDiscardImport)
            closeModalDiscardImport()
            toast.success(response?.data?.message)
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => setIsLoading(false))
    }

    return (
        <div className={styles.container}>
            <div className={styles.link}>
                <NavLink to={`/${account_id}/import`}>Criar uma nova importação</NavLink>
            </div>

            <Table
                headers={headers}
                values={getFilteredValues()}
                messageNotRegisters="Nenhum registro encontrado"
                showIndex={true}
            />
            {modalDiscardImport &&
                <ModalConfirm
                    icon={<MdDeleteOutline />}
                    title="Descartar leads importados"
                    description="Deseja realmente descartar os leads importados?"
                    text="Todos os leads importados com sucesso serão enviados para a lixeira, e os leads com erro excluídos definitivamente!"
                    onSubmit={handleDiscardImport}
                    closeModal={closeModalDiscardImport}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </div>
    )
}

export default ImportLog