import React, { useEffect, useState } from 'react'
import styles from "./poll.module.css"
import Checkbox from '../../../Form/Checkbox'
import { MdCheckCircle } from 'react-icons/md'

const Poll = ({ content }) => {
    const [maxSelections, setMaxSelections] = useState(0)

    useEffect(() => {
        if (content?.options?.length > 0) {
            content.options.forEach(option => {
                setMaxSelections(previousValue => option?.selections?.length > previousValue ? option.selections.length : previousValue)
            })
        } else {
            setMaxSelections(0)
        }
    }, [content?.options])

    return (
        <div className={styles.container}>
            <span className={styles.title}>{content?.name || "-"}</span>
            <div className={styles.subtitle}>
                {content?.select_multiple ?
                    <>
                        <div className={`${styles.icon} ${styles.multiple}`}>
                            <MdCheckCircle />
                            <MdCheckCircle />
                        </div>
                        <span>Selecione uma ou mais opções</span>
                    </>
                    :
                    <>
                        <div className={styles.icon}><MdCheckCircle /> </div>
                        <span>Selecione uma opção</span>
                    </>
                }
            </div>
            <div className={styles.options}>
                {content?.options?.length && content.options.map((option, index) => (
                    <div className={styles.option} key={index}>
                        <div className={styles.infos}>
                            <div className={styles.selection}>
                                <Checkbox pink={true} checked={option?.selections?.length > 0 && option.selections.find(contact_id => contact_id === "me")} />
                                <span>{option?.optionName || "-"}</span>
                            </div>
                            <span>{option?.selections?.length || "0"}</span>
                        </div>
                        <div className={styles.progressbarContainer}>
                            <div
                                className={styles.progressbar}
                                style={{ width: `${maxSelections > 0 && option?.selections?.length > 0 ? (option.selections.length / maxSelections) * 100 : 0}%` }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Poll
