import React, { useState } from "react";
import styles from "./utm.module.css"
import FormGroup from '../Form/FormGroup';
import Input from "../Form/Input";
import GroupDataEdit from "../Modal/ModalLead/GroupDataEdit/GroupDataEdit";

const Utm = ({ name = "utm", value = {}, edit = false, handleUpdate = () => { }, onChangeEdit = () => { } }) => {
    const [tempUtm, setTempUtm] = useState(value)

    const onChangeValues = (name, value) => {
        if (name === "url") {
            try {
                const url = new URL(value)
                const medium = url.searchParams.get("utm_medium")
                const source = url.searchParams.get("utm_source")
                const campaign = url.searchParams.get("utm_campaign")
                const content = url.searchParams.get("utm_content")
                const term = url.searchParams.get("utm_term")

                setTempUtm(previousValue => {
                    return {
                        ...previousValue,
                        url: value,
                        medium,
                        source,
                        campaign,
                        content,
                        term
                    }
                })
            } catch (err) {
                setTempUtm(previousValue => {
                    return {
                        ...previousValue,
                        [name]: value
                    }
                })
            }
        } else {
            setTempUtm(previousValue => {
                return {
                    ...previousValue,
                    [name]: value
                }
            })
        }
    }

    const cancelEdit = () => {
        setTempUtm(value)
        onChangeEdit(name, false)
    }

    return (
        <GroupDataEdit name="utm" value={value} edit={edit} onChangeEdit={onChangeEdit} onCancelEdit={cancelEdit} onUpdate={() => handleUpdate("utm", { utm: tempUtm })}>
            <FormGroup labelText="UTM">
                <div className={styles.groupUtm}>
                    <div className={styles.data}>
                        <FormGroup labelText="URL">
                            {edit ?
                                <Input placeholder="Digite a URL" value={tempUtm.url || ''} onChange={(e) => onChangeValues("url", e.target.value)} theme="noBorder" />
                                :
                                <span>{value.url || '-'}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Source">
                            {edit ?
                                <Input placeholder="Digite o source" value={tempUtm.source || ''} onChange={(e) => onChangeValues("source", e.target.value)} theme="noBorder" />
                                :
                                <span>{value.source || '-'}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Medium">
                            {edit ?
                                <Input placeholder="Digite o medium" value={tempUtm.medium || ''} onChange={(e) => onChangeValues("medium", e.target.value)} theme="noBorder" />
                                :
                                <span>{value.medium || '-'}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Campaign">
                            {edit ?
                                <Input placeholder="Digite a campaign" value={tempUtm.campaign || ''} onChange={(e) => onChangeValues("campaign", e.target.value)} theme="noBorder" />
                                :
                                <span>{value.campaign || '-'}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Term">
                            {edit ?
                                <Input placeholder="Digite o term" value={tempUtm.term || ''} onChange={(e) => onChangeValues("term", e.target.value)} theme="noBorder" />
                                :
                                <span>{value.term || '-'}</span>
                            }
                        </FormGroup>
                        <FormGroup labelText="Content">
                            {edit ?
                                <Input placeholder="Digite o content" value={tempUtm.content || ''} onChange={(e) => onChangeValues("content", e.target.value)} theme="noBorder" />
                                :
                                <span>{value.content || '-'}</span>
                            }
                        </FormGroup>
                    </div>
                </div>
            </FormGroup>
        </GroupDataEdit>
    )
}

export default Utm