import React from "react"
import ProgressSetup from "./ProgressSetup";
import image from "../../../images/setup/finish.png"

const Finish = ({ step = 0, finishSetup = () => { } }) => {
    const title = "Configurações Iniciais Concluídas!"
    const description = "Parabéns! Você concluiu com sucesso as configurações iniciais do CRM. Agora você está pronto(a) para começar a gerenciar seus clientes e oportunidades de vendas de forma eficaz."
    const btnText = "Concluir"

    return (
        <ProgressSetup
            step={step}
            title={title}
            description={description}
            srcImage={image}
            altImage="Processo Finalizado!"
            btnText={btnText}
            onClick={finishSetup}
        />
    )
}

export default Finish