import React, { useState } from 'react'
import styles from "./list.module.css"
import { adjustTextByIndentation } from '../../../../utils/utils'
import Button from './../../../Form/Button'
import ModalInfo from './../../../Modal/ModalInfo'
import { MdOutlineFormatListBulleted } from 'react-icons/md'

const List = ({ content }) => {
    const [showOptions, setShowOptions] = useState(false)

    const handleOpenOptions = () => {
        setShowOptions(true)
    }

    const handleCloseOptions = () => {
        setShowOptions(false)
    }

    const getOptionsByList = (sections) => (
        sections?.map(section => (
            <ul className={styles.options}>
                {section?.rows?.map(row => (
                    <li className={styles.option}>
                        <span>{row?.title || ""}</span>
                        <span>{row?.description || ""}</span>
                    </li>
                ))}
            </ul>
        ))
    )

    return (
        <div className={styles.container}>
            <span className={styles.title}>{content?.title || ""}</span>
            <span className={styles.description}>{adjustTextByIndentation(content?.description || "")}</span>
            <Button className={styles.button} onClick={handleOpenOptions}><MdOutlineFormatListBulleted /> {content?.buttonText || "Selecione uma opção"}</Button>
            {showOptions &&
                <ModalInfo
                    title={content?.title || "Opções da Lista"}
                    content={getOptionsByList(content?.sections)}
                    closeModal={handleCloseOptions}
                />
            }
        </div>
    )
}

export default List
