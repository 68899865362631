import React, { useState } from 'react'
import styles from "./settings.module.css"
import { MdOutlineFlag, MdOutlineList, MdOutlinePersonOff, MdOutlineRoute } from 'react-icons/md'
import { LuTextCursorInput } from 'react-icons/lu'
import { TbSettings } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import AdditionalFieldsSettings from './AdditionalFieldsSettings'
import FlagsSettings from './FlagsSettings'
import FunnelsSettings from './FunnelsSettings'
import OriginsSettings from './OriginsSettings'
import ProductsSettings from './ProductsSettings'
import ReasonsDiscardSettings from './ReasonsDiscardSettings'
import { BsFunnel } from 'react-icons/bs'
import ChatFlagsSettings from './ChatFlagsSettings'

const Settings = () => {
    const { account_id } = useParams()
    const [activeOption, setActiveOption] = useState(1)

    const optionsList = [
        { id: 1, name: "Cadastro de Produtos", icon: <MdOutlineList />, },
        { id: 2, name: "Motivos de Descartes", icon: <MdOutlinePersonOff /> },
        { id: 3, name: "Origens", icon: <MdOutlineRoute /> },
        { id: 4, name: "Campos Adicionais", icon: <LuTextCursorInput /> },
        { id: 5, name: "Etiquetas", icon: <MdOutlineFlag /> },
        { id: 6, name: "Etiquetas do Chat", icon: <MdOutlineFlag /> },
        { id: 7, name: "Funis", icon: <BsFunnel /> },
    ]

    return (
        <div className={styles.content}>
            <div className={styles.card}>
                <div className={styles.left}>
                    <div className={styles.header}>
                        <TbSettings />
                        <span>Configurações da Conta</span>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.options}>
                            {optionsList && optionsList.map(option => (
                                <div className={`${styles.option} ${activeOption === option.id ? styles.active : ''}`} key={option.id} onClick={() => setActiveOption(option.id)}>
                                    {option.icon}
                                    <span>{option.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.body}>
                        {activeOption === 1 && <ProductsSettings account_id={account_id} />}
                        {activeOption === 2 && <ReasonsDiscardSettings account_id={account_id} />}
                        {activeOption === 3 && <OriginsSettings account_id={account_id} />}
                        {activeOption === 4 && <AdditionalFieldsSettings account_id={account_id} />}
                        {activeOption === 5 && <FlagsSettings account_id={account_id} />}
                        {activeOption === 6 && <ChatFlagsSettings account_id={account_id} />}
                        {activeOption === 7 && <FunnelsSettings account_id={account_id} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings