import api from "../services/api"
import { toast } from "react-toastify"

const getRoles = async () => {
    try {
        const response = await api.get(`/roles/onlyCrm`)
        const roles = response.data?.data
        return roles
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getRoles }