import React from "react"
import styles from "./flag.module.css"

const Flag = ({ name, color }) => {
    const isColorDark = (hexColor) => {
        hexColor = hexColor.replace("#", "")

        let r = parseInt(hexColor?.substring(0, 2), 16)
        let g = parseInt(hexColor?.substring(2, 4), 16)
        let b = parseInt(hexColor?.substring(4, 6), 16)

        let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

        if (luminance <= 0.5) {
            return "light"
        } else {
            return "dark"
        }
    }

    return (
        <div className={`${styles.flag} ${styles[isColorDark(color)]}`} style={{ backgroundColor: color }}>
            <span>{name}</span>
        </div>
    )
}

export default Flag