import { createContext, useContext, useEffect, useState } from "react";

const ModalContext = createContext()

const ModalProvider = ({ children }) => {
    const [listModal, setListModal] = useState([]);

    const addCloseModalByList = (onCloseModal) => {
        const id = Date.now()

        setListModal(previousValue => {
            const isAlreadyAdded = previousValue.some(modal => modal.close === onCloseModal)

            if (!isAlreadyAdded) {
                return [...previousValue, { id, close: onCloseModal }]
            }

            return previousValue
        })

        return id
    }

    const closeModalById = (id) => {
        setListModal(previousValue => {
            const modalToClose = previousValue.find(modal => modal.id === id)
            if (modalToClose) {
                setTimeout(() => {
                    modalToClose.close()
                }, 50)
            }
            return previousValue?.filter(modal => modal.id !== id)
        })

    }

    const closeLastModalByList = () => {
        setListModal(previousValue => {
            if (previousValue.length > 0) {
                const lastModal = previousValue[previousValue.length - 1]
                setTimeout(() => {
                    lastModal.close()
                }, 50)
                return previousValue.slice(0, -1)
            }
            return previousValue
        })
    }

    useEffect(() => {
        const handleKeyPressed = (e) => {
            if (e.key === 'Escape' && listModal.length > 0) {
                closeLastModalByList()
            }
        }

        window.addEventListener('keydown', handleKeyPressed)
        return () => {
            window.removeEventListener('keydown', handleKeyPressed)
        }
    }, [listModal])

    return (
        <ModalContext.Provider value={{ addCloseModalByList, closeModalById }}>
            {children}
        </ModalContext.Provider>
    )
}

const useModal = () => {
    const context = useContext(ModalContext)

    return context
}

export { ModalProvider, useModal }

export default ModalContext