import React, { useEffect, useState } from "react"
import BreakForm from "../../Form/BreakForm"
import { HiPlus } from "react-icons/hi"
import styles from "./notes.module.css"
import CardNote from "./CardNote"
import { toast } from "react-toastify"
import moment from "moment"
import api from '../../../services/api'
import DatePicker from '../../Form/DatePicker/index'
import Select from '../../Form/Select'
import { useWebSocketContext } from "../../../contexts/webSocketContext"
import Loading from "../../../pages/layouts/Loading"
import CircleLoading from "../../CircleLoading"

const Notes = ({ lead_id, onlyFiles = false }) => {
    const initialNewNote = { isNew: true }
    const [newNote, setNewNote] = useState(initialNewNote)
    const [showNewNote, setShowNewNote] = useState(false)
    const [notes, setNotes] = useState([])
    const [filters, setFilters] = useState({})
    const [usersFilters, setUsersFilters] = useState([])
    const { lastJsonMessage } = useWebSocketContext()
    const [updateFiltersSeleted, setUpdateFiltersSeleted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)

    useEffect(() => {
        getAllNotes()
    }, [])

    useEffect(() => {
        if (notes?.length > 0) {
            updateUsersFilters()
        }
    }, [notes])

    useEffect(() => {
        if (updateFiltersSeleted) {
            setFilters(previousValue => {
                const usersSelected = usersFilters?.map(user => { return user.id })
                return { ...previousValue, users: usersSelected }
            })
            setUpdateFiltersSeleted(false)
        }
    }, [usersFilters])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "ADD_NOTE_LEAD") {
            const leadId = message?.data?.lead_id
            const dataUpdate = message?.data?.data
            if (leadId === lead_id) {
                if (!onlyFiles || dataUpdate?.files?.length > 0) {
                    setNotes(previousValue => {
                        return [...previousValue, dataUpdate]
                    })
                }
            }
        }
    }

    const updateUsersFilters = () => {
        if (!filters?.users?.length || usersFilters.length === filters?.users?.length) {
            setUpdateFiltersSeleted(true)
        }
        const users = []
        notes?.forEach(note => {
            const [userExists] = users?.filter(user => user.id === note.user_id)
            if (!userExists) {
                users.push({
                    id: note.user_id, value: note.user_created
                })
            }
        })
        users.sort((a, b) => {
            if (a.value > b.value) {
                return 1
            } else if (a.value < b.value) {
                return -1
            }
            return 0
        })
        setUsersFilters(users)
    }

    const handleCancelNewNote = () => {
        setNewNote(initialNewNote)
        setShowNewNote(false)
    }

    const handleSubmitNewNote = async (data) => {
        try {
            setLoading(true)
            const formData = new FormData()
            Object.keys(data)?.map(key => {
                if (Array.isArray(data[key])) {
                    data[key]?.map(el => {
                        formData.append(key, el)
                    })
                } else {
                    formData.append(key, data[key])
                }
            })
            const response = await api.post(`/leads/${lead_id}/notes`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            const newTask = response?.data?.data
            setNotes(previousValue => {
                return [...previousValue, newTask]
            })
            handleCancelNewNote()
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const getAllNotes = () => {
        setInitialLoading(true)
        const url = onlyFiles ? `/leads/${lead_id}/notes/files` : `/leads/${lead_id}/notes`
        api.get(url).then(response => {
            const notes = response?.data?.data
            notes?.map(note => {
                setUsersFilters(previousValue => {
                    const newValue = [...previousValue]
                    const [userExists] = newValue?.filter(user => user.id === note.user_id)
                    if (!userExists) {
                        newValue.push({
                            id: note.user_id, value: note.user_created
                        })
                    }
                    return newValue
                })
            })
            setNotes(notes || [])
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setInitialLoading(false))
    }

    const orderNotes = (a, b) => {
        if (moment(a.created_at).unix() < moment(b.created_at).unix()) {
            return 1
        } else if (moment(a.created_at).unix() > moment(b.created_at).unix()) {
            return -1
        }
        return 0
    }

    const filterNotes = (note) => {
        const dateNote = moment(note.created_at).format("YYYY-MM-DD")
        const validPeriod = (!filters.dateStart || moment(filters.dateStart).isSameOrBefore(dateNote)) && (!filters.dateEnd || moment(filters.dateEnd).isSameOrAfter(dateNote))
        const validUsers = !filters.users || filters.users.indexOf(note.user_id) !== -1
        return validPeriod && validUsers
    }

    const getFilteredNotes = () => {
        const listNotes = notes?.filter(filterNotes)
        return listNotes
    }

    const onChangeFilters = (name, value) => {
        if (name === "period") {
            setFilters(previousValue => {
                return { ...previousValue, dateStart: value.dateStart, dateEnd: value.dateEnd }
            })
        } else {
            setFilters(previousValue => {
                return { ...previousValue, [name]: value }
            })
        }
    }

    return (
        <div className={styles.notes}>
            {!onlyFiles &&
                <div className={styles.activeNotes}>
                    <BreakForm title="Anotações" iconAction={<HiPlus />} styleAction="bg-pink" handleAction={() => { setShowNewNote(true) }} />
                    {showNewNote &&
                        <CardNote
                            value={newNote}
                            onSubmit={handleSubmitNewNote}
                            onCancel={handleCancelNewNote}
                        />
                    }
                    {!showNewNote && <span>Clique no botão "+" para adicionar uma anotação!</span>}
                </div>
            }
            <div className={styles.historyNotes}>
                <BreakForm title={onlyFiles ? "Histórico de arquivos" : "Histórico de anotações"} showAction={false} />
                {notes?.length > 0 &&
                    <div className={styles.filters}>
                        <DatePicker name="Período" dateStart={filters.dateStart} dateEnd={filters.dateEnd} onChange={(dateStart, dateEnd) => onChangeFilters("period", { dateStart, dateEnd })} multiple={true} />
                        <Select name="Responsável" placeholder="Selecione o responsável" selected={filters.users} options={usersFilters} onChange={(value) => onChangeFilters("users", value)} multiple={true} />
                    </div>
                }
                <div className={styles.body}>
                    {notes?.length > 0 && getFilteredNotes().sort(orderNotes).map(el => (
                        <CardNote
                            key={el._id}
                            value={el}
                        />
                    ))}
                    {!initialLoading && !notes?.length && (onlyFiles ? <span>Nenhum arquivo anexado até o momento!</span> : <span>Nenhuma anotação feita até o momento!</span>)}
                    {!initialLoading && (notes?.length > 0 && !getFilteredNotes().length > 0) && <span>Nenhum resultado para sua busca!</span>}
                    {initialLoading && <CircleLoading />}
                </div>
            </div>
            {loading && <Loading fullPage={true} />}
        </div >
    )
}

export default Notes