import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"
import ConfigTextListItems from "../../components/ConfigTextListItems"
import Loading from "../layouts/Loading"

const OriginsSettings = ({ account_id }) => {
    const [origins, setOrigins] = useState([])
    const [originsEditable, setOriginsEditable] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (account_id) {
            getAllOriginsNotDefault()
        }
    }, [account_id])

    const getAllOriginsNotDefault = () => {
        setLoading(true)
        api.get("/origins/not-default").then(response => {
            const listOrigins = response?.data?.data || []
            setOrigins(listOrigins)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleUpdateOrigins = (e, _, origins) => {
        e.preventDefault()
        setLoading(true)
        api.patch("/origins", { origins }).then(response => {
            const newOrigins = response?.data?.data
            if (newOrigins) {
                setOrigins(newOrigins)
            }
            handleToogleEditOrigins(e)
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleToogleEditOrigins = (e) => {
        e.preventDefault()
        setOriginsEditable(previousValue => !previousValue)
    }

    return (
        <>
            <ConfigTextListItems
                title="Origens"
                placeholder="Origem"
                textNotItems="Nenhuma origem cadastrada até o momento!"
                value={origins}
                onSubmit={handleUpdateOrigins}
                onCancel={handleToogleEditOrigins}
                editable={originsEditable}
                setEdit={handleToogleEditOrigins}
            />
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default OriginsSettings