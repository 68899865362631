import React, { useEffect, useState } from "react";
import Welcome from "./Welcome";
import AddFirstFunnel from "./AddFirstFunnel";
import AddReasonsDiscard from "./AddReasonsDiscard";
import AddProducts from "./AddProducts";
import Finish from "./Finish";

const InitialSetup = ({ statusSetup, finishSetup, welcomeCompleted, setWelcomeCompleted, verifyInitialSetup }) => {
    const [defaultStep, setDefaultStep] = useState(false)

    useEffect(() => {
        getProgress()
    }, [statusSetup])

    const nextStep = () => {
        verifyInitialSetup()
        setWelcomeCompleted(true)
    }

    const getProgress = (stepDefault = false) => {
        if (stepDefault && stepDefault >= 0) {
            return listSetup[stepDefault]
        }
        let newStep = 0
        if (welcomeCompleted || statusSetup?.funnelsExists || statusSetup?.reasonsDiscardExists || statusSetup?.productsExists) {
            newStep = 1
        }
        if (newStep === 1 && statusSetup?.funnelsExists && statusSetup?.stagesExists) {
            newStep = 2
        }
        if (newStep === 2 && statusSetup?.reasonsDiscardExists) {
            newStep = 3
        }
        if (newStep === 3 && statusSetup?.productsExists) {
            newStep = 4
        }
        return listSetup[newStep]
    }

    const changeToFinishPage = () => {
        verifyInitialSetup(true)
    }

    const listSetup = [
        <Welcome nextStep={nextStep} step={1} />,
        <AddFirstFunnel nextStep={changeToFinishPage} funnel_id={statusSetup?.funnelsExists ? statusSetup?.funnel_id : undefined} step={2} />,
        <AddReasonsDiscard nextStep={changeToFinishPage} step={3} />,
        <AddProducts setDefaultStep={setDefaultStep} changeToFinishPage={changeToFinishPage} step={4} />,
        <Finish finishSetup={finishSetup} step={5} />,
    ]

    return (
        <>
            {statusSetup && getProgress(defaultStep)}
        </>
    )
}

export default InitialSetup