import React from "react";
import styles from './form.module.css'

const Button = ({ className, success, type, disabled, onClick, children }) => {
    const stylesButton = success ? styles.success : ''
    return (
        <button type={type} className={`${styles.button} ${stylesButton} ${className}`} onClick={onClick} disabled={disabled}>{children}</button>
    )
}

export default Button