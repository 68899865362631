import React, { useCallback, useEffect, useState } from "react"
import styles from "./notes.module.css"
import Table from "../../components/Table"
import api from "../../services/api"
import { toast } from "react-toastify"
import Select from "../../components/Form/Select"
import { maskDate } from "../../utils/formatter"
import DatePicker from '../../components/Form/DatePicker/index'
import Button from "../../components/Form/Button"
import Loading from '../layouts/Loading/index'
import { useCrm } from "../../contexts/crmContext"
import { useWebSocketContext } from "../../contexts/webSocketContext"
import { useData } from "../../contexts/dataContext"
import moment from "moment"
import imageFilterNote from "../../images/filter-note.png"
import BackgroundSelectFilter from "../../components/BackgroundSelectFilter"

const initialFilters = { status: [1, 2], dateStart: moment().subtract(7, 'd').format("YYYY-MM-DD"), dateEnd: moment().subtract(1, 'd').format("YYYY-MM-DD") }

const Notes = () => {
    const [notes, setNotes] = useState([])
    const [maxNotes, setMaxNotes] = useState(0)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState(initialFilters)
    const [isLoading, setIsLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [backgroundFilter, setBackgroundFilter] = useState(true)

    let isLoadingNote = false

    const { openModalLead } = useCrm()
    const { teams, users } = useData()
    const { lastJsonMessage } = useWebSocketContext()

    const headers = [
        "#",
        "Data",
        "Nome do Lead",
        "Criado por",
        "Título",
        "Descrição",
    ]

    useEffect(() => {
        if (loadMore) {
            getAllNotesByFilters()
        }
    }, [loadMore])

    useEffect(() => {
        const teamsIds = teams?.map(team => { return team.id })
        const usersIds = users?.map(user => { return user.id })
        setFilters(previousValue => {
            return { ...previousValue, teams: teamsIds, users: usersIds }
        })
    }, [teams, users])

    useEffect(() => {
        updateDataByMessageWebSocket(lastJsonMessage)
    }, [lastJsonMessage])

    const updateDataByMessageWebSocket = (message) => {
        const action = message?.action ?? undefined
        if (action === "UPDATE_TASK_LEAD") {
            const dataUpdate = message?.data?.data
            const noteId = dataUpdate?._id
            if (dataUpdate && noteId) {
                updateNoteById(dataUpdate, noteId)
            }
        }
    }

    const updateNoteById = (newNote, noteId) => {
        setNotes(previousValue => {
            return previousValue?.map(note => {
                if (note._id === noteId) {
                    return { ...note, ...newNote }
                }
                return note
            })
        })
    }

    const getAllNotesByFilters = async (newFind = false) => {
        try {
            if (!isLoadingNote) {
                isLoadingNote = true
                if (newFind) {
                    setIsLoading(true)
                }
                const queryString = new URLSearchParams(filters)?.toString()
                const response = await api.get(`/notes?${queryString}&page=${newFind ? 1 : page}`)
                const data = response.data?.data?.notes || []
                if (newFind) {
                    const maxNotes = response.data?.data?.maxNotes || 0
                    setNotes(data)
                    setMaxNotes(maxNotes)
                } else {
                    setNotes(previousValue => {
                        return [...previousValue, ...data]
                    })
                }
                setIsLoading(false)
                setPage(prev => newFind ? 2 : prev + 1)
                setLoadMore(false)
                setBackgroundFilter(false)
                isLoadingNote = false
            }
        } catch (err) {
            setIsLoading(false)
            setLoadMore(false)
            toast.error(err?.response?.data?.message)
            isLoadingNote = false
        }
    }

    const getDataNewNote = (newNote) => {
        return {
            id: newNote._id,
            value: [
                maskDate(newNote.created_at, true) || "-",
                newNote?.lead?.name || "-",
                newNote.user?.name || "-",
                newNote?.title ? `${newNote?.title?.substring(0, 40) || ""}${newNote?.title?.length > 40 ? "..." : ""}` : '',
                newNote?.description ? `${newNote?.description?.substring(0, 40) || ""}${newNote?.description?.length > 40 ? "..." : ""}` : '',
            ]
        }
    }

    const getFilteredValues = () => {
        const values = notes
        values.sort((a, b) => {
            if (a.scheduled_date > b.scheduled_date) {
                return 1
            } else if (a.scheduled_date < b.scheduled_date) {
                return - 1
            } else {
                return 0
            }
        })

        const filteredValues = values?.map((call) => getDataNewNote(call))
        return filteredValues
    }

    const handleChangeFilters = (name, value) => {
        if (name === "period") {
            const dateStart = value.dateStart
            const dateEnd = value.dateEnd
            setFilters(previousValue => { return { ...previousValue, dateStart, dateEnd } })
        } else {
            setFilters(previousValue => { return { ...previousValue, [name]: value } })
        }
    }

    const handleOpenModalLead = (noteId) => {
        const [note] = notes?.filter(note => note._id === noteId)
        if (note) {
            const leadId = note.lead_id
            openModalLead(leadId)
        }
    }

    const handleScrollTable = useCallback(() => {
        if (!loadMore && maxNotes > notes?.length) {
            setLoadMore(true)
        }
    }, [maxNotes, notes, loadMore])

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <Select multiple={true} name="Equipe" placeholder="Buscar..." options={teams} onChange={(value) => handleChangeFilters("teams", value)} selected={filters.teams ?? []} required={true} />
                <Select multiple={true} name="Usuário" placeholder="Buscar..." options={users} onChange={(value) => handleChangeFilters("users", value)} selected={filters.users ?? []} required={true} />
                <DatePicker multiple={true} name="Período" dateStart={filters.dateStart ?? ""} dateEnd={filters.dateEnd ?? ""} onChange={(dateStart, dateEnd) => handleChangeFilters("period", { dateStart, dateEnd })} />
                <Button className="bg-pink" onClick={getAllNotesByFilters} disabled={isLoading}>Filtrar</Button>
            </div>
            {!backgroundFilter &&
                <Table
                    headers={headers}
                    values={getFilteredValues()}
                    onClick={(id) => handleOpenModalLead(id)}
                    onScroll={handleScrollTable}
                    loadMore={loadMore}
                    messageNotRegisters="Nenhum registro encontrado"
                    showIndex={true}
                    showCount={true}
                    count={maxNotes}
                />
            }
            {backgroundFilter &&
                <BackgroundSelectFilter
                    text="Selecione os filtros desejados e aplique para visualizar os dados!"
                    image={imageFilterNote}
                />
            }
            {isLoading && <Loading fullPage={true} />}
        </div>
    )
}

export default Notes