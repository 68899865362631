import React from "react";
import styles from "./form.module.css"

const FormGroupInline = ({ btnAction, uniqueInput, style = {}, children }) => {
    return (
        <div className={`${styles.formGroupInline} ${btnAction ? styles.btnAction : ''} ${uniqueInput ? styles.uniqueInput : ''}`} style={style}>
            {children}
        </div>
    )
}

export default FormGroupInline