import React, { useState } from "react"
import Modal from '../index'
import Input from '../../Form/Input'
import FormGroup from "../../Form/FormGroup"
import Button from "../../Form/Button"
import FormGroupInline from '../../Form/FormGroupInline'
import { HiOutlinePencil } from "react-icons/hi"
import { toast } from "react-toastify"

const ModalEdit = ({ value, onSubmit = () => { }, closeModal = () => { } }) => {
    const [text, setText] = useState(value)

    const handleChangeValue = (newText) => {
        setText(newText)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    const handleSave = (e) => {
        e.preventDefault()
        if (text?.trim()) {
            onSubmit(text)
            closeModal()
        } else {
            toast.error("Preencha todos os campos para prosseguir!")
        }
    }

    return (
        <Modal
            icon={<HiOutlinePencil />}
            title={"Editar Mensagem"}
            description={"Preencha o campo abaixo e clique em salvar"}
            closeModal={closeModal}
        >
            <form onSubmit={handleSave}>
                <FormGroup labelText="Nova mensagem">
                    <Input type="text" placeholder="Nova mensagem" value={text ?? ""} onChange={(e) => handleChangeValue(e.target.value)} autoFocus={true} />
                </FormGroup>
                <FormGroupInline>
                    <Button className='bg-pink' type="submit">Salvar</Button>
                    <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                </FormGroupInline>
            </form>
        </Modal>
    )
}

export default ModalEdit