import React from "react";
import styles from "./sideFilter.module.css"
import { MdOutlineArrowForward, MdOutlineManageSearch } from "react-icons/md";
import Select from "../../../components/Form/Select";
import FormGroup from "../../../components/Form/FormGroup";
import Button from "../../../components/Form/Button";
import Input from "../../../components/Form/Input";
import DatePicker from "../../../components/Form/DatePicker";
import FormGroupInline from "../../../components/Form/FormGroupInline";
import { useAuth } from "../../../contexts/authContext";
import { useData } from "../../../contexts/dataContext";

const SideFilter = ({ filters = {}, handleChange = () => { }, cleanFilters = () => { }, onSubmit = () => { }, onClose = () => { } }) => {
    const { teams, users, products, origins, campaigns, flagsByoptions, typeDate, status } = useData()

    const { permissions } = useAuth()

    const onChangePeriod = (dateStart, dateEnd) => {
        handleChange("dateStart", dateStart)
        handleChange("dateEnd", dateEnd)
    }

    return (
        <div className={styles.background} onClick={onClose}>
            <div className={styles.sideFilter} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <div className={styles.description}>
                        <MdOutlineManageSearch />
                        <span>Busca por filtros</span>
                    </div>
                    <div className={styles.close} onClick={onClose}>
                        <MdOutlineArrowForward />
                    </div>
                </div>
                <div className={styles.body}>
                    <FormGroup labelText="Busca por texto">
                        <Input type="search" placeholder="Buscar" value={filters.search || ""} onChange={(e) => handleChange("search", e.target.value)} />
                    </FormGroup>
                    <FormGroup labelText="Período">
                        <DatePicker name="Período" dateStart={filters.dateStart || ""} dateEnd={filters.dateEnd || ""} onChange={(dateStart, dateEnd) => onChangePeriod(dateStart, dateEnd)} multiple={true} />
                    </FormGroup>
                    <FormGroup labelText="Filtro por">
                        <Select name="Filtro por data" placeholder="Pesquise uma opção" options={typeDate || ""} selected={filters.typeDate || []} onChange={(value) => handleChange("typeDate", value)} multiple={false} />
                    </FormGroup>
                    <FormGroup labelText="Produto">
                        <Select name="Produtos" placeholder="Pesquise um produto" options={products || []} selected={filters.products || []} onChange={(value) => handleChange("products", value)} multiple={true} />
                    </FormGroup>
                    <FormGroup labelText="Origem">
                        <Select name="Origens" placeholder="Pesquise uma origem" options={origins || []} selected={filters.origins || []} onChange={(value) => handleChange("origins", value)} multiple={true} />
                    </FormGroup>
                    <FormGroup labelText="Etiqueta">
                        <Select name="Etiquetas" placeholder="Pesquise uma etiqueta" options={flagsByoptions || []} selected={filters.flags || []} onChange={(value) => handleChange("flags", value)} multiple={true} />
                    </FormGroup>
                    <FormGroup labelText="Campanha">
                        <Select name="Campanhas" placeholder="Pesquise uma campanha" options={campaigns || []} selected={filters.campaigns || []} onChange={(value) => handleChange("campaigns", value)} multiple={true} />
                    </FormGroup>
                    <FormGroup labelText="Status">
                        <Select name="Status" placeholder="Pesquise um status" options={status || []} selected={filters.status || []} onChange={(value) => handleChange("status", value)} multiple={true} />
                    </FormGroup>
                    <FormGroup labelText="Equipe">
                        <Select name="Equipes" placeholder="Pesquise uma equipe" options={teams || []} selected={filters.teams || []} onChange={(value) => handleChange("teams", value)} multiple={true} />
                    </FormGroup>
                    {permissions?.crm > 1 &&
                        <FormGroup labelText="Responsável">
                            <Select name="Responsáveis" placeholder="Pesquise um responsável" options={users || []} selected={filters.users || []} onChange={(value) => handleChange("users", value)} multiple={true} />
                        </FormGroup>
                    }
                    <FormGroupInline>
                        <Button className="bg-purple" onClick={cleanFilters}>Limpar Dados</Button>
                        <Button className="bg-pink" onClick={onSubmit}>Filtrar</Button>
                    </FormGroupInline>
                </div>
            </div>
        </div>
    )
}

export default SideFilter