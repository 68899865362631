import React, { useState } from "react"
import styles from "./cardNote.module.css"
import { MdBlock, MdOutlineCalendarToday, MdOutlineCheck } from "react-icons/md"
import moment from "moment"
import FormGroup from "../../../Form/FormGroup"
import Button from "../../../Form/Button"
import Input from "../../../Form/Input"
import Textarea from "../../../Form/Textarea"
import InputFile from '../../../Form/InputFile/index'
import { HiOutlineExternalLink } from "react-icons/hi";
import { adjustTextByIndentation, getIconByExtensionFile } from "../../../../utils/utils"

const CardNote = ({ value = {}, onSubmit = () => { }, onCancel = () => { }, onUpdate = () => { } }) => {
    const [data, setData] = useState(value)
    const [edit, setEdit] = useState(data.isNew || false)

    const onChangeValues = (name, value) => {
        setData(previousValue => {
            return { ...previousValue, [name]: value }
        })
    }

    const handleSubmit = () => {
        setEdit(false)
        onSubmit(data).catch(() => {
            setEdit(true)
        })
    }

    return (
        <div className={styles.card}>
            <div className={`${styles.header} ${styles.bottomBorder}`}>
                <div className={styles.left}>
                    {data.isNew ?
                        <span className={styles.name}>Nova Anotação</span>
                        :
                        <>
                            <span>Criado por: </span><span className={styles.name}>{data.user_created}</span>
                        </>
                    }
                </div>
                {!edit &&
                    <div className={styles.right}>
                        <MdOutlineCalendarToday />
                        <span>{moment(data.created_at).format("DD/MM/YYYY HH:mm:ss") || "xx/xx/xxxx xx:xx"}</span>
                    </div>
                }
            </div>
            <div className={`${styles.body} ${styles.bottomBorder}`}>
                <div className={styles.content}>
                    {edit ?
                        <>
                            <FormGroup labelText="Título">
                                <Input placeholder="Título da anotação" theme="noBorder" value={data.title || ''} onChange={(e) => onChangeValues("title", e.target.value)} />
                            </FormGroup>
                            <FormGroup labelText="Descrição">
                                <Textarea placeholder="Descrição da anotação" rows={3} theme="noBorder" value={data.description || ''} onChange={(e) => onChangeValues("description", e.target.value)} />
                            </FormGroup>
                            <FormGroup labelText="Anexos">
                                <InputFile multiple={true} value={data.files || []} onChange={(value) => onChangeValues("files", value)} />
                            </FormGroup>
                        </>
                        :
                        <>
                            <span className={styles.title}>{data.title || ''}</span>
                            <div className={styles.description}>{adjustTextByIndentation(data.description) || ''}</div>
                            {data?.files?.length > 0 &&
                                <FormGroup labelText="Anexos">
                                    <div className={styles.uploads}>
                                        {data.files.map((file, index) => (
                                            <a className={styles.file} href={file.link} target="_blank" rel="noreferrer" download={true} key={index}>
                                                {getIconByExtensionFile(file.name)}
                                                <span>{file.name}</span>
                                                <HiOutlineExternalLink />
                                            </a>
                                        ))}
                                    </div>
                                </FormGroup>
                            }
                        </>
                    }
                </div>
            </div>
            {
                edit &&
                <div className={styles.footer}>
                    <div className={`${styles.inlineGroup} ${styles.bottomBorder}`}>
                        <Button className="bg-pink" onClick={handleSubmit}>Salvar <MdOutlineCheck /></Button>
                        <Button className="bg-purple" onClick={onCancel}>Cancelar <MdBlock /></Button>
                    </div>
                </div >
            }
        </div >
    )
}

export default CardNote