import api from "../services/api"
import { toast } from "react-toastify"

const getTypesTask = async () => {
    try {
        const response = await api.get(`/typeTasks`)
        const types = response.data?.data
        types?.map(type => {
            type.value = type.name
            type.id = type._id
            return type
        })
        return types
    } catch (err) {
        toast.error(err?.response?.data?.message)
    }
}

export { getTypesTask }