import React from "react"
import styles from "./cardCall.module.css"
import { MdOutlineCalendarToday } from "react-icons/md"
import moment from "moment"
import FormGroup from "../../../Form/FormGroup"
import FormGroupExpand from "../../../Form/FormGroupExpand"
import { HiOutlineExternalLink } from "react-icons/hi"
import { adjustTextByIndentation, getIconByExtensionFile, getNameUserById } from "../../../../utils/utils"
import { formatSecondsToTime } from "../../../../utils/formatter"

const CardCall = ({ data = {}, listUsers = [] }) => {
    return (
        <div className={styles.card}>
            <div className={`${styles.header} ${styles.bottomBorder}`}>
                <div className={styles.left}>
                    <span>Realizado por: </span><span className={styles.name}>{getNameUserById(listUsers, data.user_id)}</span>
                </div>
                <div className={styles.right}>
                    <MdOutlineCalendarToday />
                    <span>{moment(data.created_at).format("DD/MM/YYYY HH:mm:ss") || "xx/xx/xxxx xx:xx"}</span>
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomBorder}`}>
                <div className={styles.content}>
                    <FormGroupExpand labelText={<span className={styles.title}>Detalhes</span>} startShow={true}>
                        <div className={styles.description}>{adjustTextByIndentation(`- **Número Chamado**: ${data.phone}\n- **Status**: ${data.duration > 0 ? "Atendida" : "Caixa Postal"}\n- **Duração**: ${formatSecondsToTime(data.duration)}`) || ''}</div>
                    </FormGroupExpand>
                    {data?.transcription?.full_text &&
                        <FormGroupExpand labelText={<span className={styles.title}>Transcrição</span>} >
                            <div className={styles.description}>{adjustTextByIndentation(data.transcription.full_text) || ''}</div>
                        </FormGroupExpand>
                    }
                    {data?.transcription?.summary_text &&
                        <FormGroupExpand labelText={<span className={styles.title}>Resumo</span>} >
                            <div className={styles.description}>{adjustTextByIndentation(data.transcription.summary_text) || ''}</div>
                        </FormGroupExpand>
                    }
                    {data?.url &&
                        <FormGroup labelText={<span className={styles.title}>Gravação</span>}>
                            <div className={styles.uploads}>
                                <a className={styles.file} href={data.url} target="_blank" rel="noreferrer" download={true}>
                                    {getIconByExtensionFile("audio.mp3")}
                                    <span>Gravação da chamada</span>
                                    <HiOutlineExternalLink />
                                </a>
                            </div>
                        </FormGroup>
                    }
                </div>
            </div>
        </div >
    )
}

export default CardCall