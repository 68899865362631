import React, { useState } from "react";
import Modal from './index';
import Input from '../Form/Input';
import FormGroup from "../Form/FormGroup";
import Button from "../Form/Button";
import { MdKey } from "react-icons/md";
import FormGroupInline from '../Form/FormGroupInline';
import Select from "../Form/Select";

const optionsExpire = [
    { id: 1, value: "1 hora" },
    { id: 8, value: "8 horas" },
    { id: 24, value: "24 horas" },
    { id: 72, value: "72 horas" },
    { id: 0, value: "Nunca" },
]

const ModalApiToken = ({ value = {}, newToken, showNewToken, onSubmit, closeModal }) => {
    const [data, setData] = useState(value)

    const handleChangeValue = (name, value) => {
        setData(previousValue => { return { ...previousValue, [name]: value } })
    }

    const handleCancel = (e) => {
        e.preventDefault()
        closeModal()
    }

    return (
        <Modal
            icon={<MdKey />}
            title={!showNewToken ? "Cadastro de novo token" : "Esse é o seu novo token"}
            description={!showNewToken ? "Registre um novo token para utilizar em sua API" : "Por motivos de segurança não será possível visualizar esse token novamente! Salve ele em um local seguro, e não compartilhe com ninguém. "}
            closeModal={closeModal}
        >
            {!showNewToken &&
                <form onSubmit={(e) => onSubmit(e, data)}>
                    <FormGroup labelText="Identificação">
                        <Input type="text" placeholder="Identificação do token" value={data?.name ?? ""} onChange={(e) => handleChangeValue("name", e.target.value)} />
                    </FormGroup>
                    <FormGroup labelText="O token expira em">
                        <Select name="Expira em" placeholder="Pesquise uma opção" multiple={false} options={optionsExpire} selected={data?.expire_in ?? ""} onChange={(value) => handleChangeValue("expire_in", value)} />
                    </FormGroup>
                    <FormGroupInline>
                        <Button className='bg-pink' type="submit">Salvar</Button>
                        <Button className='bg-purple' onClick={handleCancel}>Cancelar</Button>
                    </FormGroupInline>
                </form>
            }
            {showNewToken &&
                <FormGroup labelText="Token">
                    <span>{newToken ?? ""}</span>
                </FormGroup>
            }
        </Modal>
    )
}

export default ModalApiToken