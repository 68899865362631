import React from "react"
import styles from "./menuToogle.module.css"

const MenuToogle = ({ options = [], activeOption, setActiveOption = () => { } }) => {
    return (
        <div className={styles.menuToogle}>
            {options && options.map(option => (
                <span key={option.id} className={option.id === activeOption ? styles.active : ''} onClick={() => { setActiveOption(option.id) }}>{option.value}</span>
            ))}
        </div>
    )
}

export default MenuToogle