import React from "react"
import styles from "./statusPaid.module.css"
import imagePaid from "../../../../images/payment_approved.png"
import imageInvalid from "../../../../images/invalid.png"

const StatusPaid = ({ paid = false, invalid = false }) => {
    return (
        <div className={styles.body}>
            <span className={styles.title}>
                {paid && "Parabéns, o pagamento já foi realizado!"}
                {invalid && "Não foi possível visualizar a cobrança! Por gentileza entre em contato com o suporte."}
            </span>
            <div className={styles.icon}>
                {paid && <img alt="Cobrança Paga" src={imagePaid} />}
                {invalid && <img alt="Cobrança Inválida" src={imageInvalid} />}
            </div>
            {paid &&
                <div className={styles.groupLink}>
                    <span className={styles.description}>
                        A nota fiscal foi enviada via e-mail, e também está disponível na aba "Minha conta"!
                    </span>
                </div>
            }
        </div>
    )
}

export default StatusPaid