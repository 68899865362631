import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { toast } from "react-toastify"
import ConfigTextListItems from "../../components/ConfigTextListItems"
import Loading from "../layouts/Loading"
import { getAllFlags } from "../../schemas/Chat"

const ChatFlagsSettings = ({ account_id }) => {
    const [flags, setFlags] = useState([])
    const [flagsEditable, setFlagsEditable] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (account_id) {
            getAllFlagsNotDefault()
        }
    }, [account_id])

    const getAllFlagsNotDefault = async () => {
        try {
            setLoading(true)
            const listFlags = await getAllFlags()
            setFlags(listFlags)
        } catch (err) {
            toast.error(err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateFlags = (e, _, flags) => {
        e.preventDefault()
        setLoading(true)
        api.patch("/chat/flags", { flags }).then(response => {
            const newFlags = response?.data?.data
            if (newFlags) {
                setFlags(newFlags)
            }
            handleToogleEditFlags(e)
            toast.success(response?.data?.message)
        }).catch(err => toast.error(err?.response?.data?.message)).finally(() => setLoading(false))
    }

    const handleToogleEditFlags = (e) => {
        e.preventDefault()
        setFlagsEditable(previousValue => !previousValue)
    }

    return (
        <>
            <ConfigTextListItems
                title="Etiquetas do Chat"
                placeholder="Etiqueta"
                textNotItems="Nenhuma etiqueta cadastrada até o momento!"
                value={flags}
                onSubmit={handleUpdateFlags}
                onCancel={handleToogleEditFlags}
                editable={flagsEditable}
                setEdit={handleToogleEditFlags}
                colorSelect={true}
            />
            {loading && <Loading fullPage={true} />}
        </>
    )
}

export default ChatFlagsSettings