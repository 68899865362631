import React from "react";
import styles from "./progressSetup.module.css"
import Button from "../../../../components/Form/Button";
import { MdBuild, MdOutlineCheck } from "react-icons/md";

const ProgressSetup = ({ step = 0, title, description, srcImage, altImage, btnText, btnAltText, onClick = () => { }, onClickAlt = () => { } }) => {
    const getProgressBar = (step) => {
        const progressList = [1, 2, 3, 4]
        return (
            <div className={styles.progressBar}>
                {progressList.map((el, index) => (
                    <div className={`${styles.groupStep} ${el < step ? styles.finish : (el == step ? styles.setting : '')}`} key={index}>
                        {el > 1 && <div className={styles.connector}></div>}
                        <div className={styles.step}>{el < step ? <MdOutlineCheck /> : (el == step ? <MdBuild /> : '')}</div>
                        {el < progressList.length && <div className={styles.connector}></div>}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={styles.progressSetup}>
            {getProgressBar(step)}
            <div className={styles.text}>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.description}>{description}</p>
            </div>
            <div className={styles.image}>
                <img src={srcImage} alt={altImage} />
            </div>
            <div className={styles.button}>
                <Button className="bg-pink" onClick={onClick}>{btnText}</Button>
                {btnAltText && <Button className="bg-purple" onClick={onClickAlt}>{btnAltText}</Button>}
            </div>
        </div>
    )
}

export default ProgressSetup